import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AlertService, MemberListService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../_services/encryption.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styles: []
})
export class MemberListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable

  memberList: any[] = []; // Table Data list
  closeResult: string; // Modal Close
  memberListTotal: any;


  constructor(
              public router: Router,
              private dataService:MemberListService,
              private alertService: AlertService,
              private AppService: AppService,
              private modalService: NgbModal,
              private http: HttpClient,
              private enc_dec: EncryptionService,
            ) { }
    
  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    this.getAllMember();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loadHide();
  }

  getAllMember() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            '/api/all-member',
            { 'req_data': this.enc_dec.encrypt(JSON.stringify(Object.assign(dataTablesParameters))) }, {}
          ).subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "get all member");
  
            this.memberList = resp['data'];
            this.memberListTotal = resp['recordsTotal'];
  
            const start = dataTablesParameters.start;
            this.memberList = this.memberList.map((item, index) => ({
              ...item,
              index_id: start + index + 1
            }));
  
            callback({
              recordsTotal: resp['recordsTotal'],
              recordsFiltered: resp['recordsFiltered'],
              data: []
            });
          });
      },
      columns: [
        { data: 'index_id' }, 
        { data: 'name' },
        { data: 'phone' },
        { data: 'email' },
        { data: 'UUID' }
      ]
    };
  }
  

  // getAllMember(){
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     pageLength: 10,
  //     serverSide: true,
  //     processing: true,
  //     ajax: (dataTablesParameters: any, callback) => {
  //       this.http
  //         .post<DataTablesResponse>(
  //           '/api/all-member',
  //           { 'req_data': this.enc_dec.encrypt(JSON.stringify(Object.assign(dataTablesParameters))) }, {}
  //         ).subscribe((response:any) => {
  
  //           const output = this.enc_dec.decrypt(response.res_data);
  //           var resp = JSON.parse(output);
  //           //console.log(resp,"get all member");
            
  //           this.memberList = resp['data'];
  //           this.memberListTotal = resp['recordsTotal'];
            
  //           callback({
  //             recordsTotal: resp['recordsTotal'],
  //             recordsFiltered: resp['recordsFiltered'],
  //             data: []
  //           });
  //         });
  //       },
  //     columns: [{ data: 'id' }, { data: 'name' }, { data: 'phone' }, { data: 'email' }, { data: 'UUID' }]
  //   };    
  // }

  
  openMemberListViewPage(id) {
    this.router.navigate(['member-view/' + id]);
  }


  onCloseButtonClick(){
     this.modalService.dismissAll('Cross click');  
  }
  


  allMember(){
    this.loadShow();
    this.dataService.getAllMember()
        .pipe().subscribe(
          data => { this.memberList = data['member'];
          ////console.log(data['member']);
          // this.dtTrigger.next(); // Data Table          
          this.rerender();
          this.loadHide();
        });
     }

  openMemberViewPage(id) {
    this.router.navigate(['member-view/' + id]);
  }

  
  delete(id){
    if(confirm("Are you sure you want to active/inactive the member")) {
      this.dataService.deleteMember(id)
          .pipe().subscribe(data =>{
            if(data['status']==200){
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('Member status updated successfully', true);
              this.getAllMember();
              window.location.reload();

            }else{
              this.alertService.error('Member error');
            }
          },error =>{
            this.alertService.error(error);
          });
        }
      }

      
  
  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
