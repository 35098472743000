<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12 headboxes">
      <div class="card p-0">
        <div class="card-block p-0">
          <div class="heading-box" style="background-image: url(assets/img/camp.png);">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="pagename">
                  <h3 class="card-title">Community Opinion Polls</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="total-count">
                  <h5 class="m-0">Total</h5>
                  <h2>{{totalOpinionPolls ?  totalOpinionPolls : 0}}</h2>
                </div>
              </div>
              <div class="col-md-3">
                <div class="addbtn">
                  <button id="add_btn" type="button" class="btn btn-primary addbtn"
                    (click)="open(content)" style="float: right;">
                    Add Opinion Polls
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div id="table" class="table-responsive">
              <table datatable id="wecommunity"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
              <thead>
                <tr>
                  <th>#Sl</th>
                  <th>Question Name</th>
                  <th>Likes</th>
                  <th>Comments</th>
                  <th>Report Post</th>
                  <th>Report User</th>
                  <th>Votes</th>
                  <th>ClosingDate</th>
                  <th>Created At</th>
                  <!-- <th>Status</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="opinionPollList?.length != 0">
                <tr *ngFor="let item of opinionPollList;let i = index" [attr.data-index]="i">
                  <td>{{i + 1}}</td>
                  <td>{{item.Question}}</td>
                  <td>{{item.LikeCount}} &nbsp;
                    <button *ngIf="item?.LikeCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewLikes(item,viewlikes)"><i
                     class="fa fa-solid fa fa-list"></i></button>&nbsp;
                    
                   </td>
                  <td>{{item.CommentCount}}&nbsp;
                    <button *ngIf="item?.CommentCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewComments(item,viewcomments)"><i
                     class="fa fa-solid fa fa-list"></i></button>
                  </td>
                                

                   <td>{{item.ReportCount}}&nbsp;
                    <button *ngIf="item?.ReportCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewReportPost(item,reportpost)"><i
                     class="fa fa-solid fa fa-list"></i></button>
                  </td>  

                  <td>{{item.ReportUserCount}}&nbsp;
                    <button *ngIf="item?.ReportUserCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewReportUser(item,reportuser)"><i
                     class="fa fa-solid fa fa-list"></i></button>
                  </td> 
                  
                  <td>{{item.AnswerCount}}</td>
                  <td>{{item.ClosingDate}}</td>
                  
                  <td>{{item.CreatedDate}}</td>

                  <!-- <td><span *ngIf="item.isActive=='Y'"
                    class="badge badge-success badge-pill">Active</span> <span
                    *ngIf="item.isActive=='N'" class="badge badge-danger badge-pill">In
                    active</span></td> -->

                  <td class="action"> 

                    <label class="switch">
                      <input 
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.isActive === 'Y'">
                      <span class="slider round"></span>
                    </label>

                    <button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(item,content)"><i class="fa fa-edit"></i></button>&nbsp;

                    <button *ngIf="(item.AnswerTypeID == 1 || item.AnswerTypeID == 2) && item.AnswerCount != 0"
                    type="button" class="btn text-dark btn-sm rounded bg-light"
                    (click)="onView(item, viewchart, item.AnswerTypeID)">
              <i class="fa fa-eye"></i>
            </button>
            
            <button *ngIf="item.AnswerTypeID == 3 && item.AnswerCount != 0"
                    type="button" class="btn text-dark btn-sm rounded bg-light"
                    (click)="onView(item, viewslider, item.AnswerTypeID)">
              <i class="fa fa-eye"></i>
            </button>

            <button *ngIf="item.AnswerTypeID == 4 && item.AnswerCount != 0"
                    type="button" class="btn text-dark btn-sm rounded bg-light"
                    (click)="onView(item, comment, item.AnswerTypeID)">
              <i class="fa fa-eye"></i>
            </button>
            
                  </td> 
                </tr>
              </tbody>
              <tbody *ngIf="opinionPollList?.length == 0">
                <tr>
                  <td colspan="4" class="no-data-available text-center">No data available!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> 
  </div>


     <ng-template #content let-c="close" let-d="dismiss" id="customer-add" class="">
    <!-- class="modal fade" -->
         <div class="modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{modalTitle}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <alert>
            <div class="alert alert-danger"> <a class="close">×</a></div>
          </alert>

          <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
            <div class="row">

            <div class="form-group col-md-6">
              <label for="question" class="col-form-label">Question <span>*</span></label>
              <div class=" ">
                <textarea class="form-control" type="text" id="question" name="Question" required [(ngModel)]="opinion_polls.Question" ></textarea>
                <span *ngIf=" isSubmitted && isFieldsInvalid(opinion_polls.Question)" class="alert-block">This field is required</span>
                 </div>
              </div>

              <div class="form-group col-6">
                <label for="AnswerTypeID" class="col-form-label">Answer Type<span>*</span></label>
                <div class="">
                    <select class="form-control" id="AnswerTypeID" name="AnswerTypeID"
                      [(ngModel)]="opinion_polls.AnswerTypeID">
                      <option value="" selected disabled> Select</option>
                      <option *ngFor="let item of answerTypeArr" value="{{item.answertype_id}}">
                        {{item.AnswerType}}</option>
                    </select>
                  <span *ngIf=" isSubmitted && (opinion_polls.AnswerTypeID==undefined || opinion_polls.AnswerTypeID=='')" class="alert-block">This field is required</span>
                </div>
              </div>

              <ng-container *ngIf="opinion_polls.AnswerTypeID == '1'|| opinion_polls.AnswerTypeID == '2'">
                <div class="form-group col-md-6">
                  <label for="option1" class="col-form-label">Option 1 <span>*</span></label>
                  <div>
                    <input class="form-control" type="text" id="option1" name="Option1" required [(ngModel)]="opinion_polls.Option1">
                    <span *ngIf="isSubmitted && isFieldsInvalid(opinion_polls.Option1)" class="alert-block">This field is required</span>
                  </div>
                </div>
              
                <div class="form-group col-md-6">
                  <label for="option2" class="col-form-label">Option 2 <span>*</span></label>
                  <div>
                    <input class="form-control" type="text" id="option2" name="Option2" required [(ngModel)]="opinion_polls.Option2">
                    <span *ngIf="isSubmitted && isFieldsInvalid(opinion_polls.Option2)" class="alert-block">This field is required</span>
                  </div>
                </div>
              
                <div *ngIf="optionCount >= '3'" class="form-group col-md-6">
                  <label for="option3" class="col-form-label">Option 3 <span>*</span></label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="option3" name="Option3" [(ngModel)]="opinion_polls.Option3">
                    <span class="input-group-text">
                      <button *ngIf="optionCount === 3" type="button" class="btn btn-danger" (click)="removeOption(3)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </span>
                  </div>
                  <span *ngIf="isSubmitted && isFieldsInvalid(opinion_polls.Option3)" class="alert-block">This field is required</span>
                </div>
              
                <div *ngIf="optionCount =='4'" class="form-group col-md-6">
                  <label for="option4" class="col-form-label">Option 4 <span>*</span></label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="option4" name="Option4" [(ngModel)]="opinion_polls.Option4">
                    <span class="input-group-text">
                      <button type="button" class="btn btn-danger" (click)="removeOption(4)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </span>
                  </div>
                  <span *ngIf="isSubmitted && isFieldsInvalid(opinion_polls.Option4)" class="alert-block">This field is required</span>
                </div>
              
                <!-- Conditionally show Add More button -->
                <div class="form-group col-md-12" *ngIf="optionCount < '4'">
                  <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="addMore()">Add More</button>
                </div>
              </ng-container>
              
                <div class="form-group col-md-6">
									<label for="isSponsered" class="col-form-label">Open Till <span>*</span></label>
									<div class=" ">
										<input class="form-control" type="date" id="ClosingDate" name="ClosingDate"
											required [(ngModel)]="opinion_polls.ClosingDate"  (ngModelChange)="checkClosingDate($event)" [min]="today">
										<span
											*ngIf="isSubmitted && (opinion_polls.ClosingDate==undefined || opinion_polls.ClosingDate=='')"
											class="alert-block">This field is required</span>
                      <span *ngIf="showPastDateError" class="alert-block">Please select correct or a future date.</span>
									</div>
								</div>
              </div>
           </form>
          </div>

         <div class="modal-footer">
          <button type="button" class="btn btn-secondary d-none" aria-label="Close"
            (click)="d('Cross click')">Close</button>
            <button type="submit" class="btn btn-primary savebtn" (click)="save(this.opinion_polls,f)">Save</button>
           </div>
            </div>
           </div>
     </ng-template>
   
      <!--ViewLikes modal-->
     <ng-template #viewlikes let-c="close" let-d="dismiss" id="view-likes" data-bs-backdrop="static">
         <div class="modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{modalTitle}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <alert>
            <div class="alert alert-danger"> <a class="close">×</a></div>
          </alert>

          <div id="table" class="table-responsive">

            <table datatable id="view-likes"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
            <thead class="bg-light">
              <tr>
                <th>#Sl</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of likeListArr;let i = index" [attr.data-index]="i">
                <td>{{i + 1}}</td>
                 <td>{{item.authorName ? item.authorName :'-'}}</td>
              </tr>
            </tbody>
           </table>
          </div>
          </div>

         <div class="modal-footer">
          <button type="button" class="btn btn-secondary d-none" aria-label="Close"
            (click)="d('Cross click')">Close</button>
         
           </div>
            </div>
           </div>
     </ng-template>  
      

      <!--ViewComments modal-->
     <ng-template #viewcomments let-c="close" let-d="dismiss" id="view-comments" class="">
      <div class="modal-info" role="document">
    <div class="modal-content">
     <div class="modal-header">
       <h4 class="modal-title">{{modalTitle}}</h4>
       <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
         <span aria-hidden="true">×</span>
       </button>
     </div>
     <div class="modal-body">
       <alert>
         <div class="alert alert-danger"> <a class="close">×</a></div>
       </alert>

       <div id="table" class="table-responsive">

        <table datatable id="view-comments"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
        <thead class="bg-light">
          <tr>
            <th>#Sl</th>
            <th>Name</th>
            <th>Comment</th>
            <th>Action</th>
        
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of commentListArr;let i = index" [attr.data-index]="i">
            <td>{{i + 1}}</td>
             <td>{{item.authorName}}</td>
             <td>{{item.comments_text}}</td>
             <td class="action">
              <label class="switch">
                 <input
                   type="checkbox"
                   (change)="deleteComment(item)"
                   [checked]="item.isActive === 'Y'">
                 <span class="slider round"></span>
                </label>
              </td>

          </tr>
        </tbody>
       </table>
      </div>
       </div>

      <div class="modal-footer">
       <button type="button" class="btn btn-secondary d-none" aria-label="Close"
         (click)="d('Cross click')">Close</button>
         
        </div>
         </div>
        </div>
     </ng-template>  
 

     <!--Report Post modal-->
    <ng-template #reportpost let-c="close" let-d="dismiss" id="view-report" class="">
        <div class="modal-info" role="document">
      <div class="modal-content">
       <div class="modal-header">
         <h4 class="modal-title">{{modalTitle}}</h4>
         <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
           <span aria-hidden="true">×</span>
         </button>
       </div>
       <div class="modal-body">
         <alert>
           <div class="alert alert-danger"> <a class="close">×</a></div>
         </alert>
  
         <div id="table" class="table-responsive">
  
          <table datatable id="view-report"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
          <thead class="bg-light">
            <tr>
              <th>#Sl</th>
              <th>Name</th>
              <th>Reason</th>
          
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of reportPostArr;let i = index" [attr.data-index]="i">
              <td>{{i + 1}}</td>
               <td>{{item.authorName}}</td>
               <td>{{item.report_reason ? item.report_reason : '-'}}</td>
            </tr>
          </tbody>
         </table>
        </div>
         </div>
  
        <div class="modal-footer">
         <button type="button" class="btn btn-secondary d-none" aria-label="Close"
           (click)="d('Cross click')">Close</button>
           
          </div>
           </div>
          </div>
    </ng-template> 

    
     <!--Report User modal-->
     <ng-template #reportuser let-c="close" let-d="dismiss" id="view-report" class="">
      <div class="modal-info" role="document">
    <div class="modal-content">
     <div class="modal-header">
       <h4 class="modal-title">{{modalTitle}}</h4>
       <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
         <span aria-hidden="true">×</span>
       </button>
     </div>
     <div class="modal-body">
       <alert>
         <div class="alert alert-danger"> <a class="close">×</a></div>
       </alert>

       <div id="table" class="table-responsive">

        <table datatable id="view-report"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
        <thead class="bg-light">
          <tr>
            <th>#Sl</th>
            <th>Name</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of reportUserArr;let i = index" [attr.data-index]="i">
            <td>{{i + 1}}</td>
             <td>{{item.authorName}}</td>
             <td>{{item.report_reason ? item.report_reason : '-'}}</td>
          </tr>
        </tbody>
       </table>
      </div>
       </div>

      <div class="modal-footer">
       <button type="button" class="btn btn-secondary d-none" aria-label="Close"
         (click)="d('Cross click')">Close</button>
         
        </div>
         </div>
        </div>
  </ng-template> 


   <!--View Comment modal-->
      <ng-template #comment let-c="close" let-d="dismiss" id="comment-list">
          <div class="modal-info" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h4 class="modal-title"></h4>
           <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
             <span aria-hidden="true">×</span>
           </button>
         </div>
         <div class="modal-body">
           <alert>
             <div class="alert alert-danger"> <a class="close">×</a></div>
           </alert>
           <div>
            <div id="table" class="table-responsive">
              <p class="text-center font-weight-bold">{{commentQues}}</p>
              <table datatable id="view-comment"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
              <thead class="bg-light">
                <tr>
                  <th>#Sl</th>
                  <th>Comment</th>
              
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of commentViewArr;let i = index" [attr.data-index]="i">
                  <td>{{i + 1}}</td>
                   <td>{{item.Comments}}</td>
      
                </tr>
              </tbody>
             </table>
            </div>
          </div>   
        </div>
 
          <div class="modal-footer">
           <button type="button" class="btn btn-secondary d-none" aria-label="Close"
             (click)="d('Cross click')">Close</button>
          
            </div>
             </div>
            </div>
      </ng-template>  

      <!-- View chart -->
      <ng-template #viewchart let-c="close" let-d="dismiss" id="view-chart">
        <div class="modal-info" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <h4 class="modal-title"></h4>
         <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
           <span aria-hidden="true">×</span>
         </button>
       </div>
       <div class="modal-body">
         <alert>
           <div class="alert alert-danger"> <a class="close">×</a></div>
         </alert>
         <div>
          <p class="text-center "><span class="font-weight-bold"></span>
             {{ this.chartData.Question}}</p>
          <canvas baseChart  style="width: 200px !important; height: 200px !important;" 
                  [data]="doughnutChartData"
                  [labels]="doughnutChartLabels"
                  [chartType]="doughnutChartType">
          </canvas>
        </div>   
      </div>

        <div class="modal-footer">
         <button type="button" class="btn btn-secondary d-none" aria-label="Close"
           (click)="d('Cross click')">Close</button>
        
          </div>
           </div>
          </div>
    </ng-template>  

  <!--View Slider  modal-->
        <ng-template #viewslider let-c="close" let-d="dismiss" id="view-slider">
          <div class="modal-info" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h4 class="modal-title"></h4>
           <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
             <span aria-hidden="true">×</span>
           </button>
         </div>
         <div class="modal-body">
           <alert>
             <div class="alert alert-danger"> <a class="close">×</a></div>
           </alert>
           <div>

 <p class="text-center font-weight-bold">{{sliderQuestion }}</p>
   <div class="slider-container">
     <span>{{ minValue }}</span>
     <mat-slider
       [min]="minValue"
       [max]="maxValue"
       step="0.1"
       [(ngModel)]="sliderValue"
       thumbLabel
       tickInterval="0.5"
       class="custom-slider"
       [disabled]="isSliderDisabled" 
       
     >
     </mat-slider>
     <span>{{ sliderValue }}</span>
   </div>
          </div>   
        </div>
 
          <div class="modal-footer">
           <button type="button" class="btn btn-secondary d-none" aria-label="Close"
             (click)="d('Cross click')">Close</button>
          
            </div>
             </div>
            </div>
      </ng-template> 
       
   </div>
   </div>


  