import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

  constructor(private base: BaseService) { }

  save(data){
  	return this.base.post('/api/user-create',data);
  }

  totalCount(){
    return  this.base.get('/api/get-all-total-count');
  }
  
  getAlluser(){
    return  this.base.get('/api/all-user');
  }

  getRoleList() {
    return this.base.get('/api/get-role-list');
  }

  getUser(id){
    return this.base.post('/api/get-user-data',{id:id});
  }

  userUpdate(data){
    return this.base.post('/api/user-update',data);
  }

  userDelete(id){
    return this.base.post('/api/user-delete',{id:id});
  }

  getUserPdf() {
    return this.base.get('/api/user-list-pdf');
  }

  getUserExcel() {
    return this.base.get('/api/user-list-excel');
  }
  
  
}
