import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private base: BaseService) { }


  save(data){
  	return this.base.post('/api/report-create', data);
  }
  
 
}
