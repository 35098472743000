import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SalesService {

  constructor(private base: BaseService) { }

  getAllCustomer(){
  	return this.base.get('/api/all-customer-list');
  }
  getCustomerDiscount(id){
  	return this.base.post('/api/get-customer-by-discount',{id:id});
  }
  getAllCategory(){
  	return this.base.get('/api/get-all-category');
  }
  
  getSubCategory(id){
    return this.base.post('/api/get-cat-by-subCategory',{id:id});
  }

  getCategoryByProduct(type,id){

    return this.base.post('/api/get-categoryByProduct',{cat:id,type:type});
  }

  getProductInfo(id){
    return this.base.post('/api/get-product-info',{id:id});
  }

  createSales(data){
  	return this.base.post('/api/create-new-sales',data);
  }

}