import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AlertService } from "src/app/_services";
import { EncryptionService } from "src/app/_services/encryption.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
           
class Contacts {
  user_id: number;
  name: string;
  email: string;
  created_by: string;
  created_at: string;
}

@Component({
  selector: "app-cii-contacts",
  templateUrl: "./cii-contacts.component.html",
  styleUrls: ["./cii-contacts.component.sass"],
})

export class CiiContactsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };

  dtTrigger = new Subject();
  contactList: Contacts[] = []; 
  showloding = true;
  lodingImage = false;
  totalContactList: any;
  fileList: any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {}

 
  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllFaqs();
  }

  getAllFaqs() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/admin/contactform/contact-form-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "newsFeeds");

            this.contactList = resp["data"];
            this.totalContactList = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },

      columns: [
        { data: "user_id" },
        { data: "name" },
        { data: "email" },
        { data: "created_by" },
        { data: "created_at" },
      ],
    };
  }


  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }


 
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
