<!-- <div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div> -->
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Workshops</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{workshopList.length ? workshopList.length : '0'}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Workshop
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">

            <div class="table-responsive">
							<table datatable id="schemes_table"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
                <tr>
                  <th>#Sl</th>
                  <th>Title</th>
                  <!-- <th>Description</th> -->
                  <!-- <th>Created By</th> -->
                  <th>Created Date</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

                <tbody *ngIf="workshopList?.length != 0">
                  <tr *ngFor="let item of workshopList; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item.title}}</td>
                  <!-- <td [innerHtml]="item.description"></td> -->
                  <!-- <td>
                    <span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
                    <span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
                  </td> -->
                  <td>{{item.created_at}}</td>

                  <td *ngIf="item.type == 'OFFLINE'">Offline</td>
                  <td *ngIf="item.type == 'ONLINE'">Online</td>

                  <td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span>
                    <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In
                      active</span>
                  </td>


                  <td class="action">
                    <label class="switch">
                      <input
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.is_active === 'Y'">
                      <span class="slider round"></span>
                    </label>
                    <button type="button" class="btn text-dark btn-sm  rounded bg-light"
                      (click)="edit(this.workshopList[i].id,content)"><i
                        class="fa fa-edit"></i></button>
                   </td>
              </tbody>


								<tbody *ngIf="workshopList?.length == 0">
									<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>

							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
            <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="title" class="col-form-label">Title <span class="text-danger">*</span></label>
                  <div class="">
                    <input class="form-control"  #title="ngModel"  type="text" id="title" name="title" required [(ngModel)]="workshop.title">

                      <div class="alert-block text-sm">
                        <span *ngIf="isSubmitted && isFieldsInvalid(workshop.title)" >This field is required</span>

                        <!-- <span *ngIf="title.errors?.['pattern']">
                          Name can only contain letters and spaces.
                        </span> -->
                      </div>
                    </div>
                </div>


                <div class="form-group col-md-12">
                  <label for="description" class="col-form-label">Description <span class="text-danger">*</span></label>
                  <div class="">
                    <angular-editor [config]="configbody" required id="description" [(ngModel)]="workshop.description"
                      name="description"></angular-editor>
                      <span *ngIf="isSubmitted && (workshop.description == undefined || workshop.description == '')"
                      class="alert-block text-sm">This field is required</span>
                  </div>
                </div>


                <div class="form-group col-md-6">
                  <label for="start_date_time" class="col-form-label">Start Date <span class="text-danger">*</span></label>
                  <div class="">
                    <input class="form-control" type="datetime-local" id="start_date_time" name="start_date_time" required
                      [(ngModel)]="workshop.start_date_time">
                      <span *ngIf="isSubmitted && (workshop.start_date_time == undefined || workshop.start_date_time == '')"
                      class="alert-block text-sm">This field is required</span>
                  </div>
                </div>


                <div class="form-group col-md-6">
                  <label for="end_date_time" class="col-form-label">End Date <span class="text-danger">*</span></label>
                  <div class="">
                    <input class="form-control" type="datetime-local" id="end_date_time" name="end_date_time" required
                      [(ngModel)]="workshop.end_date_time">
                      <span *ngIf="isSubmitted && (workshop.end_date_time == undefined || workshop.end_date_time == '')"
                      class="alert-block text-sm">This field is required</span>
                  </div>
                </div>

                <div class="col-md-6 form-group">
                  <div class="form-group">
                      <label class=" form-control-label">Image <span class="alert-block">*</span></label>
                      <div class="">

                          <div class="input-group">
                              <span class="input-group-btn">
                                  <label class="btn btn-dark">
                                      <samp>Browse</samp>...
                                      <input  type="file" accept="{{fileType}}" style="display: none;"
                                          (change)="onImageChange($event)" [attr.multiple]="allowMultiple">
                                  </label>
                              </span>
                              <input  type="text" class="form-control" [value]="DisplayedText" disabled=""
                                  [class.ng-invalid-right]="required && !DisplayedText"
                                  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                          </div>
                          <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3"
                              *ngIf="croppedImage !=''"  [src]="croppedImage" />


                          <span *ngIf="isSubmitted && (workshop.image==undefined || workshop.image=='')"
                              class="alert-block">This
                              field is required</span>
                      </div>
                  </div>
              </div>


              <div class="form-group col-md-6">
                <label for="schemes_ids" class="form-control-label">Schemes<span class="text-danger">*</span></label>

                <ng-select [items]="schemes_ids" name="schemes_ids" [multiple]="true"
                  bindLabel="title" bindValue="id" [(ngModel)]="selectedToScheme"
                  (change)="onChangeSchemes()">
                </ng-select>

                <!-- <span *ngIf="isSubmitted && SchemesIds.length === 0" class="alert-block text-sm">This field is required</span> -->
              </div>

              <div class="form-group col-md-4">
                <label for="type" class="col-form-label">Type <span
                  class="text-danger">*</span></label>
                <div class="">
                  <select class="form-control" id="type" name="type" required [(ngModel)]="workshop.type">
                    <option value="ONLINE">Online</option>
                    <option value="OFFLINE">Offline</option>
                  </select>
                  <span *ngIf="isSubmitted && (workshop.type == undefined || workshop.type == '')"
                    class="alert-block text-sm">This field is required</span>
                </div>
              </div>



                <!-- <div class="col-md-6">
									<div class="form-group" *ngIf="workshop.video_type == 'FILE'">
										<label class=" form-control-label">Video File <span>*</span></label>
										<div class="">
											<video style="height: 100px;width: 100px;" class="img-thumbnail mb-3">
												<source [src]="workshop.videos">
											</video>
											<div class="input-group">
												<span class="input-group-btn">
														<label class="btn btn-dark">
															<samp>Browse</samp>...
															<input type="file" accept="{{videoType}}"  style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple">
														</label>
													</span>
												<input type="text" class="form-control" [value]="DisplayedVideoText" disabled="" [class.ng-invalid-right]="required && !DisplayedVideoText"  [class.ng-valid-right]="required && DisplayedVideoText" style="height: 35px;">
											</div>
										</div>
									</div>
								</div> -->




                <div class="form-group col-md-4">
                  <label for="visible_on_home" class="col-form-label">Home Display <span
                      class="text-danger">*</span></label>
                  <div class="">
                    <select class="form-control" required id="visible_on_home" name="visible_on_home"
                      [(ngModel)]="workshop.visible_on_home">
                      <option value="N">No</option>
                      <option value="Y">Yes</option>
                    </select>
                    <span *ngIf="isSubmitted && (workshop.visible_on_home == undefined || workshop.visible_on_home == '')"
                    class="alert-block text-sm">This field is required</span>
                  </div>
                </div>


                <div class="form-group col-md-4">
                  <label for="is_active" class="col-form-label">Status</label>
                  <div class="">
                    <select class="form-control" id="is_active" name="is_active" [(ngModel)]="workshop.is_active">
                      <option value="N">Inactive</option>
                      <option value="Y">Active</option>
                    </select>
                  </div>
                </div>


                <div class="form-group col-md-4 align-self-end">
                  <label for="countries" class="form-control-label">Country <span class="text-danger">*</span></label>
                  <div class="">
                    <select class="form-control" id="countries" required name="countries" [(ngModel)]="workshop.country"
                      (ngModelChange)="selectCityById($event)">
                      <option value="" selected disabled>Please Select</option>
                      <option *ngFor="let item of countries" [value]="item.id">{{item.country_name}}</option>
                    </select>
                    <span *ngIf="isSubmitted && (workshop.country == undefined || workshop.country == '')"
                    class="alert-block text-sm">This field is required</span>
                  </div>
                </div>


                <div class="form-group col-md-4 align-self-end">
                  <label for="states" class="form-control-label">State <span class="text-danger">*</span></label>
                  <div class="">
                    <select class="form-control" id="states" required name="states" [(ngModel)]="workshop.states"
                      (ngModelChange)="selectCityById($event)">
                      <option value="" selected disabled>Please Select</option>
                      <option *ngFor="let item of states" [value]="item.id">{{item.state_name}}</option>
                    </select>
                    <span *ngIf="isSubmitted && (workshop.states == undefined || workshop.states == '')"
                    class="alert-block text-sm">This field is required</span>
                  </div>
                </div>


                <div class="form-group col-md-4">
                  <label for="city" class="form-control-label">City <span class="text-danger">*</span></label>
                  <div class="">
                    <select class="form-control" id="city" required name="city" [(ngModel)]="workshop.city">
                      <option value="" selected>Please Select</option>
                      <option *ngFor="let item of cities" [value]="item.id">{{item.city_name}}</option>
                    </select>
                    <span *ngIf="isSubmitted && (workshop.city == undefined || workshop.city == '')"
                    class="alert-block text-sm">This field is required</span>
                  </div>
                </div>

                <div class="form-group col-md-6">
                  <label for="address" class="col-form-label">Address <span class="text-danger">*</span></label>
                  <div class="">
                    <textarea class="form-control" required type="text" id="address" name="address"
                      [(ngModel)]="workshop.address"></textarea>

                      <span *ngIf="isSubmitted && (workshop.address == undefined || workshop.address == '')"
                      class="alert-block text-sm">This field is required</span>
                  </div>
                </div>

                <div class="form-group col-md-6">
                  <label for="contact" class="col-form-label">Contact</label>
                  <div class="">
                    <textarea class="form-control"  #contact_info="ngModel"  type="text" id="contact" name="contact_info"  [(ngModel)]="workshop.contact_info"></textarea>
                    </div>
                 </div>

                <div class="form-group col-md-6">
                  <label for="external_link" class="col-form-label">External Link</label>
                  <div class="">
                    <textarea class="form-control" type="text" id="external_link" name="external_link"
                      [(ngModel)]="workshop.external_link"></textarea>
                  </div>
                </div>

                <div class="form-group col-md-6">
                  <label for="registration_link" class="col-form-label">Registration Details Link</label>
                  <div class="">
                    <textarea class="form-control" type="text" id="registration_link" name="registration_link"
                      [(ngModel)]="workshop.registration_link"></textarea>

                  </div>
                </div>

                <div class="form-group col-md-6">
                  <label for="speaker_name" class="col-form-label">Speaker Name </label>
                  <div class="">
                    <input class="form-control"  #speaker_name="ngModel"  type="text" id="speaker_name" name="speaker_name"  [(ngModel)]="workshop.speaker_name">


                    </div>
                 </div>

                 <div class="col-md-6 form-group">
                  <div class="form-group">
                      <label class=" form-control-label">Speaker Image </label>
                      <div class="">

                          <div class="input-group">
                              <span class="input-group-btn">
                                  <label class="btn btn-dark">
                                      <samp>Browse</samp>...
                                      <input  type="file" accept="{{speakerFileType}}" style="display: none;"
                                          (change)="onSpeakerImageChange($event)" [attr.multiple]="allowMultiple">
                                  </label>
                              </span>
                              <input  type="text" class="form-control" [value]="DisplayedText" disabled=""
                                  [class.ng-invalid-right]="required && !DisplayedText"
                                  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                          </div>

                          <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3"
                              *ngIf="speakerImage !='' "  [src]="speakerImage" />

                      </div>
                  </div>
              </div>


                 <div class="form-group col-md-6">
                  <label for="about_speaker" class="col-form-label">About Speaker </label>
                  <div class="">
                    <textarea class="form-control"  #speaker_about="ngModel"  type="text" id="about_speaker" name="speaker_about"  [(ngModel)]="workshop.speaker_about"></textarea>
                    </div>
                 </div>


                 <div class="form-group col-md-6">
                  <label for="fees_and_payment" class="col-form-label">Fees and Payment Information </label>
                  <div class="">
                    <textarea class="form-control"  #fees_payment_info="ngModel"  type="text" id="fees_and_payment" name="fees_payment_info"  [(ngModel)]="workshop.fees_payment_info"></textarea>
                    </div>
                 </div>

                 <div class="col-md-6">
                  <div class="form-group">
                    <label for="video_type" class="col-form-label">Video Type</label>
                    <div class="">
                      <select class="form-control" id="video_type" name="video_type"
                        [(ngModel)]="workshop.video_type">
                        <option value="LINK">link</option>
                        <option value="FILE">File</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="col-md-6" *ngIf="workshop.video_type == 'LINK'">
                  <div class="form-group">
                    <label for="videos" class="col-form-label">Video Url</label>
                    <div class="">
                      <textarea class="form-control" type="text" id="videos" name="videos"
                        [(ngModel)]="workshop.videos"></textarea>
                    </div>
                  </div>
                </div>


              <div class="col-md-6" *ngIf="workshop.video_type == 'FILE'">
                  <div class="form-group">
                    <label class="form-control-label">Video File</label>
                    <div class="">
                      <video style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="workshop.videos !=''">
                        <source [src]="workshop.videos">
                      </video>
                      <div class="input-group">
                        <span class="input-group-btn">
                          <label class="btn btn-dark">
                            <samp>Browse</samp>...
                            <input type="file" accept="{{videoType}}" style="display: none;" (change)="fileChange($event)"
                              [attr.multiple]="allowMultiple">
                          </label>
                        </span>
                        <input type="text" class="form-control" [value]="DisplayedVideoText" disabled=""
                          [class.ng-invalid-right]="required && !DisplayedVideoText"
                          [class.ng-valid-right]="required && DisplayedVideoText" style="height: 35px;">
                      </div>
                    </div>
                  </div>
                </div>



              <div class="col-md-12">
                    <div class="row gy-3">
                      <div class="col-md-12">
                        <label for="question" class="col-form-label font-weight-bold"> FAQs</label>

                      </div>
                      <div class="col-md-12">
                                <div *ngFor="let faq of faqs; let i = index" class="row mb-2">
                                 <div class="col-md-10">
                                  <div class="row align-items-end">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="question{{ i }}" class="col-form-label">Question {{ i + 1 }}</label>
                                        <textarea
                                          class="form-control"
                                          id="question{{ i }}"
                                          type="text"
                                          [(ngModel)]="faq.question"
                                          name="question{{ i }}"

                                          #question="ngModel"
                                        ></textarea>

                                    </div>
                                  </div>

                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="answer{{ i }}" class="col-form-label">Answer {{ i + 1 }}</label>
                                        <textarea
                                          class="form-control"
                                          id="answer{{ i }}"
                                          type="text"
                                          [(ngModel)]="faq.answer"
                                          name="answer{{ i }}"

                                          #answer="ngModel"
                                        ></textarea>

                                      </div>
                                    </div>
                                  </div>
                                 </div>

                                  <div class="col-md-2 d-flex align-items-center">
                                    <button
                                      *ngIf="faqs.length > 1"
                                      type="button"
                                      class="btn btn-danger fw-bold"
                                      (click)="removeFaq(i)"
                                    >
                                     Remove
                                    </button>
                                    <button
                                      *ngIf="i === faqs.length - 1"
                                      type="button"
                                      class="btn btn-dark fw-bold ml-2"
                                      (click)="addFaq()"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
              </div>


              <div class="col-md-12">
                <div class="row gy-3">
                  <div class="col-md-12">
                    <label for="question" class="col-form-label font-weight-bold"> Testimonials</label>
                  </div>
                  <div class="col-md-12">
                            <div *ngFor="let testimonial of testimonials; let i = index" class="row mb-2">
                             <div class="col-md-10">
                              <div class="row align-items-end">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="name{{ i }}" class="col-form-label">Name {{ i + 1 }}</label>
                                    <textarea
                                      class="form-control"
                                      id="name{{ i }}"
                                      type="text"
                                      [(ngModel)]="testimonial.name"
                                      name="name{{ i }}"

                                      #name="ngModel"
                                    ></textarea>

                                </div>
                              </div>



                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="designation{{ i }}" class="col-form-label">Designation {{ i + 1 }}</label>
                                    <textarea
                                      class="form-control"
                                      id="designation{{ i }}"
                                      type="text"
                                      [(ngModel)]="testimonial.designation"
                                      name="designation{{ i }}"

                                      #designation="ngModel"
                                    ></textarea>
                                  </div>
                                </div>


                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="description{{ i }}" class="col-form-label">Description {{ i + 1 }}</label>
                                    <textarea
                                      class="form-control"
                                      id="description{{ i }}"
                                      type="text"
                                      [(ngModel)]="testimonial.description"
                                      name="description{{ i }}"

                                      #description="ngModel"
                                    ></textarea>

                                  </div>
                                </div>


                              <div class="col-md-6 form-group">
                                <label class="form-control-label">Testimonial Image {{ i + 1 }} </label>
                                <div>

                                  <div class="input-group">
                                    <span class="input-group-btn">
                                      <label class="btn btn-dark">
                                        <samp>Browse</samp>...
                                        
                                        <input  type="file" accept="{{testimonialFileType}}" style="display: none;"
                                                (change)="onTestimonialImageChange($event, i)" [attr.multiple]="allowMultiple">
                                      </label>
                                    </span>

                                  </div>
                                  <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3"
                                       *ngIf="testimonial.photo" [src]="testimonial.photo" />
                                </div>
                              </div>

                              </div>
                             </div>

                              <div class="col-md-2 d-flex align-items-center">
                                <button
                                  *ngIf="testimonials.length > 1"
                                  type="button"
                                  class="btn btn-danger fw-bold"
                                  (click)="removeTestimonials(i)"
                                >
                                 Remove
                                </button>


                                <button
                                  *ngIf="i === testimonials.length - 1"
                                  type="button"
                                  class="btn btn-dark fw-bold ml-2"
                                  (click)="addTestimonials()"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
              </div>

              </div>
            </form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
								<button type="submit" class="btn btn-primary savebtn" (click)="save(this.workshop,f)">Save</button>
			   		</div>
				  </div>
			  </div>
		</ng-template>
  </div>
</div>

