import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, MemberBlogsViewService } from "../_services/index";
import { appConfig } from "../app.config";
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-member-blogs-view",
  templateUrl: "./member-blogs-view.component.html",
  styles: [],
})
export class MemberBlogsViewComponent implements OnInit {
  remarkForm: any;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dataService: MemberBlogsViewService,
    private fb: FormBuilder
  ) {}

  showloding = true;
  lodingImage = false;
  urlParams: any;

  blog = {
    blog_remarks: "",
    blog_status: "",
  };

  blogData = {
    id: "",
    vartical_names: "",
    state_name: "",
    city_name: "",
    title: "",
    category_ids: "",
    sub_category_ids: "",
    short_description: "",
    description: "",
    image: "",
    external_link: "",
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
    home_display: "",
    blog_status: "",
    remark: "",
    is_active: "",
    created_by: "",
    created_at: "",
    category: "",
    sub_category: "",
    created_by_name: "",
  };

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.urlParams = params["id"];
    });
    this.getBlogDetails();

    this.remarkForm = this.fb.group({
      remarks: ["", [Validators.required, this.noWhitespaceValidator()]],
    });
  }

  get remarks() {
    return this.remarkForm.get("remarks");
  }

  noWhitespaceValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || "").trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  onBack() {
    this.router.navigate(["/member-blogs"]);
  }

  getBlogDetails() {
    this.loadShow();
    this.dataService
      .viewBlog(this.urlParams)
      .pipe()
      .subscribe((data) => {
        if (data["blog"][0]) {
          this.blogData = data["blog"][0];
          this.blogData.image = appConfig.apiUrl + "/" + data["blog"][0].image;
          this.loadHide();
        }
        //console.log(this.blogData, "get-member-blog");
      });
  }

  rejectStatus(status) {
    if (this.remarkForm.valid) {
      this.updateStatus(status);
    } else {
      this.remarkForm.markAllAsTouched();
    }
  }

  updateStatus(status) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadShow();

    const data = {
      id: this.urlParams,
      remark: this.remarkForm.value.remarks
        ? this.remarkForm.value.remarks
        : "",
      blog_status: status,
    };

    //console.log(data, "..........data");

    this.dataService
      .updateMemberBlogStatus(data)
      .pipe()
      .subscribe(
        (res) => {
          //console.log(res, "blog status");

          if (res["status"] == 200) {
            this.alertService.success(res["mesg"], true);
            this.loadHide();
            setTimeout(() => {
              this.router.navigate(["member-blogs"]);
            }, 1000);
          } else if (res["status"] == 422) {
            this.alertService.error(res["mesg"], true);
          } else {
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
