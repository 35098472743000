import {
  AfterViewInit,
  OnDestroy,
  Component,
  OnInit,
  Input,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { DataTableDirective } from "angular-datatables";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  AlertService,
  BlogCategoryService,
  AppService,
} from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";

class BlogCategory {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-blog-category",
  templateUrl: "./blog-category.component.html",
})
export class BlogCategoryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  blogCategoryList: BlogCategory[] = []; // Table Data list
  isSubmitted = false;
  modalReference: NgbActiveModal;
  modalTitle: string;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  // countryAddForm: FormGroup;
  getBlogCategory = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };

  constructor(
    public router: Router,
    private dataService: BlogCategoryService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  blogcategory = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allBlogCategory();
  }

  allBlogCategory() {
    this.loadShow();
    this.dataService
      .getAllBlogcategory()
      .pipe()
      .subscribe((data) => {
        this.blogCategoryList = data["blogcategory"];
        //this.dtTrigger.next(); // Data Table
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    this.blogcategory.id = "";
    (this.blogcategory.name = ""),
      (this.blogcategory.description = ""),
      (this.blogcategory.is_active = "Y");
    this.modalTitle = "Add Blog Category";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.isSubmitted = true;
    this.loadShow();
    this.dataService
      .getBlogcategory(id)
      .pipe()
      .subscribe((data) => {
        this.getBlogCategory = data["blogcategory"];
        this.blogcategory = {
          id: this.getBlogCategory.id,
          name: this.getBlogCategory.name,
          description: this.getBlogCategory.description,
          is_active: this.getBlogCategory.is_active,
        };
        this.loadHide();
        this.isSubmitted = false;
        this.modalTitle = "Edit Blog Category";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.blogcategory.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.blogcategory)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success(
                "Blog category saved successfully",
                true
              );
              this.allBlogCategory();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .blogcategoryUpdate(this.blogcategory)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success(
                "Blog Category updated successfully",
                true
              );
              this.allBlogCategory();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  

  delete(item) {
    this.loadShow();
    this.dataService
      .blogcategoryDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Blog Category deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allBlogCategory();
          } else {
            this.alertService.error("Blog Category error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
