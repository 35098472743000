import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CommunityListService {

  constructor(private base: BaseService) { }  

  deletePartner(id){
    return this.base.post('/api/delete-partner',{id:id});
  }

   viewCommunity(data) {
    return this.base.post('/api/thread-list-by-question-slug',data);
  }

  reportCommunity(data) {
    return this.base.post('/api/report-list-by-question-id',data);
  }

  saveReportRemarks(data) {
    return this.base.post('/api/question-delete',data);
  }

  getAllCommunity(data){
    return  this.base.post('/api/question-list',data);
  }

}
