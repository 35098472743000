import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, MemberListService } from "../_services";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-member-view",
  templateUrl: "./member-view.component.html",
  styleUrls: [],
})
export class MemberViewComponent implements OnInit {
  partner_status: any;
  remarkForm: any;
  showloding = true;
  lodingImage = false;
  id: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dataService: MemberListService,
    private fb: FormBuilder
  ) {}

  partner = {
    partner_remarks: "",
  };

  member_profile_details = {
    about_us: "",
    age: "",
    are_u_student: "",
    are_working_professional: "",
    area_of_study: "",
    area_of_study_other: "",
    badge_name: "",
    city: "",
    city_name: "",
    country: "",
    country_name: "",
    created_at: "",
    created_by: "",
    dob: "",
    edu_qualification: "",
    edu_qualification_other: "",
    first_name: "",
    gender: "",
    gendr: "",
    id: "",
    is_active: "",
    last_name: "",
    linkedin: "",
    other_city: "",
    profile_percentage: "",
    slug: "",
    state: "",
    state_name: "",
    type_of_enterprise: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    view_count: "",
    year_of_work_exp: "",
  };

  member_business_details = {
    StageOfFunding: "",
    area_of_interest: "",
    areaofinterest: "",
    business_establish: "",
    business_plan_ready: "",
    business_register_ur_name: "",
    business_registration_type: "",
    business_type: "",
    city: "",
    city_name: "",
    co_founder: "",
    country: "",
    country_name: "",
    created_at: "",
    created_by: "",
    enterprise_name: "",
    entity_registered: "",
    entity_registered_dpiit: "",
    establish_business: "",
    existing_business: "",
    fb_url: "",
    financing_ur_enterprise: "",
    funding: "",
    how_financing_ur_enterprise: "",
    id: "",
    investment_in_business: "",
    investmentinbusiness: "",
    linkedin_url: "",
    mentorship: "",
    no_of_employees: "",
    noofemployees: "",
    other_city: "",
    other_sector: "",
    plan_to_business: "",
    primary_industry: "",
    primary_industry_other: "",
    register_business: "",
    sector_of_enterprise: "",
    sectorofenterprise: "",
    stage_of_business: "",
    stage_of_funding: "",
    stageofbusiness: "",
    state: "",
    state_name: "",
    turnover: "",
    turnover_last_year: "",
    twitter_url: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    website_link: "",
    years_of_operating_bussiness: "",
  };

  member_personal_details = {
    UUID: "",
    deactive_account: "",
    email: "",
    id: "",
    image: "",
    mask_mobile_no: "",
    name: "",
    notifications: "",
    phone: "",
    slug: "",
    type: "",
  };

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      //console.log(this.id, "id");
    });
    this.getPartnerDetails();
  }

  get atr_remark() {
    return this.remarkForm.get("atr_remarks");
  }

  getPartnerDetails() {
    this.loadShow();
    const data = {
      id: this.id,
    };

    this.dataService
      .memeberViewList(data)
      .pipe()
      .subscribe((res) => {
        //console.log(res.partner, "ressssss");

        if (res["partner"]) {
          this.member_profile_details = res["partner"]["member_profile"][0];
          this.member_personal_details = res.partner.member;
          this.member_business_details = res["partner"]["member_business"][0];
        } else {
        }
        // if(res['data']['type']=='Individual'){
        //   this.partnerIndividualDetails = res['data']
        //   //console.log(this.partnerIndividualDetails,"partnerIndividualDetails");

        // }else if(res['data']['type']=='Organisation'){
        //   this.partnerOrganizationDetails = res['data']
        //   //console.log(res['data'],"this.partnerOrganizationDetails");
        // }else{
        //   //console.log(res,"dataatatatatatatat");
        // }
        this.loadHide();
      });
  }

  onBack() {
    this.router.navigate(["/member"]);
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
