<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-lg-12 headboxes">
                <div class="card p-0">
                    <div class="card-block p-0">
                        <div class="heading-box" style="background-image:none;">
                            <div class="row align-items-center">
                                <div class="col-md-1">
                                    <div class="partner-iocn">
                                        <img src="assets/img/default-icon.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                                <div class="col-md-8 align-self-center">
                                    <div class="pagename">
                                        <!-- <h3 class="card-title mb-1">Blog-View</h3> -->
                                        <h3 class="card-title mb-1">{{blogData.title}}</h3>
                                        <p>Created On on {{blogData.created_at}}</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
									<div class="excel">
									<button type="button"   class="btn btn-primary addbtn  ml-3" (click)="onBack()"><i class="fa fa-long-arrow-left"></i> Back</button>
								 </div>
							</div>		
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-block partnerview">
                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="onboard-expereince">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>Blog Details</h4>
                                                <hr>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Title</label>
                                                    <p>{{blogData.title}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Category</label>
                                                    <p>{{blogData.category}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Sub Category</label>
                                                    <p>{{blogData.sub_category}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">State</label>
                                                    <p>{{blogData.state_name}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">City</label>
                                                    <p>{{blogData.city_name}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Belongs To</label>
                                                    <p>{{blogData.vartical_names}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Short Description</label>
                                                    <p [innerHtml]="blogData.short_description"></p>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Description</label>
                                                    <p [innerHtml]="blogData.description"></p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Image</label>
                                                    <a href="{{blogData.image}}" target="_blank"><img [src]="blogData.image" height="50px" width="70px"/></a>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">External Link</label>
                                                    <p>{{blogData.external_link}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Meta Title</label>
                                                    <p>{{blogData.meta_title}}</p>
                                                </div>
                                            </div>                                            
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Meta Keyword</label>
                                                    <p>{{blogData.meta_keyword}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Meta Description</label>
                                                    <p [innerHtml]="blogData.meta_description"></p>
                                                </div>
                                            </div>                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Blog Status</label>
                                                    <p>{{blogData.blog_status}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Created By</label>
                                                    <p>{{blogData.created_by_name}}</p>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-block">
                            <div class="partner-action p-3">
                                <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="remark">Remarks</label>
                                                <form [formGroup]="remarkForm">
                                                    <textarea  class="form-control" name="remarks" id="remarks" formControlName="remarks" rows="3"></textarea>

                                                    <div *ngIf="remarks.invalid && (remarks.dirty || remarks.touched)" class="alert">
                                                        <div class="text-danger" *ngIf="remarks?.errors?.whitespace && (remarks.dirty || remarks.touched)">
                                                            <p>This field is required</p>
                                                          </div>
                                                          
                                                          <!-- <div *ngIf="errorMessage" class="alert alert-danger">
                                                            {{ errorMessage }}
                                                          </div> -->
                                                    </div>
                                                    


                                                    <!-- <div *ngIf="remarks.invalid && (remarks.dirty || remarks.touched)" class="alert">
                                                        <div *ngIf="remarks.errors && remarks.errors['required']" class="text-danger">This field is required.</div>
                                                    </div> -->
                                                  </form> 
                                               </div>
                                             </div>
                                        <div class="col-md-12 align-self-center">
                                            <div class="actiobtn">
                                                <!-- <button type="submit" (click)="updateStatus('PENDING')" 
                                                class="btn btn-success addbtn mr-2">Pending</button> -->
                                                <button type="submit" (click)="updateStatus('DRAFT')" 
                                                class="btn btn-primary addbtn mr-2">Save as Draft</button>
                                                <button type="submit" (click)="updateStatus('SUBMITTED')" 
                                                class="btn btn-primary addbtn mr-2" >Approved</button>
                                                <button type="submit" (click)="rejectStatus('REJECTED')" 
                                                class="btn btn-dark addbtn mr-2" [disabled]="!f.form.valid">Reject</button>                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>