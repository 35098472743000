import { AfterViewInit, ViewChild, OnDestroy, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AlertService, CommunityListService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styles: []
})
export class CommunityListComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  communityList: any; // Table Data list
  commentList: Subscription[] = []; // Table Data list
  reportList : Subscription[] = [];
  closeResult: string; // Modal Close

  isSubmitted =  false;
  commentCommunity : any;
  reportCommunity : any;
  slug : any;

  community = {
    report_remarks : '',
  };

  constructor(
              public router: Router,
              private dataService:CommunityListService,
              private alertService: AlertService,
              private AppService: AppService,
              private modalService: NgbModal
            )
            {

             }

  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;
  ngOnInit() {
    this.allCommunityPost();
  }

  allCommunityPost(){
    this.loadShow();
    var data = {};
    this.dataService.getAllCommunity(data)
        .pipe().subscribe(
          data => { this.communityList = data;
          ////console.log(this.communityList);
          // this.dtTrigger.next(); // Data Table
          this.rerender();
          this.loadHide();
        });
  }

  view(slug,content) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    var data = {
      'slug':slug
    };
    
    this.commentList = [];
    this.modalReference = this.modalService.open(content);
    this.loadShow();
    this.commentCommunity = this.dataService.viewCommunity(data)
        .pipe().subscribe(
          data => {
            if(data) {
              this.commentList = data['thread'];
              this.loadHide();
            }
          //console.log(this.commentList);
          // this.modalReference = this.modalService.open(content);
        });
  }

  report(id,slug,reportmodal) {
    this.slug = slug;
    var data = {
      'question_id':id,
      'post_type':'post'
    };


    //console.log(data,"dataattt");
    
    this.reportList = [];
    // //console.log(data);
    // this.modalReference.close();
    this.modalReference = this.modalService.open(reportmodal);
    this.loadShow();
    this.reportCommunity = this.dataService.reportCommunity(data)
        .pipe().subscribe(
          data => {
            if(data) {
              this.reportList = data['reportlist'];
              this.loadHide();
            }
          //console.log(this.reportList,"viewww");
          // this.modalReference = this.modalService.open(reportmodal);
        });
  }

  keepPost(val){
    this.loadShow();
    ////console.log(val);
    this.community.report_remarks = val.report_remarks;
    var data = {
      "slug": this.slug,
      "type": 2,
      "remark": this.community.report_remarks,
    };
    ////console.log(data);
    this.dataService.saveReportRemarks(data)
      .pipe().subscribe(data =>{
        // if(data['status']==200){
        //   this.alertService.success(data['message'], true);
        // }else {
        //   this.alertService.error(data['message'], true);
        // }
        this.modalReference.close();
        // this.dtTrigger = new Subject(); //  DataTable
        this.alertService.success('Post successfully delete/restore', true);
        this.allCommunityPost();
      },error =>{
        this.alertService.error(error);
        this.loadHide();
      });
  }

  deletePost(val){
    this.loadShow();
    ////console.log(val);
    this.community.report_remarks = val.report_remarks;
    var data = {
      "slug": this.slug,
      "type": 1,
      "remark": this.community.report_remarks,
    };
    ////console.log(data);
    this.dataService.saveReportRemarks(data)
      .pipe().subscribe(data =>{
        // if(data['status']==200){
        //   this.alertService.success(data['message'], true);
        // }else {
        //   this.alertService.error(data['message'], true);
        // }
        this.modalReference.close();
        // this.dtTrigger = new Subject(); //  DataTable
        this.alertService.success('Post successfully delete/restore', true);
        this.allCommunityPost();
      },error =>{
        this.alertService.error(error);
        this.loadHide();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

 

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

}
