import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AtrCategoryService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/create-atr-category', data);
  }

  atrCategoryUpdate(data){
    return this.base.post('/api/update-atr-category',data);
  }

  atrCategoryDelete(id){
    return this.base.post('/api/delete-atr-category',{id:id});
  }

  getAllAtrCategory(){
    return this.base.get('/api/get-all-atr-category');
  }

  getAtrCategory(id){
    return this.base.post('/api/edit-atr-category',{id:id});
  }


}

