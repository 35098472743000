<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">WEP Program</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{programTotal}}</h2>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<select class="form-control" id="approve_status" 
										[(ngModel)]="filterData.status"
										 (change)="setFilterData($event)">
											<option value="ALL">ALL</option>
											<option value="PENDING">PENDING</option>
											<option selected value="APPROVED">APPROVED</option>								
											<option selected value="REJECTED">REJECTED</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table id="programtable" datatable [dtOptions]="dtOptions" class="row-border hover">
								<thead>
								  <tr>
									<th>Id</th>
									<th>Name</th>
									<th>Organization Name</th>
									<th>Status</th>
									<th>Action</th>
								  </tr>
								</thead>
								<tbody *ngIf="programList?.length != 0">
								  <tr *ngFor="let program of programList; let i = index" [attr.data-index]="i">
									<td>{{ getRowNumber(i) }}</td>
									<td>{{ program.name }}</td>
									<td>{{ program.organization_name }}</td>
									<td>{{ program.approve_status}}</td>
									<td><button type="button" class="btn btn-eye" 
										(click)="openProgramInfo(program.action,program_info)">
										<i class="fa fa-eye"></i></button>
									</td>
								  </tr>
								</tbody>
								<tbody *ngIf="programList?.length == 0">
								  <tr>
									<td colspan="4" class="no-data-available">No data!</td>
								  </tr>
								</tbody>
							  </table>							  
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #program_info let-c="close" let-d="dismiss" id="program_info">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Program Information</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-bordered">
										<tbody>
											<tr>
												<th>Name</th>
												<td>{{programInfo.mentorship_program_name}}</td>
												<th>Organisation</th>
												<td>{{programInfo.organization_name}}</td>
											</tr>
											<tr>
												<th>Program Start Date</th>
												<td>{{programInfo.program_start_date}}</td>
												<th>Program End Date</th>
												<td>{{programInfo.program_end_date}}</td>
											</tr>
											<tr>
												<th>Industry</th>
												<td>{{programInfo.industry_names}}</td>
												<th>Stage of enterprise</th>
												<td>{{programInfo.stage_of_business_names}}</td>
											</tr>
											<tr>
												<th>Ecosystem key</th>
												<td>{{programInfo.ecosystem_key_names}}</td>
												<th>Areas of functional expertise</th>
												<td>{{programInfo.areas_of_functional_expertise_names}}</td>
											</tr>
											<tr>
												<th>Language</th>
												<td>{{programInfo.language_names}}</td>
												<th>Mode of engagemen</th>
												<td>{{programInfo.mode_of_engagemen_name}}</td>
											</tr>
											<tr>
												<th>Focus Industry</th>
												<td>{{programInfo.focus_industry}}</td>
												<th>Total Participants</th>
												<td>{{programInfo.total_number_of_participants}}</td>
											</tr>
											<tr>
												<th>Is Paid</th>
												<td>{{programInfo.is_paid}}</td>
												<th>Venue of the session</th>
												<td>{{programInfo.venue_of_the_session}}</td>
											</tr>
											<tr>
												<th>Duration</th>
												<td>{{programInfo.duration}}</td>
												<th>Frequency</th>
												<td>{{programInfo.frequency}}</td>
											</tr>
											<tr>
												<th>Web Link</th>
												<td>{{programInfo.web_link}}</td>
												<th>Registration Link</th>
												<td>{{programInfo.registration_link}}</td>
											</tr>																						
											<tr>
												<th>Status</th>
												<td>
													<select class="form-control" name="approve_status" id="approve_status" [(ngModel)]="programInfo.approve_status" (ngModelChange)="onChangeStatus()">
														<option disabled value="">Choose...</option>
														<!-- <option value="PENDING">Pending</option> -->
														<option value="APPROVED">Approve</option>
														<option value="REJECTED">Reject</option>
													</select>
												</td>
												<th>Remarks  <span *ngIf=" programInfo.approve_status === 'REJECTED'" class="text-danger">*</span></th>
												<td>

													<input type="hidden" id="id" name="id" [(ngModel)]="programInfo.id" >
													<textarea class="form-control" name="remarks" [required]="programInfo.approve_status === 'REJECTED'" id="remarks" [(ngModel)]="programInfo.remark" (ngModelChange)="checkRemarks()"></textarea>
													<div *ngIf="isSubmitted && programInfo.approve_status === 'REJECTED' && (!programInfo.remark || programInfo.remark.trim() === '')" class="text-danger">
														This field is required
													</div>
<!-- 
													<input type="hidden" id="id" name="id" [(ngModel)]="programInfo.id">
													<textarea class="form-control" name="remarks" id="remarks" [(ngModel)]="programInfo.remark">{{programInfo.remark}}</textarea> -->
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary" aria-label="Close"
							(click)="programStatusUpdate(program_info)">Submit</button>
						<button type="button" class="btn btn-secondary" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template>

	</div>
</div>