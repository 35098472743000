import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AwardToRewardListService } from '../_services/award-to-reward-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_services';
import { AwardToRewardService } from '../_services/award-to-reward.service';

@Component({
  selector: 'app-award-to-reward-responses',
  templateUrl: './award-to-reward-responses.component.html',
  styleUrls: []
})
export class AwardToRewardResponsesComponent implements OnInit {
  
  bodyDataArr:any=[]
  headDataArr:any=[]
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  atrList: any[] = [];
  closeResult: string;
  selectedStatus:any
  slug:any

  constructor(
              public router: Router,
              private dataService:AwardToRewardListService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private atrService :AwardToRewardService,
              public activatedRoute: ActivatedRoute,
            ) { }

  showloding = true;
  lodingImage = false;

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.slug = params['slug'];
    });

    const slug_data = {
      slug: this.slug
    }
    
    this.loadShow();
    this.atrService.getRegisteredUser(slug_data).subscribe((res:any) => {
      //console.log(res,"dataslug");
      this.headDataArr = res.data['head']
      this.bodyDataArr = res.data['body']
      this.dtTrigger.next(); 
      this.loadHide();
    });
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }
}
