import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { AlertService, CampaignService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";
import { AngularEditorConfig } from "@kolkov/angular-editor";

class Campaign {
  id: number;
  name: string;
  user_type: string;
  user_ids: number[];
  subject: string;
  body: string;
  schedule_type: string;
  schedule_date: string;
  is_active: string;
}

@Component({
  selector: "app-campaign",
  templateUrl: "./campaign.component.html",
})
export class CampaignComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable
  campaignList: Campaign[] = []; // Table Data list
  isSubmitted = false;

  getCampaign = {
    id: "",
    name: "",
    user_type: "2",
    user_ids: [],
    subject: "",
    body: "",
    schedule_type: "",
    schedule_date: "",
    is_active: "",
  };

  allCustomer: any[] = [];
  allPartner: any[] = [];
  selectedTo: number[] = [];
  closeResult: string; // Modal Close
  myString: any;
  myArray: any;

  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: CampaignService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  campaign = {
    id: "",
    name: "",
    user_type: "2",
    user_ids: [],
    subject: "",
    body: "",
    schedule_type: "",
    schedule_date: "",
    is_active: "",
  };

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    this.allCampaign();
    this.customer();
    this.partner();
  }

  changeTo() {
    this.campaign.user_ids = this.selectedTo;
  }

  changeType() {
    this.selectedTo = [];
  }

  customer() {
    this.dataService
      .getAllCustomer("2")
      .pipe()
      .subscribe((data) => {
        this.allCustomer = data["users"];
      });
  }

  partner() {
    this.dataService
      .getAllCustomer("1")
      .pipe()
      .subscribe((data) => {
        this.allPartner = data["user"];
      });
  }

  allCampaign() {
    this.loadShow();
    this.dataService
      .getAllCampaign()
      .pipe()
      .subscribe((data) => {
        this.campaignList = data["emails"];
        // this.dtTrigger.next(); // Data Table
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    // this.formName.reset();
    if (this.getCampaign.id != null) {
      this.getCampaign.id = "";
    }

    this.selectedTo = [];
    this.campaign.id = "";
    this.campaign.name = "";
    this.campaign.user_type = "2";
    this.campaign.user_ids = [];
    this.campaign.subject = "";
    this.campaign.body = "";
    this.campaign.schedule_type = "";
    this.campaign.schedule_date = "";
    this.campaign.is_active = "Y";
    this.modalTitle = "Add Email Campaign";
    this.modalReference = this.modalService.open(content);
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.campaign.name)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (f.form.valid) {
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.loadShow();

    this.dataService.getCampaign(id).subscribe((data) => {
      this.getCampaign = data["emails"];
      this.myString = this.getCampaign.user_ids;
      this.myArray = this.myString.split(",").map(Number);
      this.selectedTo = this.myArray;
      this.campaign = {
        id: this.getCampaign.id,
        name: this.getCampaign.name,
        user_type: this.getCampaign.user_type,
        user_ids: this.getCampaign.user_ids,
        subject: this.getCampaign.subject,
        body: this.getCampaign.body,
        schedule_type: this.getCampaign.schedule_type,
        schedule_date: this.getCampaign.schedule_date,
        is_active: this.getCampaign.is_active,
      };
      this.loadHide();
      this.modalTitle = "Edit Email Campaign";
      this.modalReference = this.modalService.open(content);
    });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  insertAction(val, f) {
    let formData: FormData = new FormData();
    formData.append("name", this.campaign.name);
    formData.append("user_type", this.campaign.user_type);
    formData.append("user_ids", JSON.stringify(this.campaign.user_ids));
    formData.append("subject", this.campaign.subject);
    formData.append("body", this.campaign.body);
    formData.append("schedule_type", this.campaign.schedule_type);
    formData.append("schedule_date", this.campaign.schedule_date);
    formData.append("is_active", this.campaign.is_active);

    if (val.id == "") {
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            // this.dtTrigger = new Subject(); //  DataTable
            this.allCampaign();
            this.alertService.success("Campaign created successfully", true);
            this.modalReference.close();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    } else {
      formData.append("id", val.id);
      this.dataService
        .campaignUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            // this.dtTrigger = new Subject(); //  DataTable
            this.allCampaign();
            this.alertService.success("Campaign updated successfully", true);
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }

  // delete(id){
  //     this.dataService.campaignDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Campaign deleted successfully', true);
  //             this.allCampaign();
  //           }else{
  //             this.alertService.error('Campaign error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .campaignDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Campaign deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allCampaign();
          } else {
            this.alertService.error("Campaign error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
