

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/part.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Partner</h3>
									</div>
								</div>

								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{totalPartnerList}}</h2>
									</div>
								</div>

								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<div id="table" class="table-responsive">
								<table datatable id="partner"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th >#Sl</th>
										<th style="width: 80px;">Name</th>
										<th>Type</th>
										<th>Mobile Number</th>
										<th>Email</th>
										<th>Referral Code</th>
										<th>Reffered By</th>
										<th>Approval Status</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>

									<tbody *ngIf="partnerList?.length != 0">
										<tr *ngFor="let item of partnerList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td style="width: 80px;" *ngIf="item.type == 'Individual'">{{item.first_name + '
											' + item.last_name}}</td>
										<td style="width: 80px;" *ngIf="item.type == 'Organisation'">
											{{item.organization_name}} ({{item.fullname}})</td>

										<td>{{item.type}}</td>
										<td>{{item.spoc_mobile_no}}</td>
										<td>{{item.spoc_email}}</td>
										<td>{{item.referral_code? item.referral_code:'-' }}</td>
										<td>{{item.referred_by? item.referred_by : '-' }}</td>
										<td><span *ngIf="item.approve_status=='PENDING'">Approval Pending</span>
											<span *ngIf="item.approve_status=='SUBMITTED' || item.approve_status=='APPROVED'">Approved</span>
											<span *ngIf="item.approve_status=='REJECTED'">Rejected</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'"
												class="badge badge-success badge-pill">Active</span> <span
												*ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In
												active</span></td>
										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
                              
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="openPartnerViewPage(this.partnerList[i].slug)"><i
													class="fa fa-eye"></i></button>&nbsp;
										</td>
									</tr>
								</tbody>

								<tbody *ngIf="partnerList?.length == 0">
									<tr>
										<td colspan="7" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>

							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>