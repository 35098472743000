import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor() {}

  generateExcelUsers(data: any[], fields: string[]): void {
    const filteredData = this.filterDataFieldsUser(data, fields);
    const headers = fields.map((field) => this.formatHeader(field));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    XLSX.utils.sheet_add_json(worksheet, filteredData, {
      origin: "A2",
      skipHeader: true,
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFileUsers(excelBuffer, "users");
  }

  private filterDataFieldsUser(data: any[], fields: string[]): any[] {
    return data.map((row) => {
      const filteredRow: any = {};
      fields.forEach((field) => {
        if (row[field] !== undefined) {
          filteredRow[field] = row[field];
        }
      });
      return filteredRow;
    });
  }

  private saveAsExcelFileUsers(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.xlsx`;
    link.click();
  }

  generateExcel(data: any[], fields: string[]): void {
    const filteredData = this.filterDataFields(data, fields);

    const headers = fields.map((field) => this.formatHeader(field));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    XLSX.utils.sheet_add_json(worksheet, filteredData, {
      origin: "A2",
      skipHeader: true,
    });

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Meetings");
  }

  private formatHeader(header: string): string {
    return header
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
  }

  private filterDataFields(data: any[], fields: string[]): any[] {
    return data.map((row) => {
      const filteredRow: any = {};
      fields.forEach((field) => {
        if (row[field] !== undefined) {
          filteredRow[field] = row[field];
        }
      });
      return filteredRow;
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.xlsx`;
    link.click();
  }
}
