import { Component, OnInit } from "@angular/core";
import { AlertService, PartnerViewService } from "../_services";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-partner-new-view",
  templateUrl: "./partner-new-view.component.html",
  styleUrls: [],
})
export class PartnerNewViewComponent implements OnInit {
  partner_status: any;
  remarkForm: any;
  showloding = true;
  lodingImage = false;
  urlParams: any;
  api_error:any
  is_Error:boolean = false
  errorMessage: string | null = null;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dataService: PartnerViewService,
    private fb: FormBuilder
  ) {}

  partner = {
    partner_remarks: "",
  };

  partnerIndividualDetails = {
    restrict_industry: "",
    industry_name: "",
    approve_status: "",
    city: "",
    country: "",
    city_name: "",
    country_name: "",
    first_name: "",
    created_at: "",
    created_by: "",
    email: "",
    entrepreneurs_supporting_area: "",
    facebook: "",
    gender: "",
    id: "",
    image: "",
    industry: "",
    instagram: "",
    is_active: "",
    is_support_sector: "",
    landline: "",
    last_name: "",
    linkedin: "",
    mobile: "",
    occuption: "",
    organization_name: "",
    remark: "",
    sector_name: "",
    sector: "",
    slug: "",
    spoc_email: "",
    spoc_mobile_no: "",
    state_name: "",
    states: "",
    type: "",
    type_of_emp: "",
    type_of_organization: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    year_of_establishment: "",
    website: "",
    youtube: "",
    year_of_birth: "",
    pincode: "",
    twitter: "",
    entrepreneurs_supporting_area_name: "",
  };

  partnerOrganizationDetails = {
    entrepreneurs_supporting_area_name: "",
    twitter: "",
    approve_status: "",
    pincode: "",
    city: "",
    fullname: "",
    country: "",
    city_name: "",
    country_name: "",
    created_at: "",
    created_by: "",
    email: "",
    entrepreneurs_supporting_area: "",
    facebook: "",
    first_name: "",
    full_name: "",
    gender: "",
    id: "",
    image: "",
    industry: "",
    industry_name: "",
    instagram: "",
    is_active: "",
    is_support_sector: "",
    landline: "",
    last_name: "",
    linkedin: "",
    mobile: "",
    occuption: "",
    organization_name: "",
    remark: "",
    sector_name: "",
    slug: "",
    spoc_email: "",
    spoc_mobile_no: "",
    state_name: "",
    states: "",
    type: "",
    type_of_emp: "",
    type_of_organization: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    year_of_establishment: "",
    website: "",
    youtube: "",
    other_sector: "",
    other_type_of_emp: "",
    pin_code: "",
    sector: "",
    year_of_birth: "",
  };

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.urlParams = params["slug"];
      //console.log(this.urlParams, "glugg");
    });

    this.remarkForm = this.fb.group({
      remarks: ["", [Validators.required, this.noWhitespaceValidator()]],
    });
    this.getPartnerDetails();
  }

  get remarks() {
    return this.remarkForm.get("remarks");
  }

  getPartnerDetails() {
    this.loadShow();

    this.dataService
      .viewPartner(this.urlParams)
      .pipe()
      .subscribe((res) => {
        if(res['status'] == 200)
        if (res["data"]["type"] == "Individual") {
          this.partnerIndividualDetails = res.data;
        
        } else if (res["data"]["type"] == "Organisation") {
          this.partnerOrganizationDetails = res.data;
          //console.log(res["data"], "this.partnerOrganizationDetails");
        } else {
          //console.log(res, "dataatatatatatatat");
        }
        this.loadHide();
      });
  }

  saveSubmit(status: any) {
    // this.remarkForm.reset();
    this.errorMessage = "";
    this.loadShow();

    var data = {
      slug: this.urlParams,
      status: status,
      remark: this.remarkForm.value.remarks
        ? this.remarkForm.value.remarks
        : "",
    };

    //console.log(data, "dataa");

    this.dataService
      .updatePartnerStatusV1(data)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(data["mesg"], true);
          
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.loadHide();
            setTimeout(() => {
              this.router.navigate(["partner-list"]);
            }, 1000);
          } else if (data["status"] == 422) {
            this.loadHide();
              this.is_Error = true
              this.api_error = data.mesg
              setTimeout(()=>{
                this.api_error =''
              }, 3000)
            
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  onBack() {
    this.router.navigate(["/partner-list"]);
  }

  noWhitespaceValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || "").trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  rejectStatus(status) {
    if (this.remarkForm.valid) {
      this.saveSubmit(status);
    } else {
      this.remarkForm.markAllAsTouched();
    }
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
