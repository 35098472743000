<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/rolw.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Email Notifications</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{emailNotificationTotal }}
										</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Email Notification
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table id="email_noti_table" datatable [dtOptions]="dtOptions" class="row-border hover">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Name</th>
										<th>Subject</th>
										<th>Email</th>
										<th>Created At</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody *ngIf="emailNotificationList?.length != 0">
									<tr *ngFor="let item of emailNotificationList;let i = index" [attr.data-index]="i">
										<td>{{i + 1}}</td>
										<td>{{item.name ? item.name :'-'}}</td>
										<td>{{item.Subject}}</td>
										<td>{{item.To_Email}}</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'"
												class="badge badge-success font-weight-normal badge-pill">Active</span>
											<span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In
												active</span>
										</td>
										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											 </label>
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
												(click)="delete(this.emailNotificationList[i].id)"><i
												class="fa fa-trash"></i></button> -->
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="emailNotificationList?.length == 0">
									<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="role-add">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="form-group col-md-6">
									<label for="name" class="col-5 col-form-label">Name <span>*</span> </label>
									<div class="">
										<input class="form-control" type="text" id="name" name="name" required
											[(ngModel)]="email_notification.name" pattern="[A-Za-z ]*">
										<span
											*ngIf="isSubmitted && isFieldsInvalid(email_notification.name)"
											class="alert-block">This field is required</span>
											<span  *ngIf="f.controls.name?.errors?.pattern" class="alert-block">Invalid name format</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="type" class="col-5 col-form-label">Type <span>*</span></label>
									<div class="">
										<select class="form-control" id="type" name="type"
											[(ngModel)]="email_notification.type" required>
											<option value="" selected disabled>Select Type</option>
											<option value="{{item.id}}" *ngFor="let item of typeDrop">{{item.value}}
											</option>
										</select>
									</div>
								</div>

								<div class="form-group col-md-6" *ngIf="email_notification.type=='5'">
									<label for="emails" class="col-5 col-form-label">Specific Email
									</label>
									<div class="">
										<input class="form-control" type="text" id="emails" name="emails"
											[(ngModel)]="email_notification.specific_email">
										<span *ngIf="isSubmitted && (email_notification.specific_email==undefined || email_notification.specific_email=='')"
                                            class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="schedule_type" class="col-5 col-form-label">Schedule Type
										<span>*</span></label>
									<div class="">
										<select class="form-control" id="schedule_type" name="schedule_type"
											[(ngModel)]="email_notification.schedule_type" required>
											<option value="1">Now</option>
											<option value="2">Schedule</option>
										</select>
										<span
											*ngIf="isSubmitted && (email_notification.schedule_type==undefined || email_notification.schedule_type=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6" *ngIf="email_notification.schedule_type=='2'">
									<label for="schedule_date" class="col-5 col-form-label">Schedule Date</label>
									<div class="">
										<input type=date class="form-control" id="schedule_date" name="schedule_date"
											[(ngModel)]="email_notification.schedule_date">
									</div>
								</div>


								<div class="form-group col-md-6">
									<label for="subject" class="col-5 col-form-label">Subject <span>*</span> </label>
									<div class="">
										<input class="form-control" type="text" id="subject" name="subject" required
											[(ngModel)]="email_notification.subject">
										<span
											*ngIf="isSubmitted && isFieldsInvalid(email_notification.subject)"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="is_active" class="col-5 col-form-label">Status</label>
									<div class="">
										<select class="form-control" id="is_active" name="is_active"
											[(ngModel)]="email_notification.is_active">
											<option value="Y">Active</option>
											<option value="N">Inactive</option>
										</select>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
									  <label class=" form-control-label">Attachment 1</label>

									  <div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="attachment_image1 !=''"
										  [src]="attachment_image1" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange1($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText1" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText1"
											[class.ng-valid-right]="required && DisplayedText1" style="height: 35px;">
										</div>

										<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
										  <div class="modal-content">
											<h3 class="text-center mt-2">Image Cropper</h3>
											<div class="py-4 px-4">
											  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
												[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
												(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
												(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
											  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
												href="javascript::void(0)" (click)="exit($event); closeCropper()"
												style="margin-top: 10px;">Save</button>
											</div>
										  </div>
										</ng-template>
										<!-- <span *ngIf="isSubmitted && (this.email_notification.attachement1==undefined || this.email_notification.attachement1=='')" class="alert-block">This
										  field is required</span> -->
									  </div>
									</div>
								  </div>


								  <div class="col-md-6">
									<div class="form-group">
									  <label class=" form-control-label">Attachment 2</label>

									  <div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="attachment_image2 !=''"
										  [src]="attachment_image2" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange2($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText2" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText2"
											[class.ng-valid-right]="required && DisplayedText2" style="height: 35px;">
										</div>

										<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
										  <div class="modal-content">
											<h3 class="text-center mt-2">Image Cropper</h3>
											<div class="py-4 px-4">
											  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
												[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
												(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
												(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
											  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
												href="javascript::void(0)" (click)="exit($event); closeCropper()"
												style="margin-top: 10px;">Save</button>
											</div>
										  </div>
										</ng-template>
										<!-- <span *ngIf="isSubmitted && (this.email_notification.attachement2==undefined || this.email_notification.attachement2=='')" class="alert-block">This
										  field is required</span> -->
									  </div>
									</div>
								  </div>


								<div class="col-md-6">
									<div class="form-group">
									  <label class=" form-control-label">Attachment 3</label>

									  <div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="attachment_image3 !=''"
										  [src]="attachment_image3" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange3($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText3" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText3"
											[class.ng-valid-right]="required && DisplayedText3" style="height: 35px;">
										</div>

										<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
										  <div class="modal-content">
											<h3 class="text-center mt-2">Image Cropper</h3>
											<div class="py-4 px-4">
											  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
												[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
												(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
												(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
											  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
												href="javascript::void(0)" (click)="exit($event); closeCropper()"
												style="margin-top: 10px;">Save</button>
											</div>
										  </div>
										</ng-template>
										<!-- <span *ngIf="isSubmitted && (this.email_notification.attachement2==undefined || this.email_notification.attachement2=='')" class="alert-block">This
										  field is required</span> -->
									  </div>
									</div>
								  </div>

								  <div class="col-md-6">
									<div class="form-group">
									  <label class=" form-control-label">Attachment 4</label>

									  <div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="attachment_image4 !=''"
										  [src]="attachment_image4" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange4($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText4" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText4"
											[class.ng-valid-right]="required && DisplayedText4" style="height: 35px;">
										</div>

										<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
										  <div class="modal-content">
											<h3 class="text-center mt-2">Image Cropper</h3>
											<div class="py-4 px-4">
											  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
												[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
												(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
												(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
											  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
												href="javascript::void(0)" (click)="exit($event); closeCropper()"
												style="margin-top: 10px;">Save</button>
											</div>
										  </div>
										</ng-template>
										<!-- <span *ngIf="isSubmitted && (this.email_notification.attachement2==undefined || this.email_notification.attachement2=='')" class="alert-block">This
										  field is required</span> -->
									  </div>
									</div>
								  </div>


								<div class="form-group col-md-12">
									<label for="body" class="col-form-label">Body <span>*</span></label>
									<div class=" ">
										<angular-editor [config]="configbody" id='body'
											[(ngModel)]="email_notification.body" name="body"></angular-editor>

										<span
											*ngIf=" isSubmitted && (email_notification.body==undefined || email_notification.body=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<!-- <button type="button" class="btn btn-secondary" aria-label="Close"
							(click)="d('Cross click')">Close</button> -->
						<button type="submit" class="btn btn-primary addbtn"
							(click)="save(this.email_notification,f)">Save</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
