import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AlertService, MemberListService ,AppService} from '../_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsletterAnalyticalService } from '../_services/newsletter-analytical.service';
import { EncryptionService } from '../_services/encryption.service';
import { HttpClient } from '@angular/common/http';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-newsletter-analytical',
  templateUrl: './newsletter-analytical.component.html',
  styleUrls: []
})

export class NewsletterAnalyticalComponent implements OnInit {
  newsletterList: any[] = []; // Table Data list
  newsletterTotal: any;

 
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; 
  dtTrigger = new Subject(); 

  newsletterAnalyticalList: any[] = []; // Table Data list
  closeResult: string; // Modal Close

  constructor(
              public router: Router,
              private dataService:NewsletterAnalyticalService,
              private alertService: AlertService,
              private AppService: AppService,
              private modalService: NgbModal,
              private http: HttpClient,
              private enc_dec: EncryptionService,
            ) { }
    
  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    this.getAllNewsletter();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loadHide();

  }

  // allMember(){
  //   this.loadShow();
  //   this.dataService.getNewsLetterAnalytical()
  //       .pipe().subscribe(
  //         res => { this.newsletterAnalyticalList = res['data'];
  //         //console.log(this.newsletterAnalyticalList); 
  //         this.rerender();
  //         this.loadHide();
  //     });
  //   }

getAllNewsletter(){
  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 10,
    serverSide: true,
    processing: true,
    ajax: (dataTablesParameters: any, callback) => {
      this.http
        .post<DataTablesResponse>(
          '/api/newsletter-analytic-list',
          { 'req_data': this.enc_dec.encrypt(JSON.stringify(Object.assign(dataTablesParameters))) }, {}
        ).subscribe((response:any) => {

          const output = this.enc_dec.decrypt(response.res_data);
          var resp = JSON.parse(output);
          //console.log(resp,"resp");
          
          this.newsletterList = resp['data'];
          this.newsletterTotal = resp['recordsTotal'];
          
          callback({
            recordsTotal: resp['recordsTotal'],
            recordsFiltered: resp['recordsFiltered'],
            data: []
          });
        });
      },
    columns: [{ data: 'id' }, { data: 'username' }, { data: 'email' }, { data: 'created_at' }]
  };    
}
   
    
  
  
  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
