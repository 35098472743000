import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class BlogCategoryService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/blogcategory-create', data);
  }

  getBlogcategory(id){
    return this.base.post('/api/blogcategory-edit',{id:id});
  }

  blogcategoryUpdate(data){
    return this.base.post('/api/blogcategory-update',data);
  }

  blogcategoryDelete(id){
    return this.base.post('/api/blogcategory-delete',{id:id});
  }

  getAllBlogcategory(){
    return  this.base.get('/api/get-blogcategory');
  }

}
