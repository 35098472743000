import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {
  AlertService,
  ProgramListService,
  AppService,
} from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseService } from "../_services/base.service";
import { EncryptionService } from "../_services/encryption.service";
import { BehaviorSubject } from "rxjs";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-program-list",
  templateUrl: "./program-list.component.html",
  styles: [],
})
export class ProgramListComponent implements OnInit {
  status$ = new BehaviorSubject<string>("ALL");
  dtOptions: DataTables.Settings = {};
  start: number = 0;
  pageLength: number = 10;
  programList: any[] = [];
  programTotal: any;
  closeResult: string; // Modal Close
  filterData = { status: "ALL" };
  isSubmitted: boolean = false;
  programInfo = {
    id: "",
    mentorship_program_name: "",
    organization_name: "",
    program_start_date: "",
    program_end_date: "",
    mentorship_program_detail: "",
    focus_industry: "",
    total_number_of_participants: "",
    is_paid: "",
    venue_of_the_session: "",
    web_link: "",
    frequency: "",
    duration: "",
    detail_link: "",
    registration_link: "",
    approve_status: "",
    remark: "",
    industry_names: "",
    stage_of_business_names: "",
    ecosystem_key_names: "",
    areas_of_functional_expertise_names: "",
    language_names: "",
    organization_names: "",
    mode_of_engagemen_name: "",
    type_of_mentorship_program_name: "",
  };

  constructor(
    public router: Router,
    private dataService: ProgramListService,
    private enc_dec: EncryptionService,
    private http: HttpClient,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    if (localStorage.getItem("program_approve_status") != null) {
      this.filterData.status = localStorage.getItem("program_approve_status");
    }
    this.getData();
  }

  getData() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/all-program-by-status",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(
                  Object.assign(dataTablesParameters, this.filterData)
                )
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            this.programList = resp["data"];
            //console.log(this.programList, "this.programList ");
            this.programTotal = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "organization_name" },
        { data: "approve_status" },
        { data: "action" },
      ],
    };

    this.loadHide();
    window.scroll(0, 0);
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  openProgramInfo(id, program_info) {
    this.loadShow();

    this.isSubmitted = true;

    if (
      this.programInfo.approve_status === "REJECTED" &&
      (!this.programInfo.remark || this.programInfo.remark.trim() === "")
    ) {
      return;
    }

    this.dataService
      .getProgramInfo(id)
      .pipe()
      .subscribe((data) => {
        let program = data["organisation_program"];
        // //console.log(organisation);
        this.programInfo = {
          id: program.id,
          mentorship_program_name: program.mentorship_program_name,
          organization_name: program.organization_name,
          program_start_date: program.program_start_date,
          program_end_date: program.program_end_date,
          mentorship_program_detail: program.mentorship_program_detail,
          focus_industry: program.focus_industry,
          total_number_of_participants: program.total_number_of_participants,
          is_paid: program.is_paid,
          venue_of_the_session: program.venue_of_the_session,
          web_link: program.web_link,
          frequency: program.frequency,
          duration: program.duration,
          detail_link: program.detail_link,
          registration_link: program.registration_link,
          approve_status: program.approve_status,
          remark: program.remark,
          industry_names: program.industry_names,
          stage_of_business_names: program.stage_of_business_names,
          ecosystem_key_names: program.ecosystem_key_names,
          areas_of_functional_expertise_names:
            program.areas_of_functional_expertise_names,
          language_names: program.language_names,
          organization_names: program.organization_names,
          mode_of_engagemen_name: program.mode_of_engagemen_name,
          type_of_mentorship_program_name:
            program.type_of_mentorship_program_name,
        };
        this.loadHide();
      });
    this.modalReference = this.modalService.open(program_info);
  }

  onChangeStatus(): void {
    if (this.programInfo.approve_status !== "REJECTED") {
      this.programInfo.remark = "";
    }
  }

  programStatusUpdate(program_info) {
    // //console.log(item);
    this.loadShow();
    var data = {
      program_id: this.programInfo.id,
      status: this.programInfo.approve_status,
      remarks: this.programInfo.remark ? this.programInfo.remark : "",
    };
    this.dataService
      .updateProgramStatus(data)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(data["mesg"], true);

            const table = $("#programtable").DataTable();
            table.ajax.reload(null, false);
            this.programList = this.programList.map((program: any) =>
              program.id === data.program_id
                ? {
                    ...program,
                    approve_status: data.status,
                    remark: data.remarks,
                  }
                : program
            );
          } else if (data["status"] == 422) {
            this.alertService.error(data["mesg"], true);
          }
          this.loadHide();
          this.modalService.dismissAll(program_info);
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  checkRemarks() {
    if (this.programInfo.remark) {
      this.programInfo.remark = this.programInfo.remark.trim();
    }
  }

  setFilterData(e: any) {
    this.filterData.status = e.target.value;
    localStorage.setItem("program_approve_status", this.filterData.status);
    const table = $("#programtable").DataTable();
    table.ajax.reload(null, false);
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
