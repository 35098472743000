import { HttpClient } from '@angular/common/http';
import { Component, ContentChild, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EncryptionService } from '../_services/encryption.service';
import { BaseChartDirective } from 'ng2-charts';
import { ChartXAxe } from 'chart.js';

@Component({
  selector: 'app-scroll-bar',
  templateUrl: './scroll-bar.component.html',
  styleUrls: ['./scroll-bar.component.scss']
})


export class ScrollBarComponent implements OnInit {

    @ViewChild('scroll', { static: false }) scroll: ElementRef<HTMLInputElement>;

    @ViewChild('wrapper', { static: false }) wrapper: ElementRef<HTMLDivElement>;
    @ContentChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;
    @Input() step = 10;
    @Input() axis = 'x';
    public labels = [];
    
    onScrollEvent($event) {
      $event.preventDefault();
      if ($event.deltaY < 0) {
        this.scroll.nativeElement.value = `${
          +this.scroll.nativeElement.value - 1
        }`;
      } else {
        this.scroll.nativeElement.value = `${
          +this.scroll.nativeElement.value + 1
        }`;
      }
      this.scrollEvent(+this.scroll.nativeElement.value);
    }

    ngOnInit(): void {
        
    }
  
    ngAfterViewInit() {
      this.initChartConfig();
    }
  
    paginate(array, page_size, page_number) {
      return array.slice(page_number - 1, page_number - 1 + page_size);
    }
  
    scrollEvent(value, preventCollateral = false) {
      value = Math.round(value);
      const min = this.paginate(this.labels, this.step, value)[0];
      const max = this.paginate(this.labels, this.step, value)[this.step - 1];
      const axes = this.axis === 'x' ? 'xAxes' : 'yAxes';
  
      const axesConfig = {};
      axesConfig[axes] = (
        this.chartComponent.chart.options.scales[axes] as ChartXAxe[]
      ).map((el) => ({
        ...el,
        beforeUpdate: () => {
          this.setupScroll();
          this.scrollEvent(1, true);
        },
        ticks: {
          ...el.ticks,
          min,
          max,
        },
      }));
      this.chartComponent.chart.options = {
        ...this.chartComponent.chart.options,
        scales: {
          ...this.chartComponent.chart.options.scales,
          ...axesConfig,
        },
      };
      if (!preventCollateral) {
        this.chartComponent.chart.update();
      }
    }
  
    setupScroll() {
        // Ensure chartComponent and nested properties are available
        if (!this.chartComponent || !this.chartComponent.chart || !this.chartComponent.chart.config || !this.chartComponent.chart.config.data || !this.chartComponent.chart.config.data.labels) {
          return;
        }
      
        // Compare the old labels with the new ones, if they differ, reset the scroll
        if (this.labels.toString() !== this.chartComponent.chart.config.data.labels.toString()) {
          this.scroll.nativeElement.value = '1';
        }
      
        // Get the vertical height for the wrapper
        const widthVertical = `${this.wrapper.nativeElement.offsetHeight}px`;
        
        // Handle the slider wrapper element
        const sliderWrapperVertical =
          this.wrapper.nativeElement.querySelector<HTMLDivElement>('.slider-wrapper-vertical');
        
        if (sliderWrapperVertical) {
          sliderWrapperVertical.style.setProperty('--width-vertical', widthVertical);
        }
      
        // Set the width on the scroll element itself
        this.scroll.nativeElement.style.setProperty('--width-vertical', widthVertical);
      
        // Store the labels
        this.labels = this.chartComponent.chart.config.data.labels;
      
        // Calculate the number of pages based on labels
        const pages = Math.max(this.labels.length - this.step + 1, 1);
      
        // Set the width of the scrollbar dynamically
        this.scroll.nativeElement.style.setProperty('--width', Math.max(10, 100 / pages) + '%');
      
        // Toggle visibility of the scroll bar based on the number of pages
        if (pages === 1) {
          this.scroll.nativeElement.style.display = 'none';
        } else {
          this.scroll.nativeElement.style.display = 'block';
        }
      
        // Set the max value of the scroll
        this.scroll.nativeElement.max = `${pages}`;
      }
      
  
    initChartConfig() {
      this.setupScroll();
      this.scrollEvent(1);
      this.scroll.nativeElement.addEventListener('input', (event: any) =>
        this.scrollEvent(parseInt(event.target.value))
      );
    }
  
  
}