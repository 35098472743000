import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AboutService {
    
    constructor(private base: BaseService) { }

    getAboutData() {
        return this.base.get('/api/get-about');
    }
    aboutUpdate(data){
    	return this.base.post('/api/about-update',data);
    }

    
}