<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/rolw.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Media And Announcement</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{mediaAndAnnouncementTotal }}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Media and Announcement
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">

						<div class="table-responsive">
								<table datatable id="mediaAndAnnouncement"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Title</th>
										<th>Link</th>
										<th>Status</th>
										<th>Created At</th>
										<th>Action</th>
									</tr>
								</thead>

									<tbody *ngIf="mediaAndAnnouncementList?.length != 0">
									<tr *ngFor="let item of mediaAndAnnouncementList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.title}}</td>
										<td>{{item.link}}</td>
										
										<td><span *ngIf="item.status=='Y'"
												class="badge badge-success font-weight-normal badge-pill">Active</span>
											<span *ngIf="item.status=='N'" class="badge badge-danger badge-pill">In
												active</span></td>
										<td>{{item.created_at}}</td>
										<td class="action">

												<label class="switch">
													<input 
														type="checkbox"
														(change)="delete(item)"
														[checked]="item.status === 'Y'">
													<span class="slider round"></span>
												</label>

											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="edit(this.mediaAndAnnouncementList[i].id,content)"><i
													class="fa fa-edit"></i></button>
											
										</td>
									</tr>
								</tbody>

								<tbody *ngIf="mediaAndAnnouncementList?.length == 0">
									<tr>
										<td colspan="7" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #content let-c="close" let-d="dismiss" id="role-add">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>

					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
					  </alert>

						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="form-row">
								<!-- Title Field -->
								<div class="form-group col-md-6">
									<label for="title">Title <span>*</span></label>
									<input
										class="form-control"
										type="text"
										id="title"
										name="title"
										required
										[(ngModel)]="media_and_announcement.title"
									/>
									<span *ngIf="isSubmitted && isFieldsInvalid(media_and_announcement.title)" class="alert-block">
										This field is required
									</span>
								</div>
						
								<!-- Link Field -->
								<div class="form-group col-md-6">
									<label for="link">Link <span class="text-danger">*</span></label>
									<input
										class="form-control"
										pattern="https?://.+"
										type="text"
										id="link"
										name="link"
										[(ngModel)]="media_and_announcement.link"
										required
										#link="ngModel"
									/>
									<span *ngIf="isSubmitted && isFieldsInvalid(media_and_announcement.link)" class="alert-block">
										This field is required
									</span>
									<span *ngIf="isSubmitted && link?.errors?.pattern" class="alert-block">
										Please enter a valid URL
									</span>
								</div>
							</div>
						
							<div class="form-row">
								<!-- Image Upload -->
								<div class="form-group col-md-6">
									<label for="image" class="form-control-label">Image <span>*</span></label>
									<div>
										<img
											*ngIf="croppedImage !== ''"
											[src]="croppedImage"
											class="img-thumbnail mb-3"
											style="height: 100px; width: 100px;"
										/>
										<div class="input-group">
											<span class="input-group-btn">
												<label class="btn btn-dark">
													Browse...
													<input
														type="file"
														accept="{{fileType}}"
														style="display: none;"
														(change)="onFileChange($event)"
														[attr.multiple]="allowMultiple"
													/>
												</label>
											</span>
											<input
												type="text"
												class="form-control"
												[value]="DisplayedText"
												disabled
												style="height: 35px;"
											/>
										</div>
										<span *ngIf="isSubmitted && !isEditMode && !fileList" class="alert-block">
											This field is required
										 </span>
									</div>
								</div>
						
								<!-- Status Field -->
								<div class="form-group col-md-6">
									<label for="status">Status</label>
									<select
										class="form-control"
										id="status"
										name="status"
										[(ngModel)]="media_and_announcement.status"
									>
										<option value="Y">Active</option>
										<option value="N">Inactive</option>
									</select>
								</div>
							</div>
						</form>
						
					</div>
					<div class="modal-footer">
						
                     <button type="submit" class="btn btn-primary addbtn" (click)="save(this.media_and_announcement,f)">Save</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>