<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/banner.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Atr miscellaneous</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{AtrMiscellaneousList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											 style="float: right;">
											Add ATR Miscellaneous
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
						<div id="table" class="table-responsive">
							<table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									  </tr>
								  </thead>
								<tbody>
									<tr *ngFor="let item of AwardToRewardList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<td>{{item.start_date}}</td>
                                        <td>{{item.end_date}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span> <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">Inactive</span></td>
										<td class="action">
                                            <!-- <button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(this.AwardToRewardList[i].id,content)"><i class="fa fa-edit"></i></button> -->
                                            <!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light" (click)="delete(this.AwardToRewardList[i].id)"><i class="fa fa-trash"></i></button> -->
                                        </td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>			
		</div>
		
		<ng-template #content let-c="close" let-d="dismiss" id="customer-add"> <!-- class="modal fade" -->
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								
							<div class="form-group col-md-6">
							  <label for="name" class="col-form-label">Name<span>*</span></label>
								<div class=" ">
									<input class="form-control" type="text" id="name" name="name" required [(ngModel)]="awardtoreward.name"  >
									<span *ngIf="isSubmitted && (awardtoreward.name==undefined || awardtoreward.name=='')" class="alert-block">This field is required</span>
								</div>
                              </div>
                     
							
							<div class="form-group col-md-6">
								<label for="type" class="col-form-label">Type <span>*</span> </label>
								<div class=" ">
									<select class="form-control" id="type" name="type" [(ngModel)]="awardtoreward.type">
										<option value="Offline">Offline</option>
										<option value="Online">Online</option>
									</select>
								   <span *ngIf="isSubmitted && (awardtoreward.type==undefined || awardtoreward.type=='')" class="alert-block">This field is required</span>
								  </div>
							     </div>
						      </div>
					       </form>
					     </div>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close" (click)="d('Cross click')">Close</button>
						<button type="submit" class="btn btn-primary rounded savebtn" >Save</button>
					</div>
				</div>
			</div>
		</ng-template>	
	</div>
</div>