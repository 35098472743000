import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MentorSlotsService {
  constructor(private base: BaseService) { }  

 
  slotListMentor(){
    return  this.base.get('/api/all-mentor-slot');
  }
  
  slotAddEditMentor(data) {
    return  this.base.post('/api/create-mentor-admin-slot', data);
  }

 

}