<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/video.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Video</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{videoList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Video
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
						<div id="table" class="table-responsive">
							<table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Name</th>
										<!-- <th>Video Type</th> -->
                                        <th>State</th>
                                        <th>City</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of videoList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<!-- <td>{{item.video_type}}</td> -->
										<td>{{item.state_name}}</td>
                                        <td>{{item.city_name}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span> <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">Inactive</span></td>
										<td class="action">

											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
                                            <button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(this.videoList[i].id,content)"><i class="fa fa-edit"></i></button>
                                            <!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light" (click)="delete(this.videoList[i].id)"><i class="fa fa-trash"></i></button> -->
											<!-- <ui-switch size="small" defaultBgColor="red" (change)="delete(this.videoList[i].id)" *ngIf="item.is_active=='Y'" checked></ui-switch>
											<ui-switch size="small" defaultBgColor="red" (change)="delete(this.videoList[i].id)" *ngIf="item.is_active=='N'"></ui-switch> -->
                                        </td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<ng-template #content let-c="close" let-d="dismiss" id="customer-add"> <!-- class="modal fade" -->
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group ">
										<label for="name" class=" col-form-label">Name <span>*</span></label>
										<div class="">
											<input class="form-control" type="text" id="name" name="name" required [(ngModel)]="video.name" pattern="[A-Za-z ]*">
											<span *ngIf="isSubmitted && this.isFieldsInvalid(this.video.name)" class="alert-block">This field is required</span>
											<!-- <span  *ngIf="f.controls.name.errors.pattern" 	class="alert-block">Invalid name format</span> -->

										</div>
									</div>		
								</div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="category_id" class=" form-control-label">Category
											<span>*</span></label>
										<div class="">
											<select class="form-control" id="category_id" name="category_id" required
												[(ngModel)]="video.category_id">
												<option value="" selected disabled>Please Select</option>
												<option *ngFor="let item of cat" value="{{item.id}}">{{item.name}}
												</option>
											</select>
											<span
												*ngIf="isSubmitted && (video.category_id==undefined || video.category_id=='')"
												class="alert-block">This field is required</span>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="video_type" class=" col-form-label">Video Type <span>*</span></label>
										<div class="">
											<select class="form-control" id="video_type" name="video_type" required [(ngModel)]="video.video_type">
												<option value="1">URL</option>
												<option value="2">Upload</option>
											</select>
											<span *ngIf="isSubmitted && (video.video_type==undefined || video.video_type=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>
								
								<div class="col-md-6">
									<div class="form-group " *ngIf="video.video_type =='1'">
										<label for="video_url" class=" col-form-label">Video Url<span>*</span></label>
										<div class="">
											<textarea class="form-control" type="text" id="video_url" name="video_url" [(ngModel)]="video.video_url"></textarea>
											<span *ngIf="isSubmitted && (video.video_url==undefined || video.video_url=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group " *ngIf="video.video_type =='2'">
										<label class=" form-control-label">Video File <span>*</span></label>
										<div class="">
											<video style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="video.video_url !=''" >
												<source [src]="video.video_url">
											</video>
											<div class="input-group">
												<span class="input-group-btn">
														<label class="btn btn-dark">
															<samp>Browse</samp>...
															<input type="file" accept="{{fileType}}"  style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple">
														</label>
													</span>
												<input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText"  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
												<!-- <span *ngIf="isSubmitted && (video.video_url==undefined || video.video_url=='')" class="alert-block">This field is required</span> -->
											</div>
										</div>
									</div>
								</div>
								
								<div class="col-md-6" *ngIf="video.video_type =='1'">
                                    <div class="form-group">
                                        <label class="form-control-label">Video Thumbnail <span>*</span></label>
                                        <div class=" ">										
                                            <div class="input-group">
                                                <span class="input-group-btn">
                                                    <label class="btn btn-dark">
                                                        <samp>Browse</samp>...
                                                        <input type="file" accept="{{fileType}}" style="display:none;" (change)="fileChange1($event)" [attr.multiple]="allowMultiple">
                                                    </label>
                                                </span>
                                                <input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText" [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                                            </div>
                                            <img style="height: 100px;width: 100px;" class="img-thumbnail mt-3" *ngIf="video.video_thumb !=''" [src]="video.video_thumb" />
                                            <!-- <span *ngIf="isSubmitted && (video.video_thumb==undefined || video.video_thumb=='')" class="alert-block">This field is required</span> -->
                                        </div>
                                    </div>
                                </div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="vartical_ids" class=" form-control-label">Vertical <span>*</span></label>
										<div class="">
											<!-- <select class="form-control" id="event_categorys_ids" name="event_categorys_ids" required [(ngModel)]="video.event_categorys_ids">
												<option value="" selected disabled>Please Select</option>
												<option *ngFor="let item of cat" value="{{item.id}}">{{item.name}}</option>
											</select> -->
											<ng-select [items]="verticals" name="vartical_ids" [multiple]="true" bindLabel="name" bindValue="id"
												[(ngModel)]="selectedToVertical" required (change)="changeToVertical()">
											</ng-select>
											<span *ngIf="isSubmitted && (video.vartical_ids==undefined || video.vartical_ids=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="sector_of_business" class=" form-control-label">Sector of Business <span>*</span></label>
										<div class="">											
											<ng-select [items]="AreaSectorOfBusiness" name="sector_of_business" [multiple]="true" bindLabel="name" bindValue="id"
												[(ngModel)]="selectedTo" required (change)="changeTo()">
											</ng-select>
											<span *ngIf="isSubmitted && (video.sector_of_business==undefined || video.sector_of_business=='')" class="alert-block">This field is required</span>
										</div>
									</div>			
								</div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="sectorofenterprise" class=" form-control-label">Sector of Enterprise <span>*</span></label>
										<div class="">
											<ng-select [items]="sectorofenterprise" name="sectorofenterprise" [multiple]="true" bindLabel="name" bindValue="id"
												[(ngModel)]="selectedToEnterprise" required (change)="changeToEnterprise()">
											</ng-select>
											<span *ngIf="isSubmitted && (video.sectorofenterprise==undefined || video.sectorofenterprise=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group ">
										<label for="areaofinterest" class=" form-control-label">Area of Interest <span>*</span></label>
										<div class="">
											<ng-select [items]="areaofinterest" name="areaofinterest" [multiple]="true" bindLabel="name" bindValue="id"
												[(ngModel)]="selectedToInterest" required (change)="changeToInterest()">
											</ng-select>
											<span *ngIf="isSubmitted && (video.areaofinterest==undefined || video.areaofinterest=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group ">
										<label for="areaofstudy" class=" form-control-label">Area of Study <span>*</span></label>
										<div class="">
											<ng-select [items]="areaofstudy" name="areaofstudy" [multiple]="true" bindLabel="name" bindValue="id"
												[(ngModel)]="selectedToStudy" required (change)="changeToStudy()">
											</ng-select>
											<span *ngIf="isSubmitted && (video.areaofstudy==undefined || video.areaofstudy=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group ">
										<label for="state" class=" form-control-label">State <span>*</span></label>
										<div class="">
											<select class="form-control" id="state" name="state" required [(ngModel)]="video.state" (ngModelChange)="selectCityById($event)">
												<option value="" selected disabled>Please Select</option>
												<option *ngFor="let item of states" value="{{item.id}}">{{item.state_name}}</option>
											</select>
											<span *ngIf="isSubmitted && (video.state==undefined || video.state=='')" class="alert-block">This field is required</span>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<!-- *ngIf="video.state !=''" -->
									<div class="form-group">
										<label for="city" class=" form-control-label">City <span>*</span></label>
										<div class="">
											<select class="form-control" id="city" name="city" required [(ngModel)]="video.city">
												<option value="" selected>Please Select</option>
												<option *ngFor="let item of cities" value="{{item.id}}">{{item.city_name}}</option>
											</select>
											<span *ngIf="isSubmitted && (video.city==undefined || video.city=='')" class="alert-block">This field is required</span>
										</div>
									</div>		
								</div>
								
								<div class="form-group col-md-6">
									<label for="home_display" class="col-form-label">Home Display</label>
									<div class="  ">
										<select class="form-control" id="home_display" name="home_display" [(ngModel)]="video.home_display">
											<option value="N">No</option>
											<option value="Y">Yes</option>
										</select>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="is_active" class=" col-form-label">Status</label>
										<div class="">
											<select class="form-control" id="is_active" name="is_active" [(ngModel)]="video.is_active">
												<option value="N">Inactive</option>
												<option value="Y">Active</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close" (click)="d('Cross click')">Close</button>
						<button type="submit" class="btn btn-primary savebtn rounded" (click) ="save(this.video,f)">Save</button>

					</div>
				</div>
			</div>
		</ng-template>	
	</div>
</div>