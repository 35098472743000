import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AlertService, RoleService } from '../_services';
import { Router } from '@angular/router';
import { MediaAndAnnouncementService } from '../_services/media-and-announcement.service';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../_services/encryption.service';
import { DomSanitizer } from '@angular/platform-browser';

class MediaAndAnnouncement {
  id: number;
  title: string;
  link: string;
  image:string;
  status: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-media-and-annoucement',
  templateUrl: './media-and-annoucement.component.html',
  styleUrls: ['./media-and-annoucement.component.sass']
})

export class MediaAndAnnoucementComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isSubmitted :boolean = false;
  croppedImage: any = "";
  mobileCroppedImage: any = "";
  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg, .jpeg, .png, .bmp";
  fileList: any;
  @Input() required: boolean;
  DisplayedText :string = ''
  isEditMode: boolean = false;

  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };

  dtTrigger = new Subject(); 
  mediaAndAnnouncementList: MediaAndAnnouncement[] = [];
  clicked = false;
  mediaAndAnnouncementTotal:any
  start: number = 0;
  pageLength: number = 10;

  getMediaAndAnnouncement = {
    id: "",
    title: "",
    link: "",
    image: "",
    status: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;

  constructor(
    public router: Router,
    private dataService: MediaAndAnnouncementService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private sanitizer: DomSanitizer
  ) {}


  media_and_announcement = {
    id: "",
    title: "",
    link: "",
    image: "",
    status: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllMediaAndAnnouncement();
    this.loadHide()
  }

  getAllMediaAndAnnouncement() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/media-annoncement-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            const resp = JSON.parse(output);
            //console.log(resp, "get media and announcement");

            this.mediaAndAnnouncementList = resp['data']

            this.mediaAndAnnouncementTotal = resp["recordsTotal"];

            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "title" },
        { data: "link" },
        { data: "status" },
        { data: "created_at" }

      ],
    };
  }


  open(content) {
    this.croppedImage = "";
    this.DisplayedText = ''
    this.isSubmitted = false;
    this.media_and_announcement.id = "";
    (this.media_and_announcement.title = ""),
    (this.media_and_announcement.link = ""),
    (this.media_and_announcement.image = "");
    (this.media_and_announcement.status = "Y");
    this.modalTitle = "Add Role";
    this.modalReference = this.modalService.open(content);
  }


  onFileChange(event: any) {
    this.fileList = event.target.files;
    let hasFile = this.fileList && this.fileList.length > 0;
    this.DisplayedText = this.fileList[0].name

    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = ".jpg , .jpeg, .png , .bmp";

      // Validate file type
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "Please select a valid file type. Supported file types are .jpg, .jpeg, .png, .bmp"
        );
        this.fileList = null;
        return false;
      }

      // Validate file size
      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          // Check dimensions
          if (img.height > img.width) {
            alert(
              "Please select a banner image (width should be greater than height)."
            );
            this.fileList = null;
            this.DisplayedText = ''
            this.croppedImage = ""; // Reset the image preview
            event.target.value = ""; // Reset the file input
            return;
          } else {
            this.croppedImage = reader.result as string;
            this.getMediaAndAnnouncement.image = this.croppedImage;
          }
        };
      };
    }
    return false;
  }


  edit(id, content) {
    this.isEditMode = true;
    this.loadShow();
    this.dataService
      .edit(id)
      .pipe()
      .subscribe((res) => {
        
        this.getMediaAndAnnouncement = res["data"];
        this.croppedImage = this.getMediaAndAnnouncement.image || "";
        this.media_and_announcement = {
          id: this.getMediaAndAnnouncement.id,
          title: this.getMediaAndAnnouncement.title,
          link: this.getMediaAndAnnouncement.link,
          image : this.getMediaAndAnnouncement.image,
          status: this.getMediaAndAnnouncement.status,
        };
        this.loadHide();
        this.modalTitle = "Edit Media And Announcement";
        this.modalReference = this.modalService.open(content);
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    this.isEditMode = !!val.id;

    if (this.isFieldsInvalid(this.media_and_announcement.title)) {
      return;
    }

    
    if (!this.isEditMode && !this.fileList) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }


    $(":submit").attr("disabled", "disabled");
    if (f.form.valid) {
      let formData: FormData = new FormData();

      formData.append("title", this.media_and_announcement.title);
      formData.append("status", this.media_and_announcement.status);
      formData.append("link", this.media_and_announcement.link);

      this.loadShow;
      if (val.id == "") {
        if (this.fileList !== undefined) {
          formData.append("image", this.croppedImage);
        }

        this.dataService
          .save(formData)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("Role saved successfully", true);
              const table = $("#mediaAndAnnouncement").DataTable();
              table.ajax.reload(null, false);
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
            }
          );
      } else {
        formData.append("id", this.media_and_announcement.id);
        if (this.fileList && this.fileList.length > 0) {
          formData.append("image", this.croppedImage);
        } else {
          formData.append("image", "");
        }
        this.dataService
          .update(formData)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success("Role updated successfully", true);
              const table = $("#mediaAndAnnouncement").DataTable();
              table.ajax.reload(null, false);
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
            }
          );
       }
     }
    this.loadHide;
  }


  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }


  delete(item) {
    this.loadShow();
    this.dataService
      .delete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Media And Announcement deleted successfully", true);
            item.status = item.status === "Y" ? "N" : "Y";
            const table = $("#mediaAndAnnouncement").DataTable();
            table.ajax.reload(null, false);
          } else {
            this.alertService.error("Role error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }


  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
