<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn mb-2">
		<div class="row">
			<div class="col-lg-12">
				<div class="card p-3">
					<ngb-tabset>
						<ngb-tab title="Profile Setting">
							<ng-template ngbTabContent>
								<form method="post" enctype="multipart/form-data" #f="ngForm">
									<div class="col-lg-6 px-3">
										<div class="form-group row">
											<label for="name" class="col-12 col-form-label">Name <span>*</span> </label>
											<div class="col-12">
												<input class="form-control" type="text" id="name" name="name"
													[(ngModel)]="profile.name" required>
												<span
													*ngIf="isSubmitted && (profile.name==undefined || profile.name=='')"
													class="alert-block">This field is required</span>
											</div>
										</div>

										<div class="form-group row">
											<label for="phone" class="col-12 col-form-label">Phone <span>*</span>
											</label>
											<div class="col-12">
												<input class="form-control" type="text" id="phone" name="phone" required
													[(ngModel)]="profile.phone">
												<span
													*ngIf="isSubmitted && (profile.phone==undefined || profile.phone=='')"
													class="alert-block">This field is required</span>
											</div>
										</div>


										<div class="form-group row">
											<label for="email" class="col-12 col-form-label">Email
												<span>*</span></label>
											<div class="col-12">
												<input class="form-control" type="text" id="email" name="email" readonly
													[(ngModel)]="profile.email">
													<span
													*ngIf="isSubmitted && (profile.email==undefined || profile.email=='')"
													class="alert-block">This field is required</span>
											</div>
										</div>


										<div class="form-group row">
											<label for="address" class="col-12 col-form-label">Address <span>*</span>
											</label>
											<div class="col-12">
												<input class="form-control" type="text" id="address" name="address"
													required [(ngModel)]="profile.address">
													<span
													*ngIf="isSubmitted && (profile.address==undefined || profile.address=='')"
													class="alert-block">This field is required</span>
											</div>
										</div>
										<div class="form-group row">
											<label class="col-md-12 form-control-label">image</label>
											<div class="col-md-12">
												<p>

													<img style="height: 150px;width: 150px;" class="img-thumbnail"
														*ngIf="profile.image !=''" [src]="profile.image" />
												</p>
												<div class="input-group">
													<span class="input-group-btn">
														<label class="btn btn-success">
															<samp>Browse</samp>...

															<input type="file" accept="{{fileType}}" name="file"
																style="display: none;" (change)="onFileChange($event)"
																[attr.multiple]="allowMultiple">
														</label>
													</span>
													<input type="text" class="form-control" [value]="DisplayedText"
														[class.ng-invalid-right]="required && !DisplayedText"
														[class.ng-valid-right]="required && DisplayedText"
														style="height: 35px;">
												</div>
											</div>
										</div>
										<p class="text-success">{{update_profile}}</p>
										<button type="submit" class="btn btn-primary addbtn mt-2"
											 (click)="UpdateProfile(this.profile,f)">Update
											Profile</button>
									</div>
								</form>
							</ng-template>
						</ngb-tab>
						<ngb-tab title=" Change Password">
							<ng-template ngbTabContent>
								<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
									<div class="col-lg-6 px-3">
										<div class="form-group row">
											<label for="currentPassword" class="col-12 col-form-label">Current Password
												<span>*</span> </label>
											<div class="col-12">
												<input class="form-control" type="password" id="currentPassword"
													name="currentPassword" required
													[(ngModel)]="password.currentPassword">
												<span
													*ngIf="isSubmitted && (password.currentPassword==undefined || password.currentPassword=='')"
													class="alert-block">This field is required</span>

											</div>
										</div>
										<div class="form-group row">
											<label for="newPassword" class="col-12 col-form-label">New Password
												<span>*</span> </label>
											<div class="col-12">
												<input class="form-control" type="password" id="newPassword"
													name="newPassword" required [(ngModel)]="password.newPassword">
												<span
													*ngIf="isSubmitted && (password.newPassword==undefined || password.newPassword=='')"
													class="alert-block">This field is required</span>

											</div>
										</div>

										<div class="form-group row">
											<label for="confirmPassword" class="col-12 col-form-label">Confirm Password
												<span>*</span></label>
											<div class="col-12">
												<input class="form-control" type="password" id="confirmPassword"
													name="confirmPassword" required
													[(ngModel)]="password.confirmPassword">
												<span
													*ngIf="isSubmitted && (password.confirmPassword==undefined || password.confirmPassword=='')"
													class="alert-block">This field is required</span>

											</div>
										</div>

										<div class="alert-block mb-3">
											<span *ngIf="isError">{{showError}}</span>

										</div>

										<p class="text-success">{{update_pass}}</p>
										<button type="submit" class="btn btn-primary addbtn mt-3"
											(click)="UpdatePassword(this.password,f)">Update Password</button>
									</div>
								</form>
							</ng-template>
						</ngb-tab>
					</ngb-tabset>
				</div>

			</div>
		</div>
	</div>
</div>