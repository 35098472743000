import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, CityService, AppService } from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class City {
  id: number;
  city_name: string;
  state_id: number;
  state_name: string;
  country_id: number;
  country_name: string;
  created_by_name: string;
  created_at: string;
  is_active: string;
}

@Component({
  selector: "app-city",
  templateUrl: "./city.component.html",
})
export class CityComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [7], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable
  cityList: City[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getCity = {
    id: "",
    city_name: "",
    state_id: "",
    country_id: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  cntry = {};
  stat = {};
  constructor(
    public router: Router,
    private dataService: CityService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  city = {
    id: "",
    city_name: "",
    state_id: "",
    country_id: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.allCity();
    this.dataService
      .getAllCountry()
      .pipe()
      .subscribe((data) => {
        this.cntry = data["country"];
      });
    this.dataService
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.stat = data["state"];
      });
  }

  allCity() {
    this.loadShow();
    this.dataService
      .getAllCity()
      .pipe()
      .subscribe((data) => {
        this.cityList = data["city"];
        this.rerender();
        this.loadHide();
      });
  }

  selectStateById(id) {
    this.dataService
      .getStateById(id)
      .pipe()
      .subscribe((data) => {
        this.stat = data["state"];
      });
  }

  open(content) {
    this.isSubmitted = false;
    this.city.id = "";
    (this.city.city_name = ""),
      (this.city.state_id = ""),
      (this.city.country_id = "");
    this.city.is_active = "Y";
    this.modalTitle = "Add City";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getCity(id)
      .pipe()
      .subscribe((data) => {
        this.getCity = data["city"];
        this.selectStateById(this.getCity.country_id);
        // let state_id;
        // if(this.getCity.state_id==null){
        //   state_id ='';
        // }else{
        //   state_id = this.getCity.state_id;
        //   this.selectStateById(state_id);
        // }
        //console.log(this.getCity, "getxityy");

        this.city = {
          id: this.getCity.id,
          country_id: this.getCity.country_id,
          city_name: this.getCity.city_name,
          state_id: this.getCity.state_id,
          is_active: this.getCity.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit City";
        this.modalReference = this.modalService.open(content);
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.city.city_name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.city)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("City saved successfully", true);
              this.allCity();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .cityUpdate(this.city)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("City updated successfully", true);
              this.allCity();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.cityDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('City deleted successfully', true);
  //             this.allCity();
  //           }else{
  //             this.alertService.error('City error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //      }
  //    }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .cityDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("City deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allCity();
          } else {
            this.alertService.error("City error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
