import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsletterService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/newsletter-create', data);
  }

  newsletterUpdate(data){
    return this.base.post('/api/newsletter-update',data);
  }

  newsletterDelete(id){
    return this.base.post('/api/newsletter-delete',{id:id});
  }

  getAllNewsletter(){
    return  this.base.get('/api/get-newsletter');
  }

  getNewsletter(id){
    return this.base.post('/api/newsletter-edit',{id:id});
  }

}
