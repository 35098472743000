import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  AlertService,
  AreaOfSpecializationService,
  AppService,
} from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class AreaOfSpecialization {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-area-of-specialization",
  templateUrl: "./area-of-specialization.component.html",
})
export class AreaOfSpecializationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  areaofspecializationList: AreaOfSpecialization[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getAreaofspecialization = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  constructor(
    public router: Router,
    private dataService: AreaOfSpecializationService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  areaofspecialization = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allAreaofspecialization();
  }

  allAreaofspecialization() {
    this.loadShow();
    this.dataService
      .getAllAreaofspecialization()
      .pipe()
      .subscribe((data) => {
        this.areaofspecializationList = data["areaofspecialization"];
        this.rerender();
        this.loadHide();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  open(content) {
    this.isSubmitted = false;
    if (this.getAreaofspecialization.id != null) {
      this.getAreaofspecialization.id = "";
    }
    this.areaofspecialization.name = "";
    this.areaofspecialization.description = "";
    this.areaofspecialization.is_active = "Y";
    this.modalTitle = "Add area of specialization";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getAreaofspecialization(id)
      .pipe()
      .subscribe((data) => {
        this.getAreaofspecialization = data["areaofspecialization"];
        this.areaofspecialization = {
          id: this.getAreaofspecialization.id,
          name: this.getAreaofspecialization.name,
          description: this.getAreaofspecialization.description,
          is_active: this.getAreaofspecialization.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit area of specialization";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.areaofspecialization.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.areaofspecialization)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Area of specialization saved successfully",
                  true
                );
                this.allAreaofspecialization();
              } else if (data["status"] == 422) {
                this.alertService.error(data.mesg.name);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
                this.loadHide();
              } else {
                this.alertService.error(data.message);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
                this.loadHide();
              }
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .areaofspecializationUpdate(this.areaofspecialization)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Area of specialization updated successfully",
                  true
                );
                this.allAreaofspecialization();
              } else if (data["status"] == 300) {
                this.loadHide();
                this.alertService.error(data.mesg);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
              }
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.areaofspecializationDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Area of specialization deleted successfully', true);
  //             this.allAreaofspecialization();
  //           }else{
  //             this.alertService.error('Area of specialization error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //     }
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .areaofspecializationDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Area of specialization deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allAreaofspecialization();
          } else {
            this.alertService.error("Area of specialization error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
