import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-mentorship-metrics',
  templateUrl: './mentorship-metrics.component.html',
  styleUrls: ['./mentorship-metrics.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MentorshipMetricsComponent implements OnInit {
  public barChartLegend = true;
  public barChartPlugins = [];
   // Bar Chart Data
   public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40, 28, 48, 40, 19, 86, 40], label: 'Number of Mentors' },
  ];

    // Bar Chart Labels
    public barChartLabels: Label[] = [
      'jan-24',
      'feb-24',
      'mar-24',
      'apr-24',
      'may-24',
      'jun-24',
      'jul-24',
      'aug-24',
      'sep-24',
      'oct-24',
      'nov-24',
      'dec-24',
      'jan-25',
    ];
    // Bar Chart Options
    public barChartOptions = {
      responsive: true,
      maintainAspectRatio: false, // Ensures the chart respects container height/width
      scales: {
        x: {
          ticks: {
            autoSkip: false, // Ensures all labels are displayed
            minRotation: 0, // Keeps labels horizontal
            maxRotation: 0,
          },
          grid: {
            display: false, // Optional: Hide vertical gridlines for a cleaner look
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true, // Enables tooltips for interactivity
        },
      },
      layout: {
        padding: 10, // Adds padding around the chart
      },
      datasets: {
        bar: {
          barThickness: 20,
          categoryPercentage: 1.0, // Ensures full category width is used
          barPercentage: 0.5, // Sets the gap between bars within the category
        },
      },
    };

    // Bar Chart Type
    public chartTypeBar: string = 'bar';

    // Show Chart Legend
    public chartLegendBar = true;

    // end

    //line1 graph
   
    public chartDataL1: ChartDataSets[] = [
      { data: [0, 53, 47, 139], label: 'Mentee' },
      { data: [0, 48, 28, 120], label: 'Mentor' }
    ];

    
    public chartLabelsL1: Label[] = ['Jan-24', 'Feb-24', 'Mar-24', 'Jul-24'];

    
    public chartOptionsL1 = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            stepSize: 5 // Adjust this value to increase or decrease the gap between Y-axis values
          }
        }
      }
    };

    public chartTypeL1: string = 'line';

    public chartLegendL1 = true;

    // Add custom colors here
    public chartColorsL1 = [
      {
        borderColor: 'rgba(255, 99, 132, 1)', // Color for the Mentee line
        backgroundColor: 'transparent' // Fill color for the Mentee line
      },
      {
        borderColor: 'rgba(54, 162, 235, 1)', // Color for the Mentor line
        backgroundColor: 'transparent' // Fill color for the Mentor line
      }
    ];
  chartComponent: any;

  
    


        //line2 graph
        public chartDataL2 : ChartDataSets[] = [
          { data: [0, 59, 80, 81, 56, 55, 40], label: 'Request Sent' },
          { data: [0, 48, 40, 19, 86, 27, 90], label: 'Request Accepted' }
        ];
        public chartLabelsL2 : Label[]  = ['Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jul-24'];
    
        public chartOptionsL2 = {
          responsive: true,
          maintainAspectRatio: false
        };
    
        public chartTypeL2: string = 'line';
    
        public chartLegendL2 = true;
    
        // Add custom colors here
        public chartColorsL2 = [
          {
            borderColor: 'rgba(255, 99, 132, 1)', // Color for the Mentee line
            backgroundColor: 'transparent' // Fill color for the Mentee line
          },
          {
            borderColor: 'rgba(54, 162, 235, 1)', // Color for the Mentor line
            backgroundColor: 'transparent' // Fill color for the Mentor line
          }
        ];
    
        //end


    //line3 graph
    public chartDataL3 : ChartDataSets[]  = [
      { data: [0, 35, 45, 26, 48, 33, 19, 27, 27, 76, 65, 40, 19, 86, 58, 48, 86, 27, 59, 80, 81, 48, 40, 19, 86, 27, 56, 48, 40, 19, 86, 58, 48, 27, 55, 40], label: 'Request Sent' },
      { data: [0, 48, 40, 19, 48, 40, 19, 86, 27, 56, 48, 40, 45, 86, 58, 48, 33, 27, 59, 80, 81, 48, 40, 19, 86, 27, 56, 48, 40, 92, 86, 58, 45, 27, 55,  90], label: 'Request Accepted' }
    ];
    public chartLabelsL3 : Label[]  = ['Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24',  'Jul-24'];

    public chartOptionsL3 = {
      responsive: true,
      maintainAspectRatio: false
    };

    public chartTypeL3: string = 'line';

    public chartLegendL3 = true;

    // Add custom colors here
    public chartColorsL3 = [
      {
        borderColor: 'rgba(255, 99, 132, 1)', // Color for the Mentee line
        backgroundColor: 'transparent' // Fill color for the Mentee line
      },
      {
        borderColor: 'rgba(54, 162, 235, 1)', // Color for the Mentor line
        backgroundColor: 'transparent' // Fill color for the Mentor line
      }
    ];

    //end

  constructor() { }

  ngOnInit() {
  }


  }

  export class MonthSelectorComponent {
    fromMonth: string = '';
    toMonth: string = '';
  
    onMonthChange(): void {
      if (this.fromMonth && this.toMonth && this.fromMonth > this.toMonth) {
        if (this.fromMonth > this.toMonth) {
          alert('"From Month" should not be later than "To Month".');
          this.fromMonth = '';
        } else if (this.toMonth < this.fromMonth) {
          alert('"To Month" should not be earlier than "From Month".');
          this.toMonth = '';
        }
      }
    }
  }

  
