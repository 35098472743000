import {Component,OnInit} from "@angular/core";

@Component({
  selector: "app-mentorship-session",
  templateUrl: "./mentorship-session.component.html",
})


export class MentorshipSessionComponent implements OnInit{
    ngOnInit(): void {
        
    }

}