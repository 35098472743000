import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AtrMiscellaneousService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/create-atr-mis', data);
  }

  awardToRewardUpdate(data){
    return this.base.post('/api/update-atr-mis',data);
  }

  awardToRewardDelete(id){
    return this.base.post('/api/delete-atr-mis',{id:id});
  }

  getAtrMiscellenous(){
    return this.base.get('/api/get-all-atr-mis');
  }

  getAwardToReward(id){
    return this.base.post('/api/edit-atr-mis',{id:id});
  }

  getPreDefinedAtr(data){
    return this.base.post('/api/add-predefined-atr-column',data);
  }

  deletePredefinedFields(data:any){
   return this.base.post('/api/delete-predefined-atr-column',data);

  }
}


