import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PartnerViewService {

  constructor(private base: BaseService) { }  

  deletePartner(id){
    return this.base.post('/api/delete-partner',{id:id});
  }zzz

  viewPartner(slug) {
    return this.base.post('/api/view-onboard-partner',{slug:slug});
  }

  updatePartnerStatus(data) {
    return this.base.post('/api/update-onboard-partner-status',data);
  }

  updatePartnerStatusV1(data) {
    return this.base.post('/api/update-onboard-partner-status-v1',data);
  }

  getAllPartner(){
    return  this.base.get('/api/get-all-partner');
  }

}
