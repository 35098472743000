<!-- <div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div> -->

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/part.png);">
							<div class="row align-items-center">
								
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Jio Meetings</h3>
									</div>
								</div>

								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{jioMeetListTotal}}</h2>
									</div>
								</div>

								<div class="col-md-6">
										<div class="excel">
										<button type="button"  *ngIf="exl" title="XL" class="btn btn-primary addbtn  ml-3" (click)="exportToExcel()"><i class="fa fa-file-excel-o"></i> Excel</button>
								   </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th style="width: 18%;">Meeting Topic</th>
										<th>Mentor Name</th>
										<th>Mentee Name</th>
										<th>Mentor Meeting Duration</th>
										<th>Mentee Meeting Duration</th>
										<th>Created Date</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody *ngIf="jioMeetList?.length != 0">
									<tr *ngFor="let item of jioMeetList; let i = index" [attr.data-index]="i">
										<td>{{ getRowNumber(i) }}</td>
										<td style="width: 18%;">{{item.topic}}</td>
										<td>{{item.mentor_name}}</td>
										<td>{{item.mentee_name}}</td>
										<td>{{item.Mentor_Meeting_Duration}}</td>
										<td>{{item.Mentee_Meeting_Duration}}</td>
										<td>{{item.created_at}}</td>
										<td>{{item.status}}</td>
										<td class="action">
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="openJioMeetViewPage(this.jioMeetList[i].slug)"><i
													class="fa fa-eye"></i></button>&nbsp;
											<button *ngIf="item.feedback_status_mentee == 'SUCCESS' || item.feedback_status_mentor == 'SUCCESS'" type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="openFeedbackModel(this.jioMeetList[i].slug,item,content)"><i
													class="fa fa-star"></i></button>&nbsp;
										    </td>
									      </tr>
								       </tbody>
									<tbody *ngIf="jioMeetList?.length == 0">
										<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #content let-c="close" let-d="dismiss" id="customer-add" >
	<div class="modal-info" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{modalTitle}}</h4>
				<button type="button" class="close" aria-label="Close" (click)="onCloseButtonClick()">
					<span aria-hidden="true">×</span>
				  </button>
			</div>
			<div class="modal-body">
				<div class="feedback-mentee mb-3" >
					<h5>Feedback by Mentee</h5>
						<div class="card-body" style="background-color:#faf1f3;">
							<h6>{{menteeName}}</h6>
							<!-- <span class="Rating">3 </span>
							<span class="stars">
								<i class="fa fa-star text-danger" aria-hidden="true"></i>
								<i class="fa fa-star text-danger" aria-hidden="true"></i>
								<i class="fa fa-star text-danger" aria-hidden="true"></i>
							</span> -->
							<div class="stars">
								<i *ngFor="let star of getMenteeStars(dynamic_rating)" class="fa fa-star text-danger" aria-hidden="true"></i>
							  </div>
							<p>{{menteeFeedBack.feedback}}</p>
						</div>
			     	</div>

				<div class="feedback-mentee mb-3">
					<h5>Feedback by Mentor</h5>
						<div class="card-body" style="background-color:#faf1f3;">
							<h6>{{mentorName }}</h6>
							<!-- <span class="Rating">3 </span>
							<span class="stars">
								<i class="fa fa-star text-danger" aria-hidden="true"></i>
								<i class="fa fa-star text-danger" aria-hidden="true"></i>
								<i class="fa fa-star text-danger" aria-hidden="true"></i>
							</span> -->

							<!-- <span class="Rating">{{dynamicRating}}</span>
							<span class="stars">
							  <i *ngFor="let star of getMentorStars(dynamicRating)" class="fa fa-star text-danger" aria-hidden="true"></i>
							</span> -->

							<div class="stars">
								<i *ngFor="let star of getMentorStars(dynamicRating)" class="fa fa-star text-danger" aria-hidden="true"></i>
							  </div>
							<p>{{mentorFeedBack.feedback}}</p>
						</div>
				   </div>
			   </div>

			<div class="modal-footer">
				<button type="button" class="btn btn-secondary d-none" aria-label="Close"
					(click)="d('Cross click')">Close</button>

				<!-- <div *ngIf="!isSubmitted">
					<button type="submit" class="btn btn-primary rounded savebtn"
						(click)="save(this.areaofinterest,f)">Save</button>
				</div> -->
				<!-- <div *ngIf="isSubmitted">
					<app-loader></app-loader>
				</div> -->
			</div>
		</div>
	</div>
</ng-template>