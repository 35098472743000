import { Component, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { AlertService, PartnerListService } from "../_services";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { EncryptionService } from "../_services/encryption.service";
import { HttpClient } from "@angular/common/http";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-partner-new-list",
  templateUrl: "./partner-new-list.component.html",
  styleUrls: [],
})


export class PartnerNewListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  partnerList: any[] = [];
  totalPartnerList :any
  closeResult: string;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [
      { orderable: false, targets: 8 }, // Disable sorting for the 'Action' column (index 6)
    ],
  };
  constructor(
    public router: Router,
    private dataService: PartnerListService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService,
  ) {}

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllPartnerList();
    
  }


  getAllPartnerList(){
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/get-all-onboard-partner",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "newsFeeds");

            this.partnerList = resp["data"];
            this.totalPartnerList = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
        columns: [
          { data: "id" },
          { data: "fullname" },
          { data: "type" },
          { data: "spoc_mobile_no" },
          { data: "spoc_email" },
          { data: "referral_code" },
          { data: "referred_by" },
          { data: "approve_status" },
          { data: "created_at" },  
      ],
    };
 }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  openPartnerViewPage(slug) {
    this.router.navigate(["partner-view/" + slug]);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  // delete(slug){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.deletePartner(slug)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Partner deleted successfully', true);
  //             this.allPartner();
  //           }else{
  //             this.alertService.error('Partner error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //      }
  // }

  delete(item) {
    //console.log(item, "item");

    this.loadShow();
    this.dataService
      .deletePartner(item.slug)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Partner deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
          } else {
            this.alertService.error("Partner error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
