import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, WepInAMinuteService, AppService } from '../_services/index';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from '../app.config';

@Component({
  selector: 'app-wep-in-a-minute',
  templateUrl: './wep-in-a-minute.component.html'
})
export class WepInAMinuteComponent implements OnInit {

  @Input() allowMultiple: boolean;
  @Input() fileType = ".mp4";
  @Input() fileType1 = ".jpg , .png , .bmp";
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList: any;
  fileList1: any;
  video1: string = '';
  video_thumb1: string = '';
  loading:boolean =false

  constructor(
    public router: Router,
    private dataService: WepInAMinuteService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) { }

  wepinaminute = {
    id: '',
    wep_in_a_minute: '',
    video_type: '',
    videos: '',
    video_thumb: '',
    is_active: ''
  };
  
  modalReference:NgbActiveModal;
  modalTitle:string;

  showloding = true;
  lodingImage = false;
  
  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.allWepInAMinute();    
  }  

  allWepInAMinute() {
    this.loadShow();
    this.dataService.getAllWepInAMinute()
      .pipe().subscribe(data => {
        let wepinaminute = data['wepinaminute'][0];
        let video_urls:string;
        
        if (wepinaminute.video_type == '1') {
          video_urls = wepinaminute.videos;
        } else {
          video_urls = appConfig.apiUrl + '/' + wepinaminute.videos;
        }
        //console.log(wepinaminute.videos);
        this.wepinaminute = {
          id: wepinaminute.id,
          wep_in_a_minute: wepinaminute.wep_in_a_minute,
          video_type: wepinaminute.video_type,
          videos: video_urls,
          video_thumb: wepinaminute.video_thumb,
          is_active: wepinaminute.is_active
        }
        this.loadHide();
      });
    }

  
  Update(){
    this.insertAction();
    this.loadShow()
  }

  insertAction() {
    this.loadShow();
    let formData: FormData = new FormData();

    if (this.fileList != undefined) {
      let file: File = this.fileList[0];
      // formData.append('videos', file, file.name);
      // //console.log('videos :', this.video1);
      formData.append('videos', this.video1);

    } else {
      formData.append('videos', this.wepinaminute.videos);
    }

    if (this.fileList1 != undefined) {
      let file1: File = this.fileList1[0];
      // formData.append('video_thumb', file1, file1.name);
      formData.append('video_thumb',this.video_thumb1);
      // //console.log('video_thumb : ', this.video_thumb1);
    }

    formData.append('id', this.wepinaminute.id);
    formData.append('wep_in_a_minute', this.wepinaminute.wep_in_a_minute);
    formData.append('video_type', this.wepinaminute.video_type);
    // formData.append('videos', this.wepinaminute.videos);
    // formData.append('video_thumb', this.wepinaminute.video_thumb);
    formData.append('is_active', this.wepinaminute.is_active);

    this.dataService.wepinaminuteUpdate(formData)
      .pipe().subscribe(data => {
        this.alertService.success('Wep in a minute updated successfully', true);
        this.allWepInAMinute();
        this.loadHide();
      }, error => {
        this.alertService.error(error);
        this.loadHide();
      });
  }

  fileChange(event: any) {
    this.fileList = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(this.fileList[0].name.lastIndexOf('.'));
      // Only process image files.
      var validFileType = ".mp4";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert("please select valid file type. The supported file types are .mp4");
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText = this.fileList.length + ' file(s) has been selected';
      }
      else {
        let file: File = this.fileList[0];
        this.DisplayedText = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
          this.video1 = reader.result as string;
          // this.wepinaminute.videos = this.video1;
        };
      }
      this.onSelection.emit(this.fileList);
    }
  }

  fileChange1(event: any) {
    this.fileList1 = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList1 && this.fileList1.length > 0;
    if (hasFile) {
      var extension = this.fileList1[0].name.substring(this.fileList1[0].name.lastIndexOf('.'));
      // Only process image files.
      var validFileType = ".jpg , .png , .bmp";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert("please select valid file type. The supported file types are .jpg , .png , .bmp");
        this.fileList1 = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList1[0].size > 1000000) {
        alert(`File size is more than 1 Mb`);
        this.fileList1 = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList1.length > 1;
      if (multipleFile) {
        this.DisplayedText = this.fileList1.length + ' file(s) has been selected';
      }
      else {
        let file: File = this.fileList1[0];
        this.DisplayedText = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList1[0]);
        reader.onload = () => {
          this.video_thumb1 = reader.result as string;
          // this.wepinaminute.video_thumb = this.video_thumb1;
        };
      }
      this.onSelection.emit(this.fileList1);
    }
  }

  pdfExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-pdf", "_blank");
  }
  xlExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-excel", "_blank");
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

}
