<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12 headboxes" >
      <div class="card p-0">
        <div class="card-block p-0">
          <div class="heading-box" style="background-image: url(assets/img/quali.png);">
            <div class="row align-items-center">
              <div class="col-md-3">
                <div class="pagename">
                  <h3 class="card-title">Offering Verticals</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="total-count">
                  <h5 class="m-0">Total</h5>
                  <h2>{{offeringVerticalsTotal ?  offeringVerticalsTotal : 0 }}</h2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="addbtn">
                  <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="open(content)" style="float: right;"> 
                    Add Verticals
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
        
          <div class="table-responsive">
            <table datatable id="offeringPartnertable"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
              <thead>
                <tr>
                  <th>#Sl</th>										
                  <th style="width: 20%;">Name</th>
                  <th style="width: 20%;">Created At</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="offeringVerticalsList?.length != 0">
                <tr *ngFor="let item of offeringVerticalsList; let i = index" [attr.data-index]="i">
                  <td>{{i+1}}</td>										
                  <td>{{item.name}}</td>
                  
                  <td >{{item.created_at}}</td>
                  <td><span *ngIf="item.status=='Y'" class="badge badge-success font-weight-normal badge-pill">Active</span> <span *ngIf="item.status=='N'" class="badge badge-danger badge-pill">In active</span></td>
                  <td class="action">

                    <label class="switch">
                      <input 
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.status === 'Y'">
                      <span class="slider round"></span>
                    </label>
                   
                    <button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(this.offeringVerticalsList[i].id,content)"><i class="fa fa-edit"></i></button>
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="offeringVerticalsList?.length == 0">
								<tr>
								  <td colspan="7" class="no-data-available text-center">No data available!</td>
								</tr>
							</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-c="close" let-d="dismiss" id="verticals-add"> 
    <div class="modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{modalTitle}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <alert>
            <div class="alert alert-danger"> <a class="close">×</a></div>
          </alert>
          <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
            <div class="form-group row">
              <label for="name" class="col-md-12 col-form-label">Name <span>*</span> </label>
              <div class="  col-md-12">
                <input class="form-control" type="text" id="name" name="name" required [(ngModel)]="offering_verticals.name"  >
                <span *ngIf="isSubmitted && isFieldsInvalid(offering_verticals.name)" class="alert-block">This field is required</span>
                <span  *ngIf="f.controls.name?.errors?.pattern" class="alert-block">Invalid name format</span>
              </div>
            </div>
           

            <div class="form-group row">
              <label for="status" class="col-md-12 col-form-label">Status</label>
              <div class="  col-md-12">
                <select class="form-control" id="status" name="status" [(ngModel)]="offering_verticals.status">
                  <option value="Y">Active</option>
                  <option value="N">Inactive</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary d-none" aria-label="Close" (click)="d('Cross click')">Close</button>
          <button type="submit" class="btn btn-primary rounded savebtn" (click) ="save(this.offering_verticals,f)">Save</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
</div>



