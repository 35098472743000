import { QualificationService } from "./_services/qualification.service";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Layouts
import { FullLayoutComponent } from "./layouts/full-layout.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CustomerComponent } from "./customer/customer.component";
import { UserComponent } from "./user/user.component";
import { UserRoleComponent } from "./user/user-role/user-role.component";
import { CategoryComponent } from "./category/category.component";
import { SubCategoryComponent } from "./sub-category/sub-category.component";
import { ProductComponent } from "./product/product.component";
import { DamagedProductComponent } from "./damaged-product/damaged-product.component";
import { PurchasesComponent } from "./purchases/purchases.component";
import { PurchasesHistoryComponent } from "./purchases/purchases-history/purchases-history.component";
import { PurchasesDetailsComponent } from "./purchases/purchases-details/purchases-details.component";
import { PurchasesDuePaymentComponent } from "./purchases/purchases-due-payment/purchases-due-payment.component";
import { SalesComponent } from "./sales/sales.component";
import { SalesHistoryComponent } from "./sales/sales-history/sales-history.component";
import { InvoiceDetailsComponent } from "./sales/invoice-details/invoice-details.component";
import { SalesTakePaymentComponent } from "./sales/sales-take-payment/sales-take-payment.component";
import { SettingComponent } from "./setting/setting.component";
import { ProfileSettingsComponent } from "./profile-settings/profile-settings.component";
import { SupplierComponent } from "./supplier/supplier.component";
import { SalesReportComponent } from "./report/sales-report.component";
import { PurchasesReportComponent } from "./report/purchases-report/purchases-report.component";

import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./_guards/index";

import { NotificationComponent } from "./notification/notification.component";

import { CountryComponent } from "./country/country.component";
import { StateComponent } from "./state/state.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogCategoryComponent } from "./blog-category/blog-category.component";
import { BlogSubCategoryComponent } from "./blog-sub-category/blog-sub-category.component";
import { BannersComponent } from "./banners/banners.component";
import { AreaOfInterestComponent } from "./area-of-interest/area-of-interest.component";
import { TypeOfEnterpriseComponent } from "./type-of-enterprise/type-of-enterprise.component";
import { SectorOfEnterpriseComponent } from "./sector-of-enterprise/sector-of-enterprise.component";
import { StageOfBusinessComponent } from "./stage-of-business/stage-of-business.component";
import { QualificationComponent } from "./qualification/qualification.component";
import { CityComponent } from "./city/city.component";
import { FaqComponent } from "./faq/faq.component";
import { FaqCategoryComponent } from "./faq-category/faq-category.component";
import { AreaOfSpecializationComponent } from "./area-of-specialization/area-of-specialization.component";
import { NewsletterComponent } from "./newsletter/newsletter.component";
import { PastEventComponent } from "./past-event/past-event.component";
import { EventComponent } from "./event/event.component";
import { EventCategoryComponent } from "./event-category/event-category.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { VideoComponent } from "./video/video.component";
import { CampaignComponent } from "./campaign/campaign.component";
import { RoleComponent } from "./user/role/role.component";
import { WepInAMinuteComponent } from "./wep-in-a-minute/wep-in-a-minute.component";
import { PartnerListComponent } from "./partner-list/partner-list.component";
import { PartnerViewComponent } from "./partner-view/partner-view.component";
import { VerticalsComponent } from "./verticals/verticals.component";
import { AreaOfStudyComponent } from "./area-of-study/area-of-study.component";
import { WtiAwardComponent } from "./wti-award/wti-award.component";
import { AreaSectorOfBusinessComponent } from "./area-sector-of-business/area-sector-of-business.component";
import { WepExpectationsComponent } from "./wep-expectations/wep-expectations.component";
import { MemberBlogsComponent } from "./member-blogs/member-blogs.component";
import { MemberBlogsViewComponent } from "./member-blogs-view/member-blogs-view.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { CommunityListComponent } from "./community-list/community-list.component";
import { MemberListComponent } from "./member-list/member-list.component";
import { PublicationsComponent } from "./publications/publications.component";
import { MentorsComponent } from "./mentors/mentors.component";
import { OrganisationListComponent } from "./organisation-list/organisation-list.component";
import { MentorListComponent } from "./mentor-list/mentor-list.component";
import { ProgramListComponent } from "./program-list/program-list.component";
import { DashboardBlankComponent } from "./dashboard-blank/dashboard-blank.component";
import { AwardToRewardComponent } from "./award-to-reward/award-to-reward.component";
import { AtrCategoryComponent } from "./atr-category/atr-category.component";
import { AtrMiscellaneousComponent } from "./atr-miscellaneous/atr-miscellaneous.component";
import { AwardToRewardRegisteredComponent } from "./award-to-reward-registered/award-to-reward-registered.component";
import { NewsletterAnalyticalComponent } from "./newsletter-analytical/newsletter-analytical.component";
import { AwardToRewardPartnerListComponent } from "./award-to-reward-partner-list/award-to-reward-partner-list.component";
import { AwardToRewardPartnerViewComponent } from "./award-to-reward-partner-view/award-to-reward-partner-view.component";
import { OtpLogsComponent } from "./otp-logs/otp-logs.component";
import { AwardToRewardResponsesComponent } from "./award-to-reward-responses/award-to-reward-responses.component";
import { EmailNotificationComponent } from "./email-notification/email-notification.component";
import { JioMeetingsComponent } from "./jio-meetings/jio-meetings.component";
import { PartnerNewListComponent } from "./partner-new-list/partner-new-list.component";
import { PartnerNewViewComponent } from "./partner-new-view/partner-new-view.component";
import { JioMeetingsViewComponent } from "./jio-meetings-view/jio-meetings-view.component";
import { InviteMembersComponent } from "./invite-members/invite-members.component";
import { MemberViewComponent } from "./member-view/member-view.component";
import { MentorSlotsComponent } from "./mentor-slots/mentor-slots.component";
import { MentorVerificationComponent } from "./mentor-verification/mentor-verification.component";
import { PartnerOfferringOldComponent } from "./partner-offerring-old/partner-offerring-old.component";
import { PartnerOfferingComponent } from "./partner-offering/partner-offering.component";
import { OfferingVerticalsComponent } from "./offering-verticals/offering-verticals.component";
import { WeCommunityOpinionPollsComponent } from "./we-community-opinion-polls/we-community-opinion-polls.component";
import { WeCommunityNewsfeedsComponent } from "./we-community-newsfeeds/we-community-newsfeeds.component";
import { MediaAndAnnoucementComponent } from "./media-and-annoucement/media-and-annoucement.component";
import { ReportsComponent } from "./reports/reports.component";

import { CiiAreaOfInterestComponent } from "./_ciiyi/cii-area-of-interest/cii-area-of-interest.component";
import { CiiSectorOfEnterpriseComponent } from "./_ciiyi/cii-sector-of-enterprise/cii-sector-of-enterprise.component";
import { CiiSchemesComponent } from "./_ciiyi/cii-schemes/cii-schemes.component";
import { CiiWorkshopsComponent } from "./_ciiyi/cii-workshops/cii-workshops.component";
import { CiiFaqsComponent } from "./_ciiyi/cii-faqs/cii-faqs.component";
import { CiiContactsComponent } from "./_ciiyi/cii-contacts/cii-contacts.component";
import { CiiRegisteredWorkshopsComponent } from "./_ciiyi/cii-registered-workshops/cii-registered-workshops.component";
import { CiiRequestWorkshopsComponent } from "./_ciiyi/cii-request-workshops/cii-request-workshops.component";
import { ResourcesListComponent } from "./resources-list/resources-list.component";
import { CommunityGroupsComponent } from "./community-groups/community-groups.component";
import { MentorshipMetricsComponent } from "./mentorship-metrics/mentorship-metrics.component";
import { ScrollBarComponent } from "./scrollbar/scroll-bar.component";
import { ConnectionRequestsComponent } from "./connection-requests/connection-requests.component";
import { MentorshipProvidedByMentorComponent } from "./mentorship-provided-by-mentor/mentorship-provided-by-mentor.component";
import { MentorshipProvidedByMenteeComponent } from "./mentorship-provided-by-mentee/mentorship-provided-by-mentee.component";
import { MentorAvailabilitySlotsComponent } from "./mentor-availability-slots/mentor-availability-slots.component";
import { MentorshipSessionComponent } from "./mentorship-session/mentorship-session.component";

export const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: FullLayoutComponent,
    data: {
      title: "Dashboard",
    },
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: { title: "Home" },
      },
      {
        path: "customer",
        component: CustomerComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Customers" },
      },
      {
        path: "user",
        component: UserComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Users" },
      },
      {
        path: "user-role/:id",
        component: UserRoleComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Users Role" },
      },
      {
        path: "member",
        component: MemberListComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Members" },
      },
      {
        path: "member-view/:id",
        component: MemberViewComponent,
        canActivate: [AuthGuard],
        data: { title: "View Members" },
      },
      {
        path: "invite-members",
        component: InviteMembersComponent,
        canActivate: [AuthGuard],
        data: { title: "Invite Members" },
      },
      {
        path: "country",
        component: CountryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Countries" },
      },
      {
        path: "about",
        component: AboutComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage About" },
      },
      {
        path: "video",
        component: VideoComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Videos" },
      },
      {
        path: "contact",
        component: ContactComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Contact" },
      },
      {
        path: "blog",
        component: BlogComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Schemes" },
      },

      
      {
        path: "blog-category",
        component: BlogCategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Scheme Category" },
      },
      {
        path: "blog-sub-category",
        component: BlogSubCategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Scheme Sub Category" },
      },
      {
        path: "banners",
        component: BannersComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Banners" },
      },
      {
        path: "state",
        component: StateComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage States" },
      },
      {
        path: "area-of-interest",
        component: AreaOfInterestComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Areas of Interest" },
      },
      {
        path: "sector-of-enterprise",
        component: SectorOfEnterpriseComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Sectors of Enterprise" },
      },
      {
        path: "type-of-enterprise",
        component: TypeOfEnterpriseComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Types of Enterprise" },
      },
      {
        path: "stage-of-business",
        component: StageOfBusinessComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Stages of Business" },
      },
      {
        path: "area-of-specialization",
        component: AreaOfSpecializationComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Areas of Specialization" },
      },
      {
        path: "qualification",
        component: QualificationComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Qualifications" },
      },
      {
        path: "city",
        component: CityComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Cities" },
      },
      {
        path: "faq",
        component: FaqComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage FAQ" },
      },
      {
        path: "faq-category",
        component: FaqCategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage FAQ Category" },
      },
      {
        path: "newsletter",
        component: NewsletterComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Newsletters" },
      },
      {
        path: "publications",
        component: PublicationsComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Publications" },
      },
      {
        path: "past-event",
        component: PastEventComponent,
        canActivate: [AuthGuard],
        data: { title: "Past Events" },
      },
      {
        path: "upcoming-event",
        component: EventComponent,
        canActivate: [AuthGuard],
        data: { title: "Upcoming Events" },
      },
      {
        path: "event-category",
        component: EventCategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Event Catgory" },
      },
      {
        path: "category",
        component: CategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Category" },
      },
      {
        path: "subcategory",
        component: SubCategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage SubCategory" },
      },
      {
        path: "product",
        component: ProductComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Product" },
      },
      {
        path: "damaged-product",
        component: DamagedProductComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Damaged Product" },
      },
      {
        path: "purchases",
        component: PurchasesComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Purchases" },
      },
      {
        path: "purchases-history",
        component: PurchasesHistoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Purchases History" },
      },
      {
        path: "purchases-details/:id",
        component: PurchasesDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Purchases Details" },
      },
      {
        path: "purchases-due-payment/:id",
        component: PurchasesDuePaymentComponent,
        canActivate: [AuthGuard],
        data: { title: "Purchases Due Payment" },
      },
      {
        path: "sales",
        component: SalesComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Sales" },
      },
      {
        path: "sales-history",
        component: SalesHistoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Sales History" },
      },
      {
        path: "sales-invoice-details/:id",
        component: InvoiceDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Invoice Details" },
      },
      {
        path: "sales-take-payment/:id",
        component: SalesTakePaymentComponent,
        canActivate: [AuthGuard],
        data: { title: "Sales TakePayment" },
      },
      {
        path: "setting",
        component: SettingComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Setting" },
      },
      {
        path: "profile-settings",
        component: ProfileSettingsComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Profile Settings" },
      },
      {
        path: "supplier",
        component: SupplierComponent,
        canActivate: [AuthGuard],
        data: { title: "Manage Supplier" },
      },
      {
        path: "sales-report",
        component: SalesReportComponent,
        canActivate: [AuthGuard],
        data: { title: "Sales Report" },
      },
      {
        path: "purchases-report",
        component: PurchasesReportComponent,
        canActivate: [AuthGuard],
        data: { title: "Purchases Report" },
      },
      {
        path: "notification",
        component: NotificationComponent,
        canActivate: [AuthGuard],
        data: { title: "Notification" },
      },
      {
        path: "campaign",
        component: CampaignComponent,
        canActivate: [AuthGuard],
        data: { title: "Campaign" },
      },
      {
        path: "role",
        component: RoleComponent,
        canActivate: [AuthGuard],
        data: { title: "Role" },
      },
      {
        path: "wep-in-a-minute",
        component: WepInAMinuteComponent,
        canActivate: [AuthGuard],
        data: { title: "WEP In A Minute" },
      },
      
      {
        path: "partner-list",
        component: PartnerNewListComponent,
        canActivate: [AuthGuard],
        data: { title: "Partner List" },
      },

      
      {
        path: "partner-view/:slug",
        component: PartnerNewViewComponent,
        canActivate: [AuthGuard],
        data: { title: "Partner View" },
      },

      {
        path: "verticals",
        component: VerticalsComponent,
        canActivate: [AuthGuard],
        data: { title: "Verticals" },
      },
      {
        path: "area-of-study",
        component: AreaOfStudyComponent,
        canActivate: [AuthGuard],
        data: { title: "Area Of Study" },
      },
      {
        path: "wti-award",
        component: WtiAwardComponent,
        canActivate: [AuthGuard],
        data: { title: "Wti Award" },
      },
      {
        path: "area-sector-of-business",
        component: AreaSectorOfBusinessComponent,
        canActivate: [AuthGuard],
        data: { title: "Area Sector Of Business" },
      },
      {
        path: "wep-expectation",
        component: WepExpectationsComponent,
        canActivate: [AuthGuard],
        data: { title: "Wep Expectations" },
      },
      {
        path: "member-blogs",
        component: MemberBlogsComponent,
        canActivate: [AuthGuard],
        data: { title: "Member Blogs" },
      },
      {
        path: "member-blogs-view/:id",
        component: MemberBlogsViewComponent,
        canActivate: [AuthGuard],
        data: { title: "Member Blogs View" },
      },
      {
        path: "contact-form",
        component: ContactFormComponent,
        canActivate: [AuthGuard],
        data: { title: "Contact Form" },
      },
      {
        path: "community-list",
        component: CommunityListComponent,
        canActivate: [AuthGuard],
        data: { title: "Community List" },
      },
      {
        path: "mentor",
        component: MentorsComponent,
        canActivate: [AuthGuard],
        data: { title: "WEP Mentorship" },
      },
      {
        path: "organisation-list",
        component: OrganisationListComponent,
        canActivate: [AuthGuard],
        data: { title: "Organisation List" },
      },
      {
        path: "mentor-list",
        component: MentorListComponent,
        canActivate: [AuthGuard],
        data: { title: "WEP Mentorship" },
      },
      {
        path: "program-list",
        component: ProgramListComponent,
        canActivate: [AuthGuard],
        data: { title: "WEP Mentorship" },
      },
      {
        path: "dashboard-blank",
        component: DashboardBlankComponent,
        canActivate: [AuthGuard],
        data: { title: "Home" },
      },
      {
        path: "award-to-reward",
        component: AwardToRewardPartnerListComponent,
        canActivate: [AuthGuard],
        data: { title: "Award To Reward" },
      },
      {
        path: "award-to-reward-category",
        component: AtrCategoryComponent,
        canActivate: [AuthGuard],
        data: { title: "Atr Category" },
      },
      {
        path: "award-to-reward-miscellaneous",
        component: AtrMiscellaneousComponent,
        canActivate: [AuthGuard],
        data: { title: "Atr Miscellaneous" },
      },
      {
        path: "award-to-reward-registered",
        component: AwardToRewardRegisteredComponent,
        canActivate: [AuthGuard],
        data: { title: "Atr Register" },
      },
      {
        path: "newsletter-analytical",
        component: NewsletterAnalyticalComponent,
        canActivate: [AuthGuard],
        data: { title: "Newsletter Analytical" },
      },
      {
        path: "atr-view-list/:id",
        component: AwardToRewardPartnerViewComponent,
        canActivate: [AuthGuard],
        data: { title: "Award To Reward View" },
      },
      {
        path: "otp-logs",
        component: OtpLogsComponent,
        canActivate: [AuthGuard],
        data: { title: "OTP logs" },
      },
      {
        path: "atr-response/:slug",
        component: AwardToRewardResponsesComponent,
        canActivate: [AuthGuard],
        data: { title: "ATR Responses" },
      },
      {
        path: "email-nitifications",
        component: EmailNotificationComponent,
        canActivate: [AuthGuard],
        data: { title: "Email Notification" },
      },
      {
        path: "jio-meetings",
        component: JioMeetingsComponent,
        canActivate: [AuthGuard],
        data: { title: "Jio meet" },
      },
      {
        path: "jio-meet-view/:slug",
        component: JioMeetingsViewComponent,
        canActivate: [AuthGuard],
        data: { title: "Jio meet view" },
      },
      {
        path: "mentor-slot",
        component: MentorSlotsComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentor Slots" },
      },
      {
        path: "mentor-verification",
        component: MentorVerificationComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentor Verfication" },
      },

      {
        path: "partner-offering-old",
        component: PartnerOfferringOldComponent,
        canActivate: [AuthGuard],
        data: { title: "Partner Offering Old" },
      },

      {
        path: "community-resources",
        component: ResourcesListComponent,
        canActivate: [AuthGuard],
        data: { title: "Resources" },
      },

      {
        path: "community-groups",
        component: CommunityGroupsComponent,
        canActivate: [AuthGuard],
        data: { title: "Groups" },
      },

      {
        path: "partner-offering",
        component: PartnerOfferingComponent,
        canActivate: [AuthGuard],
        data: { title: "Partner Offering New" },
      },

      {
        path: "offering-verticals",
        component: OfferingVerticalsComponent,
        canActivate: [AuthGuard],
        data: { title: "Offering Verticals" },
      },

      {
        path: "media-and-announcement",
        component: MediaAndAnnoucementComponent,
        canActivate: [AuthGuard],
        data: { title: "Media And Announcement" },
      },

      {
        path: "reports",
        component: ReportsComponent,
        canActivate: [AuthGuard],
        data: { title: "Reports" },
      },

      {
        path: "we-community-opinion-polls",
        component: WeCommunityOpinionPollsComponent,
        canActivate: [AuthGuard],
        data: { title: "We Community Opinion Polls" },
      },

      {
        path: "we-community-newsfeeds",
        component: WeCommunityNewsfeedsComponent,
        canActivate: [AuthGuard],
        data: { title: "We Community News Feeds" },
      },

      {
        path: "ciiyi-blogs",
        component: CiiSchemesComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Schemes" },
      },

      {
        path: "ciiyi-workshops",
        component: CiiWorkshopsComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Workshops" },
      },

      {
        path: "ciiyi-area-of-interest",
        component: CiiAreaOfInterestComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Area of Interest" },
      },

      {
        path: "ciiyi-sector-of-enterprise",
        component: CiiSectorOfEnterpriseComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Sector of Enterprise" },
      },

      {
        path: "ciiyi-faqs",
        component: CiiFaqsComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Faqs" },
      },

      {
        path: "ciiyi-contacts",
        component: CiiContactsComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Contacts" },
      },

      {
        path: "scroll-bar",
        component: ScrollBarComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Contacts" },
      },

      
      {
        path: "ciiyi-request-workshops",
        component: CiiRequestWorkshopsComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Request Workshops" },
      },

      {
        path: "ciiyi-registered-workshops",
        component: CiiRegisteredWorkshopsComponent,
        canActivate: [AuthGuard],
        data: { title: "CII Registered Workshops" },
      },
      

      {
        path: "mentorship-metrics",
        component: MentorshipMetricsComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentorshiop Metrics" },
      },

      {
        path: "mentorship-provided-by-mentor",
        component: MentorshipProvidedByMentorComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentorshiop Provided By Mentor" },
      },

      {
        path: "mentorship-provided-by-mentee",
        component: MentorshipProvidedByMenteeComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentorshiop Provided By Mentee" },
      },

      {
        path: "connection-requests",
        component: ConnectionRequestsComponent,
        canActivate: [AuthGuard],
        data: { title: "Connection Requests" },
      },

      {
        path: "mentor-availability-slots",
        component: MentorAvailabilitySlotsComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentor Availability Slots" },
      },

      {
        path: "mentorship-session",
        component: MentorshipSessionComponent,
        canActivate: [AuthGuard],
        data: { title: "Mentorship Sessions" },
      },
      
      
      
    ],
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "login",
    },
  },

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule],
  /* imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ] */
})
export class AppRoutingModule {}
