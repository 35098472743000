import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EncryptionService } from '../_services/encryption.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
           
class Contacts {
  user_id: number;
  name: string;
  email: string;
  created_by: string;
  created_at: string;
}
@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.sass']
})
export class ResourcesListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject();
  contactList: Contacts[] = []; 
  showloding = true;
  lodingImage = false;
  totalResourceList: any;
  fileList: any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {}

 
  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllFaqs();
  }
  
  getAllFaqs() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      // order: [[4, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/downloadFile-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            
            this.contactList = resp["data"];
            //console.log(resp, "newsFeeds");
            this.totalResourceList = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },      

      columns: [
        { data: "id" },
        { data: "title" },
        { data: "filename" },
        { data: "downloaded_by" },
        { data: "downloaded_date" },
      ],
    };
  }


  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }


 
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }



  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
