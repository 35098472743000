import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SubCategoryService {

  constructor(private base: BaseService) { }

  save(data){
  	return this.base.post('/api/subCategory-save',data);
  }

  getAllSubCategory(){
    return  this.base.get('/api/get-subCategoryGridData');//.map((response: Response) => response.json());
  }

  getCategory()
  {
    return this.base.get('/api/get-all-category');//.map((response: Response) => response.json());
  }


  getSubCategory(id){
    return this.base.post('/api/get-subCategory',{id:id});
  }
  subCategoryUpdate(data){
    return this.base.post('/api/subCategory-update',data);
  }

  subCategoryDelete(id){
     return this.base.post('/api/subCategory-delete',{id:id});
  }

}
