import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AwardToRewardService {
  constructor(private http:HttpClient,
    private base:BaseService) { }

  save(data){
    return this.base.post('/api/create-atr', data);
  }

  awardToRewardUpdate(data){
    return this.base.post('/api/update-atr',data);
  }

  awardToRewardDelete(id){
    return this.base.post('/api/delete-atr',{id:id});
  }

  getAllAwardToReward(){
    return this.base.get('/api/get-all-atr');
  }

  getAwardToReward(id){
    return this.base.post('/api/edit-atr',{id:id});
  }
  
  getAllState(){
    return  this.base.get('/api/all-indian-state');
  }

  getAllCity(){
    return  this.base.get('/api/all-city');
  }

  getCityById(id){
    return this.base.post('/api/all-city-by-id',{id:id});
  }

  getAllActiveAtrCategory(){
    return this.base.get('/api/get-all-active-atr-category');
  }

  getAllCountry(){
    return this.base.get('/api/all-country');
  }

  getAllAttributes(){
    return this.base.get('/api/get-all-atr-attributes');
  }

   createAtr(data){
    return this.base.post('/api/create-atr-form',data);
  }

  EditAtr(id){
    return this.base.post('/api/edit-atr-form',{id:id});
  }

  updateAtr(data){
    return this.base.post('/api/update-atr-form',data);
  }

  getRegisteredUser(data){
    return this.base.post('/api/get-atrregistertion-by-atrid',data);
  }
}
