<!-- <div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div> -->
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
  <div class="animated fadeIn">
      <div class="row">
        <div class="col-lg-12 headboxes">
          <div class="card p-0">
            <div class="card-block p-0">
              <div class="heading-box" style="background-image: url(assets/img/blog.png);">
                <div class="row align-items-center">
                  <div class="col-md-3">
                    <div class="pagename">
                      <h3 class="card-title">Community Resources </h3>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="total-count">
                      <h5 class="m-0">Total</h5>
                      <h2>{{totalResourceList}}</h2>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="addbtn">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-lg-12">
          <div class="card">
            <div class="card-block">
             <div id="table" class="table-responsive">
              <table datatable id="schemes_table"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
                  <thead>
                    <tr>
                      <th>#Sl</th>
                      <th style="width:20%">Title</th>
                      <th style="width:30%" >Filename</th>
                      <th>Downloaded By</th>
                      <th>Downloaded Date</th>
             </tr>
                  </thead>
                    <tbody *ngIf="contactList?.length != 0">
                      <tr *ngFor="let item of contactList; let i = index">
                        <td>{{i+1}}</td>
                        <td style="width:20%">{{item.title}}</td>
                        <td style="width:30%">{{item.filename}}</td>
                        <td>{{item.downloaded_by}}</td>
                        <td>{{item.downloaded_date}}</td>
                    </tr>
                  </tbody>
  
                  <tbody *ngIf="contactList?.length == 0">
                    <tr>
                      <td colspan="4" class="no-data-available text-center">No data available!</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  