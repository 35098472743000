import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AreaOfInterestService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');

  save(data){
  	return this.base.post('/api/areaofinterest-create', data);
  }

  getAllAreaofinterest(){
    return  this.base.get('/api/all-areaofinterest');
  }

  getAreaOfInterest(id){
    return this.base.post('/api/get-areaofinterest',{id:id});
  }

  areaofinterestUpdate(data){
    return this.base.post('/api/areaofinterest-update',data);
  }

  areaofinterestDelete(id){
    return this.base.post('/api/areaofinterest-delete',{id:id});
  }
  
}
