import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";

// import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { LocationStrategy, HashLocationStrategy } from "@angular/common";

import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { TabsModule } from 'ngx-bootstrap/tabs';
import { NAV_DROPDOWN_DIRECTIVES } from "./components/shared/nav-dropdown.directive";

import { SIDEBAR_TOGGLE_DIRECTIVES } from "./components/shared/sidebar.directive";
import { BreadcrumbsComponent } from "./components/shared/breadcrumb.component";

// Routing Module
import { AppRoutingModule } from "./app.routing";

// Layouts
import { FullLayoutComponent } from "./layouts/full-layout.component";
import { AlertComponent } from "./components/alert/index";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CustomerComponent } from "./customer/customer.component";
import { UserComponent } from "./user/user.component";
import { UserRoleComponent } from "./user/user-role/user-role.component";
import { CategoryComponent } from "./category/category.component";
import { SubCategoryComponent } from "./sub-category/sub-category.component";
import { ProductComponent } from "./product/product.component";
import { DamagedProductComponent } from "./damaged-product/damaged-product.component";
import { PurchasesComponent } from "./purchases/purchases.component";
import { PurchasesHistoryComponent } from "./purchases/purchases-history/purchases-history.component";
import { PurchasesDetailsComponent } from "./purchases/purchases-details/purchases-details.component";
import { PurchasesDuePaymentComponent } from "./purchases/purchases-due-payment/purchases-due-payment.component";
import { SalesComponent } from "./sales/sales.component";
import { SalesHistoryComponent } from "./sales/sales-history/sales-history.component";
import { InvoiceDetailsComponent } from "./sales/invoice-details/invoice-details.component";
import { SalesTakePaymentComponent } from "./sales/sales-take-payment/sales-take-payment.component";
import { SettingComponent } from "./setting/setting.component";
import { ProfileSettingsComponent } from "./profile-settings/profile-settings.component";
import { SupplierComponent } from "./supplier/supplier.component";
import { SalesReportComponent } from "./report/sales-report.component";
import { PurchasesReportComponent } from "./report/purchases-report/purchases-report.component";

import {
  fakeBackendProvider,
  JwtInterceptor,
  ErrorInterceptor,
} from "./_helpers";
import { ChartsModule } from "ng2-charts";
import { AuthGuard } from "./_guards/index";
import { ThemeService } from "ng2-charts"; // Example if you're using ng2-charts

import { NgSelectModule } from "@ng-select/ng-select";
import {
  RichTextEditorAllModule,
  ToolbarService,
  LinkService,
  ImageService,
  HtmlEditorService,
  TableService,
} from "@syncfusion/ej2-angular-richtexteditor";

import { NotificationComponent } from "./notification/notification.component";
import { TextEditorComponent } from "./components/text-editor/text-editor.component";

import { CountryComponent } from "./country/country.component";
import { StateComponent } from "./state/state.component";
import { CityComponent } from "./city/city.component";
import { AreaOfInterestComponent } from "./area-of-interest/area-of-interest.component";
import { QualificationComponent } from "./qualification/qualification.component";
import { SectorOfEnterpriseComponent } from "./sector-of-enterprise/sector-of-enterprise.component";
import { TypeOfEnterpriseComponent } from "./type-of-enterprise/type-of-enterprise.component";
import { StageOfBusinessComponent } from "./stage-of-business/stage-of-business.component";
import { AreaOfSpecializationComponent } from "./area-of-specialization/area-of-specialization.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogCategoryComponent } from "./blog-category/blog-category.component";
import { BlogSubCategoryComponent } from "./blog-sub-category/blog-sub-category.component";
import { BannersComponent } from "./banners/banners.component";
import { NewsletterComponent } from "./newsletter/newsletter.component";
import { EventComponent } from "./event/event.component";
import { EventCategoryComponent } from "./event-category/event-category.component";
import { FaqComponent } from "./faq/faq.component";
import { FaqCategoryComponent } from "./faq-category/faq-category.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { VideoComponent } from "./video/video.component";
import { CampaignComponent } from "./campaign/campaign.component";
import { RoleComponent } from "./user/role/role.component";
import { WepInAMinuteComponent } from "./wep-in-a-minute/wep-in-a-minute.component";
import { PartnerListComponent } from "./partner-list/partner-list.component";
import { PartnerViewComponent } from "./partner-view/partner-view.component";
import { VerticalsComponent } from "./verticals/verticals.component";
import { AreaOfStudyComponent } from "./area-of-study/area-of-study.component";
import { WtiAwardComponent } from "./wti-award/wti-award.component";
import { AreaSectorOfBusinessComponent } from "./area-sector-of-business/area-sector-of-business.component";
import { WepExpectationsComponent } from "./wep-expectations/wep-expectations.component";
import { MemberBlogsComponent } from "./member-blogs/member-blogs.component";
import { MemberBlogsViewComponent } from "./member-blogs-view/member-blogs-view.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { CommunityListComponent } from "./community-list/community-list.component";
import { PastEventComponent } from "./past-event/past-event.component";
import { MemberListComponent } from "./member-list/member-list.component";
import { PublicationsComponent } from "./publications/publications.component";
import { MentorsComponent } from "./mentors/mentors.component";
import { OrganisationListComponent } from "./organisation-list/organisation-list.component";
import { MentorListComponent } from "./mentor-list/mentor-list.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { ProgramListComponent } from "./program-list/program-list.component";
import { DashboardBlankComponent } from "./dashboard-blank/dashboard-blank.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { AwardToRewardComponent } from "./award-to-reward/award-to-reward.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";

import {
  OrgListService,
  ProgramListService,
  MentorListService,
  MemberListService,
  PastEventService,
  CommunityListService,
  ContactFormService,
  MemberBlogsService,
  MemberBlogsViewService,
  AreaSectorOfBusinessService,
  WepExpectationsService,
  WtiAwardService,
  VerticalsService,
  AreaOfStudyService,
  PartnerListService,
  PartnerViewService,
  WepInAMinuteService,
  RoleService,
  CampaignService,
  VideoService,
  ContactService,
  AboutService,
  FaqService,
  FaqCategoryService,
  EventCategoryService,
  EventService,
  NewsletterService,
  BannersService,
  BlogService,
  BlogCategoryService,
  BlogSubCategoryService,
  AreaOfSpecializationService,
  StageOfBusinessService,
  TypeOfEnterpriseService,
  SectorOfEnterpriseService,
  QualificationService,
  AreaOfInterestService,
  CityService,
  StateService,
  CountryService,
  AlertService,
  AuthenticationService,
  AppService,
  SettingService,
  DashboardService,
  CategoryService,
  CustomerService,
  DamagedProductService,
  PurchasesHistoryService,
  SalesHistoryService,
  SupplierService,
  InvoiceDetailsService,
  UserRoleService,
  UserService,
  ProductService,
  PurchaseReportService,
  PurchaseService,
  PurchasesDetailsService,
  PurchasesDuePaymentService,
  SalesService,
  SalesTakePaymentService,
  SubCategoryService,
  ReportService,
  NotificationService,
  PublicationService,
} from "./_services/index";
import { LoaderComponent } from "./loader/loader.component";
import { AtrCategoryComponent } from "./atr-category/atr-category.component";
import { AtrMiscellaneousComponent } from "./atr-miscellaneous/atr-miscellaneous.component";
import { AwardToRewardRegisteredComponent } from "./award-to-reward-registered/award-to-reward-registered.component";
import { FilesService } from "./_services/files.service";
import { NewsletterAnalyticalComponent } from "./newsletter-analytical/newsletter-analytical.component";
import { AwardToRewardPartnerListComponent } from "./award-to-reward-partner-list/award-to-reward-partner-list.component";
import { AwardToRewardPartnerViewComponent } from "./award-to-reward-partner-view/award-to-reward-partner-view.component";
import { OtpLogsComponent } from "./otp-logs/otp-logs.component";
import { AwardToRewardResponsesComponent } from "./award-to-reward-responses/award-to-reward-responses.component";
import { EmailNotificationComponent } from "./email-notification/email-notification.component";
import { JioMeetingsComponent } from "./jio-meetings/jio-meetings.component";
import { PartnerNewListComponent } from "./partner-new-list/partner-new-list.component";
import { PartnerNewViewComponent } from "./partner-new-view/partner-new-view.component";
import { JioMeetingsViewComponent } from "./jio-meetings-view/jio-meetings-view.component";
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from "@angular/material/core";
import { InviteMembersComponent } from "./invite-members/invite-members.component";
import { MemberViewComponent } from "./member-view/member-view.component";
import { MentorSlotsComponent } from "./mentor-slots/mentor-slots.component";
import { MentorVerificationComponent } from "./mentor-verification/mentor-verification.component";
import { PartnerOfferringOldComponent } from "./partner-offerring-old/partner-offerring-old.component";
import { PartnerOfferingComponent } from "./partner-offering/partner-offering.component";
import { OfferingVerticalsComponent } from "./offering-verticals/offering-verticals.component";
import { WeCommunityOpinionPollsComponent } from "./we-community-opinion-polls/we-community-opinion-polls.component";
import { WeCommunityNewsfeedsComponent } from "./we-community-newsfeeds/we-community-newsfeeds.component";
import { MatSliderModule } from '@angular/material/slider';
import { CUSTOM_DATE_FORMATS, ReportsComponent } from './reports/reports.component';
import { MediaAndAnnoucementComponent } from "./media-and-annoucement/media-and-annoucement.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CiiAreaOfInterestComponent } from './_ciiyi/cii-area-of-interest/cii-area-of-interest.component';
import { CiiSectorOfEnterpriseComponent } from './_ciiyi/cii-sector-of-enterprise/cii-sector-of-enterprise.component';
import { CiiSchemesComponent } from './_ciiyi/cii-schemes/cii-schemes.component';
import { CiiWorkshopsComponent } from './_ciiyi/cii-workshops/cii-workshops.component';
import { CiiFaqsComponent } from './_ciiyi/cii-faqs/cii-faqs.component';
import { CiiContactsComponent } from './_ciiyi/cii-contacts/cii-contacts.component';
import { CiiRequestWorkshopsComponent } from './_ciiyi/cii-request-workshops/cii-request-workshops.component';
import { CiiRegisteredWorkshopsComponent } from './_ciiyi/cii-registered-workshops/cii-registered-workshops.component';
import { ResourcesListComponent } from './resources-list/resources-list.component';
import { CommunityGroupsComponent } from './community-groups/community-groups.component';
import { MentorshipMetricsComponent } from './mentorship-metrics/mentorship-metrics.component';
import { ScrollBarComponent } from "./scrollbar/scroll-bar.component";
import { MentorshipProvidedByMentorComponent } from "./mentorship-provided-by-mentor/mentorship-provided-by-mentor.component";
import { MentorshipProvidedByMenteeComponent } from "./mentorship-provided-by-mentee/mentorship-provided-by-mentee.component";
import { ConnectionRequestsComponent } from "./connection-requests/connection-requests.component";
import { MentorAvailabilitySlotsComponent } from "./mentor-availability-slots/mentor-availability-slots.component";
import { MentorshipSessionComponent } from "./mentorship-session/mentorship-session.component";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UiSwitchModule,
    AppRoutingModule,
    NgbModule,
    // BsDropdownModule.forRoot(),
    // TabsModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    AngularEditorModule,
    ReactiveFormsModule,
    ChartsModule,
    DataTablesModule,
    RichTextEditorAllModule,
    NgSelectModule,
    NgSelectModule,
    ImageCropperModule,
    DragDropModule,
    MatInputModule,
    MatNativeDateModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,

  ],
  declarations: [
    AppComponent,
    FullLayoutComponent,
    AlertComponent,
    LoginComponent,
    DashboardComponent,
    CustomerComponent,
    UserComponent,
    UserRoleComponent,
    CategoryComponent,
    SubCategoryComponent,
    ProductComponent,
    DamagedProductComponent,
    PurchasesComponent,
    PurchasesDetailsComponent,
    PurchasesDuePaymentComponent,
    PurchasesHistoryComponent,
    PurchasesReportComponent,
    SalesComponent,
    SalesHistoryComponent,
    SalesReportComponent,
    SalesTakePaymentComponent,
    InvoiceDetailsComponent,
    SettingComponent,
    ProfileSettingsComponent,
    SupplierComponent,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    NotificationComponent,
    TextEditorComponent,
    CountryComponent,
    CityComponent,
    QualificationComponent,
    StateComponent,
    SectorOfEnterpriseComponent,
    TypeOfEnterpriseComponent,
    ScrollBarComponent,
    StageOfBusinessComponent,
    AreaOfSpecializationComponent,
    AreaOfInterestComponent,
    BlogComponent,
    BannersComponent,
    NewsletterComponent,
    EventComponent,
    EventCategoryComponent,
    MentorshipProvidedByMentorComponent,
    MentorshipProvidedByMenteeComponent,
    BlogCategoryComponent,
    MentorAvailabilitySlotsComponent,
    MentorshipSessionComponent,
    BlogSubCategoryComponent,
    FaqCategoryComponent,
    FaqComponent,
    AboutComponent,
    ContactComponent,
    VideoComponent,
    CampaignComponent,
    RoleComponent,
    WepInAMinuteComponent,
    PartnerListComponent,
    PartnerViewComponent,
    VerticalsComponent,
    AreaOfStudyComponent,
    WtiAwardComponent,
    AreaSectorOfBusinessComponent,
    WepExpectationsComponent,
    MemberBlogsComponent,
    MemberBlogsViewComponent,
    ContactFormComponent,
    CommunityListComponent,
    PastEventComponent,
    MemberListComponent,
    PublicationsComponent,
    MentorsComponent,
    OrganisationListComponent,
    MentorListComponent,
    ProgramListComponent,
    DashboardBlankComponent,
    LoaderComponent,
    AwardToRewardComponent,
    AtrCategoryComponent,
    AtrMiscellaneousComponent,
    AwardToRewardRegisteredComponent,
    NewsletterAnalyticalComponent,
    AwardToRewardPartnerListComponent,
    AwardToRewardPartnerViewComponent,
    OtpLogsComponent,
    AwardToRewardResponsesComponent,
    EmailNotificationComponent,
    JioMeetingsComponent,
    PartnerNewListComponent,
    PartnerNewViewComponent,
    JioMeetingsViewComponent,
    InviteMembersComponent,
    MemberViewComponent,
    MentorSlotsComponent,
    MentorVerificationComponent,
    PartnerOfferringOldComponent,
    PartnerOfferingComponent,
    OfferingVerticalsComponent,
    WeCommunityOpinionPollsComponent,
    WeCommunityNewsfeedsComponent,
    MediaAndAnnoucementComponent,
    ReportsComponent,
    CiiAreaOfInterestComponent,
    CiiSectorOfEnterpriseComponent,
    CiiSchemesComponent,
    CiiWorkshopsComponent,
    CiiFaqsComponent,
    CiiContactsComponent,
    CiiRequestWorkshopsComponent,
    CiiRegisteredWorkshopsComponent,
    ResourcesListComponent,
    CommunityGroupsComponent,
    ConnectionRequestsComponent,
    MentorshipMetricsComponent,
  ],
  providers: [
    OrgListService,
    ProgramListService,
    MentorListService,
    MemberListService,
    PastEventService,
    CommunityListService,
    ContactFormService,
    MemberBlogsService,
    MemberBlogsViewService,
    AreaSectorOfBusinessService,
    WepExpectationsService,
    WtiAwardService,
    VerticalsService,
    AreaOfStudyService,
    PartnerListService,
    PartnerViewService,
    WepInAMinuteService,
    RoleService,
    ThemeService,
    CampaignService,
    VideoService,
    ContactService,
    AboutService,
    FaqService,
    FaqCategoryService,
    EventCategoryService,
    EventService,
    NewsletterService,
    PublicationService,
    BannersService,
    BlogService,
    BlogCategoryService,
    BlogSubCategoryService,
    AreaOfSpecializationService,
    StageOfBusinessService,
    TypeOfEnterpriseService,
    SectorOfEnterpriseService,
    QualificationService,
    AreaOfInterestService,
    CityService,
    StateService,
    CountryService,
    AlertService,
    AuthenticationService,
    AppService,
    CategoryService,
    CustomerService,
    DashboardService,
    DamagedProductService,
    ProductService,
    PurchaseService,
    PurchasesHistoryService,
    PurchasesDetailsService,
    PurchasesDuePaymentService,
    PurchaseReportService,
    ReportService,
    SalesService,
    SalesHistoryService,
    SalesTakePaymentService,
    SettingService,
    SubCategoryService,
    SupplierService,
    InvoiceDetailsService,
    UserRoleService,
    UserService,
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    TableService,
    NotificationService,
    /*{
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },*/

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
   
    // provider used to create fake backend
    fakeBackendProvider,
    FilesService,
    /* AppService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    SettingService */
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
