import { Injectable } from '@angular/core';
// import {  Http,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppService {

  private dataArraySubject = new BehaviorSubject<any[]>([]);
  dataArray$ = this.dataArraySubject.asObservable();

  constructor(private base: BaseService,private router: Router) { }

  setDataArray(data: any[]) {
    this.dataArraySubject.next(data);
  }

  navigateToRoute(routesArray: any[], requestedRoute: string): void {
    const route = routesArray.find(item => item.route === requestedRoute);
    if (route) {
      this.router.navigate([`/${requestedRoute}`]);
    } else {
      console.error(`Route ${requestedRoute} not found in the provided array.`);
    }}


  checkLogin(token){
    let bodyString = token; // Stringify payload
    return  this.base.post('/api/get-user',bodyString);
  }

  profileUpdate(data){
  	return this.base.post('/api/user-profileUpdate',data);
  }

  updatePassword(data)
  {
  	return this.base.post('/api/user-passwordUpdate',data);
  }

  getMenu() {
    return this.base.get('/api/get-all-menu');
  }

  checkPermission(val){
    return this.base.post('/api/check-user-permission',{menu:val});
  }
}
