import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { AlertService, VideoService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";

class Video {
  id: number;
  name: string;
  video_type: string;
  video_url: string;
  video_thumb: string;
  category_id: number;
  vartical_ids: string;
  sector_of_business: string;
  sectorofenterprise: string;
  areaofinterest: string;
  areaofstudy: string;
  state: number;
  city: number;
  home_display: string;
  is_active: string;
}

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() allowMultiple: boolean;
  @Input() fileType: string;
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList: any;
  fileList1: any;
  pdf = false;
  croppedVideo: any;
  exl = false;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [7], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable
  videoList: Video[] = []; // Table Data list
  isSubmitted = false;

  getVideo = {
    id: "",
    name: "",
    video_type: "",
    video_url: "",
    video_thumb: "",
    category_id: "",
    vartical_ids: "",
    sector_of_business: "",
    sectorofenterprise: "",
    areaofinterest: "",
    areaofstudy: "",
    state: "",
    city: "",
    home_display: "",
    is_active: "",
  };

  closeResult: string; // Modal Close
  selectedToEnterprise: any;
  myStringEnterprise: any;
  myArrayEnterprise: any;
  selectedToInterest: any;
  myStringInterest: any;
  myArrayInterest: any;
  selectedToStudy: any;
  myStringStudy: any;
  myArrayStudy: any;
  selectedTo: any;
  selectedToVertical: any;
  myStringVertical: any;
  myArrayVertical: any;
  myString: any;
  myArray: any;
  url: any = "https://nitistg.s3.ap-south-1.amazonaws.com";

  constructor(
    public router: Router,
    private dataService: VideoService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  video = {
    id: "",
    name: "",
    video_type: "",
    video_url: "",
    video_thumb: "",
    category_id: "",
    vartical_ids: "",
    sector_of_business: "",
    sectorofenterprise: "",
    areaofinterest: "",
    areaofstudy: "",
    state: "",
    city: "",
    home_display: "",
    is_active: "",
  };

  modalReference: NgbActiveModal;
  modalTitle: string;
  verticals = {};
  states = {};
  cities = {};
  cat = {};
  AreaSectorOfBusiness = {};
  sectorofenterprise = {};
  areaofinterest = {};
  areaofstudy = {};
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    this.allVideo();
    this.dataService
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.states = data["state"];
      });
    this.dataService
      .getAllCity()
      .pipe()
      .subscribe((data) => {
        this.cities = data["state"];
      });
    this.dataService
      .getActiveVertical()
      .pipe()
      .subscribe((data) => {
        this.verticals = data["verticals"];
      });
    this.dataService
      .getActiveAreaSectorOfBusiness()
      .pipe()
      .subscribe((data) => {
        this.AreaSectorOfBusiness = data["AreaSectorOfBusiness"];
      });
    this.dataService
      .getSectorofEnterprise()
      .pipe()
      .subscribe((data) => {
        this.sectorofenterprise = data["sectorofenterprise"];
      });
    this.dataService
      .getAreaofInterest()
      .pipe()
      .subscribe((data) => {
        this.areaofinterest = data["areaofinterest"];
      });
    this.dataService
      .getAreaofStudy()
      .pipe()
      .subscribe((data) => {
        this.areaofstudy = data["area_of_study"];
      });
    this.dataService
      .getCategory()
      .pipe()
      .subscribe((data) => {
        this.cat = data["cat"];
      });
  }

  changeToVertical() {
    this.video.vartical_ids = this.selectedToVertical;
  }

  changeTo() {
    this.video.sector_of_business = this.selectedTo;
  }

  changeToEnterprise() {
    this.video.sectorofenterprise = this.selectedToEnterprise;
  }

  changeToInterest() {
    this.video.areaofinterest = this.selectedToInterest;
  }

  changeToStudy() {
    this.video.areaofstudy = this.selectedToStudy;
  }

  allVideo() {
    this.loadShow();
    this.dataService
      .getAllVideo()
      .pipe()
      .subscribe((data) => {
        this.videoList = data["videos"];
        // this.dtTrigger.next(); // Data Table
        this.rerender();
        this.pdf = true;
        this.exl = true;
        this.loadHide();
      });
  }

  selectCityById(id) {
    this.dataService
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  open(content) {
    this.isSubmitted = false;

    if (this.getVideo.id != null) {
      this.getVideo.id = "";
    }

    this.selectedTo = "";
    this.selectedToVertical = "";
    this.selectedToInterest = "";
    this.selectedToEnterprise = "";
    this.selectedToStudy = "";

    this.video.id = "";
    this.video.name = "";
    this.video.video_type = "";
    this.video.video_url = "";
    this.video.video_thumb = "";
    this.video.category_id = "";
    this.video.vartical_ids = "";
    this.video.sector_of_business = "";
    this.video.sectorofenterprise = "";
    this.video.areaofinterest = "";
    this.video.areaofstudy = "";
    this.video.state = "";
    this.video.city = "";
    this.video.home_display = "N";
    this.video.is_active = "Y";
    this.DisplayedText = "";
    this.modalTitle = "Add Video";
    this.modalReference = this.modalService.open(content);
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.video.name)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  edit(id, content) {
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.getVideo(id).subscribe((data) => {
      this.getVideo = data["videos"];
      //console.log(this.getVideo, "get");

      this.selectCityById(this.getVideo.state);
      let video_urls;
      if (this.getVideo.video_type == "1") {
        video_urls = this.getVideo.video_url;
      } else {
        video_urls = this.url + "/" + this.getVideo.video_url;
      }
      this.myStringVertical = this.getVideo.vartical_ids;
      if (this.myStringVertical) {
        this.myArrayVertical = this.myStringVertical.split(",").map(Number);
        this.selectedToVertical = this.myArrayVertical;
      }

      this.myString = this.getVideo.sector_of_business;
      if (this.myString) {
        this.myArray = this.myString.split(",").map(Number);
        this.selectedTo = this.myArray;
      }

      this.myStringEnterprise = this.getVideo.sectorofenterprise;
      if (this.myStringEnterprise) {
        this.myArrayEnterprise = this.myStringEnterprise.split(",").map(Number);
        this.selectedToEnterprise = this.myArrayEnterprise;
      }

      this.myStringInterest = this.getVideo.areaofinterest;
      if (this.myStringInterest) {
        this.myArrayInterest = this.myStringInterest.split(",").map(Number);
        this.selectedToInterest = this.myArrayInterest;
      }

      this.myStringStudy = this.getVideo.areaofstudy;
      if (this.myStringStudy) {
        this.myArrayStudy = this.myStringStudy.split(",").map(Number);
        this.selectedToStudy = this.myArrayStudy;
      }

      this.video = {
        id: this.getVideo.id,
        name: this.getVideo.name,
        video_type: this.getVideo.video_type,
        video_url: video_urls,
        video_thumb: this.getVideo.video_thumb,
        vartical_ids: this.getVideo.vartical_ids,
        category_id: this.getVideo.category_id,
        sector_of_business: this.getVideo.sector_of_business,
        sectorofenterprise: this.getVideo.sectorofenterprise,
        areaofinterest: this.getVideo.areaofinterest,
        areaofstudy: this.getVideo.areaofstudy,
        state: this.getVideo.state,
        city: this.getVideo.city,
        home_display: this.getVideo.home_display,
        is_active: this.getVideo.is_active,
      };
      this.loadHide();
      this.modalTitle = "Edit Video";
      this.modalReference = this.modalService.open(content);
    });
  }

  insertAction(val, f) {
    let formData: FormData = new FormData();

    formData.append("name", this.video.name);
    formData.append("video_type", this.video.video_type);
    formData.append("category_id", this.video.category_id);
    formData.append("sectorofenterprise", this.selectedToEnterprise.join(","));
    formData.append("areaofinterest", this.selectedToInterest.join(","));
    formData.append("areaofstudy", this.selectedToStudy.join(","));
    formData.append("vartical_ids", this.selectedToVertical.join(","));
    formData.append("sector_of_business", this.selectedTo.join(","));
    // formData.append('vartical_ids', JSON.stringify(this.video.vartical_ids));
    // formData.append('sector_of_business', JSON.stringify(this.video.sector_of_business));
    formData.append("state", this.video.state);
    formData.append("city", this.video.city);
    formData.append("home_display", this.video.home_display);
    formData.append("is_active", this.video.is_active);

    if (val.id == "") {
      if (this.fileList != undefined) {
        let file: File = this.fileList[0];
        formData.append("video_url", this.croppedVideo);
        //console.log(this.croppedVideo, "videoupload");
      } else {
        formData.append("video_url", this.video.video_url);
      }

      if (this.fileList1 != undefined) {
        let file1: File = this.fileList1[0];
        formData.append("video_thumb", file1, file1.name);
        //console.log(file1, file1.name, "videoupload");
      }
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            //console.log(data, "dataaa");
            this.allVideo();
            this.alertService.success("Video saved successfully", true);
            this.croppedVideo = "";
            this.fileList1 = "";
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.croppedVideo == null || this.croppedVideo == "") {
        formData.append("video_url", "");
      } else {
        formData.append("video_url", this.croppedVideo);
      }

      if (this.fileList1 != undefined) {
        let file1: File = this.fileList1[0];
        formData.append("video_thumb", file1, file1.name);
      } else {
        formData.append("video_thumb", "");
      }

      this.dataService
        .videoUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            // this.dtTrigger = new Subject(); //  DataTable
            this.allVideo();
            this.alertService.success("Video updated successfully", true);
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  // delete(id){
  //     this.dataService.videoDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Video deleted successfully', true);
  //             this.allVideo();
  //           }else{
  //             this.alertService.error('Video error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //    }

  delete(item) {
    this.loadShow();
    this.dataService
      .videoDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Video deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allVideo();
          } else {
            this.alertService.error("Video error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  fileChange(event: any) {
    this.fileList = event.target.files;
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = ".mp4";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are .mp4"
        );
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
          this.croppedVideo = reader.result as string;
          //this.memberData.image = reader.result as string;
        };
        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList);
    }
  }

  fileChange1(event: any) {
    this.fileList1 = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList1 && this.fileList1.length > 0;
    if (hasFile) {
      var extension = this.fileList1[0].name.substring(
        this.fileList1[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = ".jpg , .png , .bmp";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are .jpg , .png , .bmp"
        );
        this.fileList1 = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList1[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList1 = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList1.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList1.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList1[0];

        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList1);
    }
  }

  pdfExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-pdf", "_blank");
  }
  xlExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-excel", "_blank");
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
