<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-lg-12 headboxes">
                <div class="card p-0">
                    <div class="card-block p-0">
                        <div class="heading-box" style="background-image:none;">
                            <div class="row align-items-center">
                                <div class="col-md-1">
                                    <div class="partner-iocn">
                                        <img src="assets/img/default-icon.png" class="img-fluid" alt="">
                                    </div>
                                </div>

                                <div class="col-md-8 align-self-center">
                                    <div class="pagename">
                                        <h3 class="card-title mb-1">{{jio_meet_details.topic}}</h3>
                                    </div>
                                </div>

                                <div class="col-md-3">
									<div class="excel">
									<button type="button"   class="btn btn-primary addbtn  ml-3" (click)="onBack()"><i class="fa fa-long-arrow-left"></i> Back</button>
								 </div>
							   </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>


            <div class="col-lg-12">
                <div class="card">
                    <div class="card-block partnerview">
                        <div class="row">
                            <div class="col-md-12">
                                <h4>Mentee Details</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group justify-content-start">
                                    <label for="">Image</label>
                                    <img *ngIf="jio_meet_details.mentee_image" [src]="jio_meet_details.mentee_image"
                                        style="height: 100px;width: 100px;">
                                    <img *ngIf="!jio_meet_details.mentee_image" src="assets/img/default-profile.png"
                                        style="height: 100px;width: 100px;" alt="">

                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Mentee Name</label>
                                    <p>{{jio_meet_details.mentee_name}} </p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Mobile</label>
                                    <p>{{ jio_meet_details.mentee_phone_no}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Gender</label>
                                    <p>{{jio_meet_details.mentee_gender}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Qualification</label>
                                    <p>{{jio_meet_details.mentee_qualification_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Country</label>
                                    <p>{{jio_meet_details.mentee_country_name}}
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">State</label>
                                    <p>{{jio_meet_details.mentee_state_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">City</label>
                                    <p>{{jio_meet_details.mentee_city_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Industry Experience</label>
                                    <p>{{jio_meet_details.industry_experience}}</p>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <h4>Mentor Details</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group justify-content-start">
                                    <label for="">Image</label>
                                    <img *ngIf="jio_meet_details.mentor_image" [src]="jio_meet_details.mentor_image"
                                        style="height: 100px;width: 100px;">
                                    <img *ngIf="!jio_meet_details.mentor_image" src="assets/img/default-profile.png"
                                        style="height: 100px;width: 100px;" alt="">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Mentor Name</label>
                                    <p>{{jio_meet_details.mentor_name}} </p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Mobile</label>
                                    <p>{{ jio_meet_details.mentor_phone_no}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Gender</label>
                                    <p>{{jio_meet_details.mentor_gender}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Qualification</label>
                                    <p>{{jio_meet_details.qualification}}</p>
                                </div>
                            </div>

                            <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="">Country</label>
                                            <p>{{jio_meet_details.mentee_country_name}}
                                               </p>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="">State</label>
                                            <p>{{jio_meet_details.mentee_state_name}}</p>
                                        </div>
                                    </div>

                                     <div class="col-md-6" >
                                        <div class="form-group">
                                            <label for="">City</label>
                                            <p>{{jio_meet_details.mentee_city_name}}</p>
                                        </div>
                                    </div> -->

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Industry Experience</label>
                                    <p>{{jio_meet_details.mentor_industry_experience}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>