import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TypeOfEnterpriseService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/typeofenterprise-create', data);
  }

  getAllTypeofenterprise(){
    return  this.base.get('/api/all-typeofenterprise');
  }

  getTypeofenterprise(id){
    return this.base.post('/api/get-typeofenterprise',{id:id});
  }

  typeofenterpriseUpdate(data){
    return this.base.post('/api/typeofenterprise-update',data);
  }

  typeofenterpriseDelete(id){
    return this.base.post('/api/typeofenterprise-delete',{id:id});
  }
}
