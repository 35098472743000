<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box">
							<div class="row align-items-center">
								<div class="col-md-6">
									<div class="pagename">
										<h3 class="card-title">Newsletter Analytical</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{newsletterTotal}}</h2>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>

			
			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" class="row-border hover">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Username</th>
                                        <th>Email</th>
										<th>Created Date</th>
									</tr>
								</thead>
								<tbody *ngIf="newsletterList?.length != 0">
									<tr *ngFor="let item of newsletterList;let i = index" [attr.data-index]="i">
										<td>{{item.id}}</td>
										<td>{{item.username}}</td>
										<td>{{item.email}}</td>										
										<td>{{item.created_at}}</td>
										
									
									</tr>
								</tbody>
								<tbody *ngIf="newsletterList?.length == 0">
									<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>