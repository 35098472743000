import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { AlertService, PartnerViewService } from '../_services/index';
import { AwardToRewardListService } from '../_services/award-to-reward-list.service';
import { FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-award-to-reward-partner-view',
  templateUrl: './award-to-reward-partner-view.component.html',
  styleUrls: []
})
export class AwardToRewardPartnerViewComponent implements OnInit {
  remarkForm:any
  atr_status: any;
  url :any="https://nitistg.s3.ap-south-1.amazonaws.com"
  remark_val: any;
  atrDetails:any
  constructor(
      public router: Router,
      public activatedRoute: ActivatedRoute,
      private alertService: AlertService,
      private dataService:AwardToRewardListService,
      private fb:FormBuilder
  ) { }

  showloding = true;
  lodingImage = false;
  urlParams:any;

  competetion = {
    "application_mode" : '',
    "atr_individual_id" : '',
    "id" : '',
    "partner_id" : '',
    "shortlisting_criteria" : ''
  }

  capacity_building = {
    "atr_individual_id" : '',
    "capacity_building_duration" : '',
    "capacity_building_duration_other" : '',
    "capacity_building_mode" : '',
    "capacity_building_support" : '',
    "capacity_building_support_other" : '',
    "id" : '',
    "partner_id" : '',
  }

  outreach = {
    "atr_individual_id": '',
    "id": '',
    "is_user": '',
    "outreach_plan": '',
    "partner_id": '',
  }

  reward = {
    "atr_individual_id" : '', 
    "id" : '', 
    "nature_of_reward" : '', 
    "partner_id" : '', 
    "reward_recipients" : '', 
    "reward_recipients_other" : '', 
     "winner_selection" : '', 
  }



  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.urlParams = params['id'];
    });
    
    this.getPartnerDetails();

    this.remarkForm = this.fb.group({
      atr_remarks: ['', [Validators.required]]
      // You can add more validators as needed
    });
  }

  get atr_remark() {
    return this.remarkForm.get('atr_remarks');
  }

  getPartnerDetails(){
      this.loadShow();
      this.dataService.viewAwardToRewardV1(this.urlParams).subscribe((res:any)=>{
             //console.log(res.data,"ressssss");
                if(res.data) {
                this.atrDetails = res.data    
                }
                if(res.data['competetion'][0]) {
                  this.competetion = res.data['competetion'][0];
                }

                if(res.data['capacity_building'][0]) {
                  this.capacity_building = res.data['capacity_building'][0];
                }

                if(res.data['outreach'][0]) {
                  this.outreach = res.data['outreach'][0];
                }

                // if(res.data['handholding'][0]) {
                //   this.handholding = res.data['handholding'][0];
                // }
                if(res.data['reward'][0]) {
                  this.reward = res.data['reward'][0]
                }      
                this.loadHide();
            })
         }


  saveSubmit(val,status){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loadShow();

    this.remark_val = val?val:''
    //console.log(this.remark_val,"teamkkk");
    
    var data = {
      "id": this.urlParams, 
      "status": status,
      "remark": this.remark_val ,    
    };
    //console.log(data);
    
    this.dataService.updateAwardToReward(data)
      .pipe().subscribe(data =>{                
        if(data['status']==200){
          this.alertService.success(data['mesg'], true);
          //console.log(data)
          
        }else if(data['status']==422){
          this.alertService.error(data['mesg'], true);
        }
        
        setTimeout(()=>{
          this.router.navigate(['award-to-reward']);
        }, 3000);
      },error =>{
        this.alertService.error(error);
        this.loadHide();
      });
   }
   
   rejectStatus(val,status){
    if(this.remarkForm.valid){
    this.saveSubmit(val,status)
   }else{
    this.remarkForm.markAllAsTouched();
    }
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }
}

