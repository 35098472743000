<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-lg-12 headboxes">
                <div class="card p-0">
                    <div class="card-block p-0">
                        <div class="heading-box" style="background-image:none;">
                            <div class="row align-items-center">
                                <div class="col-md-1">
                                    <div class="partner-iocn">
                                        <img *ngIf="member_personal_details.image" [src]="member_personal_details.image"
                                            style="height: 100px;width: 100px;">
                                        <img *ngIf="!member_personal_details.image" src="assets/img/default-profile.png"
                                            class="img-fluid" alt="">
                                    </div>
                                </div>
                                <div class="col-md-8 align-self-center">
                                    <div class="pagename">
                                        <h3 class="card-title mb-1" *ngIf="member_personal_details?.name">
                                            {{member_personal_details?.name}}</h3>
                                    </div>
                                </div>

                                <div class="col-md-3">
									<div class="excel">
									<button type="button"   class="btn btn-primary addbtn  ml-3" (click)="onBack()"><i class="fa fa-long-arrow-left"></i> Back</button>
								 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-block partnerview">

                        <div class="row">
                            <div class="col-md-12">
                                <h4>About Us</h4>
                                <hr>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <!-- <label for="">About Us</label> -->
                                    <div class="fw-bold" *ngIf="member_profile_details?.about_us">
                                        {{member_profile_details.about_us}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <h4>Member Details</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <p *ngIf="member_profile_details?.first_name || member_profile_details?.last_name">
                                        {{member_profile_details.first_name}} {{ member_profile_details.last_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6 d-none">
                                <div class="form-group">
                                    <label for="">Last Name</label>
                                    <p></p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Gender</label>
                                    <p *ngIf="member_profile_details?.gendr">{{member_profile_details.gendr}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Age</label>
                                    <p *ngIf="member_profile_details?.age">{{member_profile_details.age}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">DOB Year</label>
                                    <p *ngIf="member_profile_details?.dob">{{member_profile_details.dob}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Are you student</label>
                                    <p *ngIf="member_profile_details?.are_u_student">{{member_profile_details.are_u_student}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Are you working professional</label>
                                    <p *ngIf="member_profile_details?.are_working_professional">{{member_profile_details.are_working_professional}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Year of work experience</label>
                                    <p *ngIf="member_profile_details?.year_of_work_exp">{{member_profile_details.year_of_work_exp}}</p>
                                </div>
                            </div>

                                   
                            
                            


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Area of Study</label>
                                    <p
                                        *ngIf="member_profile_details?.area_of_study_other || member_profile_details?.area_of_study">
                                        {{member_profile_details.area_of_study_other ?
                                        member_profile_details.area_of_study_other :
                                        member_profile_details.area_of_study}}
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Type of Enterprise</label>
                                    <p *ngIf="member_profile_details?.type_of_enterprise">
                                        {{member_profile_details.type_of_enterprise}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Education</label>
                                    <p
                                        *ngIf="member_profile_details?.edu_qualification || member_profile_details?.edu_qualification_other">
                                        {{member_profile_details.edu_qualification_other ?
                                        member_profile_details.edu_qualification_other :
                                        member_profile_details.edu_qualification}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Country</label>
                                    <p *ngIf="member_profile_details?.country_name">
                                        {{member_profile_details.country_name}}</p>
                                </div>
                            </div>



                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">State</label>
                                    <p *ngIf="member_profile_details?.state_name">{{member_profile_details.state_name}}
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">City</label>
                                    <p *ngIf="member_profile_details?.city_name || member_profile_details?.other_city">{{member_profile_details.city_name || member_profile_details?.other_city}}
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Linkedin</label>
                                    <p *ngIf="member_profile_details?.linkedin">{{member_profile_details.linkedin}}</p>
                                </div>
                            </div>

                     



                            <div class="col-md-12 ">
                                <h4>MEMBER BUSINESS DETAILS</h4>
                                <hr>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Enterprise Name</label>
                                    <p *ngIf="member_business_details?.enterprise_name">
                                        {{member_business_details.enterprise_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Entity Registered</label>
                                    <p *ngIf="member_business_details?.entity_registered">
                                        {{member_business_details.entity_registered}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Establish Business</label>
                                    <p *ngIf="member_business_details?.establish_business">
                                        {{member_business_details.establish_business}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Area of Interest</label>
                                    <p *ngIf="member_business_details?.areaofinterest">
                                        {{member_business_details.areaofinterest}}</p>
                                </div>
                            </div>



                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Existing Business</label>
                                    <p *ngIf="member_business_details?.existing_business">
                                        {{member_business_details.existing_business}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">No of Employees</label>
                                    <p *ngIf="member_business_details?.noofemployees">
                                        {{member_business_details.noofemployees}}</p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Financing Enterprise</label>
                                    <p *ngIf="member_business_details?.financing_ur_enterprise">
                                        {{member_business_details.financing_ur_enterprise}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Business Establish</label>
                                    <p *ngIf="member_business_details?.business_establish">
                                        {{member_business_details.business_establish}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Business Registered Name</label>
                                    <p *ngIf="member_business_details?.business_register_ur_name">
                                        {{member_business_details.business_register_ur_name}}</p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">CO founder</label>
                                    <p *ngIf="member_business_details?.co_founder">
                                        {{member_business_details.co_founder}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Sector of Enterprise</label>
                                    <p
                                        *ngIf="member_business_details?.sector_of_enterprise || member_business_details?.sectorofenterprise">
                                        {{member_business_details.sector_of_enterprise ?
                                        member_business_details.sector_of_enterprise
                                        :member_business_details.sectorofenterprise}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Stage of Business</label>
                                    <p *ngIf="member_business_details?.stageofbusiness">
                                        {{member_business_details.stageofbusiness}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Turnover</label>
                                    <p *ngIf="member_business_details?.turnover">{{member_business_details.turnover}}
                                    </p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Country Name</label>
                                    <p *ngIf="member_business_details?.country_name">
                                        {{member_business_details.country_name}}</p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">State Name</label>
                                    <p *ngIf="member_business_details?.state_name">
                                        {{member_business_details.state_name}}</p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">City Name</label>
                                    <p
                                        *ngIf="member_business_details?.city_name || member_business_details?.other_city">
                                        {{member_business_details.city_name ? member_business_details.city_name : member_business_details.other_city}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Website Link</label>
                                    <p *ngIf="member_business_details?.website_link">
                                        {{member_business_details.website_link}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Linkedin Url</label>
                                    <p *ngIf="member_business_details?.linkedin_url">
                                        {{member_business_details.linkedin_url}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Facebook Url</label>
                                    <p *ngIf="member_business_details?.fb_url">{{member_business_details.fb_url}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Twitter Url</label>
                                    <p *ngIf="member_business_details?.twitter_url">
                                        {{member_business_details.twitter_url}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>