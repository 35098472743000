import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SalesTakePaymentService {

  constructor(private base: BaseService) { }

  getInvoiceDetails(val){
    return this.base.post('/api/get-invoice-details',{id:val});
  }
   
  takePayment(data){
    return this.base.post('/api/sales-take-payment',data);
  }

}


