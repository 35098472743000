import {
  AfterViewInit,
  OnDestroy,
  Component,
  OnInit,
  Input,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { DataTableDirective } from "angular-datatables";
import { map } from "rxjs/operators";
import { AlertService, AppService, RoleService } from "../../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";

class Role {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-role",
  templateUrl: "./role.component.html",
})
export class RoleComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };

  // dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  roleList: Role[] = []; // Table Data list
  clicked = false;
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getRole = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;

  constructor(
    public router: Router,
    private dataService: RoleService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  role = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allRole();
  }

  allRole() {
    this.loadShow();
    this.dataService
      .getAllRole()
      .pipe()
      .subscribe((data) => {
        this.roleList = data["roles"];
        // this.dtTrigger.next(); // Data Table
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    this.role.id = "";
    (this.role.name = ""),
      (this.role.description = ""),
      (this.role.is_active = "Y");
    this.modalTitle = "Add Role";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getRole(id)
      .pipe()
      .subscribe((data) => {
        this.getRole = data["roles"];
        this.role = {
          id: this.getRole.id,
          name: this.getRole.name,
          description: this.getRole.description,
          is_active: this.getRole.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit Role";
        this.modalReference = this.modalService.open(content);
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.role.name)) {
      return;
    }

    $(":submit").attr("disabled", "disabled");
    if (f.form.valid) {
      let formData: FormData = new FormData();

      formData.append("name", this.role.name);
      formData.append("is_active", this.role.is_active);
      formData.append("description", this.role.description);

      this.loadShow;
      if (val.id == "") {
        this.dataService
          .save(formData)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success("Role saved successfully", true);
              this.allRole();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
            }
          );
      } else {
        formData.append("id", this.role.id);

        this.dataService
          .roleUpdate(formData)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success("Role updated successfully", true);
              this.allRole();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
            }
          );
      }
    }
    this.loadHide;
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .roleDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Role deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";

            this.allRole();
          } else {
            this.alertService.error("Role error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );

    this.loadHide();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
