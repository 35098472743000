import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, RoleService, SettingService} from '../_services/index';

@Component({
  selector: 'app-dashboard-blank',
  templateUrl: './dashboard-blank.component.html',
})
export class DashboardBlankComponent implements OnInit {

  constructor(
    public router: Router,
    private dataServiceRole:RoleService,
  ) { }

  showloding = true;
  lodingImage = false;

  usertype:any;
  menu_url:any;

  ngOnInit() {
  
    this.loadShow();
    if (localStorage.getItem('currentUser') !=null) {
      var data = JSON.stringify(localStorage.getItem('currentUser'));
      //console.log(data,"dataataaa");
      
      this.dataServiceRole.getRolesMenu(data)
        .pipe().subscribe(data => { 
          // //console.log(data);
          this.usertype = data['user'].type;
          this.menu_url = data['menu'].route_url;          
          if(this.usertype != 1) {
            // this.router.navigate(['/'+menu_url]);
            window.location.href='/'+this.menu_url;
          } if(this.usertype == 1) {
            window.location.href='/dashboard';
            // this.router.navigate(['/dashboard']);
            // this.router.navigate(['/user']);
          }
        });        
    }
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
      this.showloding = false;
    this.lodingImage = true;
  }

}
