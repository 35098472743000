import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MentorListService } from "../_services/mentor-list.service";
import { EncryptionService } from "../_services/encryption.service";
import { HttpClient } from "@angular/common/http";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "../_services";
import { CommunityGroupsService } from "../_services/community-groups.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-community-groups",
  templateUrl: "./community-groups.component.html",
  styleUrls: ["./community-groups.component.sass"],
})
export class CommunityGroupsComponent implements OnInit {
  isCopied: boolean = false;
  dtOptions: DataTables.Settings = {};
  isChecked: boolean = false;
  verifyValue: string = "N";
  groupList: any[] = []; // Table Data list
  totalGroups: any;
  closeResult: string; // Modal Close
  filterData = { group_status: "ALL", group_id: "ALL" };
  start: number = 0;
  pageLength: number = 10;
  grpMasterArr: any = [];
  grpId:any
  member_profile_details = {
    about_us: "",
    age: "",
    are_u_student: "",
    are_working_professional: "",
    area_of_study: "",
    area_of_study_other: "",
    badge_name: "",
    city: "",
    city_name: "",
    country: "",
    country_name: "",
    created_at: "",
    created_by: "",
    dob: "",
    edu_qualification: "",
    edu_qualification_other: "",
    first_name: "",
    gender: "",
    gendr: "",
    id: "",
    is_active: "",
    last_name: "",
    linkedin: "",
    other_city: "",
    profile_percentage: "",
    slug: "",
    state: "",
    state_name: "",
    type_of_enterprise: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    view_count: "",
    year_of_work_exp: "",
  };

  member_business_details = {
    StageOfFunding: "",
    area_of_interest: "",
    areaofinterest: "",
    business_establish: "",
    business_plan_ready: "",
    business_register_ur_name: "",
    business_registration_type: "",
    business_type: "",
    city: "",
    city_name: "",
    co_founder: "",
    country: "",
    country_name: "",
    created_at: "",
    created_by: "",
    enterprise_name: "",
    entity_registered: "",
    entity_registered_dpiit: "",
    establish_business: "",
    existing_business: "",
    fb_url: "",
    financing_ur_enterprise: "",
    funding: "",
    how_financing_ur_enterprise: "",
    id: "",
    investment_in_business: "",
    investmentinbusiness: "",
    linkedin_url: "",
    mentorship: "",
    no_of_employees: "",
    noofemployees: "",
    other_city: "",
    other_sector: "",
    plan_to_business: "",
    primary_industry: "",
    primary_industry_other: "",
    register_business: "",
    sector_of_enterprise: "",
    sectorofenterprise: "",
    stage_of_business: "",
    stage_of_funding: "",
    stageofbusiness: "",
    state: "",
    state_name: "",
    turnover: "",
    turnover_last_year: "",
    twitter_url: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    website_link: "",
    years_of_operating_bussiness: "",
  };

  member_personal_details = {
    UUID: "",
    deactive_account: "",
    email: "",
    id: "",
    image: "",
    mask_mobile_no: "",
    name: "",
    notifications: "",
    phone: "",
    slug: "",
    type: "",
  };

  group_details = {
    created_at : "",
    created_by : "",
    group_id : "",
    id :"",
    reject_reason: "",
    status: "",
    updated_at: "",
    updated_by: "",
    user_id:""
    }

  mentorInfo = {
    id: "",
    type: "",
    organization_name: "",
    age_of_organization: "",
    first_name: "",
    last_name: "",
    type_of_organization: "",
    birth_yr: "",
    gender: "",
    address: "",
    country: "",
    country_name: "",
    state: "",
    state_name: "",
    city: "",
    city_others: "",
    city_name: "",
    qualification: "",
    qualification_name: "",
    spoken_lang: "",
    spoken_lang_names: "",
    primary_industry: "",
    primary_industry_name: "",
    stage_of_enterprise: "",
    stage_of_enterprise_names: "",
    industry_experience: "",
    industry_experience_name: "",
    area_of_functional_expertise: "",
    area_of_functional_expertise_name: "",
    provide_mentoring: "",
    kind_of_mentoring_name: "",
    preferred_track_of_mentorship: "",
    track_of_mentorship_names: "",
    ecosystem_key: "",
    ecosystem_key_names: "",
    preference_across: "",
    preference_across_name: "",
    mode_of_engagement: "",
    mode_of_engagement_name: "",
    willing_no_of_mentees: "",
    willing_no_of_mentees_name: "",
    ecosystem_key_other: "",
    no_of_hours_per_month: "",
    no_of_hours_per_month_name: "",
    linkedin_profile_url: "",
    approve_status: "",
    remark: "",
    profileimage: "",
    document: "",
    primary_industry_others: "",
    spoken_lang_others: "",
    qualification_others: "",
    meeting_date: "",
    meeting_end_time: "",
    meeting_link: "",
    meeting_start_time: "",
  };

  partnerInfo = {
    approve_status: "",
    city: "",
    city_name: "",
    country: "",
    country_name: "",
    created_at: "",
    created_by: "",
    entrepreneurs_supporting_area: "",
    entrepreneurs_supporting_area_name: "",
    facebook: "",
    first_name: "",
    fullname: "",
    gender: "",
    id: "",
    image: "",
    industry: "",
    industry_name: "",
    instagram: "",
    invited_id: "",
    is_active: "",
    is_support_sector: "",
    landline: "",
    last_name: "",
    linkedin: "",
    occuption: "",
    organization_name: "",
    other_sector: "",
    other_type_of_emp: "",
    pincode: "",
    referred_by: "",
    remark: "",
    restrict_industry: "",
    sector: "",
    slug: "",
    state_name: "",
    states: "",
    twitter: "",
    type: "",
    type_of_emp: "",
    type_of_organization: "",
    updated_at: "",
    updated_by: "",
    user_id: "",
    view_count: "",
    website: "",
    year_of_birth: "",
    year_of_establishment: "",
    youtube: "",
  };

  constructor(
    public router: Router,
    private dataService: CommunityGroupsService,
    private enc_dec: EncryptionService,
    private http: HttpClient,
    private alertService: AlertService,
    private modalService: NgbModal
  ) {}

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;
  isSubmitted: boolean = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (localStorage.getItem("community_group") != null) {
      this.filterData.group_id = localStorage.getItem("community_group");
    }

    if (localStorage.getItem("community_group_status") != null) {
      this.filterData.group_status = localStorage.getItem(
        "community_group_status"
      );
    }

    this.getGroupMasters();

    this.getCommunityGroupsList();
  }

  getGroupMasters() {
    this.dataService.getMaster().subscribe((res: any) => {
      this.grpMasterArr = res["GROUP_MASTER"];
    });
  }

  getCommunityGroupsList() {
    this.loadShow();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      // order: [[8, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/we-community-groups-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(
                  Object.assign(dataTablesParameters, this.filterData)
                )
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "DataTablesResponse");

            this.groupList = resp["data"];
            this.totalGroups = resp["recordsFiltered"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },

      columns: [
        // { data: "id" },
        { data: "id" },
        { data: "title" },
        { data: "username" },
        { data: "requested_date" },
        { data: "group_status" },
        { data: "usertype" },
      ],
    };
    this.loadHide();
    window.scroll(0, 0);
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  viewMentorDetails(id, mentorDetails) {
    this.loadShow();
    const modalOptions: NgbModalOptions = {
      backdrop: 'static', 
      keyboard: false,   
    };

    this.dataService.getGroupDetails(id).subscribe((res: any) => {
      let mentor_details = res["user_details"];
       this.group_details  = res['group_details']

      this.isChecked = mentor_details.is_verify === "Y";
      this.verifyValue = this.isChecked ? "Y" : "N";
      this.mentorInfo = {
        id: mentor_details.id,
        type: mentor_details.type,
        organization_name: mentor_details.organization_name,
        age_of_organization: mentor_details.age_of_organization,
        first_name: mentor_details.first_name,
        last_name: mentor_details.last_name,
        qualification_others: mentor_details.qualification_others,
        type_of_organization: mentor_details.type_of_organization,
        birth_yr: mentor_details.birth_yr,
        gender: mentor_details.gender,
        address: mentor_details.address,
        country: mentor_details.country,
        country_name: mentor_details.country_name,
        state: mentor_details.state,
        state_name: mentor_details.state_name,
        city: mentor_details.city,
        city_name: mentor_details.city_name,
        city_others: mentor_details.city_others,
        ecosystem_key_other: mentor_details.ecosystem_key_other,
        qualification: mentor_details.qualification,
        qualification_name: mentor_details.qualification_name,
        spoken_lang: mentor_details.spoken_lang,
        spoken_lang_names: mentor_details.spoken_lang_names,
        primary_industry: mentor_details.primary_industry,
        meeting_date: mentor_details.meeting_date,
        meeting_end_time: mentor_details.meeting_end_time,
        meeting_link: mentor_details.meeting_link,
        meeting_start_time: mentor_details.meeting_start_time,
        primary_industry_name: mentor_details.primary_industry_name,
        stage_of_enterprise: mentor_details.stage_of_enterprise,
        stage_of_enterprise_names: mentor_details.stage_of_enterprise_names,
        industry_experience: mentor_details.industry_experience,
        industry_experience_name: mentor_details.industry_experience_name,
        area_of_functional_expertise:
          mentor_details.area_of_functional_expertise,
        area_of_functional_expertise_name:
          mentor_details.area_of_functional_expertise_name,
        provide_mentoring: mentor_details.provide_mentoring,
        kind_of_mentoring_name: mentor_details.kind_of_mentoring_name,
        preferred_track_of_mentorship:
          mentor_details.preferred_track_of_mentorship,
        track_of_mentorship_names: mentor_details.track_of_mentorship_names,
        ecosystem_key: mentor_details.ecosystem_key,
        ecosystem_key_names: mentor_details.ecosystem_key_names,
        preference_across: mentor_details.preference_across,
        preference_across_name: mentor_details.preference_across_name,
        mode_of_engagement: mentor_details.mode_of_engagement,
        mode_of_engagement_name: mentor_details.mode_of_engagement_name,
        willing_no_of_mentees: mentor_details.willing_no_of_mentees,
        willing_no_of_mentees_name: mentor_details.willing_no_of_mentees_name,
        no_of_hours_per_month: mentor_details.no_of_hours_per_month,
        no_of_hours_per_month_name: mentor_details.no_of_hours_per_month_name,
        linkedin_profile_url: mentor_details.linkedin_profile_url,
        approve_status: mentor_details.approve_status,
        remark: mentor_details.remark,
        profileimage: mentor_details.profileimage,
        document: mentor_details.document,
        primary_industry_others: mentor_details.primary_industry_others,
        spoken_lang_others: mentor_details.spoken_lang_others,
      };
      this.loadHide();
    });
    this.modalReference = this.modalService.open(mentorDetails,modalOptions);
  }

  viewMenteeDetails(id, memberDetails) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static', 
      keyboard: false,   
    };
    this.loadShow();
    this.dataService
      .getGroupDetails(id)
      .pipe()
      .subscribe((data) => {
        let mentee_details = data["user_details"];
        this.group_details  = data['group_details']
        //console.log(mentee_details, "mentee");

        this.isChecked = mentee_details.is_verify === "Y";
        this.verifyValue = this.isChecked ? "Y" : "N";

        this.member_profile_details = mentee_details["member_profile"][0];
        this.member_personal_details = mentee_details["member"];
        this.member_business_details = mentee_details["member_business"][0];

        this.loadHide();
      });
    this.modalReference = this.modalService.open(memberDetails,modalOptions);
  }


  viewPartnerDetails(id, partnerDetails) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static', 
      keyboard: false,   
    };
    this.loadShow();
    this.dataService
      .getGroupDetails(id)
      .pipe()
      .subscribe((data) => {
        this.partnerInfo = data["user_details"];
        this.group_details  = data['group_details']

        this.loadHide();
      });
    this.modalReference = this.modalService.open(partnerDetails,modalOptions);
  }

  onChangeStatus(): void {
    if (this.group_details.status !== "REJECTED") {
      this.group_details.reject_reason = "";
    }
  }


  onCheckboxChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isChecked = input.checked;
  }


  grpStatusUpdate(grp_detail:any) {
    this.isSubmitted = true;

    if (
      grp_detail.status === "REJECTED" &&
      (!grp_detail.reject_reason || grp_detail.reject_reason.trim() === "")
    ) {
      return;
    }

    this.loadShow();
    var data = {
      id: grp_detail.id,
      status: grp_detail.status,
      reason: grp_detail.reject_reason ? grp_detail.reject_reason : "",
    };

    this.dataService
      .updateStatus(data)
      .pipe()
      .subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.alertService.success(res["mesg"], true);
            this.modalReference.close();
            const table = $("#grouptable").DataTable();
            table.ajax.reload(null, false);
            this.loadHide();
            
          } else if (res["status"] == 422) {
            this.loadHide();
            this.alertService.error(res["mesg"]);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
       
        },
        (error) => {
          this.alertService.error("error");
          document
          .querySelector(".modal")
          .scrollTo({ top: 0, behavior: "smooth" });
        }
      );
   }


  getFilteredSpokenLanguages(mentorInfo: any): string {
    let spokenLangs = mentorInfo.spoken_lang_names;

    if (typeof spokenLangs === "string") {
      spokenLangs = spokenLangs.split(",").map((lang) => lang.trim());
    }

    if (Array.isArray(spokenLangs)) {
      return spokenLangs.filter((lang: string) => lang !== "Others").join(", ");
    }
    return "";
  }

  checkRemarks() {
    if (this.group_details.reject_reason) {
      this.group_details.reject_reason = this.group_details.reject_reason.trim();
    }
  }

  containsOthers(spokenLangNames: any) {
    // Check if 'Others' is included in the spoken_lang_names string
    return spokenLangNames.includes("Others");
  }



  setFilterData(e: any) {
    this.filterData.group_status = e.target.value;
    localStorage.setItem("group_status", this.filterData.group_status);
  }

  onSetGroup(e: any) {
    this.filterData.group_id = e.target.value;
    localStorage.setItem("community_group", this.filterData.group_id);
    const table = $("#grouptable").DataTable();
    table.ajax.reload(null, false);
  }

  onSetStatus(e: any) {
    this.filterData.group_status = e.target.value;
    localStorage.setItem(
      "community_group_status",
      this.filterData.group_status
    );

    const table = $("#grouptable").DataTable();
    table.ajax.reload(null, false);
  }

  copyToClipboard(link: string) {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        this.isCopied = true;

        setTimeout(() => (this.isCopied = false), 2000); // Reset after 2 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

}
