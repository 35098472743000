<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12" style="margin: auto;">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title">WEP In A Minute</h5>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
                            <div class="form-group row">
								<label for="wep_in_a_minute" class="col-md-12 col-form-label">WEP In A Minute <span>*</span></label>
								<div class="col-md-12">
									<textarea class="form-control" type="text" id="wep_in_a_minute" name="wep_in_a_minute" required [(ngModel)]="wepinaminute.wep_in_a_minute"></textarea>
								</div>
							</div>

							<div class="form-group row">
								<label for="email" class="col-md-12 col-form-label">Video Type <span>*</span></label>
								<div class="col-md-12">
									<select class="form-control" id="video_type" name="video_type" required [(ngModel)]="wepinaminute.video_type">
                                        <option value="1">URL</option>
                                        <option value="2">Upload</option>
                                    </select>
								</div>
							</div>

							<div class="form-group row" *ngIf="wepinaminute.video_type =='1'">
								<label for="lat" class="col-md-12 col-form-label">Video Url <span>*</span> </label>
								<div class="col-md-12">
									<textarea class="form-control" type="text" id="videos" name="videos" required [(ngModel)]="wepinaminute.videos"></textarea>
								</div>
							</div>
							
							<div class="form-group row" *ngIf="wepinaminute.video_type =='2'">
								<label for="long" class="col-md-12 col-form-label">Video File <span>*</span></label>
								<div class="col-md-12">
									<video style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="wepinaminute.videos !=''" >
                                        <source [src]="wepinaminute.videos">
                                    </video>
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                            <label class="btn btn-dark">
                                                <samp>Browse</samp>...
                                                <input type="file" accept="{{fileType}}"  style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple" required>                                                        
                                            </label>                                                    
                                        </span>                                                
                                        <input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText"  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">												
                                    </div>
								</div>
							</div>
                            <div class="form-group row" >
								<label for="long" class="col-md-12 col-form-label">Video Thumbnail <span>*</span></label>
								<div class="col-md-12">
									<div class="input-group">
                                        <span class="input-group-btn">
                                            <label class="btn btn-dark">
                                                <samp>Browse</samp>...
                                                <input type="file" accept="{{fileType1}}" style="display:none;" (change)="fileChange1($event)" [attr.multiple]="allowMultiple" required>
                                            </label>
                                        </span>
                                        <input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText" [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                                    </div>
                                    <img style="height: 100px;width: 100px;" class="img-thumbnail mt-3" *ngIf="wepinaminute.video_thumb !=''" [src]="wepinaminute.video_thumb" />
								</div>
							</div>
							
								<button type="submit" class="btn btn-primary rounded savebtn" [disabled]="!f.form.valid" (click) ="Update()">Update</button>
							
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


