import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class PartnerOfferingNewService {
  constructor(private base: BaseService) {}

  save(data) {
    return this.base.post("/api/partner-offering-create-V1", data);
  }

  partnerOfferingUpdate(data) {
    return this.base.post("/api/partner-offering-update-V1", data);
  }

  partnerOfferingDelete(id) {
    return this.base.post("/api/partner-offering-update-status-V1", { id: id });
  }

  getAllPartnerOffering() {
    return this.base.get("/api/get-list-partner-offering-V1");
  }

  getPartnerOffering(id) {
    return this.base.post("/api/edit-partner-offering-V1", { id: id });
  }

  getAllState() {
    return this.base.get("/api/all-indian-state");
  }

  getAllCity() {
    return this.base.get("/api/all-city");
  }

  getCategoryList() {
    return this.base.get("/api/offering-verticals");
  }

  getCityById(id) {
    return this.base.post("/api/all-city-by-id", { id: id });
  }
}
