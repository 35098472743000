import {Component,OnInit} from "@angular/core";

@Component({
  selector: "app-mentor-availability-slots",
  templateUrl: "./mentor-availability-slots.component.html",
})


export class MentorAvailabilitySlotsComponent implements OnInit{
    ngOnInit(): void {
        
    }

}