import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { MasterService } from "src/app/_ciiyi_services/master.service";
import { WorkshopsService } from "src/app/_ciiyi_services/workshops.service";
import { AlertService } from "src/app/_services";
import { EncryptionService } from "src/app/_services/encryption.service";
import { appConfig } from "src/app/app.config";

class DataTablesResponse {
  data: any;
  draw: any;
  recordsFiltered: any;
  recordsTotal: any;
}

class Workshop {
  id: any;
  title: any;
  schemes_ids: any;
  start_date_time: any;
  end_date_time: any;
  description: any;
  type: any;
  country: any;
  states: any;
  city: any;
  address: any;
  video_type: any;
  videos: any;
  image: any;
  external_link: any;
  registration_link: any;
  visible_on_home: any;
  is_active: any;
  created_by_name: any;
  created_at: any;
  speaker_name: any;
  speaker_photo: any;
  speaker_about: any;
  fees_payment_info: any;
  contact_info: any;
}

interface SubCategory {
  id: number;
  name: string;
}

@Component({
  selector: "app-cii-workshops",
  templateUrl: "./cii-workshops.component.html",
  styleUrls: ["./cii-workshops.component.sass"],
})
export class CiiWorkshopsComponent implements OnInit {
  @Input() allowMultiple: any;
  @Input() fileType: string = ".jpg , .jpeg, .png , .bmp";
  @Input() speakerFileType: string = ".jpg , .jpeg, .png , .bmp";
  @Input() testimonialFileType: string = ".jpg , .jpeg, .png , .bmp";

  @Input() videoType: string;

  @Input() required: any;
  @Input() maxSizeInKb: any;

  @Output() onSelection = new EventEmitter<FileList>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  loading: boolean = false;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();

  DisplayedText: string = "";
  DisplayedVideoText: string = "";
  DisplayedSpeakerText: string = "";

  fileList: any;
  isSubmitted: boolean = false;
  croppedImage: any = "";
  speakerImage: any = "";
  faqs: { question: string; answer: string }[] = [{ question: "", answer: "" }];
  testimonials: {
    name: string;
    photo: string;
    designation: string;
    description: string;
  }[] = [{ name: "", photo: "", designation: "", description: "" }];

  image_cropper: any;
  @Input() allowMultipleVideo: boolean = true;
  fileListImage: any;
  fileListVideo: any;
  imageList: any;
  speakerImageList: any;
  testimonialImageList: any;
  pdf = false;
  mySchemes: any;
  exl = false;
  workshopList: Workshop[] = []; // Table Data list
  eventMediaList = [];
  eventMediaListImage = [];
  eventMediaListVideo = [];
  event_id: any;
  apiUrl: any;
  selectedToScheme: any;
  error: any = { isError: false, errorMessage: "" };

  getWorkshop = {
    id: "",
    title: "",
    schemes_ids: "",
    start_date_time: "",
    end_date_time: "",
    description: "",
    type: "",
    country: "",
    states: "",
    city: "",
    address: "",
    video_type: "",
    videos: "",
    image: "",
    external_link: "",
    registration_link: "",
    visible_on_home: "",
    is_active: "Y",
    speaker_name: "",
    speaker_photo: "",
    speaker_about: "",
    fees_payment_info: "",
    contact_info: "",
  };

  closeResult: any; // Modal Close
  dynamicArray: Array<any> = [];
  newDynamic: any;
  croppedVideo: any;
  mySchemeString: any;
  showloding = true;
  lodingImage = false;
  mySchemeArray: any;
  imageChangedEvent: any = "";
  selectedSchemes: any[] = [];

  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: WorkshopsService,
    private alertService: AlertService,
    private service: MasterService,
       private http: HttpClient,
        private enc_dec: EncryptionService,
        private modalService: NgbModal
  ) {}

  workshop = {
    id: "",
    title: "",
    schemes_ids: "",
    start_date_time: "",
    end_date_time: "",
    description: "",
    type: "",
    country: "",
    states: "",
    city: "",
    address: "",
    video_type: "",
    videos: "",
    external_link: "",
    image: "",
    registration_link: "",
    visible_on_home: "",
    is_active: "",
    speaker_name: "",
    speaker_photo: "",
    speaker_about: "",
    fees_payment_info: "",
    contact_info: "",
  };

  modalReference!: NgbActiveModal;
  modalCropper!: NgbActiveModal;
  modalTitle: any;
  SchemesIds: any = [];
  states: any = [];
  cities: any = [];
  countries: any = [];
  schemes_ids = {};

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // this.allWorkshops();
    this.getAllWorkshopsList()
    this.apiUrl = appConfig.apiUrl + "/";
    this.newDynamic = { event_type: "", event_label: "", is_mandatory: "" };
    this.dynamicArray.push(this.newDynamic);

    this.service
      .getAllCountry()
      .pipe()
      .subscribe((data: any) => {
        this.countries = data["country"];
      });

    this.service
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.states = data["state"];
      });

    this.cities = [];

    // this.service
    //   .getAllCity()
    //   .pipe()
    //   .subscribe((data) => {
    //     this.cities = data["state"];
    //     //console.log(this.cities, "ctuii");
    //   });

    this.dataService
      .getSchemes()
      .pipe()
      .subscribe((data) => {
        this.schemes_ids = data["blog"];
      });
  }

  isFieldsInvalid(value: any) {
    return !value || value.trim().length === 0;
  }

  onChangeSchemes() {
    this.workshop.schemes_ids = this.selectedToScheme;
  }

  allWorkshops() {
    this.loadShow();
    this.dataService
      .getAllWorkshops()
      .pipe()
      .subscribe((data) => {
        this.workshopList = data["workshop"];
        this.rerender();
        this.loadHide();
      });
  }

  fileChange(event: any) {
    this.fileList = event.target.files;
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = ".mp4";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are .mp4"
        );
        this.fileList = null;
        this.DisplayedVideoText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedVideoText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedVideoText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
          this.croppedVideo = reader.result as string;
          //console.log(this.croppedVideo, "cropped");
          //this.memberData.image = reader.result as string;
        };
        this.DisplayedVideoText = file.name;
      }
      this.onSelection.emit(this.fileList);
    }
  }


  getAllWorkshopsList() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/admin/workshop/workshopList",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "workshops");

            this.workshopList = resp["data"];
            // this.totalBlogList = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },

      columns: [
        { data: 'id' },
        { data: 'title' },
        { data: 'title' },
        { data: 'title' },
        { data: 'title' },
    
      ],
    };
  }

  selectCityById(id: any) {
    this.service
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  open(content: any) {
    this.faqs = [{ question: "", answer: "" }];
    this.testimonials = [
      { name: "", photo: "", designation: "", description: "" },
    ];

    this.selectedToScheme = "";
    this.croppedImage = "";
    this.speakerImage = '';
    //console.log()
    this.croppedVideo = "";
    this.DisplayedText = "";
    this.DisplayedSpeakerText = "";
    this.DisplayedVideoText = "";
    // this.SchemesIds = '';
    this.isSubmitted = false;
    this.workshop.id = "";
    this.workshop.title = "";
    this.workshop.start_date_time = "";
    this.workshop.end_date_time = "";
    this.workshop.description = "";
    this.workshop.type = "";
    this.workshop.country = "";
    this.workshop.states = "";
    this.workshop.city = "";
    this.workshop.address = "";
    this.workshop.video_type = "";
    this.workshop.videos = "";
    this.workshop.external_link = "";
    this.workshop.registration_link = "";
    this.workshop.visible_on_home = "";
    this.workshop.is_active = "Y";
    this.modalTitle = "Add Workshop";
    this.modalReference = this.modalService.open(content);
  }

  removeFaq(index: number) {
    this.faqs[index].question = "";
    this.faqs[index].answer = "";
    this.faqs.splice(index, 1);
  }

  addFaq() {
    this.faqs.push({ question: "", answer: "" });
  }

  removeTestimonials(index: number) {
    this.testimonials[index].name = "";
    this.testimonials[index].photo = "";
    this.testimonials[index].description = "";
    this.testimonials[index].designation = "";

    this.testimonials.splice(index, 1);
  }

  addTestimonials() {
    this.testimonials.push({
      name: "",
      photo: "",
      description: "",
      designation: "",
    });
  }

  save(val: any, f: any) {
    // //console.log(this.SchemesIds,"schmesss");
    // if(this.SchemesIds){
    //   this.workshop.schemes_ids = this.SchemesIds.join(',')
    // }
    this.isSubmitted = true;

    if (this.workshop.start_date_time && this.workshop.end_date_time) {
      const startDateTime = new Date(this.workshop.start_date_time);
      const endDateTime = new Date(this.workshop.end_date_time);

      if (startDateTime > endDateTime) {
        document
        .querySelector(".modal")
        .scrollTo({ top: 0, behavior: "smooth" });

         this.alertService.error(
          "Start Date cannot be greater than End Date",
          true
        );
        return;
      }
    }
    this.isSubmitted = true;

    if (
      this.isFieldsInvalid(this.workshop.title) ||
      this.isFieldsInvalid(this.workshop.address)
    ) {
      return;
    }

    //console.log(val, f, "form");
    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val);
    } else {
      // document.querySelector(".modal")?.scrollTo({top:0,behavior:'smooth'});
    }
  }

  edit(id: any, content: any) {
    this.isSubmitted = true;
    this.croppedImage = ''
     this.testimonialImageList = ''
    this.speakerImage = ''
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.editWorkshop(id).subscribe((data: any) => {
      //console.log(data, "edit dataaaaa");
      let workshop_faqs = data["workshop"]["faqs"];
      let workshop_testimonials = data["workshop"]["testimonials"];

      if (!workshop_faqs || workshop_faqs.length === 0) {
        workshop_faqs = [{ question: "", answer: "" }];
      }
      this.faqs = workshop_faqs;

      if (!workshop_testimonials || workshop_testimonials.length === 0) {
        workshop_testimonials = [
          { name: "", photo: "", designation: "", description: "" },
        ];
      }

      this.testimonials = workshop_testimonials;

      this.getWorkshop = data["workshop"];
      this.croppedImage = this.getWorkshop.image;
      this.speakerImage = this.getWorkshop.speaker_photo;
      //console.log(this.speakerImage,"speaker imag")
      if (this.getWorkshop.states != null) {
        this.selectCityById(this.getWorkshop.states);
        //console.log(this.selectCityById(this.getWorkshop.states), "ghghhgh");
      }

      this.mySchemeString = this.getWorkshop.schemes_ids;
      if (this.mySchemeString) {
        this.mySchemes = this.mySchemeString
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        //console.log(this.mySchemes);
        this.selectedToScheme = this.mySchemes;
      }

      this.croppedImage = this.getWorkshop.image;
      this.speakerImage = this.getWorkshop.speaker_photo;

      this.workshop = {
        id: this.getWorkshop.id,
        title: this.getWorkshop.title,
        start_date_time: this.getWorkshop.start_date_time,
        end_date_time: this.getWorkshop.end_date_time,
        description: this.getWorkshop.description,
        type: this.getWorkshop.type,
        country: this.getWorkshop.country,
        schemes_ids: this.getWorkshop.schemes_ids,
        states: this.getWorkshop.states,
        city: this.getWorkshop.city,
        address: this.getWorkshop.address,
        video_type: this.getWorkshop.video_type,
        image: this.getWorkshop.image,
        videos:
          this.getWorkshop.video_type === "LINK" && this.getWorkshop.videos
            ? this.getWorkshop.videos.replace(
                "https://nitistg.s3.ap-south-1.amazonaws.com/",
                ""
              )
            : this.getWorkshop.videos,
        external_link: this.getWorkshop.external_link,
        registration_link: this.getWorkshop.registration_link,
        visible_on_home: this.getWorkshop.visible_on_home,
        is_active: this.getWorkshop.is_active,
        speaker_name: this.getWorkshop.speaker_name,
        speaker_photo: this.getWorkshop.speaker_photo,
        speaker_about: this.getWorkshop.speaker_about,
        fees_payment_info: this.getWorkshop.fees_payment_info,
        contact_info: this.getWorkshop.contact_info,
      };
      this.loadHide();
      this.isSubmitted = false;
      this.modalTitle = "Edit Event";
      this.modalReference = this.modalService.open(content);
    });
  }

  onTestimonialImageChange(event: any, index: number) {
    this.testimonialImageList = event.target.files;

    if (this.testimonialImageList && this.testimonialImageList.length > 0) {
      const file = this.testimonialImageList[0];

      // Validate file type and size
      const extension = file.name.substring(file.name.lastIndexOf("."));
      const validFileType = this.fileType;

      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          `Please select a valid file type. Supported types are: ${this.fileType}`
        );
        return;
      }

      if (file.size > 5000000) {
        alert("File size exceeds 5MB");
        return;
      }

      // Read the file as base64 and update testimonial photo
      const reader = new FileReader();
      reader.onload = () => {
        this.testimonials[index].photo = reader.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      // No new file selected, we might want to clear the image only if it's a URL
      this.testimonials[index].photo = ""; // Clear the image if no file is selected
    }
  }

  onImageChange(event: any) {
    this.imageList = event.target.files;
    let hasFile = this.imageList && this.imageList.length > 0;
    if (hasFile) {
      var extension = this.imageList[0].name.substring(
        this.imageList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.imageList = null;
        this.croppedImage = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.imageList[0]);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.workshop.image = this.croppedImage;
      };

      if (this.imageList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.imageList = null;
        return false;
      }
      let multipleFile = this.imageList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.imageList[0];
      }
    }
    return false;
  }

  onSpeakerImageChange(event: any) {
    this.speakerImageList = event.target.files;
    let hasFile = this.speakerImageList && this.speakerImageList.length > 0;
    if (hasFile) {
      var extension = this.speakerImageList[0].name.substring(
        this.speakerImageList[0].name.lastIndexOf(".")
      );
      var validFileType = this.speakerFileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.speakerFileType
        );
        this.speakerImageList = null;
        this.speakerImage = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.speakerImageList[0]);
      reader.onload = () => {
        this.speakerImage = reader.result as string;
        this.workshop.speaker_photo = this.speakerImage;
      };

      if (this.speakerImageList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.speakerImageList = null;
        return false;
      }
      let multipleFile = this.speakerImageList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.speakerImageList[0];
      }
    }
    return false;
  }

  upload(id: any, media: any) {
    this.event_id = id;
    this.modalTitle = "Upload Media";
    this.modalReference = this.modalService.open(media);
    // this.getAllMedia();
  }


  rerender(): void {
    // Wait for the DataTable to be initialized before attempting to destroy
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first (if already initialized)
        dtInstance.destroy();
        // Reinitialize the table again
        this.dtTrigger.next();
      });
    }
  }

  ngAfterViewInit(): void {
  // Add a check if the table is already initialized, then trigger only if needed
  if (!this.dtElement.dtInstance) {
    this.dtTrigger.next();
  }
}


  insertAction(val: any) {
    const workshopFaqs = JSON.stringify(this.faqs);

    //console.log(this.workshop, "workshoopp");

    let formData: FormData = new FormData();
    //console.log(this.SchemesIds, "shemeesssssss");
    formData.append("title", this.workshop.title);
    formData.append("schemes_ids", this.selectedToScheme.join(","));
    formData.append("start_date_time", this.workshop.start_date_time);
    formData.append("end_date_time", this.workshop.end_date_time);
    formData.append("description", this.workshop.description);
    formData.append("type", this.workshop.type);
    formData.append("country", this.workshop.country);
    formData.append("states", this.workshop.states);
    formData.append("city", this.workshop.city);
    formData.append("address", this.workshop.address);
    formData.append("video_type", this.workshop.video_type);
    formData.append("external_link", this.workshop.external_link)
    formData.append("registration_link", this.workshop.registration_link);
    formData.append("visible_on_home", this.workshop.visible_on_home);
    formData.append("is_active", this.workshop.is_active);
    formData.append("workshop_faqs", workshopFaqs);

    formData.append("speaker_name", this.workshop.speaker_name);
    // formData.append("speaker_photo", this.workshop.speaker_photo);
    formData.append("speaker_about", this.workshop.speaker_about);
    formData.append("fees_payment_info", this.workshop.fees_payment_info);
    formData.append("contact_info", this.workshop.contact_info);

    //console.log("speaker_photo", this.speakerImage);
    if (val.id == "") {
      const testimonialsData = JSON.stringify(this.testimonials);
       formData.append("testimonials", testimonialsData);

      if (this.imageList !== undefined) {
        formData.append("image", this.croppedImage);
      }

      if (this.speakerImageList !== undefined) {
        formData.append("speaker_photo", this.speakerImage);
      }

      if (this.fileList != undefined) {
        let file: File = this.fileList[0];
        formData.append("videos", this.croppedVideo);
        //console.log(this.croppedVideo, "videoupload");
      } else {
        formData.append("videos", this.workshop.videos);
      }

      this.dataService
        .createWorkshop(formData)
        .pipe()
        .subscribe(
          (res: any) => {
            if (res["status"] == 200) {
              //console.log(res, "works api");
              this.allWorkshops();
              this.croppedVideo = "";
              this.alertService.success("workshop created successfully", true);
              this.modalReference.close();
            } else if (res["status"] == 422) {
              this.alertService.error(res.mesg, true);
              document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }else if(res["status"] == 500){
              this.alertService.error(res.mesg, true);
              document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    } else {
      formData.append("id", val.id);

      if(this.workshop.video_type == 'FILE'){
        if (this.croppedVideo == null || this.croppedVideo == "") {
          formData.append("videos", "");
        } else {
          formData.append("videos", this.croppedVideo);
        }
      }else{
        formData.append("videos", this.workshop.videos);
      }


      if (this.testimonials && this.testimonials.length > 0) {
        this.testimonials.forEach((testimonial, index) => {
          const isURL =
            testimonial.photo && testimonial.photo.startsWith("https://");

          if (isURL) {
            this.testimonials[index].photo = "";
            //console.log(`Cleared photo for testimonial at index ${index}`);
          }
        });

        const testimonialsData = JSON.stringify(this.testimonials);
        formData.append("testimonials", testimonialsData);

        //console.log(this.testimonials, "Updated testimonials");
        //console.log(testimonialsData, "testimonialsData to send");
      }

      if (this.imageList !== undefined) {
        formData.append("image", this.croppedImage);
      }

      if (this.speakerImageList !== undefined) {
        formData.append("speaker_photo", this.speakerImage);
      }

      this.dataService
        .updateWorkshop(formData)
        .pipe()
        .subscribe(
          (data: any) => {
            if (data["status"] == 200) {
              this.modalReference.close();
              this.allWorkshops();
              this.alertService.success("workshop updated successfully", true);
            } else if (data["status"] == 422) {
              this.alertService.error(data.mesg);
              window.scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }

  multiselect(e: any) {
    //console.log(e.target.value, this.SchemesIds, "dheemeee");
  }


  delete(item) {
    this.loadShow();
    this.dataService
      .deleteWorkshop(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Workshop deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allWorkshops();
          } else {
            this.alertService.error("Workshop error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  addRow() {
    this.newDynamic = { event_type: "", event_label: "", is_mandatory: "" };
    this.dynamicArray.push(this.newDynamic);
    // this.toastr.success('New row added successfully', 'New Row');
    //console.log(this.dynamicArray);
    return true;
  }

  deleteRow(index: any) {
    if (this.dynamicArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      return true;
    }
  }

  pdfExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-pdf", "_blank");
  }
  xlExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-excel", "_blank");
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  exit(event: any) {
    this.fileList = event.exitTransform;
  }

  closeCropper() {
    this.modalCropper.close();
  }

  uploadmedia() {
    let formData: FormData = new FormData();
    formData.append("event_id", this.event_id);
    if (this.fileListImage != undefined) {
      for (let i = 0; i < this.fileListImage.length; i++) {
        let file: File = this.fileListImage[i];
        formData.append("event_images[" + i + "]", file, file.name);
      }
    }

    if (this.fileListVideo != undefined) {
      for (let i = 0; i < this.fileListVideo.length; i++) {
        let file: File = this.fileListVideo[i];
        formData.append("event_videos[" + i + "]", file, file.name);
      }
    }
  }
}
