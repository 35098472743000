import {
  AfterViewInit,
  OnDestroy,
  Component,
  OnInit,
  Input,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { DataTableDirective } from "angular-datatables";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  AlertService,
  BlogSubCategoryService,
  AppService,
} from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";

class BlogSubCategory {
  id: number;
  name: string;
  category_id: number;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-blog-sub-category",
  templateUrl: "./blog-sub-category.component.html",
})
export class BlogSubCategoryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  // dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  blogSubCategoryList: BlogSubCategory[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getBlogSubCategory = {
    id: "",
    name: "",
    category_id: "",
    description: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  cat = {};
  constructor(
    public router: Router,
    private dataService: BlogSubCategoryService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  blogsubcategory = {
    id: "",
    name: "",
    category_id: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.allBlogSubCategory();
    this.dataService
      .getAllBlogcategory()
      .pipe()
      .subscribe((data) => {
        this.cat = Array.isArray(data["cat"]) ? data["cat"] : []; // Ensure it's an array
      });
  }

  allBlogSubCategory() {
    this.loadShow();
    this.dataService
      .getAllBlogSubCategory()
      .pipe()
      .subscribe((data) => {
        this.blogSubCategoryList = data["blogsubcategory"];
        // this.dtTrigger.next(); // Data Table
        this.rerender();
        this.loadHide();
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  open(content) {
    this.isSubmitted = false;
    this.blogsubcategory.id = "";
    (this.blogsubcategory.name = ""),
      (this.blogsubcategory.category_id = ""),
      (this.blogsubcategory.description = ""),
      (this.blogsubcategory.is_active = "Y");
    this.modalTitle = "Add Blog Category";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.isSubmitted = true;
    this.loadShow();
    this.dataService
      .getBlogSubCategory(id)
      .pipe()
      .subscribe((data) => {
        this.getBlogSubCategory = data["blogsubcategory"];
        this.blogsubcategory = {
          id: this.getBlogSubCategory.id,
          name: this.getBlogSubCategory.name,
          category_id: this.getBlogSubCategory.category_id,
          description: this.getBlogSubCategory.description,
          is_active: this.getBlogSubCategory.is_active,
        };
        this.loadHide();
        this.isSubmitted = false;
        this.modalTitle = "Edit Blog Sub Category";
        this.modalReference = this.modalService.open(content);
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.blogsubcategory.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.blogsubcategory)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success(
                "Blog sub category saved successfully",
                true
              );
              this.allBlogSubCategory();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .blogSubCategoryUpdate(this.blogsubcategory)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success(
                "Blog Sub Category updated successfully",
                true
              );
              this.allBlogSubCategory();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  // delete(id){
  //     this.dataService.blogSubCategoryDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Blog Sub Category deleted successfully', true);
  //             this.allBlogSubCategory();
  //           }else{
  //             this.alertService.error('Blog Sub Category error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .blogSubCategoryDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Blog Sub Category deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allBlogSubCategory();
          } else {
            this.alertService.error("Blog Sub Category error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
