<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div class="card-block-title">
							<h5 class="card-title">SubCategory</h5>
							<button id="add_btn" type="button" class="btn btn-info pull-right" (click)="open(content)"> 
								Add SubCategory
							</button>
						</div>
						<br/>
						<div class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-hover" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Category</th>
										<th>Sub Category</th>
										<th>Description</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of subCategoryList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.categoryName}}</td>
										<td>{{item.name}}</td>
										<td>{{item.description}}</td>
										<td><span *ngIf="item.status==1" class="badge badge-success badge-pill">Active</span> <span *ngIf="item.status==0" class="badge badge-danger badge-pill">In active</span></td>
										<td><button  class="btn btn-outline-danger btn-sm" (click)="delete(this.subCategoryList[i].id)">x</button> <button type="button" class="btn btn-outline-primary btn-sm" (click)="edit(this.subCategoryList[i].id,content)"><i class="fa fa-pencil fa-lg mt-1"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<!--/.col-->
		</div>
	</div>
	<!-- class="modal fade" -->
	<ng-template #content let-c="close" let-d="dismiss" id="customer-add"> 
		<div class="modal-info" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{modalTitle}}</h4>
					<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body">
					<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
						<div class="form-group row">
							<label for="category" class="col-5 form-control-label">Category <span>*</span></label>
							<div class="col-7">
								<select class="form-control" id="category" name="category"  required [(ngModel)]="subCat.category">
									<option value="" selected disabled>Please Select</option>
									<option *ngFor="let item of allcategory" value="{{item.id}}">{{item.name}}</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label for="subCategory" class="col-5 col-form-label">Sub Category <span>*</span></label>
							<div class="col-7">
								<input class="form-control" type="text" id="subCategory" name="subCategory" required [(ngModel)]="subCat.subCategory">
							</div>
						</div>
						<div class="form-group row">
							<label for="description" class="col-5 col-form-label">Description</label>
							<div class="col-7">
								<input class="form-control" type="text" id="description" name="description" [(ngModel)]="subCat.description">
							</div>
						</div>
						<div class="form-group row">
							<label for="status" class="col-5 col-form-label">Status</label>
							<div class="col-7">
								<select class="form-control" id="status" name="status" [(ngModel)]="subCat.status">
									<option value="1" selected>Active</option>
									<option value="0">Deactive</option>
								</select>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">Close</button>
					<button type="submit" class="btn btn-outline-primary" [disabled]="!f.form.valid" (click) ="save(this.subCat)">Save</button>
				</div>
			</div>
		</div>
	</ng-template>
</div>



