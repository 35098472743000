import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CountryService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/country-create', data);
  }

  getAllCountry(){
    return  this.base.get('/api/all-country');
  }

  getCountry(id){
    return this.base.post('/api/get-country',{id:id});
  }

  countryUpdate(data){
    return this.base.post('/api/country-update',data);
  }

  countryDelete(id){
    return this.base.post('/api/country-delete',{id:id});
  }
}
