<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/part.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Award To Reward</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{(atrList?.length > 0) ? atrList?.length : 0 }}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th style="width: 80px;">Name</th>
										<th>Objective</th>
										<th>Approval Status</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of atrList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td style="width: 80px;">{{item.atr_name}}</td>
										<td>{{item.objective}}</td>
										<td><span *ngIf="item.status=='PENDING_FOR_APPROVAL'">Approval Pending</span>
											<span *ngIf="item.status=='APPROVED'">Approved</span>
											<span *ngIf="item.status=='REJECTED'">Rejected</span>
										</td>
									
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'"
												class="badge badge-success badge-pill">Active</span> <span
												*ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In
												active</span></td>
										<td class="action">
											<div class="d-flex">
												<label class="switch">
													<input 
														type="checkbox"
														(change)="delete(item)"
														[checked]="item.is_active === 'Y'">
													<span class="slider round"></span>
												 </label>

												<button type="button" class="btn text-dark btn-sm  rounded bg-light"
													(click)="openPartnerViewPage(this.atrList[i].id)" title="View"><i
														class="fa fa-eye"></i></button>
												<button type="button"
													class="btn text-dark btn-sm  rounded bg-light ml-1" title="Response"
													(click)="viewResponse(item.slug)"><i
													class="fa fa-file" ></i></button>										

													<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
													 (click)="delete(this.atrList[i].id)"><i
											          class="fa fa-trash"></i></button> -->
														
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


