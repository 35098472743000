import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MemberBlogsService {

  constructor(private base: BaseService) { }  

  blogDelete(id){
    return this.base.post('/api/blog-delete',{id:id});
  }

  getMemberBlog(){
    return  this.base.get('/api/get-member-blog');
  }

  viewBlog(id) {
    return this.base.post('/api/blog-view',{id:id});
  }  

}
