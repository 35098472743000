import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';
@Injectable()
export class UserRoleService {

  constructor(private base: BaseService) { }

  getUser(id){
    return this.base.post('/api/user-assing-role-data',{id:id});
  }
  
  getRoleData() {
    return this.base.get('/api/user-role-data');
  }

  addUserRole(menu,submenu,user_id){
    return this.base.post('/api/add-user-role',{menu:menu,subMenu:submenu,userId:user_id});
  }
}
