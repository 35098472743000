import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SupplierService {

  constructor(private base: BaseService) { }

  save(data){
  	return this.base.post('/api/supplier-save',data);

  }

  getAllSupplier(){
    return  this.base.get('/api/get-all-supplier');
  }

  getSupplier(id){
    return this.base.post('/api/get-supplier',{id:id});
  }

  supplierUpdate(data){
    return this.base.post('/api/supplier-update',data);
  }
  
  getSupplierInfo(id){
    return this.base.post('/api/get-supplier-info',{id:id});
  }

}
