import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WeCNewsfeedsService {
  constructor(private base: BaseService) {}

  deleteNewsFeeds(id) :Observable<any>{
    return this.base.post("/api/we-community-newsfeed-update-status", {
      id: id,
    });
  }

  deleteComments(id) {
    return this.base.post("/api/we-community-comment-status", {
      id: id,
    });
  }

  

  viewNewsFeed(id) {
    return this.base.post("/api/we-community-posts-view", {
      id: id,
    });
  }
}
