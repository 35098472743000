import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MentorVerificationService {

  constructor(private base: BaseService) { }

  onMentorVerification(data) {
    return  this.base.post('/api/mentor-verification-by-id', data);
  }
}
