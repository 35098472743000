import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import {
  AlertService,
  WepExpectationsService,
  AppService,
} from "../_services/index";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class WepExpectations {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-wep-expectations",
  templateUrl: "./wep-expectations.component.html",
  styles: [],
})
export class WepExpectationsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable
  wepexpectationsList: WepExpectations[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getWepExpectations = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  constructor(
    public router: Router,
    private dataService: WepExpectationsService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  wepexpectations = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allWepExpectations();
  }

  allWepExpectations() {
    this.loadShow();
    this.dataService
      .getAllWepExpectation()
      .pipe()
      .subscribe((data) => {
        this.wepexpectationsList = data["WepExpectations"];
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    this.wepexpectations.id = "";
    (this.wepexpectations.name = ""),
      (this.wepexpectations.description = ""),
      (this.wepexpectations.is_active = "Y");
    this.modalTitle = "Add Wep Expectation";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .WepExpectationEdit(id)
      .pipe()
      .subscribe((data) => {
        this.getWepExpectations = data["WepExpectations"];
        this.wepexpectations = {
          id: this.getWepExpectations.id,
          name: this.getWepExpectations.name,
          description: this.getWepExpectations.description,
          is_active: this.getWepExpectations.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit Wep Expectation";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.wepexpectations.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.wepexpectations)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success(
                "Wep expectation saved successfully",
                true
              );
              this.allWepExpectations();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .WepExpectationUpdate(this.wepexpectations)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success(
                "Wep expectations updated successfully",
                true
              );
              this.allWepExpectations();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.WepExpectationDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Wep expectations deleted successfully', true);
  //             this.();
  //           }else{
  //             this.alertService.error('Wep expectations error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //       }
  //    }

  delete(item) {
    this.loadShow();
    this.dataService
      .WepExpectationDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Wep expectations deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allWepExpectations();
          } else {
            this.alertService.error("Wep expectations error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
