import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AlertService, RoleService } from "../_services";
import { EmailNotificationService } from "../_services/email-notification.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../_services/encryption.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

class EmailNotification {
  id: number;
  type: number;
  name: string;
  body: string;
  specific_email: string;
  schedule_date: string;
  schedule_type: string;
  subject: string;
  attachement1: string;
  attachement2: string;
  attachement3: string;
  attachement4: string;
  is_active: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-email-notification",
  templateUrl: "./email-notification.component.html",
  styleUrls: [],
})
export class EmailNotificationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg, .jpeg, .png, .bmp";
  @Input() fileTypeImage: string = ".jpg, .jpeg, .png, .bmp";
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  emailNotificationList: any[] = []; // Table Data list
  clicked = false;
  isSubmitted = false;
  required: boolean = true;
  @Output() onSelection = new EventEmitter<FileList>();
  emailNotificationTotal: any;
  // countryAddForm: FormGroup;
  DisplayedText1 : string = "";
  DisplayedText2 : string = "";
  DisplayedText3 : string = "";
  DisplayedText4: string = "";

  fileList1: any;
  fileList2: any;
  fileList3: any;
  fileList4: any;

  imageChangedEvent: any = "";
  croppedImage: any = "";
  image_cropper: "";

  getEmailNotification = {
    id: "",
    type: "",
    name: "",
    Body: "",
    specific_email: "",
    schedule_date: "",
    schedule_type: "",
    subject: "",
    attachement1: "",
    attachement2: "",
    attachement3: "",
    attachement4: "",
    is_active: "N",
  };

  attachment_image1: any = "";
  attachment_image2: any = "";
  attachment_image3: any = "";
  attachment_image4: any = "";

  modalReference: NgbActiveModal;
  modalTitle: string;

  typeDrop: any = [
    { id: 1, value: "members" },
    { id: 2, value: "partner" },
    { id: 3, value: "mentor" },
    { id: 4, value: "organizations" },
    { id: 5, value: "specific" },
  ];

  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: EmailNotificationService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService
  ) {}

  email_notification = {
    id: "",
    type: "",
    name: "",
    body: "",
    specific_email: "",
    schedule_date: "",
    schedule_type: "",
    subject: "",
    attachement1: "",
    attachement2: "",
    attachement3: "",
    attachement4: "",
    is_active: "Y",
  };

  showloding = true;
  lodingImage = false;

  ngOnInit() {
    this.allEmailNotification();
    this.loadHide();
    window.scroll(0, 0);
  }

   allEmailNotification() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/get-all-email-notification",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "resppp");
 
            this.emailNotificationList = resp["data"];
            this.emailNotificationTotal = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "subject" },
        { data: "email" },
        { data: "created_at" },
        { data: "status" },
        { data: "action" },
      ],
      columnDefs: [
        { targets: [6], orderable: false }, // Disable sorting for the "Action" column (index 6)
      ],
    };
  }
 

  open(content) {
    this.isSubmitted = false;
    this.email_notification.id = "";
    this.email_notification.name = "";
    (this.email_notification.type = ""),
      (this.email_notification.body = ""),
      (this.email_notification.specific_email = "");
    (this.email_notification.schedule_type = ""),
      (this.email_notification.schedule_date = ""),
      (this.email_notification.subject = "");
    (this.email_notification.attachement1 = ""),
      (this.email_notification.attachement2 = ""),
      (this.email_notification.attachement3 = ""),
      (this.email_notification.attachement4 = ""),
      (this.email_notification.is_active = "Y");
    this.modalTitle = "Add Email Notification";

    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();

    this.DisplayedText1 = "";
    this.DisplayedText2 = "";
    this.DisplayedText3 = "";
    this.DisplayedText4 = "";

    this.dataService
      .editEmailNotification(id)
      .pipe()
      .subscribe((data) => {
        this.getEmailNotification = data["emails"];
        this.email_notification = {
          id: this.getEmailNotification.id,
          type: this.getEmailNotification.type,
          name: this.getEmailNotification.name,
          body: this.getEmailNotification.Body,
          specific_email: this.getEmailNotification.type,
          schedule_date: this.getEmailNotification.schedule_date,
          schedule_type: this.getEmailNotification.schedule_type,
          subject: this.getEmailNotification.type,
          attachement1: this.getEmailNotification.attachement1,
          attachement2: this.getEmailNotification.attachement2,
          attachement3: this.getEmailNotification.attachement3,
          attachement4: this.getEmailNotification.attachement4,
          is_active: "Y",
        };
        this.loadHide();
        this.modalTitle = "Edit Email Notification";
        this.modalReference = this.modalService.open(content);
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    if (
      this.isFieldsInvalid(this.email_notification.name) ||
      this.isFieldsInvalid(this.email_notification.subject)
    ) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    $(":submit").attr("disabled", "disabled");
    if (f.form.valid) {
      this.loadShow;
      if (val.id == "") {
        this.dataService
          .save(this.email_notification)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                //console.log(data, "response");
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Email Notification saved successfully",
                  true
                );
                const table = $("#email_noti_table").DataTable();
                table.ajax.reload(null, false);
              } else {
                this.loadHide();
                this.alertService.error("error");
              }
            },
            (error) => {
              this.loadHide();
              window.scrollTo({ top: 0, behavior: "smooth" });
              this.alertService.error(error);
            }
          );
      } else {
        this.dataService
          .emailNotificationUpdate(this.email_notification)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success(
                "Email Notification updated successfully",
                true
              );
              const table = $("#email_noti_table").DataTable();
              table.ajax.reload(null, false);
            },
            (error) => {
              this.loadHide();
              window.scrollTo({ top: 0, behavior: "smooth" });
              this.alertService.error(error);
            }
          );
      }
    }
    this.loadHide;
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  onFileChange1(event: any) {
    this.fileList1 = event.target.files;
    this.DisplayedText1 = this.fileList1[0].name
  
    let hasFile = this.fileList1 && this.fileList1.length > 0;
    if (hasFile) {
      var extension = this.fileList1[0].name.substring(
        this.fileList1[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList1 = null;
        this.DisplayedText1 = ""
        this.attachment_image1 = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList1[0]);
      reader.onload = () => {
        this.attachment_image1 = reader.result as string;
        //console.log(this.attachment_image1);
        this.email_notification.attachement1 = this.attachment_image1;
      };

      if (this.fileList1[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList1 = null;
        return false;
      }
      let multipleFile = this.fileList1.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList1[0];
      }
    }
    return false;
  }

  onFileChange2(event: any) {
    this.fileList2 = event.target.files;
    this.DisplayedText2 = this.fileList2[0].name

    let hasFile = this.fileList2 && this.fileList2.length > 0;
    if (hasFile) {
      var extension = this.fileList2[0].name.substring(
        this.fileList2[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList2 = null;
        this.DisplayedText2 = ""
        this.attachment_image2 = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList2[0]);
      reader.onload = () => {
        this.attachment_image2 = reader.result as string;
        //console.log(this.attachment_image2);
        this.email_notification.attachement2 = this.attachment_image2;
      };

      if (this.fileList2[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList2 = null;
        return false;
      }

      let multipleFile = this.fileList2.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList2[0];
      }
    }
    return false;
  }

  onFileChange3(event: any) {
    this.fileList3 = event.target.files;
    this.DisplayedText3 = this.fileList3[0].name

    let hasFile = this.fileList3 && this.fileList3.length > 0;
    if (hasFile) {
      var extension = this.fileList3[0].name.substring(
        this.fileList3[0].name.lastIndexOf(".")
      );

      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList3 = null;
        this.DisplayedText3 = ''
        this.attachment_image3 = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList3[0]);
      reader.onload = () => {
        this.attachment_image3 = reader.result as string;
        //console.log(this.attachment_image3);

        this.email_notification.attachement3 = this.attachment_image3;
      };

      if (this.fileList3[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList3 = null;
        return false;
      }

      let multipleFile = this.fileList3.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList3[0];
      }
    }
    return false;
  }

  onFileChange4(event: any) {
    this.fileList4 = event.target.files;
    this.DisplayedText4 = this.fileList4[0].name

    let hasFile = this.fileList4 && this.fileList4.length > 0;
    if (hasFile) {
      var extension = this.fileList4[0].name.substring(
        this.fileList4[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList4 = null;
        this.DisplayedText4 = ''
        this.attachment_image4 = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList4[0]);
      reader.onload = () => {
        this.attachment_image4 = reader.result as string;
        this.email_notification.attachement4 = this.attachment_image4;
      };

      if (this.fileList4[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList4 = null;
        return false;
      }

      let multipleFile = this.fileList4.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList4[0];
      }
    }
    return false;
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .emailNotificationDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Event Notification deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allEmailNotification();
          } else {
            this.alertService.error("Event Notification error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    // this.event.image = '';
    // this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  exit(event: any) {
    // this.fileList = event.exitTransform;
  }

  openCropper(contentCrop) {
    // this.modalCropper = this.modalService.open(contentCrop);
  }

  closeCropper() {
    // this.modalCropper.close();
  }
}
