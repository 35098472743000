import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MediaAndAnnouncementService {

  constructor(private base: BaseService) { }

  save(data){
  	return this.base.post('/api/media-annoncement-create', data);
  }

  edit(id){
    return this.base.post('/api/media-annoncement-edit',{id:id});
  }

  update(data){
    return this.base.post('/api/media-annoncement-update',data);
  }

  delete(id){
    return this.base.post('/api/media-annoncement-status',{id:id});
  }

  // getAllMediaAndAnnouncement(){
  //   return  this.base.get('/api/get-eventcategory');
  // }

}
