<!-- <div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div> -->

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/part.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">OTP Logs</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{totalOtpList}}</h2>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable id="otp_logs"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th >#Sl</th>
										<th style="width: 20%">Name</th>
										<th>Email</th>
										<th style="width: 10%;">Mobile</th>
										<th>Created Date</th>
									</tr>
								  </thead>

									<tbody *ngIf="optLogsList?.length != 0">
										<tr *ngFor="let item of optLogsList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td style="width: 20%">{{item.name}}</td>
										<td>{{item.email}}</td>
                                        <td>{{item.phone}}</td>
										<!-- <td><span *ngIf="item.status=='PENDING'">Approval Pending</span>
											<span *ngIf="item.status=='APPROVED'">Approved</span>
											<span *ngIf="item.status=='REJECTED'">Rejected</span>
										</td> -->
										
										<td>{{item.created_at}}</td>
									</tr>
								</tbody>

								  <tbody *ngIf="optLogsList?.length == 0">
								<tr>
								  <td colspan="7" class="no-data-available text-center">No data available!</td>
								</tr>
							</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>			
		</div>

	</div>
</div>