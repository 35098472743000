import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class WtiAwardService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/add-wti-award', data);
  }

  wtiAwardEdit(id){
    return this.base.post('/api/edit-wti-award',{id:id});
  }

  wtiAwardUpdate(data){
    return this.base.post('/api/update-wti-award',data);
  }

  wtiAwardDelete(id){
    return this.base.post('/api/delete-wti-award',{id:id});
  }

  getAllWtiAward(){
    return  this.base.get('/api/get-all-wti-award');
  }


}
