import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, SalesService,PurchaseService,NotificationService } from '../_services/index';

export interface INotification {
  subject:string;
  type:number;
  to:number[];
  body:string;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() allowMultiple: boolean;
  @Input() fileType: string;
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList:any;

  showloding = true;
  lodingImage = false;
  notification ={
    subject:'',
    type:1,
    to:[],
    body:''
  }
  allCustomer:any[] = [];
  allSupplier:any[] = [];
  constructor(
    private alertService:AlertService,
    private dataService:SalesService,
    private purchaseService:PurchaseService,
    private notificationService:NotificationService,
  ) { }

  selectedTo:number[]=[];
  ngOnInit() {
    setTimeout(() => {
      this.showloding = false;
      this.lodingImage = true;
    },500);
    this.customer();
    this.supplier();
  }
  changeTo(){
    this.notification.to = this.selectedTo;
  }

  changeType(){
    this.selectedTo = [];
  }

  customer(){
		this.dataService.getAllCustomer()
			.pipe().subscribe(data => { 
    				this.allCustomer = data['customer'];
      });
  }
  
  supplier(){
		this.purchaseService.getAllSupplier()
			.subscribe(data =>{
						this.allSupplier = data['supplier'];
		});
  }
  
  fileChange(event: any) {
    this.fileList = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
        var extension = this.fileList[0].name.substring(this.fileList[0].name.lastIndexOf('.'));
        // Only process image files.
        var validFileType = ".jpg , .png , .bmp , .pdf";
        if (validFileType.toLowerCase().indexOf(extension) < 0) {
            alert("please select valid file type. The supported file types are .jpg , .png , .bmp , .pdf");
          this.fileList = null;
          this.DisplayedText = "";
          return false;
        }
   
        /* if (this.fileList[0].size > 165535) {
            alert(`File size is more than 165 Kb`);
            this.fileList = null;
            this.DisplayedText = "";
            return false;
        } */

        let multipleFile = this.fileList.length > 1;
        if (multipleFile) {
            this.DisplayedText = this.fileList.length + ' file(s) has been selected';
        }
        else {
            let file: File = this.fileList[0];
            this.DisplayedText = file.name;
        }
        this.onSelection.emit(this.fileList);
    }
  }

  editorData(event){
    this.notification.body = event;
    // //console.log(this.notification)
    /* let formData:FormData = new FormData();
    if(this.fileList !=undefined){
      let file: File = this.fileList[0];
      formData.append('file', file, file.name);
    }

    formData.append('subject', this.notification.subject);
    formData.append('to', JSON.stringify(this.notification.to));
    formData.append('type', JSON.stringify(this.notification.type));
    formData.append('address', this.notification.body); */


    this.notificationService.send(this.notification)
    .pipe().subscribe(data =>{
      this.alertService.success('Email send successful', true);
    },error =>{
      this.alertService.error(error);
    });
  }
}
