<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Cities</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{cityList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="open(content)" style="float: right;"> 
											Add City
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
					
						<div class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>City</th>
										<th>State</th>
										<th>Country</th>
										<th class="d-none">Created By</th>
										<th>Created Date</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of cityList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>										
										<td>{{item.city_name}}</td>
										<td>{{item.state_name}}</td>
										<td>{{item.country_name}}</td>
										<td class="d-none"><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success font-weight-normal badge-pill">Active</span> <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In active</span></td>
										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light" (click)="delete(this.cityList[i].id)"><i class="fa fa-trash"></i></button>&nbsp; -->
											<button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(this.cityList[i].id,content)"><i class="fa fa-edit"></i></button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #content let-c="close" let-d="dismiss" id="customer-add"> 
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">   
							<div class="row">                         
							<div class="form-group col-md-6">
								<label for="country_id" class="form-control-label">Country <span>*</span></label>
								<div class=" ">
									<select class="form-control" id="country_id" name="country_id"  required [(ngModel)]="city.country_id" (ngModelChange)="selectStateById($event)">
										<option value="" selected disabled>Please Select</option>
										<option *ngFor="let item of cntry" value="{{item.id}}">{{item.country_name}}</option>
									</select>
									<span *ngIf="isSubmitted && (city.country_id==undefined || city.country_id=='')" class="alert-block">This field is required</span>
								</div>
							</div>
							<!-- *ngIf="city.country_id !=''" -->
							<div class="form-group col-md-6">
								<label for="state_id" class="form-control-label">State <span>*</span></label>
								<div class=" ">
									<select class="form-control" id="state_id" name="state_id" required [(ngModel)]="city.state_id">
										<option value="" selected>Please Select</option>
										<option *ngFor="let item of stat" value="{{item.id}}">{{item.state_name}}</option>
									</select>
									<span *ngIf="isSubmitted && (city.state_id==undefined || city.state_id=='')" class="alert-block">This field is required</span>
								</div>
							</div>

							<div class="form-group col-md-6">
								<label for="city_name" class="col-form-label">City <span>*</span> </label>
								<div class=" ">
									<input class="form-control" type="text" id="city_name" name="city_name" required [(ngModel)]="city.city_name"  pattern="[A-Za-z ]*">
									<span *ngIf="isSubmitted && isFieldsInvalid(city.city_name)" class="alert-block">This field is required</span>
									<span  *ngIf="f.controls.city_name?.errors?.pattern" 	class="alert-block">Invalid format</span>
								</div>
							 </div>	
							 							
							<div class="form-group col-md-6">
								<label for="is_active" class="col-form-label">Status</label>
								<div class=" ">
									<select class="form-control" id="is_active" name="is_active" [(ngModel)]="city.is_active">
										<option value="Y">Active</option>
										<option value="N">Inactive</option>
									</select>
								  </div>
							    </div>
							  </div>
							
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close" (click)="d('Cross click')">Close</button>
						<button type="submit" class="btn btn-primary rounded savebtn" (click) ="save(this.city,f)">Save</button>

					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>



