import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class JioMeetingsService {
  constructor(private base: BaseService) {}

  getFeedbackList(data) {
    return this.base.post("/api/jiomeet-feedback-list", data);
  }

  viewJioMeet(data) {
    return this.base.post("/api/jio-meet-view", data);
  }

  getJiomeetList() {
    return this.base.get("/api/jio-meet-list");
  }

  exportJiomeetList() {
    return this.base.get("/api/export-jiomeet");
  }
}
