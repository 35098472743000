import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SectorOfEnterpriseService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/admin/sectorofenterprise/sectorofenterprise-create', data);
  }

  getAllSectorofenterprise(){
    return this.base.get('/api/admin/sectorofenterprise/all-sectorofenterprise');
  }

  getSectorofenterprise(id){
    return this.base.post('/api/admin/sectorofenterprise/get-sectorofenterprise',{id:id});
  }

  sectorofenterpriseUpdate(data){
    return this.base.post('/api/admin/sectorofenterprise/sectorofenterprise-update',data);
  }

  sectorofenterpriseDelete(id){
    return this.base.post('/api/admin/sectorofenterprise/sectorofenterprise-delete',{id:id});
  }

 
}

