import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FaqService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');

  save(data){
  	return this.base.post('/api/faq-create', data);
  }

  faqUpdate(data){
    return this.base.post('/api/faq-update',data);
  }

  faqDelete(id){
    return this.base.post('/api/faq-delete',{id:id});
  }

  getFaqById(id){
    return this.base.post('/api/faq-edit',{id:id});
  }

  getAllFaq(){
    return  this.base.get('/api/get-all-faq');
  }

  getFaqCategory() {
    return  this.base.get('/api/get-faq-category');
  }

  getBlogCategory() {
    return  this.base.get('/api/all-blogcategory');
  }


}
