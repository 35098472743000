import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';

@Injectable({
  providedIn: 'root'
})
export class WorkshopsService {

  constructor(private base:BaseService) { }

  createWorkshop(data:any){
  	return this.base.post('/api/admin/workshop/workshop-create', data);
  }

  updateWorkshop(data:any){
  	return this.base.post('/api/admin/workshop/workshop-update', data);
  }

  deleteWorkshop(id:any){
  	return this.base.post('/api/admin/workshop/workshop-delete',{id:id});
  }

  editWorkshop(id:any){
  	return this.base.post('/api/admin/workshop/workshop-edit', {id:id});
  }

  getAllWorkshops(){
    return this.base.get('/api/admin/workshop/all-workshop');
  }

  getActiveWorkshops(){
    return this.base.get('/api/admin/workshop/get-active-workshop');
  }
  
  getSchemes(){
    return this.base.get('/api/admin/blog/get-active-blog');
  }

  getAllState(){
    return  this.base.get('/api/all-indian-state');
  }

  getAllCity(){
    return  this.base.get('/api/all-city');
  }
}
