import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';


@Injectable()
export class PurchasesHistoryService {

  constructor(private base: BaseService) { }



  getAllPurchases(){
    return this.base.get('/api/get-all-purchases');
  }

  getPurchasesDetails(val){
    return this.base.post('/api/get-purchases-details',{purchase_code:val});
  }
}