import {

  Component,
  OnInit,

} from "@angular/core";

@Component({
  selector: "app-mentorship-provided-by-mentor",
  templateUrl: "./mentorship-provided-by-mentor.component.html",
})
export class MentorshipProvidedByMentorComponent implements OnInit{

    ngOnInit(): void {
        
    }

}