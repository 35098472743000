import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {
  AlertService,
  MentorListService,
  AppService,
} from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseService } from "../_services/base.service";
import { EncryptionService } from "../_services/encryption.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-mentor-list",
  templateUrl: "./mentor-list.component.html",
  styles: [],
})
export class MentorListComponent implements OnInit {
  isCopied: boolean = false;
  dtOptions: DataTables.Settings = {};
  isChecked: boolean = false;
  verifyValue: string = "N";
  mentorList: any[] = []; // Table Data list
  totalMentors: any;
  closeResult: string; // Modal Close
  filterData = { status: "ALL" };
  start: number = 0;
  pageLength: number = 10;
  mentorInfo = {
    id: "",
    type: "",
    organization_name: "",
    age_of_organization: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    type_of_organization: "",
    birth_yr: "",
    gender: "",
    address: "",
    country: "",
    country_name: "",
    state: "",
    state_name: "",
    city: "",
    city_others: "",
    city_name: "",
    qualification: "",
    qualification_name: "",
    spoken_lang: "",
    spoken_lang_names: "",
    primary_industry: "",
    primary_industry_name: "",
    stage_of_enterprise: "",
    stage_of_enterprise_names: "",
    industry_experience: "",
    industry_experience_name: "",
    area_of_functional_expertise: "",
    area_of_functional_expertise_name: "",
    provide_mentoring: "",
    kind_of_mentoring_name: "",
    preferred_track_of_mentorship: "",
    track_of_mentorship_names: "",
    ecosystem_key: "",
    ecosystem_key_names: "",
    preference_across: "",
    preference_across_name: "",
    mode_of_engagement: "",
    mode_of_engagement_name: "",
    willing_no_of_mentees: "",
    willing_no_of_mentees_name: "",
    ecosystem_key_other: "",
    no_of_hours_per_month: "",
    no_of_hours_per_month_name: "",
    linkedin_profile_url: "",
    approve_status: "",
    remark: "",
    profileimage: "",
    document: "",
    primary_industry_others: "",
    spoken_lang_others: "",
    qualification_others: "",
    meeting_date: "",
    meeting_end_time: "",
    meeting_link: "",
    meeting_start_time: "",
  };

  constructor(
    public router: Router,
    private dataService: MentorListService,
    private enc_dec: EncryptionService,
    private http: HttpClient,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;
  isSubmitted: boolean = false;
  ngOnInit() {
    if (localStorage.getItem("mentor_approve_status") != null) {
      this.filterData.status = localStorage.getItem("mentor_approve_status");
    }

    this.getMentorListing();
  }

  getMentorListing() {
    this.loadShow();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      order: [[8, "desc"]], // Update column index for "created_at" column to descending

      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/all-mentor-by-status",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(
                  Object.assign(dataTablesParameters, this.filterData)
                )
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            this.mentorList = resp["data"];
            this.totalMentors = resp["recordsFiltered"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
            //console.log(resp, "DataTablesResponse");
          });
      },

      // Change column sequence: Action, Status, Active/Inactive, Name, Email, Mobile, Onboarded Date, Created At, Referral Code, Activated/Inactivated, Inactivated Date, Approved/Rejected By

      columns: [
        // { data: "id" },
        { data: "action" },
        { data: "status" },
        { data: "approve_status" },
        { data: "name" },
        { data: "email" },
        { data: "mobile" },
        { data: "approved_rejected_date" },
        { data: "created_at" }, // Make sure this index matches
        { data: "referral_code" },
        { data: "inactivated_by_name" },
        { data: "inactivated_date" },
        { data: "approved_rejected_by_name" },
      ],
    };
    this.loadHide();
    window.scroll(0, 0);
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  openMentorInfo(id, mentorinfo_ind) {
    this.loadShow();
    this.dataService
      .getMentorInfo(id)
      .pipe()
      .subscribe((data) => {
        let mentors = data["data"];
        //console.log(mentors, ",MEtooorrr");

        this.isChecked = mentors.is_verify === "Y";
        this.verifyValue = this.isChecked ? "Y" : "N";
        this.mentorInfo = {
          id: mentors.id,
          type: mentors.type,
          organization_name: mentors.organization_name,
          age_of_organization: mentors.age_of_organization,
          first_name: mentors.first_name,
          last_name: mentors.last_name,
          email: mentors.email,
          qualification_others: mentors.qualification_others,
          mobile: mentors.mobile,
          type_of_organization: mentors.type_of_organization,
          birth_yr: mentors.birth_yr,
          gender: mentors.gender,
          address: mentors.address,
          country: mentors.country,
          country_name: mentors.country_name,
          state: mentors.state,
          state_name: mentors.state_name,
          city: mentors.city,
          city_name: mentors.city_name,
          city_others: mentors.city_others,
          ecosystem_key_other: mentors.ecosystem_key_other,
          qualification: mentors.qualification,
          qualification_name: mentors.qualification_name,
          spoken_lang: mentors.spoken_lang,
          spoken_lang_names: mentors.spoken_lang_names,
          primary_industry: mentors.primary_industry,
          meeting_date: mentors.meeting_date,
          meeting_end_time: mentors.meeting_end_time,
          meeting_link: mentors.meeting_link,
          meeting_start_time: mentors.meeting_start_time,
          primary_industry_name: mentors.primary_industry_name,
          stage_of_enterprise: mentors.stage_of_enterprise,
          stage_of_enterprise_names: mentors.stage_of_enterprise_names,
          industry_experience: mentors.industry_experience,
          industry_experience_name: mentors.industry_experience_name,
          area_of_functional_expertise: mentors.area_of_functional_expertise,
          area_of_functional_expertise_name:
            mentors.area_of_functional_expertise_name,
          provide_mentoring: mentors.provide_mentoring,
          kind_of_mentoring_name: mentors.kind_of_mentoring_name,
          preferred_track_of_mentorship: mentors.preferred_track_of_mentorship,
          track_of_mentorship_names: mentors.track_of_mentorship_names,
          ecosystem_key: mentors.ecosystem_key,
          ecosystem_key_names: mentors.ecosystem_key_names,
          preference_across: mentors.preference_across,
          preference_across_name: mentors.preference_across_name,
          mode_of_engagement: mentors.mode_of_engagement,
          mode_of_engagement_name: mentors.mode_of_engagement_name,
          willing_no_of_mentees: mentors.willing_no_of_mentees,
          willing_no_of_mentees_name: mentors.willing_no_of_mentees_name,
          no_of_hours_per_month: mentors.no_of_hours_per_month,
          no_of_hours_per_month_name: mentors.no_of_hours_per_month_name,
          linkedin_profile_url: mentors.linkedin_profile_url,
          approve_status: mentors.approve_status,
          remark: mentors.remark,
          profileimage: mentors.profileimage,
          document: mentors.document,
          primary_industry_others: mentors.primary_industry_others,
          spoken_lang_others: mentors.spoken_lang_others,
        };
        this.loadHide();
      });
    this.modalReference = this.modalService.open(mentorinfo_ind);
  }

  onChangeStatus(): void {
    if (this.mentorInfo.approve_status !== "REJECTED") {
      this.mentorInfo.remark = "";
    }
  }

  onCheckboxChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isChecked = input.checked;
    this.verifyValue = this.isChecked ? "Y" : "N";
  }

  mentorStatusUpdate(item: any, mentorinfo_ind) {
    this.isSubmitted = true;

    if (
      this.mentorInfo.approve_status === "REJECTED" &&
      (!this.mentorInfo.remark || this.mentorInfo.remark.trim() === "")
    ) {
      return;
    }

    // if (
    //   this.mentorInfo.approve_status === "REJECTED" &&
    //   !this.mentorInfo.remark
    // ) {
    //   return;
    // }

    this.loadShow();
    var data = {
      mentor_id: this.mentorInfo.id,
      status: this.mentorInfo.approve_status,
      remarks: this.mentorInfo.remark ? this.mentorInfo.remark : "",
      mentor_verify: this.verifyValue,
    };

    this.dataService
      .updateMentorStatus(data)
      .pipe()
      .subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.alertService.success(res["mesg"], true);
            const table = $("#mentortable").DataTable();
            table.ajax.reload(null, false);
            this.mentorList = this.mentorList.map((mentor) =>
              mentor.id === data.mentor_id
                ? {
                    ...mentor,
                    approve_status: data.status,
                    remark: data.remarks,
                  }
                : mentor
            );
          } else if (res["status"] == 422) {
            this.alertService.error(res["mesg"], true);
          }
          this.loadHide();
          this.modalService.dismissAll(mentorinfo_ind);
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  getFilteredSpokenLanguages(mentorInfo: any): string {
    let spokenLangs = mentorInfo.spoken_lang_names;

    if (typeof spokenLangs === "string") {
      spokenLangs = spokenLangs.split(",").map((lang) => lang.trim());
    }

    if (Array.isArray(spokenLangs)) {
      return spokenLangs.filter((lang: string) => lang !== "Others").join(", ");
    }
    return "";
  }

  checkRemarks() {
    if (this.mentorInfo.remark) {
      this.mentorInfo.remark = this.mentorInfo.remark.trim();
    }
  }

  containsOthers(spokenLangNames: any) {
    // Check if 'Others' is included in the spoken_lang_names string
    return spokenLangNames.includes("Others");
  }

  // delete(id) {
  //   //console.log(id,"id");
  //   if (confirm("Are you sure to delete")) {
  //     this.dataService.deleteMentor(id)
  //       .pipe().subscribe(data => {
  //         if (data['status'] == 200) {
  //           // this.alertService.success('Event deleted successfully', true);
  //           const table = $('#mentortable').DataTable();
  //           table.ajax.reload(null, false);
  //         } else {
  //           // this.alertService.error('Event error');
  //         }
  //       }, error => {
  //         this.alertService.error(error);
  //       });
  //     }
  //   }

  delete(mentor) {
    //console.log(mentor, "mentorr");
    this.loadShow();
    this.dataService
      .deleteMentor(mentor.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Mentor deleted successfully", true);
            mentor.status = mentor.status === "Y" ? "N" : "Y";
            const table = $("#mentortable").DataTable();
            table.ajax.reload(null, false);
          } else {
            this.alertService.error("Mentor error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  setFilterData(e: any) {
    this.filterData.status = e.target.value;
    localStorage.setItem("mentor_approve_status", this.filterData.status);
    const table = $("#mentortable").DataTable();
    table.ajax.reload(null, false);
  }

  copyToClipboard(link: string) {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        this.isCopied = true;

        setTimeout(() => (this.isCopied = false), 2000); // Reset after 2 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  // copyToClipboard(link:any): void {
  //   const tempInput = document.createElement('input');
  //   tempInput.value = link;
  //   document.body.appendChild(tempInput);
  //   tempInput.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(tempInput);
  //   //console.log('Link copied to clipboard!');
  // }
}
