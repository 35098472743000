import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, StateService, AppService } from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class State {
  id: number;
  state_name: string;
  country_id: number;
  is_active: string;
}

@Component({
  selector: "app-state",
  templateUrl: "./state.component.html",
})
export class StateComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  cdtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable
  stateList: State[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getState = {
    id: "",
    state_name: "",
    country_id: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  cntry = {};
  constructor(
    public router: Router,
    private dataService: StateService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  state = {
    id: "",
    state_name: "",
    country_id: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.allState();
    this.dataService
      .getAllCountry()
      .pipe()
      .subscribe((data) => {
        this.cntry = data["country"];
      });
  }

  allState() {
    this.loadShow();
    this.dataService
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.stateList = data["state"];
        this.rerender();
        this.loadHide();
        //console.log(this.stateList, "statelist");
      });
  }

  open(content) {
    this.isSubmitted = false;
    if (this.getState.id != null) {
      this.getState.id = "";
    }
    (this.state.state_name = ""),
      (this.state.country_id = ""),
      (this.state.is_active = "Y");
    this.modalTitle = "Add State";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getState(id)
      .pipe()
      .subscribe((data) => {
        this.getState = data["state"];
        this.state = {
          id: this.getState.id,
          state_name: this.getState.state_name,
          country_id: this.getState.country_id,
          is_active: this.getState.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit State";
        this.modalReference = this.modalService.open(content);
      });
  }

  save(val, f) {
    this.isSubmitted = true;

    if (this.isFieldsInvalid(this.state.state_name)) {
      return;
    }

    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.state)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("State saved successfully", true);
              this.allState();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .stateUpdate(this.state)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("State updated successfully", true);
              this.allState();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .stateDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("State deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allState();
          } else {
            this.alertService.error("State error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
