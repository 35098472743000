import { Component, OnInit } from '@angular/core';
import { AppService, AuthenticationService} from '../_services/index';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})

export class FullLayoutComponent implements OnInit {
  userData = {
    profileName:'',
    image:''
  }

  showHeaderFooter: boolean = true;
  getToken:any;
  showDNClass: boolean = false;
  setting = {image:''};
  constructor(public authenticationService: AuthenticationService,private dataService:AppService,
    private route: ActivatedRoute, private router: Router) { }
  nav:any;

  public disabled = false;
  public status: {isopen: boolean} = {isopen: false};

  public toggled(open: boolean): void {
    // //console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  ngOnInit(): void {
    
    setTimeout(() => {
        this.showDNClass = true;
    },20);

    this.getToken = window.localStorage.getItem('currentUser');
      this.dataService.checkLogin(this.getToken)
          .pipe().subscribe(data => {
                if(data['user']){
                  this.userData = {
                      profileName:data['user'].name,
                      image:data['user'].image,
                    }

                  this.dataService.getMenu()
                    .pipe().subscribe(data => { 
                      this.nav = data['data'];                      
                      this.setData()                                         
                    });
                   }
                 });

                 this.router.events.subscribe(() => {
                  this.checkRoute();
                });
                this.checkRoute();
            
            let settingData = JSON.parse(localStorage.getItem('setting'));
            this.setting = {image:settingData.image};
           }

           setData() {
            const dataArray = this.nav
            this.dataService.setDataArray(dataArray);
          }

  public logout(): void{
    this.authenticationService.logout();
  }


  checkRoute() {
    const currentUrl = this.router.url;
    const urlTree = this.router.parseUrl(currentUrl);
    const queryParams = urlTree.queryParams;
    if (currentUrl.startsWith('/mentor-verification') && queryParams['q1']) {
      this.showHeaderFooter = false;
    } else {
      this.showHeaderFooter = true;
    }
  }


  // checkRoute(routeName) {
  //   const foundRoute = this.nav.find(route => route.route === routeName);
  //   if (foundRoute) {
  //       //console.log("founddddd");
  //       window.location.href = foundRoute.route;
  //   } 
  //   else {
  //       alert(`Route '${routeName}' not found!`);
  //       //console.log("not  founddddd");
  //   }
  // }

  
  
}