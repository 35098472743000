import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class WepExpectationsService {

    constructor(private base: BaseService) { }

    save(data){
      return this.base.post('/api/add-wep-expectation', data);
    }
  
    WepExpectationEdit(id){
      return this.base.post('/api/edit-wep-expectation',{id:id});
    }
  
    WepExpectationUpdate(data){
      return this.base.post('/api/update-wep-expectation',data);
    }
  
    WepExpectationDelete(id){
      return this.base.post('/api/delete-wep-expectation',{id:id});
    }
  
    getAllWepExpectation(){
      return  this.base.get('/api/get-all-wep-expectation');
    }
}
