<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12" style="margin: auto;">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title">About WEP</h5>
						<!-- <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="form-group row">
								<label for="content" class="col-5 col-form-label">Description <span>*</span></label>
								<div class="col-12">
									<angular-editor [config]="configcontent"  id='content' [(ngModel)]="about.content" name="content"></angular-editor>
									<span *ngIf="isSubmitted && (!about.content || about.content.length === 0)" class="alert-block">
										This field is required
									  </span>
								</div>
							</div>						
							<button type="submit" class="btn btn-primary savebtn rounded" [disabled]="!f.form.valid" (click) ="Update(f)">Update</button>
						</form> -->

						<form [formGroup]="aboutForm" >
							<div class="form-group row">
								<label for="content" class="col-5 col-form-label">Description <span>*</span></label>
								<div class="col-12">
									<angular-editor [config]="configcontent" id="content" formControlName="content" name="content"></angular-editor>
									<div *ngIf="content.invalid && (content.dirty || content.touched)" class="text-danger">
										<div *ngIf="content.errors?.required">Description is required.</div>
									</div>
								</div>
							</div>
							<button type="submit" class="btn btn-primary savebtn rounded"  (click)="Update()">Update</button>
						</form>
						

					</div>
				</div>
			</div>
			<!--/.col-->
		</div>
	</div>
</div>