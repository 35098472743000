import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationService {

  constructor(private base: BaseService) { }

  getEmailNotifications(){
    return this.base.get('/api/get-all-email-notification');
  }

  save(data){
  	return this.base.post('/api/create-email-notification', data);
  }

  editEmailNotification(id){
    return this.base.post('/api/edit-email-notification',{id:id});
  }

  emailNotificationUpdate(data){
    return this.base.post('/api/update-email-notification',data);
  }

  emailNotificationDelete(id){
    return this.base.post('/api/delete-email-notification',{id:id});
  }
}