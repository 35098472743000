import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { map } from "rxjs/operators";

@Injectable()
export class RoleService {
  constructor(private base: BaseService) {}

  getToken = window.localStorage.getItem("token");

  save(data) {
    return this.base.post("/api/role-create", data);
  }

  getRole(id) {
    return this.base.post("/api/role-edit", { id: id });
  }

  roleUpdate(data) {
    return this.base.post("/api/role-update", data);
  }

  roleDelete(id) {
    return this.base.post("/api/role-delete", { id: id });
  }

  getAllRole() {
    return this.base.get("/api/get-role");
  }

  getRolesMenu(data) {
    return this.base.post("/api/user-assing-role-menu", data);
  }
}
