import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SchemesService {
  constructor(private base: BaseService) {}

  save(data: any) {
    return this.base.post('/api/admin/blog/blog-create-v2', data);
  }

  sendDataTablesRequest(data: any) {
    return this.base.post('/api/admin/blog/get-blog', data);
  }

  blogUpdate(data: any) {
    return this.base.post('/api/admin/blog/blog-update-v2', data);
  }

  blogDelete(id: any) {
    return this.base.post('/api/admin/blog/blog-delete', { id: id });
  }

  getAllBlog() {
    return this.base.get('/api/admin/blog/get-active-blog');
  }

  getBlog(id: any) {
    return this.base.post('/api/admin/blog/blog-edit', { id: id });
  }

  getAllState() {
    return this.base.get('/api/admin/state/all-indian-state');
  }

  getAllCity() {
    return this.base.get('/api/admin/city/all-city');
  }

  getCityById(id: any) {
    return this.base.post('/api/admin/city/all-city-by-id', { id: id });
  }

  getSectorofEnterprise() {
    return this.base.get(
      '/api/admin/sectorofenterprise/get-active-sectorofenterprise'
    );
  }

  getAreaofInterest() {
    return this.base.get('/api/admin/areaofinterest/get-active-areaofinterest');
  }

  getAreaofStudy() {
    return this.base.get('/api/admin/areaofstudy/get-all-active-area-of-study');
  }

  getCategory() {
    return this.base.get('/api/admin/blogcategory/all-blogcategory');
  }

  getBlogType() {
    return this.base.get('/api/admin/blog/blog-types');
  }

  getSubCategory(id: any) {
    return this.base.post('/api/admin/blogsubcategory/all-blogsubcategory', {
      id: id,
    });
  }
}
