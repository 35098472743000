import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService, AppService, PastEventService } from '../_services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OtpLogsService } from '../_services/otp-logs.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../_services/encryption.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-otp-logs',
  templateUrl: './otp-logs.component.html',
  styleUrls: []
})
export class OtpLogsComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  showloding = true;
  dtTrigger = new Subject();
  optLogsList: any[] = [];
  lodingImage = false;
  dtOptions: DataTables.Settings = {};
  totalOtpList :any
  constructor(
    public router: Router,
              private dataService:OtpLogsService,
              private alertService: AlertService,
              private AppService: AppService,
              private toastr: ToastrService,
              private http: HttpClient,
              private enc_dec: EncryptionService,
  ) { }

  ngOnInit() {
    this.getAllOtpLogs()
  }



   getAllOtpLogs(){
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 10,
        serverSide: true,
        processing: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .post<DataTablesResponse>(
              "/api/otp-log",
              {
                req_data: this.enc_dec.encrypt(
                  JSON.stringify(Object.assign(dataTablesParameters))
                ),
              },
              {}
            )
            .subscribe((response: any) => {
              const output = this.enc_dec.decrypt(response.res_data);
              var resp = JSON.parse(output);
              //console.log(resp, "otplogs");
  
              this.optLogsList = resp["data"];
              this.totalOtpList = resp["recordsTotal"];
              callback({
                recordsTotal: resp["recordsTotal"],
                recordsFiltered: resp["recordsFiltered"],
                data:  this.optLogsList,
              });
            });
        },
        columns: [
          { data: "id" },
          { data: "name" },
          { data: "email" },
          { data: "phone" },
          { data: "created_at" },
         
        ],
      };
    
   }

   rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }  

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }


  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

}
