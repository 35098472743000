import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchasesDuePaymentService {

  constructor(private base: BaseService) { }

  getInvoiceDetails(val){
    return this.base.post('/api/get-purchases-invoice-details',{id:val});
  }
   
  paymentUpdate(data){
    return this.base.post('/api/purchases-payment-update',data);
  }

}
