import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AreaOfInterestService } from 'src/app/_ciiyi_services/area-of-interest.service';
import { AlertService } from 'src/app/_services';


class AreaOfInterest {
  id: number;
  name: string;
  description: string;
  is_active: string;
}
@Component({
  selector: 'app-cii-area-of-interest',
  templateUrl: './cii-area-of-interest.component.html',
  styleUrls: ['./cii-area-of-interest.component.sass']
})

export class CiiAreaOfInterestComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  loading: boolean = false;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  areaofinterestList: AreaOfInterest[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getAreaOfInterest = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };

  modalReference: NgbActiveModal;
  modalTitle: string;
  constructor(
    public router: Router,
    private dataService: AreaOfInterestService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) {}
  areaofinterest = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allAreaofinterest();
  }

  allAreaofinterest() {
    this.loadShow();
    this.dataService
      .getAllAreaofinterest()
      .pipe()
      .subscribe((data) => {
        this.areaofinterestList = data["areaofinterest"];
        this.rerender();
        this.loadHide();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  open(content) {
    if (this.getAreaOfInterest.id != null) {
      this.getAreaOfInterest.id = "";
    }
    this.isSubmitted = false;
    (this.areaofinterest.name = ""),
      (this.areaofinterest.description = ""),
      (this.areaofinterest.is_active = "Y");
    this.modalTitle = "Add area of interest";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getAreaOfInterest(id)
      .pipe()
      .subscribe((data) => {
        this.getAreaOfInterest = data["areaofinterest"];
        this.areaofinterest = {
          id: this.getAreaOfInterest.id,
          name: this.getAreaOfInterest.name,
          description: this.getAreaOfInterest.description,
          is_active: this.getAreaOfInterest.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit area of interest";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.areaofinterest.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.areaofinterest)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Area of interest saved successfully",
                  true
                );
                this.allAreaofinterest();
              } else if (data["status"] == 422) {
                this.alertService.error(data.mesg.name);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
                this.loadHide();
              } else {
                this.alertService.error(data.message);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
                this.loadHide();
              }
            },
            (error) => {}
          );
      } else {
        this.dataService
          .areaofinterestUpdate(this.areaofinterest)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Area of interest updated successfully",
                  true
                );
                this.allAreaofinterest();
              } else if (data["status"] == 300) {
                this.loadHide();
                this.alertService.error(data.mesg);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
              }
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.areaofinterestDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Area of interest deleted successfully', true);
  //             this.allAreaofinterest();
  //           }else{
  //             this.alertService.error('Area of interest error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //       }
  //     }

  delete(item) {
    this.loadShow();
    this.dataService
      .areaofinterestDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Area of interest deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allAreaofinterest();
          } else {
            this.alertService.error("Area of interest error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
