import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AlertService, EventService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";
import { ToastrService } from "ngx-toastr";
import { DynamicGrid } from "src/app/_services/grid.modal";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { DataTableDirective } from "angular-datatables";

class Event {
  id: number;
  name: string;
  about: string;
  from_date: string;
  to_date: string;
  event_type: string;
  event_address: string;
  event_state: number;
  event_city: number;
  sectorofenterprise: string;
  areaofinterest: string;
  areaofstudy: string;
  event_category: string;
  event_vertical: string;
  oraganised_by: string;
  event_language: string;
  who_is_relevant_for: string;
  associate_partners: string;
  registration_details_link: string;
  keywords: string;
  eventinput: Array<DynamicGrid> = [];
  home_display: string;
  is_active: string;
}

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
})
export class EventComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [8], orderable: false }],
  };
  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg, .jpeg, .png, .bmp";
  // @Input() fileType: string = ".jpg, .jpeg, .png, .bmp, .tiff, .tif, .gif, .svg, .webp, .ico, .psd, .heic, .heif, .raw, .jp2, .cr2, .nef, .arw, .dng, .dds, .pcx, .tga, .hdr, .exr, .avif";
  @Input() fileTypeImage: string = ".jpg, .jpeg, .png, .bmp";
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList: any;
  isSubmitted = false;

  @Input() allowMultipleImage: boolean = true;
  @Input() allowMultipleVideo: boolean = true;
  // @Input() fileTypeImage: string = ".jpg , .png , .bmp";
  @Input() fileTypeVideo: string = ".mp4 , .ogg, .webm";

  fileListImage: any;
  fileListVideo: any;
  pdf = false;
  exl = false;
  dtTrigger = new Subject(); //  DataTable
  eventList: Event[] = []; // Table Data list
  eventMediaList = [];
  eventMediaListImage = [];
  eventMediaListVideo = [];
  event_id: any;
  apiUrl: any;
  error: any = { isError: false, errorMessage: "" };

  getEvent = {
    id: "",
    name: "",
    about: "",
    from_date: "",
    to_date: "",
    image: "",
    event_type: "",
    event_address: "",
    event_state: "",
    event_city: "",
    sectorofenterprise: "",
    areaofinterest: "",
    areaofstudy: "",
    event_category: "",
    event_vertical: "",
    oraganised_by: "",
    event_language: "",
    who_is_relevant_for: "",
    associate_partners: "",
    registration_details_link: "",
    keywords: "",
    eventinput: [],
    home_display: "",
    is_active: "",
  };

  closeResult: string; // Modal Close
  dynamicArray: Array<DynamicGrid> = [];
  newDynamic: any = {};

  selectedToEnterprise: any;
  myStringEnterprise: any;
  myArrayEnterprise: any;

  selectedToInterest: any;
  myStringInterest: any;
  myArrayInterest: any;

  selectedToStudy: any;
  myStringStudy: any;
  myArrayStudy: any;

  selectedToPartners: any;
  myStringPartners: any;
  myArrayPartners: any;

  imageChangedEvent: any = "";
  croppedImage: any = "";
  image_cropper: "";

  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: EventService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  event = {
    id: "",
    name: "",
    about: "",
    from_date: "",
    to_date: "",
    image: "",
    event_type: "",
    event_address: "",
    event_state: "",
    event_city: "",
    sectorofenterprise: "",
    areaofinterest: "",
    areaofstudy: "",
    event_category: "",
    event_vertical: "",
    oraganised_by: "",
    event_language: "",
    who_is_relevant_for: "",
    associate_partners: "",
    registration_details_link: "",
    keywords: "",
    eventinput: [],
    home_display: "",
    is_active: "",
  };

  modalReference: NgbActiveModal;
  modalCropper: NgbActiveModal;
  modalTitle: string;
  cat = {};
  states: any[] = [];
  cities = {};
  sectorofenterprise: any[] = [];
  areaofinterest = {};
  areaofstudy = {};
  associatepartners = {};
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.apiUrl = appConfig.apiUrl + "/";
    this.allEvent();
    this.newDynamic = { event_type: "", event_label: "", is_mandatory: "" };
    // this.dynamicArray.push(this.newDynamic);

    this.dataService.getAllState().subscribe((data) => {
      //console.log(data); // Log data to see its structure
      this.states = Array.isArray(data["state"]) ? data["state"] : [];
    });
    this.dataService
      .getAllCity()
      .pipe()
      .subscribe((data) => {
        this.cities = data["state"];
      });
    this.dataService
      .getEventCategory()
      .pipe()
      .subscribe((data) => {
        this.cat = data["cat"];
      });
    this.dataService
      .getSectorofEnterprise()
      .pipe()
      .subscribe((data) => {
        this.sectorofenterprise = data["sectorofenterprise"];
        //console.log(this.sectorofenterprise, "sector");

        if (Array.isArray(this.sectorofenterprise)) {
          this.sectorofenterprise.unshift({ id: "all", name: "Select All" });
        }
      });
    this.dataService
      .getAreaofInterest()
      .pipe()
      .subscribe((data) => {
        this.areaofinterest = data["areaofinterest"];

        if (Array.isArray(this.areaofinterest)) {
          this.areaofinterest.unshift({ id: "all", name: "Select All" });
        }
      });
    this.dataService
      .getAreaofStudy()
      .pipe()
      .subscribe((data) => {
        this.areaofstudy = data["area_of_study"];

        if (Array.isArray(this.areaofstudy)) {
          this.areaofstudy.unshift({ id: "all", name: "Select All" });
        }
      });
    this.dataService
      .getAssociatePartners()
      .pipe()
      .subscribe((data) => {
        this.associatepartners = data["partner"];
      });
  }

  getAllMedia() {
    var data = { event_id: this.event_id };
    this.dataService
      .getEventMediaById(data)
      .pipe()
      .subscribe((data) => {
        this.eventMediaList = data["eventGallery"];
        for (let i = 0; i < this.eventMediaList.length; i++) {
          if (this.eventMediaList[i].type == "image") {
            this.eventMediaListImage.push(this.eventMediaList[i]);
          } else if (this.eventMediaList[i].type == "video") {
            this.eventMediaListVideo.push(this.eventMediaList[i]);
          }
        }
      });
  }

  removeEventMedia(id: any) {
    var data = { id: id };
    if (confirm("Are you sure to delete")) {
      this.dataService
        .eventMediaDelete(data)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              this.alertService.success(
                "Event media deleted successfully",
                true
              );
              this.eventMediaListImage = [];
              this.eventMediaListVideo = [];
              this.getAllMedia();
            } else {
              this.alertService.error("Event error");
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }

  compareTwoDates() {
    //console.log("This date");
    if (new Date(this.getEvent.from_date) < new Date(this.getEvent.to_date)) {
      this.error = {
        isError: true,
        errorMessage: "End Date can not before start date",
      };
    }
  }

  allEvent() {
    this.loadShow();
    var data = { type: "UP" };
    this.dataService
      .getAllEvent(data)
      .pipe()
      .subscribe((data) => {
        this.eventList = data["event"];
        this.pdf = true;
        this.exl = true;
        this.rerender();
        this.loadHide();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  changeToEnterprise() {
    this.event.sectorofenterprise = this.selectedToEnterprise;
    if (this.selectedToEnterprise.includes("all")) {
      this.selectedToEnterprise = (
        this.sectorofenterprise as { id: string; name: string }[]
      )
        .filter((sector) => sector.id !== "all")
        .map((sector) => sector.id);
    }
    this.event.sectorofenterprise = this.selectedToEnterprise;
  }

  changeToInterest() {
    if (this.selectedToInterest.includes("all")) {
      this.selectedToInterest = (
        this.areaofinterest as { id: string; name: string }[]
      )
        .filter((sector) => sector.id !== "all")
        .map((sector) => sector.id);
    }
    this.event.areaofinterest = this.selectedToInterest;
  }

  changeToStudy() {
    if (this.selectedToStudy.includes("all")) {
      this.selectedToStudy = (
        this.areaofstudy as { id: string; name: string }[]
      )
        .filter((sector) => sector.id !== "all")
        .map((sector) => sector.id);
    }
    this.event.areaofstudy = this.selectedToStudy;
  }

  changeToPartners() {
    this.event.associate_partners = this.selectedToPartners;
  }

  selectCityById(id) {
    this.dataService
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  open(content) {
    // this.dynamicArray.forEach((item) => {
    //   item.event_type = "";
    //   item.event_label = "";
    //   item.is_mandatory = "";
    // });

    this.croppedImage = "";
    this.selectedToPartners = "";
    this.selectedToStudy = "";
    this.selectedToInterest = "";
    this.selectedToEnterprise = "";
    this.fileList = "";
    this.isSubmitted = false;
    this.event.id = "";
    this.event.name = "";
    this.event.about = "";
    this.event.from_date = "";
    this.event.to_date = "";
    this.event.image = "";
    this.event.event_type = "";
    this.event.event_address = "";
    this.event.event_state = "";
    this.event.event_city = "";
    this.event.sectorofenterprise = "";
    this.event.areaofinterest = "";
    this.event.registration_details_link = "";
    this.event.keywords = "";
    this.event.areaofstudy = "";
    this.event.event_category = "";
    this.event.event_vertical = "";
    this.event.oraganised_by = "";
    this.event.event_language = "";
    this.event.eventinput = [];
    this.event.home_display = "N";
    this.event.is_active = "Y";

    this.modalTitle = "Add Event";
    this.modalReference = this.modalService.open(content);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  save(val, f) {
    this.isSubmitted = true;

    if (this.isFieldsInvalid(this.event.name)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.croppedImage = "";
    this.isSubmitted = true;
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.getEvent(id).subscribe((data) => {
      this.getEvent = data["event"];
      // this.dynamicArray = this.getEvent.eventinput;

      if (this.getEvent.event_state != null) {
        this.selectCityById(this.getEvent.event_state);
      }
      this.myStringEnterprise = this.getEvent.sectorofenterprise;
      if (this.myStringEnterprise) {
        this.myArrayEnterprise = this.myStringEnterprise.split(",").map(Number);
        this.selectedToEnterprise = this.myArrayEnterprise;
      }

      this.myStringInterest = this.getEvent.areaofinterest;
      if (this.myStringInterest) {
        this.myArrayInterest = this.myStringInterest.split(",").map(Number);
        this.selectedToInterest = this.myArrayInterest;
      }

      this.myStringStudy = this.getEvent.areaofstudy;
      if (this.myStringStudy) {
        this.myArrayStudy = this.myStringStudy.split(",").map(Number);
        this.selectedToStudy = this.myArrayStudy;
      }

      this.myStringPartners = this.getEvent.associate_partners;
      if (this.myStringPartners) {
        this.myArrayPartners = this.myStringPartners.split(",").map(Number);
        this.selectedToPartners = this.myArrayPartners;
      }

      this.croppedImage = this.getEvent.image || "";

      this.event = {
        id: this.getEvent.id,
        name: this.getEvent.name,
        about: this.getEvent.about,
        from_date: this.getEvent.from_date,
        to_date: this.getEvent.to_date,
        image: this.getEvent.image,
        // image:appConfig.apiUrl+'/'+this.getEvent.image,
        // image: localStorage.getItem("event_image"),
        event_type: this.getEvent.event_type,
        event_address: this.getEvent.event_address,
        event_state: this.getEvent.event_state,
        event_city: this.getEvent.event_city,
        sectorofenterprise: this.getEvent.sectorofenterprise,
        areaofinterest: this.getEvent.areaofinterest,
        areaofstudy: this.getEvent.areaofstudy,
        event_category: this.getEvent.event_category,
        event_vertical: this.getEvent.event_vertical,
        oraganised_by: this.getEvent.oraganised_by,
        event_language: this.getEvent.event_language,
        who_is_relevant_for: this.getEvent.who_is_relevant_for,
        associate_partners: this.getEvent.associate_partners,
        registration_details_link: this.getEvent.registration_details_link,
        keywords: this.getEvent.keywords,
        eventinput: this.getEvent.eventinput,
        home_display: this.getEvent.home_display,
        is_active: this.getEvent.is_active,
      };
      this.loadHide();
      this.isSubmitted = false;
      this.modalTitle = "Edit Event";
      this.modalReference = this.modalService.open(content);
    });
  }

  upload(id, media) {
    this.event_id = id;
    this.modalTitle = "Upload Media";
    this.modalReference = this.modalService.open(media);
    this.getAllMedia();
  }

  insertAction(val) {
    let formData: FormData = new FormData();

    formData.append("name", this.event.name);
    formData.append("about", this.event.about);
    formData.append("from_date", this.event.from_date);
    formData.append("to_date", this.event.to_date);
    formData.append("event_type", this.event.event_type);
    formData.append("event_address", this.event.event_address);
    formData.append("event_state", this.event.event_state);
    formData.append("event_city", this.event.event_city);

    if (this.selectedToEnterprise) {
      formData.append(
        "sectorofenterprise",
        this.selectedToEnterprise.join(",")
      );
    } else {
      formData.append("sectorofenterprise", "");
    }

    if (this.selectedToInterest) {
      formData.append("areaofinterest", this.selectedToInterest.join(","));
    } else {
      formData.append("areaofinterest", "");
    }

    if (this.selectedToStudy) {
      formData.append("areaofstudy", this.selectedToStudy.join(","));
    } else {
      formData.append("areaofstudy", "");
    }

    if (this.selectedToPartners) {
      formData.append("associate_partners", this.selectedToPartners.join(","));
    } else {
      formData.append("associate_partners", "");
    }

    // formData.append('event_category', this.event.event_category);
    formData.append("event_vertical", this.event.event_vertical);
    formData.append("oraganised_by", this.event.oraganised_by);
    formData.append("event_language", this.event.event_language);
    formData.append("who_is_relevant_for", this.event.who_is_relevant_for);
    formData.append(
      "registration_details_link",
      this.event.registration_details_link
    );
    formData.append("keywords", this.event.keywords);
    // formData.append("event_inputs", JSON.stringify(this.dynamicArray));
    formData.append("home_display", this.event.home_display);
    formData.append("is_active", this.event.is_active);
    //console.log(this.event, "tesMULTI-CHECKBOX");

    if (val.id == "") {
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              (this.croppedImage = ""), //  DataTable
                this.allEvent();

              this.alertService.success("Event created successfully", true);
              this.modalReference.close();
            } else if (data["status"] == 422) {
              this.alertService.error(data.mesg);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      // if (this.fileList !== undefined) {
      //   formData.append("image", this.croppedImage);
      // }

      if (this.fileList && this.fileList.length > 0) {
        formData.append("image", this.croppedImage);
      } else {
        formData.append("image", "");
      }
      this.dataService
        .eventUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              this.modalReference.close();
              this.allEvent();
              this.alertService.success("Event updated successfully", true);
            } else if (data["status"] == 422) {
              this.alertService.error(data.mesg);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  // delete(id) {
  //   if (confirm("Are you sure to delete")) {
  //     this.dataService.eventDelete(id)
  //       .pipe().subscribe(data => {
  //         if (data['status'] == 200) {
  //           this.alertService.success('Event deleted successfully', true);
  //           this.allEvent();
  //         } else {
  //           this.alertService.error('Event error');
  //         }
  //       }, error => {
  //         this.alertService.error(error);
  //       });
  //   }
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .eventDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Event deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allEvent();
          } else {
            this.alertService.error("Event error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  fileChange(event: any) {
    this.fileList = event;
    
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = this.fileType;
      //alert(hasFile)

      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList);
    }
  }

  addRow() {
    this.newDynamic = { event_type: "", event_label: "", is_mandatory: "" };
    this.dynamicArray.push(this.newDynamic);
    this.toastr.success("New row added successfully", "New Row");
    //console.log(this.dynamicArray);
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      this.toastr.error(
        "Can't delete the row when there is only one row",
        "Warning"
      );
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      this.toastr.warning("Row deleted successfully", "Delete row");
      return true;
    }
  }

  pdfExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-pdf", "_blank");
  }

  xlExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-excel", "_blank");
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.event.image = file;
      this.croppedImage = "";
    }
    this.imageChangedEvent = event;
    //console.log(file, "files");
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    this.DisplayedText = this.fileList[0].name

    let hasFile = this.fileList && this.fileList.length > 0;

    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = ".jpg , .jpeg, .png , .bmp";

      // Validate file type
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "Please select a valid file type. Supported file types are .jpg, .jpeg, .png, .bmp"
        );
        this.fileList = null;
        return false;
      }

      // Validate file size
      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          // Check dimensions
          if (img.height > img.width) {
            alert(
              "Please select a banner image (width should be greater than height)."
            );
            this.fileList = null;
            this.DisplayedText = ""
            this.croppedImage = ""; // Reset the image preview
            event.target.value = ""; // Reset the file input
            return;
          } else {
            this.croppedImage = reader.result as string;
            this.event.image = this.croppedImage;
          }
        };
      };
    }
    return false;
  }

  // onFileChange(event: any) {
  //   this.fileList = event.target.files;
  //   // let filetypeToCompare = this.fileType.replace('*','');
  //   let hasFile = this.fileList && this.fileList.length > 0;
  //   if (hasFile) {
  //     var extension = this.fileList[0].name.substring(
  //       this.fileList[0].name.lastIndexOf(".")
  //     );
  //     // Only process image files.
  //     // var validFileType = ".jpg , .jpeg, .png , .bmp";
  //     var validFileType = this.fileType;
  //     if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
  //       alert(
  //         "please select valid file type. The supported file types are " +
  //           this.fileType
  //       );
  //       this.fileList = null;
  //       this.croppedImage = "";
  //       return false;
  //     }

  //     const reader = new FileReader();
  //     reader.readAsDataURL(this.fileList[0]);
  //     reader.onload = () => {
  //       this.croppedImage = reader.result as string;
  //       //this.memberData.image = reader.result as string;

  //       this.event.image = this.croppedImage;
  //     };

  //     if (this.fileList[0].size > 5000000) {
  //       alert(`File size is more than 5 MB`);
  //       this.fileList = null;

  //       return false;
  //     }

  //     let multipleFile = this.fileList.length > 1;
  //     if (multipleFile) {
  //       //this.DisplayedText = this.fileList.length + ' file(s) has been selected';
  //     } else {
  //       let file: File = this.fileList[0];
  //       //this.DisplayedText = file.name;
  //     }
  //     //this.onSelection.emit(this.fileList);
  //   }
  //   return false;
  //   /*const reader = new FileReader();

  //   if (event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {

  //       this.imageSrc = reader.result as string;
  //       this.blogCategory.controls['image'].setValue(this.imageSrc);

  //       this.blogCategory.patchValue({
  //         fileSource: reader.result as string
  //       });

  //     };

  //   }*/
  // }

  imageCropped(event: ImageCroppedEvent) {
    // this.event.image = '';
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  exit(event: any) {
    this.fileList = event.exitTransform;
  }

  openCropper(contentCrop) {
    this.modalCropper = this.modalService.open(contentCrop);
  }

  closeCropper() {
    this.modalCropper.close();
  }

  uploadmedia() {
    let formData: FormData = new FormData();
    formData.append("event_id", this.event_id);
    if (this.fileListImage != undefined) {
      for (let i = 0; i < this.fileListImage.length; i++) {
        let file: File = this.fileListImage[i];
        formData.append("event_images[" + i + "]", file, file.name);
      }
    }

    if (this.fileListVideo != undefined) {
      for (let i = 0; i < this.fileListVideo.length; i++) {
        let file: File = this.fileListVideo[i];
        formData.append("event_videos[" + i + "]", file, file.name);
      }
    }

    this.dataService
      .eventMediaUpload(formData)
      .pipe()
      .subscribe(
        (data) => {
          this.modalReference.close();
          this.allEvent();
          this.alertService.success("Event media uploaded successfully", true);
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  fileChangeImage(event: any) {
    this.fileListImage = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileListImage && this.fileListImage.length > 0;
    if (hasFile) {
      var extension = this.fileListImage[0].name.substring(
        this.fileListImage[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileListImage = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileListImage[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileListImage = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileListImage.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileListImage.length + " file(s) has been selected";
      } else {
        let file: File = this.fileListImage[0];
        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileListImage);
    }
  }

  fileChangeVideo(event: any) {
    this.fileListVideo = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileListVideo && this.fileListVideo.length > 0;
    if (hasFile) {
      var extension = this.fileListVideo[0].name.substring(
        this.fileListVideo[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = this.fileTypeVideo;
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileTypeVideo
        );
        this.fileListVideo = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileListVideo[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileListVideo = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileListVideo.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileListVideo.length + " file(s) has been selected";
      } else {
        let file: File = this.fileListVideo[0];
        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileListVideo);
    }
  }
}
