import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EncryptionService } from 'src/app/_services/encryption.service';

class DataTablesResponse {
  data: any;
  draw: any;
  recordsFiltered: any;
  recordsTotal: any;
}


class Reg_Workshops {
  id: number;
  name: string;
  email: string;
  query: string;
  created_by: string;
  created_at: string;
}

@Component({
  selector: 'app-cii-request-workshops',
  templateUrl: './cii-request-workshops.component.html',
  styleUrls: ['./cii-request-workshops.component.sass']
})

export class CiiRequestWorkshopsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };

  dtTrigger = new Subject();
  reqWorkshopList:Reg_Workshops[] = []; 
  showloding = true;
  lodingImage = false;
  totalWorkshops: any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {}
 
  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadHide()
    this.getAllFaqs();
  }

  getAllFaqs() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/admin/workshop-request/workshop-request-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "newsFeeds");

            this.reqWorkshopList = resp["data"];
            this.totalWorkshops = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
        },

        
      columns: [
        { data: "id" },
        { data: "full_name" },
        { data: "email" },
        { data: "mobile_no" },
        { data: "workshop_title" },

        
      ],
    };
  }


  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}
