import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MentorVerificationService } from '../_services/mentor-verification.service';

@Component({
  selector: 'app-mentor-verification',
  templateUrl: './mentor-verification.component.html',
  styleUrls: ['./mentor-verification.component.sass' , './mentor-verification.component.scss'],
  
})
export class MentorVerificationComponent implements OnInit {
  slug:any
  error_msg:any
  meeting_link: any;
  safeURL: any;
  meeting_details: any = [];
  model_show: boolean = true;
  meeting_show: boolean = false;
  @ViewChild('jioMeetFrame', { static: false }) jioMeetFrame!: ElementRef;
  constructor(
    private router: Router,
    private service: MentorVerificationService,
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      //console.log(params,"paramasss");
      
      if (params["q1"] != undefined && params["q1"] != '') {
        this.slug = params["q1"] || '';
        if (this.slug != '') {
          this.getData()
        } else {
          this.error_msg = 'Invalid Verification link';
        }
      } else {
        this.error_msg = 'Invalid Verification link';
      }
    })

    window.addEventListener("message", (event) => {
      //console.log(event);
      if (event.data == 'callEnded') {
        this.callEnded();
      } else if (event.data == 'callStarted') {
        this.callStarted();
      }
    });
  }

  callEnded() {
    this.router.navigate(['/mentor-list']); 

  }

  callStarted(){

  }

  getData() {
    this.service.onMentorVerification({ "id": this.slug }).subscribe((resp:any) => {
      if (resp['status'] === 200) {
        this.meeting_details = resp.data;
        //console.log(this.meeting_details,"meeting detial");
        
        if (this.meeting_details.is_verify === 'N') {
          this.error_msg = ''
          this.model_show = true
          this.meeting_link = this.meeting_details.meeting_link;
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.meeting_link);
          this.meeting_show = true
        }
      } else if (resp['status'] === 322) {
        this.error_msg = (resp && resp.mesg) ? resp.mesg : 'You are already verified'
       
      } else {
        this.error_msg = (resp && resp.mesg) ? resp.mesg : 'You are already verified'
      }
    }, error => {
      console.error('api error: ', error)
    })
  }

}
