<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-lg-12 headboxes">
                <div class="card p-0">
                    <div class="card-block p-0">
                        <div class="heading-box" style="background-image:none;">
                            <div class="row align-items-center">

                                <div class="col-md-1">
                                    <div class="partner-iocn">
                                        <img *ngIf="partnerIndividualDetails.image" [src]="partnerIndividualDetails.image" style="height: 100px;width: 100px;">
                                        <img *ngIf="!partnerIndividualDetails.image" src="assets/img/default-profile.png" class="img-fluid" alt="">
                                    </div>
                                </div>

                                <div class="col-md-8 align-self-center">
                                    <div class="pagename ml-5">
                                        <h3 class="card-title mb-1" *ngIf="partnerIndividualDetails.type =='Individual'">{{partnerIndividualDetails?.first_name}} {{ partnerIndividualDetails.last_name}}</h3>
                                        <h3 class="card-title mb-1" *ngIf="partnerOrganizationDetails.type =='Organisation'">{{partnerOrganizationDetails.organization_name}}</h3>
                                    </div>
                                </div>

                                <div class="col-md-3">
									<div class="excel">
									<button type="button"   class="btn btn-primary addbtn  ml-3" (click)="onBack()"><i class="fa fa-long-arrow-left"></i> Back</button>
								 </div>
							</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" *ngIf="partnerIndividualDetails.type =='Individual'" >
                <div class="card">
                    <div class="card-block partnerview">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h4>Basic Details</h4>
                                            <hr>
                                        </div>
<!-- 
                                        <div class="col-md-6">
                                            <div class="form-group  justify-content-start">
                                                <label for="">Partner Logo</label>
                                                <img [src]="partnerIndividualDetails.image" style="height: 100px;width: 100px;">
                                            </div>
                                        </div> -->

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">First Name</label>
                                                <p>{{partnerIndividualDetails.first_name}} </p>
                                            </div>
                                         </div>

                                         <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Last Name</label>
                                                <p>{{ partnerIndividualDetails.last_name}}</p>
                                            </div>
                                         </div>

                                         <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Gender</label>
                                                <p>{{partnerIndividualDetails.gender}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Occuption</label>
                                                <p>{{partnerIndividualDetails.occuption}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Type Of Employment</label>
                                                <p>{{partnerIndividualDetails.type_of_emp}}
                                                   </p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Year Of Birth</label>
                                                <p>{{partnerIndividualDetails.year_of_birth}}</p>
                                            </div>
                                        </div>

                                         <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Sector Name</label>
                                                <p>{{partnerIndividualDetails.sector}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Industry</label>
                                                <p>{{partnerIndividualDetails.industry_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Country</label>
                                                <p>{{partnerIndividualDetails.country_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">State</label>
                                                <p>{{partnerIndividualDetails.state_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">City</label>
                                                <p>{{partnerIndividualDetails.city_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Pincode</label>
                                                <p>{{partnerIndividualDetails.pincode}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Email
                                                </label>
                                                <p>{{partnerIndividualDetails.email}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6"  >
                                            <div class="form-group">
                                                <label for="">Mobile</label>
                                                <p>{{partnerIndividualDetails.mobile}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6"  >
                                            <div class="form-group">
                                                <label for="">Landline</label>
                                                <p>{{partnerIndividualDetails.landline}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6"  >
                                            <div class="form-group">
                                                <label for="">Website Link</label>
                                                <p>{{partnerIndividualDetails.website}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4>Social Media Channel Links</h4>
                                            <hr>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Linkedin</label>
                                                    <p>{{partnerIndividualDetails.linkedin}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Twitter</label>
                                                    <p>{{partnerIndividualDetails.twitter}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Facebook</label>
                                                    <p>{{partnerIndividualDetails.facebook}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Youtube</label>
                                                    <p>{{partnerIndividualDetails.youtube}}</p>
                                                </div>
                                               </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Instagram</label>
                                                <p>{{partnerIndividualDetails.instagram}}</p>
                                            </div>
                                           </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4>INDUSTRY EXPERIENCE DETAILS</h4>
                                            <hr>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Support Entrepreneurs</label>
                                                    <p>{{partnerIndividualDetails.entrepreneurs_supporting_area_name}}</p>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Support Sector/Industry</label>
                                                    <p>{{partnerIndividualDetails.restrict_industry}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                    <div class="card-block" *ngIf="partnerIndividualDetails.approve_status != 'SUBMITTED'" >
                        <div class="partner-action p-3" >
                            
                                <div class="row" >
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="atr_remarks">Remarks</label>
                                            <form [formGroup]="remarkForm">
                                                <textarea  class="form-control" name="remarks" id="atr_remarks" formControlName="remarks" rows="3"></textarea>

                                                <div *ngIf="remarks.invalid && (remarks.dirty || remarks.touched)" class="alert">
                                                    <div class="text-danger" *ngIf="remarks?.errors?.whitespace && (remarks.dirty || remarks.touched)">
                                                        <p>This field is required</p>
                                                      </div>
                                                      
                                                      <div *ngIf="errorMessage" class="alert alert-danger">
                                                        {{ errorMessage }}
                                                      </div>
                                                </div>
                                              </form>
                                           </div>
                                        </div>
                                    <div class="col-md-12 align-self-center">
                                        <div *ngIf="is_Error" class="text-danger text-sm mb-2">
                                            {{ api_error }}
                                          </div>
                                        <div class="actiobtn">
                                            <!-- <button type="submit" (click)="rejectStatus('SAVE_AS_DRAFT')"
                                            class="btn btn-dark mr-2" >Save as Draft</button> -->
                                            <button type="submit" (click)="saveSubmit('SUBMITTED')"
                                            class="btn btn-primary mr-2 addbtn" >Approve</button>
                                            <button type="submit" (click)="rejectStatus('REJECTED')"
                                            class="btn btn-success addbtn mr-2">Reject</button>
                                        </div>
                                    </div>
                                </div>
                        </div>

                          
                    </div>

                      <div class="card-block">
                        <div class="partner-action p-3" *ngIf="partnerIndividualDetails.approve_status == 'SUBMITTED'" >
                            <div class="text-center font-weight-bold">Approved</div>
                        </div> 
                      </div>
                </div>
            </div>

            <div class="col-lg-12" *ngIf="partnerOrganizationDetails.type =='Organisation'" >
                <div class="card">
                    <div class="card-block partnerview">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h4>Basic Details</h4>
                                            <hr>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Partner Logo</label>
                                                <img *ngIf="partnerOrganizationDetails.image" [src]="partnerOrganizationDetails.image" style="height: 100px;width: 100px;">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Name Of Organization</label>
                                                <p>{{partnerOrganizationDetails.organization_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6 " >
                                            <div class="form-group">
                                                <label for="">Year Of Established</label>
                                                <p>{{partnerOrganizationDetails.year_of_establishment}}</p>
                                            </div>
                                        </div>


                                         <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Type Of Organization</label>
                                                <p>{{partnerOrganizationDetails.type_of_organization}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Sector Name</label>
                                                <p>{{partnerOrganizationDetails.sector_name}}</p>
                                            </div>

                                        </div>  <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Industry Name</label>
                                                <p>{{partnerOrganizationDetails.industry_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Country</label>
                                                <p>{{partnerOrganizationDetails.country_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">State</label>
                                                <p>{{partnerOrganizationDetails.state_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">City</label>
                                                <p>{{partnerOrganizationDetails.city_name}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Pincode</label>
                                                <p>{{partnerOrganizationDetails.pincode}}</p>
                                            </div>
                                        </div>

                                         <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Email</label>
                                                <p>{{partnerOrganizationDetails.email}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Mobile</label>
                                                <p>{{partnerOrganizationDetails.mobile}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6" >
                                            <div class="form-group">
                                                <label for="">Landline</label>
                                                <p>{{partnerOrganizationDetails.landline}}
                                                   </p>
                                            </div>
                                        </div>

                                        <div class="col-md-6"  >
                                            <div class="form-group">
                                                <label for="">Website</label>
                                                <p>{{partnerOrganizationDetails.website}}</p>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4>Social Media Channel Links</h4>
                                            <hr>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Linkedin</label>
                                                    <p>{{partnerOrganizationDetails.linkedin}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Twitter</label>
                                                    <p>{{partnerOrganizationDetails.twitter}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Facebook</label>
                                                    <p>{{partnerOrganizationDetails.facebook}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Youtube</label>
                                                    <p>{{partnerOrganizationDetails.youtube}}</p>
                                                </div>
                                               </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Instagram</label>
                                                <p>{{partnerOrganizationDetails.instagram}}</p>
                                            </div>
                                           </div>
                                         </div>

                                        <div class="col-md-12">
                                            <h4>AUTHORIZED REPRESENTATIVE AS THE POINT OF CONTACT FOR WEP
                                            </h4>
                                            <hr>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Full Name</label>
                                                    <p>{{partnerOrganizationDetails.fullname}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Email</label>
                                                    <p>{{partnerOrganizationDetails.spoc_email}}</p>
                                                </div>
                                               </div>
                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Mobile Number</label>
                                                    <p>{{partnerOrganizationDetails.spoc_mobile_no}}</p>
                                                </div>
                                             </div>
                                           </div>

                                        <div class="col-md-12">
                                            <h4>INDUSTRY EXPERIENCE DETAILS</h4>
                                            <hr>

                                               <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Support Entrepreneurs</label>
                                                    <p>{{partnerOrganizationDetails.entrepreneurs_supporting_area_name}}</p>
                                                </div>
                                               </div>
                                              </div>
                                             </div>
                                            </div>

                        <div class="card-block" >
                            <div class="partner-action p-3" *ngIf="partnerIndividualDetails.approve_status != 'SUBMITTED'">
                                
                                    <div class="row" >
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="atr_remarks">Remarks</label>
                                                <form [formGroup]="remarkForm">
                                                    <textarea  class="form-control" name="remarks" id="atr_remarks" formControlName="remarks" rows="3"></textarea>
    
                                                    <div *ngIf="remarks.invalid && (remarks.dirty || remarks.touched)" class="alert">
                                                        <div class="text-danger" *ngIf="remarks?.errors?.whitespace && (remarks.dirty || remarks.touched)">
                                                            <p>This field is required</p>
                                                          </div>
                                                          
                                                          <div *ngIf="errorMessage" class="alert alert-danger">
                                                            {{ errorMessage }}
                                                          </div>
                                                    </div>
                                                  </form>
                                               </div>
                                            </div>


                                        <div class="col-md-12 align-self-center">
                                            <div *ngIf="is_Error"  class="text-danger text-sm mb-2">
                                                {{ api_error }}
                                              </div>

                                            <div class="actiobtn">


                                                <!-- <button type="submit" (click)="rejectStatus('SAVE_AS_DRAFT')"
                                                class="btn btn-dark mr-2" >Save as Draft</button> -->
                                                <button type="submit" (click)="saveSubmit('SUBMITTED')"
                                                class="btn btn-primary mr-2 addbtn" >Approve</button>
                                                <button type="submit" (click)="rejectStatus('REJECTED')"
                                                class="btn btn-success addbtn mr-2">Reject</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                            <div class="card-block">

                            <div class="partner-action p-3" *ngIf="partnerIndividualDetails.approve_status == 'SUBMITTED'" >
                                <div class="text-center font-weight-bold">Approved</div>
                            </div>    
                       
                    </div>
                </div>
              </div>
           </div>
        </div>
</div>
