import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DamagedProductService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/damaged-product-save', data);
  }

  allDamagedProduct(){
    return  this.base.get('/api/all-damaged-product');
  }

  getAllProduct(type){
    return this.base.post('/api/get-all-product-by-damaged',{type:type});
  }

  getDamagedProduct(id){
    return this.base.post('/api/get-damaged-product',{id:id});
  }

  damagedProductUpdate(data){
    return this.base.post('/api/damaged-product-update',data);
  }

}
