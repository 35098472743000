import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FaqCategoryService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');

  save(data){
  	return this.base.post('/api/faqcategory-create', data);
  }

  faqCategoryUpdate(data){
    return this.base.post('/api/faqcategory-update',data);
  }

  faqCategoryDelete(id){
    return this.base.post('/api/faqcategory-delete',{id:id});
  }

  getFaqCategoryById(id){
    return this.base.post('/api/faqcategory-edit',{id:id});
  }

  getAllFaqCategory(){
    return  this.base.get('/api/get-faqcategory');
  }


}
