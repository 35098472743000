<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-block">
                        <div class="card-block-title">
                            <h5 class="card-title">Send Email</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-8 px-3">
                                <div class="form-group row">
                                    <label for="subject" class="col-1 col-form-label">Title</label>
                                    <div class="col-7">
                                        <input class="form-control" type="text" id="subject" name="subject"  required [(ngModel)]="notification.subject">
                                  </div>
                                 </div>
                                <div class="form-group row">
                                    <label for="supplierName" class="col-1 form-control-label">Type <span>*</span> </label>
                                    <div class="col-7">
                                        <select class="form-control" id="type" name="type" [(ngModel)]="notification.type" (change)="changeType()" required>
                                            <option value="1" selected>Customer</option>
                                            <option value="2">Supplier</option>
                                        </select>
                                     </div>
                                  </div>
                                <div class="form-group row">
                                    <label for="supplierName" class="col-1 form-control-label">TO <span>*</span> </label>
                                    <div class="col-7" *ngIf="notification.type==1">
                                        <ng-select [items]="allCustomer" [multiple]="true" bindLabel="name" bindValue="id"
                                            [(ngModel)]="selectedTo" (change)="changeTo()" [maxSelectedItems]="10">
                                        </ng-select>
                                    </div>
                                    <div class="col-7" *ngIf="notification.type==2">
                                        <ng-select [items]="allSupplier" [multiple]="true" bindLabel="name" bindValue="id"
                                            [(ngModel)]="selectedTo" (change)="changeTo()">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-1 form-control-label">File</label>
                                    <div class="col-md-7">
                                      <!-- <img style="height: 100px;width: 100px;" class="img-thumbnail"/> -->
                                      <div class="input-group">
                                          <span class="input-group-btn">
                                                  <label class="btn btn-primary">
                                                    <samp>Browse</samp>...
                                                      <input type="file" accept="{{fileType}}" #fileInput  name="file" style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple" >
                                                  </label>
                                              </span>
                                          <input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText"  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                                      </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="supplierName" class="col-1 form-control-label">Body <span>*</span> </label>
                                    <div class="col-11">
                                        <app-text-editor (editorData)="editorData($event)"></app-text-editor> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
