import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AlertService, VerticalsService } from "../_services";
import { OfferingVerticalsService } from "../_services/offering-verticals.service";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../_services/encryption.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class OfferingVerticals {
  id: number;
  name: string;
  status: string;
}

@Component({
  selector: "app-offering-verticals",
  templateUrl: "./offering-verticals.component.html",
  styleUrls: ["./offering-verticals.component.sass"],
})
export class OfferingVerticalsComponent implements OnInit {
  @Input() allowMultiple: boolean;
  @ViewChild(DataTableDirective, { static: false })
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  offeringVerticalsList: OfferingVerticals[] = [];
  isSubmitted = false;
  modalReference: NgbActiveModal;
  modalTitle: string;
  start: number = 0;
  pageLength: number = 10;
  offeringVerticalsTotal: any;
  getOfferingVerticals = {
    id: "",
    name: "",
    status: "N",
  };

  constructor(
    public router: Router,
    private dataService: OfferingVerticalsService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService
  ) {}

  offering_verticals = {
    id: "",
    name: "",
    status: "Y",
  };

  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getPartnerOfferingListing();
    this.loadHide();
  }

  getPartnerOfferingListing() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        // this.start = dataTablesParameters.start;
        // this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/offering-vertical-ajax-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            const resp = JSON.parse(output);
            //console.log(resp, "get all offering verticals");

            this.offeringVerticalsList = resp["data"];
            this.offeringVerticalsTotal = resp["recordsTotal"];

            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "created_at" },
        { data: "status" },
      ],
    };
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  open(content) {
    this.isSubmitted = false;
    this.offering_verticals.id = "";
    (this.offering_verticals.name = ""), (this.offering_verticals.status = "Y");
    this.modalTitle = "Add Verticals";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .editVertical(id)
      .pipe()
      .subscribe((res) => {
        //console.log(res, "edittttttt");

        this.getOfferingVerticals = res["data"];
        this.offering_verticals = {
          id: this.getOfferingVerticals.id,
          name: this.getOfferingVerticals.name,
          status: this.getOfferingVerticals.status,
        };
        this.loadHide();
        this.modalTitle = "Edit Verticals";
        this.modalReference = this.modalService.open(content);
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  save(val, f) {
    this.isSubmitted = true;

    if (this.isFieldsInvalid(this.offering_verticals.name)) {
      return;
    }

    if (f.form.valid) {
      this.loadShow(); // Show loader

      if (val.id === "") {
        // If ID is empty, create a new record
        this.dataService.save(this.offering_verticals).subscribe(
          (data) => {
            this.loadHide(); // Hide loader after response
            if (data["status"] === 200) {
              this.alertService.success(
                "Offering Verticals saved successfully",
                true
              );

              // Reload DataTable
              const table = $("#offeringPartnertable").DataTable();
              table.ajax.reload(null, false);

              // Close modal
              this.modalReference.close();
            } else if (data["status"] === 422) {
              // Handle validation error from backend
              if (data.mesg && data.mesg.name) {
                this.alertService.error(data.mesg.name);
              }
            }

            // Scroll to top of modal (only if modal exists)
            const modalElement = document.querySelector(".modal");
            if (modalElement) {
              modalElement.scrollTo({ top: 0, behavior: "smooth" });
            }
          },
          (error) => {
            // Handle error from the save API
            this.loadHide(); // Hide loader on error
            this.alertService.error(error);

            // Scroll to top of modal (only if modal exists)
            const modalElement = document.querySelector(".modal");
            if (modalElement) {
              modalElement.scrollTo({ top: 0, behavior: "smooth" });
            }
          }
        );
      } else {
        // If ID exists, update the record
        this.dataService.verticalUpdate(this.offering_verticals).subscribe(
          (data) => {
            this.loadHide(); // Hide loader after response
            if (data["status"] === 200) {
              this.modalReference.close(); // Close modal
              this.alertService.success(
                "Offering Verticals updated successfully",
                true
              );

              // Reload DataTable
              const table = $("#offeringPartnertable").DataTable();
              table.ajax.reload(null, false);
            } else if (data["status"] === 422) {
              // Handle validation error from backend
              if (data.mesg && data.mesg.name) {
                this.alertService.error(data.mesg.name);
              }
            }
          },
          (error) => {
            this.loadHide(); // Hide loader on error
            this.alertService.error(error);

            // Scroll to top of modal (only if modal exists)
            const modalElement = document.querySelector(".modal");
            if (modalElement) {
              modalElement.scrollTo({ top: 0, behavior: "smooth" });
            }
          }
        );
      }
    }
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .verticalDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              " Offering Verticals deleted successfully",
              true
            );
            item.status = item.status === "Y" ? "N" : "Y";
            this.loadHide();
          } else {
            this.alertService.error(" Offering Verticals error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
