import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { SchemesService } from 'src/app/_ciiyi_services/schemes.service';
import { AlertService } from 'src/app/_services';
import { EncryptionService } from 'src/app/_services/encryption.service';

class Blog {
  id: number;
  title: string;
  name_of_department: string;
  target_audiences: string;
  short_description: string;
  description: string;
  gender: string;
  link_for_government_site: string;
  image: string;
  application_process: string;
  sectorofenterprise: string;
  areaofinterest: string;
  link_for_guideline: string;
  blog_type: string;
  home_display: string;
  is_active: string;
  faq_link: string;
  documnets_required: any;
  eligibility: any;
  terms_and_conditions: any;
  implementing_agency: any;
  applicable_for: any;
}

class DataTablesResponse {
  data: any;
  draw: any;
  recordsFiltered: any;
  recordsTotal: any;
}

interface SubCategory {
  id: number;
  name: string;
}

@Component({
  selector: 'app-cii-schemes',
  templateUrl: './cii-schemes.component.html',
  styleUrls: ['./cii-schemes.component.sass']
})
export class CiiSchemesComponent implements OnInit {

  
  configdescription: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg , .png , .bmp";
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  faqs: { question: string; answer: string }[] = [{ question: "", answer: "" }];

  fileList: any;
  isSubmitted = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  blogList: Blog[] = []; // Table Data list
  // productAddForm: FormGroup;
  //randomnumber = Math.floor(Math.random() * 100000000);

  getBlog = {
    id: "",
    title: "",
    name_of_department: "",
    target_audiences: "",
    short_description: "",
    description: "",
    gender: "",
    link_for_government_site: "",
    image: "",
    application_process: "",
    sectorofenterprise: "",
    areaofinterest: "",
    link_for_guideline: "",
    blog_type: "",
    home_display: "",
    is_active: "",
    documnets_required: "",
    eligibility: "",
    terms_and_conditions: "",
    implementing_agency: "",
    applicable_for: "",
    faq_link: "",
  };

  closeResult: string; // Modal Close

  selectedToEnterprise: any;
  myStringEnterprise: any;
  myArrayEnterprise: any;

  selectedToInterest: any;
  myStringInterest: any;
  myArrayInterest: any;

  selectedToStudy: any;
  myStringStudy: any;
  myArrayStudy: any;

  imageChangedEvent: any = "";
  croppedImage: any = "";
  image_cropper: "";

  constructor(
    public router: Router,
    private dataService: SchemesService,
    private alertService: AlertService,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {}

  blog = {
    id: "",
    title: "",
    name_of_department: "",
    target_audiences: "",
    short_description: "",
    description: "",
    gender: "",
    link_for_government_site: "",
    image: "",
    application_process: "",
    sectorofenterprise: "",
    areaofinterest: "",
    link_for_guideline: "",
    blog_type: "",
    home_display: "",
    is_active: "Y",
    documnets_required: "",
    eligibility: "",
    faq_link: "",
    terms_and_conditions: "",
    implementing_agency: "",
    applicable_for: "",
  };

  totalBlogList: any;
  modalReference: NgbActiveModal;
  modalCropper: NgbActiveModal;
  modalTitle: string;
  cat = {};
  subCat = [];
  states = {};
  cities = {};
  blogType = {};
  sectorofenterprise = {};
  areaofinterest = {};
  areaofstudy = {};
  targetaudience: any = [];
  setting = { currency: "" };
  showloding = true;
  lodingImage = false;
  errroShow:any
  isValid :boolean = false

  ngOnInit() {
    this.loadHide()
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllBlogList();
    this.dataService
      .getSectorofEnterprise()
      .pipe()
      .subscribe((data) => {
        this.sectorofenterprise = data["sectorofenterprise"];
      });

    this.dataService
      .getAreaofInterest()
      .pipe()
      .subscribe((data) => {
        this.areaofinterest = data["areaofinterest"];
      });

    this.dataService
      .getBlogType()
      .pipe()
      .subscribe((data) => {
        this.targetaudience = data["target_audiences"];
        //console.log(data, "vlooooo");
        if (typeof data["blog-type"] === "object") {
          this.blogType = Object.values(data["blog-type"]); // Get array of values
        } else {
          this.blogType = []; // Default to an empty array in case of error
        }

        ////console.log(this.blogType);
      });

    let settingData = JSON.parse(localStorage.getItem("setting"));
    this.setting = { currency: settingData.currency };
  }

 

  removeFaq(index: number) {
    this.faqs[index].question = "";
    this.faqs[index].answer = "";
    this.faqs.splice(index, 1);
  }

  addFaq() {
    this.faqs.push({ question: "", answer: "" });
  }

 
  getAllBlogList() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/admin/blog/get-blog",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "newsFeeds");

            this.blogList = resp["data"];
            this.totalBlogList = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },

      columns: [
        { data: 'id' },
        { data: 'title' },
        { data: 'title' },
        { data: 'title' },
        { data: 'title' },
        { data: 'title' },
      ],
    };
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  changeToEnterprise() {
    this.blog.sectorofenterprise = this.selectedToEnterprise;
  }

  changeToInterest() {
    this.blog.areaofinterest = this.selectedToInterest;
  }

  selectCat(id) {
    this.dataService
      .getSubCategory(id)
      .pipe()
      .subscribe((data) => {
        this.subCat = data["subCat"];
      });
  }

  selectCityById(id) {
    this.dataService
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  save(val, f) {
    this.isSubmitted = true;

    if (
      this.isFieldsInvalid(this.blog.title) ||
      this.isFieldsInvalid(this.blog.short_description)
    ) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (f.form.valid) {
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.isSubmitted = true;
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.getBlog(id).subscribe((data) => {
      this.getBlog = data["blog"];
      let blogData = data["blog"]["blog_faqs"];
      this.faqs = blogData;

      if (!blogData || blogData.length === 0) {
        blogData = [{ question: "", answer: "" }];
      }
      this.faqs = blogData;


      this.myStringEnterprise = this.getBlog.sectorofenterprise;
      if (this.myStringEnterprise) {
        this.myArrayEnterprise = this.myStringEnterprise
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        this.selectedToEnterprise = this.myArrayEnterprise;
      }

      this.myStringInterest = this.getBlog.areaofinterest;
      if (this.myStringInterest) {
        this.myArrayInterest = this.myStringInterest
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        //console.log(this.myArrayInterest);
        this.selectedToInterest = this.myArrayInterest;
      }

      this.croppedImage = this.getBlog.image;

      this.blog = {
        id: this.getBlog.id,
        title: this.getBlog.title,
        name_of_department: this.getBlog.name_of_department,
        target_audiences: this.getBlog.target_audiences,
        short_description: this.getBlog.short_description,
        description: this.getBlog.description,
        image: this.getBlog.image,
        gender: this.getBlog.gender,
        sectorofenterprise: this.getBlog.sectorofenterprise,
        areaofinterest: this.getBlog.areaofinterest,
        link_for_government_site: this.getBlog.link_for_government_site,
        application_process: this.getBlog.application_process,
        link_for_guideline: this.getBlog.link_for_guideline,
        applicable_for: this.getBlog.applicable_for,
        documnets_required: this.getBlog.documnets_required,
        terms_and_conditions: this.getBlog.terms_and_conditions,
        implementing_agency: this.getBlog.implementing_agency,
        blog_type: this.getBlog.blog_type,
        faq_link: this.getBlog.faq_link,
        home_display: this.getBlog.home_display,
        eligibility: this.getBlog.eligibility,
        is_active: this.getBlog.is_active,
      };
      this.loadHide();
      this.isSubmitted = false;
      this.modalTitle = "Edit Scheme";
      this.modalReference = this.modalService.open(content);
    });
  }

  fileChange(event: any) {
    this.fileList = event;
    this.DisplayedText = this.fileList[0].name;
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;

      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList);
    }
  }

  open(content) {
    // this.faqs = [];
    this.faqs = [{ question: "", answer: "" }];
    this.isSubmitted = false;
    this.selectedToEnterprise = "";
    this.selectedToInterest = "";
    this.blog.id = "";

    (this.blog.title = ""),
      (this.blog.name_of_department = ""),
      (this.blog.target_audiences = ""),
      (this.blog.short_description = ""),
      (this.blog.description = ""),
      (this.blog.gender = ""),
      (this.blog.link_for_government_site = ""),
      (this.blog.image = ""),
      (this.blog.application_process = ""),
      (this.blog.sectorofenterprise = ""),
      (this.blog.areaofinterest = ""),
      (this.blog.applicable_for = ""),
      (this.blog.link_for_guideline = ""),
      (this.blog.documnets_required = ""),
      (this.blog.implementing_agency = ""),
      (this.blog.terms_and_conditions = ""),
      (this.blog.eligibility = ""),
      (this.blog.blog_type = ""),
      (this.blog.faq_link = ""),
      (this.blog.home_display = ""),
      (this.blog.is_active = "Y");
    this.modalTitle = "Add Scheme";
    this.modalReference = this.modalService.open(content);
  }

  insertAction(val, f) {
    this.loadShow();

    const blogFaqs = JSON.stringify(this.faqs);

    let formData: FormData = new FormData();
    formData.append("title", this.blog.title);
    formData.append("name_of_department", this.blog.name_of_department);
    formData.append("target_audiences", this.blog.target_audiences);
    formData.append("short_description", this.blog.short_description);
    formData.append("description", this.blog.description);
    formData.append("gender", this.blog.gender);
    formData.append(
      "link_for_government_site",
      this.blog.link_for_government_site
    );
    formData.append("application_process", this.blog.application_process);
    formData.append("sectorofenterprise", this.selectedToEnterprise.join(","));
    formData.append("areaofinterest", this.selectedToInterest.join(","));
    formData.append("applicable_for", this.blog.applicable_for);
    formData.append("link_for_guideline", this.blog.link_for_guideline);
    formData.append("documnets_required", this.blog.documnets_required);
    formData.append("implementing_agency", this.blog.implementing_agency);
    formData.append("terms_and_conditions", this.blog.terms_and_conditions);
    formData.append("eligibility", this.blog.eligibility);
    formData.append("blog_type", this.blog.blog_type);
    formData.append("home_display", this.blog.home_display);
    formData.append("is_active", this.blog.is_active);
    formData.append("blog_faqs", blogFaqs);
    formData.append("faq_link", this.blog.faq_link);

    if (val.id == "") {
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data.status == 200) {
              this.croppedImage = "";
              this.alertService.success("Blog created successfully", true);
              const table = $("#schemes_table").DataTable();
              table.ajax.reload(null, false);
              this.modalReference.close();
            } else {
              //console.log("error");
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }

      this.dataService
        .blogUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            const table = $("#schemes_table").DataTable();
              table.ajax.reload(null, false);
            this.alertService.success("Blog updated successfully", true);
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    this.DisplayedText = this.fileList[0].name;

    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.DisplayedText = "";
        this.croppedImage = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.blog.image = this.croppedImage;
      };

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;

        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList[0];
      }
    }
    return false;
  }

 

  delete(item) {
    this.loadShow();
    this.dataService
      .blogDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Blog deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            //console.log(data,"res")
            const table = $("#schemes_table").DataTable();
              table.ajax.reload(null, false);
          } else {
            this.alertService.error("Blog error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.blog.image = file;
      this.croppedImage = "";
    }
    this.imageChangedEvent = event;
    //console.log(file, "files");
  }

  imageCropped(event: ImageCroppedEvent) {
    // this.blog.image = '';
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  exit(event: any) {
    this.fileList = event.exitTransform;
  }

  openCropper(contentCrop) {
    this.modalCropper = this.modalService.open(contentCrop);
  }

  closeCropper() {
    this.modalCropper.close();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
