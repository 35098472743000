import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class BannersService {
  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/banners-create', data);
  }

  bannersUpdate(data){
    return this.base.post('/api/banners-update',data);
  }

  bannersDelete(id){
    return this.base.post('/api/banners-delete',{id:id});
  }

  getAllBanners(){
    return  this.base.get('/api/get-banners');
  }

  getBanners(id){
    return this.base.post('/api/banners-edit',{id:id});
  }

}
