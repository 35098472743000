import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  AlertService,
  EventCategoryService,
  AppService,
} from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class EventCategory {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-event-category",
  templateUrl: "./event-category.component.html",
})
export class EventCategoryComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  eventCategoryList: EventCategory[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getEventCategory = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  constructor(
    public router: Router,
    private dataService: EventCategoryService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  eventcategory = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allEventCategory();
  }

  allEventCategory() {
    this.loadShow();
    this.dataService
      .getAllEventcategory()
      .pipe()
      .subscribe((data) => {
        this.eventCategoryList = data["eventcategory"];
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    if (this.getEventCategory.id != null) {
      this.getEventCategory.id = "";
    }
    (this.eventcategory.name = ""),
      (this.eventcategory.description = ""),
      (this.eventcategory.is_active = "Y");
    this.modalTitle = "Add Event Category";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getEventcategory(id)
      .pipe()
      .subscribe((data) => {
        this.getEventCategory = data["eventcategory"];
        this.eventcategory = {
          id: this.getEventCategory.id,
          name: this.getEventCategory.name,
          description: this.getEventCategory.description,
          is_active: this.getEventCategory.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit Event Category";
        this.modalReference = this.modalService.open(content);
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.eventcategory.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.eventcategory)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success(
                "Event category saved successfully",
                true
              );
              this.allEventCategory();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .eventcategoryUpdate(this.eventcategory)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success(
                "Event Category updated successfully",
                true
              );
              this.allEventCategory();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.eventcategoryDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Event Category deleted successfully', true);
  //             this.allEventCategory();
  //           }else{
  //             this.alertService.error('Event Category error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //       }
  //     }

  delete(item) {
    this.loadShow();
    this.dataService
      .eventcategoryDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Event Category  deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allEventCategory();
          } else {
            this.alertService.error("Event Category  error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
