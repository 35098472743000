import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MentorSlotsService } from '../_services/mentor-slots.service';

declare const $: any
declare const mobile: any;
declare const home: any;

@Component({
  selector: 'app-mentor-slots',
  templateUrl: './mentor-slots.component.html',
  styleUrls: ['./mentor-slots.component.sass']
})
export class MentorSlotsComponent implements OnInit {

  // loader1$!: Observable<LoaderState>
  successMsg: any = '';
  getList: any = []
  upcomingMentorsList: any = []
  connectData: any = [];
  rejectstatus: any;
  mentor: any;
  slidershow: boolean = false
  menteeConnected: any;
  reject_meeting_date: any;
  reject_meeting_title: any
  menteeRequested: any;
  requestError: any;
  menteeRejectId: any;
  rejectError: any;
  meetId: any
  mentee_id: any;
  mentee_name: any;
  mentee_image: any;
  menteeChatDetails: any;
  member_image: any;
  userToken: any;
  username: any;
  connection_status: any;
  check_submit: boolean = false;
  reject_mentee_name: any = ''

  days: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  selectedDays: string[] = [];
  times: string[] = ['10:00', '11:00', '12:00']; // Add more if needed
  slots: any = {};
  slots_delete: any = {};

  startTimes: string[] = [];
  endTimes: string[] = [];

  // Selected start and end times
  selectedStartTime: string = '';
  selectedEndTime: string = '';

  startTimeError: { [key: string]: boolean[][] } = {};
  endTimeError: { [key: string]: boolean[][] } = {};
  constructor(
    private mentorService: MentorSlotsService,
    public router: Router) {
    this.generateTimeOptions();
  }
  // this.loader1$ = this.loaderService.getLoaderState();
  showloding :any= true
  lodingImage :any= false;

  ngOnInit(): void {

    setTimeout(() => {
      this.userToken = localStorage.getItem("member_login_token");
      this.member_image = localStorage.getItem("member_image");
      this.username = localStorage.getItem("username");
    }, 1000);
    this.slotList();
  }


  mergeAndSortTimeSlots(data: any[]): string[] {
    let allTimeSlots: string[] = [];

    // Iterate over each entry in the data array
    for (let entry of data) {
      let start = entry.start_time.split(':').map(Number);
      let end = entry.to_time.split(':').map(Number);

      // Convert time to minutes for easier manipulation
      let startTimeInMinutes = start[0] * 60 + start[1];
      let endTimeInMinutes = end[0] * 60 + end[1];

      // Generate time slots of 30-minute intervals
      for (let i = startTimeInMinutes; i <= endTimeInMinutes; i += 30) {
        let hours = Math.floor(i / 60);
        let minutes = i % 60;
        let time = `${hours < 10 ? '0' + hours : hours}:${minutes === 0 ? '00' : minutes}`;
        allTimeSlots.push(time);
      }
    }

    // Sort the time slots chronologically
    allTimeSlots.sort((a: any, b: any) => {
      let [hoursA, minutesA] = a.split(':').map(Number);
      let [hoursB, minutesB] = b.split(':').map(Number);

      if (hoursA === hoursB) {
        return minutesA - minutesB;
      } else {
        return hoursA - hoursB;
      }
    });

    // Remove duplicates using a Set
    let uniqueTimeSlots = Array.from(new Set(allTimeSlots));

    return uniqueTimeSlots;
  }

  generateTimeOptions(): string[] {
    const options: string[] = [];
    for (let hour = 0; hour <= 24; hour++) {
      for (let minute of ['00', '30']) {
        const formattedHour = (hour < 10 ? '0' : '') + hour.toString();

        var time = `${formattedHour}:${minute}`;
        if (formattedHour == '24') {
          if (minute == "00") {
            time = `00:00`;
          }
          else break;
        }
        options.push(time);
        this.startTimes.push(time);
        this.endTimes.push(time);
      }
    }
    return options;

  }

  slotList() {
    this.loadShow()
    this.slots = [];
    this.mentorService.slotListMentor().subscribe(resp => {
      if (resp.status == 200) {
        if (resp.data.length != 0) {
          resp.data.forEach((slot: any, index: number) => {
            // resp.forEach((slot: any, index: number) => {
            if (!this.slots[slot.day]) {
              this.slots[slot.day] = [];
            }
            var to_time = slot.to_time.split(':').slice(0, 2).join(':');
            var start_time = slot.from_time.split(':').slice(0, 2).join(':');

            this.slots[slot.day].push({ start_time: start_time, to_time: to_time, start_time_data: [], end_time_data: [] });

            this.selectedDays.push(slot.day);
            this.selectedDays = Array.from(new Set(this.selectedDays));

          });

          const sortByStartTime = (slots: any[]) => {
            return slots.sort((a, b) => {
              const timeA = a.start_time.split(":").join("");
              const timeB = b.start_time.split(":").join("");
              return parseInt(timeA) - parseInt(timeB); // Sort start_time in ascending order
            });
          };

          // Sort timeSlots object by day and then by start_time
          const sortedTimeSlots = Object.keys(this.slots).reduce((acc: any, day: string) => {
            const sortedSlots = sortByStartTime(this.slots[day]);
            acc[day] = sortedSlots;
            return acc;
          }, {});

          for (let check of this.days) {
            if (this.slots.hasOwnProperty(check)) {
              const check1 = sortedTimeSlots[check];

              sortedTimeSlots[check].forEach((slot: any, index: number) => {
                if (check1.length > 1) {
                  if (index === 0) {
                    const startIndex = this.startTimes.findIndex((time: any) => time === sortedTimeSlots[check][index].start_time);
                    if (startIndex !== -1) {
                      slot.start_time_data = this.startTimes.slice(startIndex);
                      const endIndex = this.startTimes.findIndex((time: any) => time === sortedTimeSlots[check][index].to_time);
                      if (endIndex !== -1) {
                        // Include the end time in the end_time_data array
                        slot.end_time_data = this.startTimes.slice(startIndex, endIndex + 1);
                      }
                    }
                  } else {
                    const selectedStartTime = sortedTimeSlots[check][index - 1].to_time;
                    const startIndex = this.startTimes.findIndex((time: any) => time === selectedStartTime);
                    if (startIndex !== -1) {
                      // Include the data from the previous slot's end time to the current slot's start time
                      slot.start_time_data = this.endTimes.slice(startIndex);
                    }

                    // const nextSlotStartTime = sortedTimeSlots[check][index + 1].start_time;
                    // if (nextSlotStartTime !== undefined) {
                    //   const endIndex = this.startTimes.findIndex((time: any) => time === sortedTimeSlots[check][index].to_time);
                    //   if (endIndex !== -1) {

                    //     slot.end_time_data = this.endTimes.slice(startIndex, endIndex + 1);
                    //   }
                    // } else {

                    //   slot.end_time_data = this.endTimes.slice(startIndex);
                    // }


                    const nextSlot = sortedTimeSlots[check][index + 1];

                    if (nextSlot && nextSlot.start_time !== undefined) {
                      const nextSlotStartTime = nextSlot.start_time;
                      const endIndex = this.startTimes.findIndex((time: any) => time === sortedTimeSlots[check][index].to_time);
                      if (endIndex !== -1) {
                        slot.end_time_data = this.endTimes.slice(startIndex, endIndex + 1);
                      }
                    } else {
                      slot.end_time_data = this.endTimes.slice(startIndex);
                    }
                  }
                } else {
                  slot.start_time_data = this.startTimes;
                  const endIndex = this.startTimes.findIndex((time: any) => time === sortedTimeSlots[check][index].start_time);
                  if (endIndex !== -1) {
                    // Slice the end times array starting from the index of selected start time
                    slot.end_time_data = this.endTimes.slice(endIndex);
                  }
                }
              });
            }

          }
          this.slots = sortedTimeSlots;

        } else {
          const currentDay = this.days[new Date().getDay()];
          if (!this.slots[currentDay]) {
            this.slots[currentDay] = [];
          }
          const endTime = '08:00'; // Assuming this is the endTime you want to start from

          const endIndex = this.startTimes.findIndex((time: any) => time === endTime);
          var timeDataFromEndToEnd: any = [];
          if (endIndex !== -1) {
            timeDataFromEndToEnd = this.endTimes.slice(endIndex);
          }

          this.slots[currentDay].push({ start_time: '08:00', to_time: '08:30', start_time_data: this.startTimes, end_time_data: timeDataFromEndToEnd });
          this.selectedDays.push(currentDay);
          this.selectedDays = Array.from(new Set(this.selectedDays));

        }

        this.loadHide()
      }

    }, error => {
      this.loadHide()

    });

 


  }


  isDaySelected(day: string): boolean {
    return this.selectedDays.includes(day);
  }

  toggleDaySelection(day: string): void {
    if (this.isDaySelected(day)) {
      const index = this.selectedDays.indexOf(day);
      if (index !== -1) {
        this.selectedDays.splice(index, 1);
      }
      //console.log(this.slots[day], 'this.slots[day]')
      // Additionally, reset the time slots for the selected day
      if (this.slots.hasOwnProperty(day)) {
        if (Object.keys(this.slots[day]).length > 0) {
          this.slots_delete[day] = this.slots[day];
        }
        delete this.slots[day];
      }
    } else {
      if (this.slots_delete.hasOwnProperty(day)) {
        //console.log(this.slots_delete, 'jj')
        //console.log(this.slots, 'jj2')
        if (Object.keys(this.slots_delete[day]).length > 0) {
          this.slots[day] = this.slots_delete[day];
        }
        delete this.slots_delete[day];
      }
      this.selectedDays.push(day);
      // Also, add an empty time slot for the selected day if it's not already present
      if (!this.slots.hasOwnProperty(day)) {
        const endTime = '08:00'; // Assuming this is the endTime you want to start from

        const endIndex = this.startTimes.findIndex(time => time === endTime);
        var timeDataFromEndToEnd: any = [];
        if (endIndex !== -1) {
          timeDataFromEndToEnd = this.endTimes.slice(endIndex);
        }
        this.slots[day] = [{ start_time: '08:00', to_time: '08:30', start_time_data: this.startTimes, end_time_data: timeDataFromEndToEnd }];
      }

    }
    if (this.selectedDays.length == 0) {
      this.check_submit = false;
      // this.check_submit = true;
    } else {
      this.check_submit = false;
    }
    // //console.log(this.slots, '6')
  }

  getTimeSlots(day: string, index: number): any[] {
    return this.slots[day] || [];
  }

  startTimes1(day: string, index: number): string[] {
    if (index === 0) {
      return this.startTimes;
    }
    const selectedStartTime = this.slots[day][index - 1].to_time;
    const selectedEndTime = this.slots[day][index - 1].start_time;
    const startIndex = this.startTimes.findIndex(time => time === selectedStartTime);
    const endIndex = this.startTimes.findIndex(time => time === selectedEndTime);
    if (startIndex !== -1) {
      // Slice the end times array starting from the index of selected start time
      return this.endTimes.slice(startIndex);
    }
    return this.endTimes.slice(endIndex);
  }



  addSlot(day: string): void {
    if (!this.slots[day]) {
      this.slots[day] = [];
    }
    //console.log(this.slots[day]);

    const endTime = this.slots[day][this.slots[day].length - 1].to_time; // Existing end time
    // //console.log(endTime,'endTime')

    const [hours, minutes] = endTime.split(':').map(Number); // Split the time into hours and minutes
    let newHours = hours;
    let newMinutes = minutes + 30;

    // Adjust hours and minutes if necessary
    if (newMinutes >= 60) {
      newHours += Math.floor(newMinutes / 60);
      newMinutes %= 60;
    }

    // Ensure hours stay within the 24-hour format
    newHours %= 24;

    // Format the new time
    const newStartTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;

    let newHours_1 = hours + 1; // Increment hours by 1
    let newMinutes_1 = minutes;

    // Adjust hours if necessary
    if (newHours_1 >= 24) {
      newHours_1 %= 24;
    }

    // Format the new time
    const newEndTime = `${String(newHours_1).padStart(2, '0')}:${String(newMinutes_1).padStart(2, '0')}`;

    // const startIndex = this.startTimes.findIndex(time => time === selectedStartTime);




    var start_time_data: any = [];
    const startIndex = this.startTimes.findIndex(time => time === endTime);
    //console.log(startIndex, 'startIndex')
    if (startIndex !== -1) {
      // Slice the end times array starting from the index of selected start time
      start_time_data = this.endTimes.slice(startIndex);
    }


    var endIndex_time_data: any = [];
    const endIndex = this.startTimes.findIndex(time => time === newStartTime);
    if (endIndex !== -1) {
      // Slice the end times array starting from the index of selected start time
      endIndex_time_data = this.endTimes.slice(endIndex);
    }


    const startTime = this.slots[day][this.slots[day].length - 1].start_time;


    const startIndex_1 = this.startTimes.findIndex(time => time === startTime);
    const endIndex_1 = this.endTimes.findIndex(time => time === endTime);
    if (startIndex_1 !== -1 && endIndex_1 !== -1) {
      // Include the data from startTime to endTime (inclusive)
      this.slots[day][this.slots[day].length - 1].end_time_data = this.endTimes.slice(startIndex_1, endIndex_1 + 1);
    }

    this.slots[day].push({ start_time: newStartTime, to_time: newEndTime, start_time_data: start_time_data, end_time_data: endIndex_time_data });




  }

  generateEndTimeOptions(day: string, index: number): string[] {
    const selectedStartTime = this.slots[day][index].start_time;
    const startIndex = this.startTimes.findIndex(time => time === selectedStartTime);
    if (startIndex !== -1) {
      // If the next index exists and it's not the last index
      if (index + 1 < this.slots[day].length) {
        const nextStartTime = this.slots[day][index + 1].start_time;
        const endIndex = this.endTimes.findIndex(time => time === nextStartTime);
        if (endIndex !== -1) {
          // Slice the end times array from the current index to the next start time index
          return this.endTimes.slice(startIndex, endIndex);
        }
      } else {
        // If the next index doesn't exist or it's the last index, return end times from the current start time index to the end
        return this.endTimes.slice(startIndex);
      }
    }
    return [];
  }
  convertTimeToSeconds(timeString: string): number {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 3600) + (minutes * 60);
  }

  onStartTimeChange(day: string, index: number): void {
    const newStartTime = this.slots[day][index].start_time;
    let msg = '';

    // Check if the new start time matches any existing start time for the given day
    //   const isStartTimePresent = this.slots[day].some((slot: any, i: number) => {
    //     if (i !== index) {
    //         const existingStartTime = new Date('1970-01-01T' + slot.start_time);
    //         const existingEndTime = new Date('1970-01-01T' + slot.to_time);
    //         const newStartTimeDate = new Date('1970-01-01T' + this.slots[day][index].start_time);

    //         // Check for overlap or conflict
    //         if ((newStartTimeDate >= existingStartTime && newStartTimeDate < existingEndTime) ||
    //             (existingStartTime >= newStartTimeDate && existingStartTime < newStartTimeDate)) {
    //             return true; // Conflict found
    //         }
    //     }
    //     return false;
    // });
    const isStartTimePresent = this.slots[day].some((slot: any, i: number) => {
      if (i !== index) {
        const existingStartTime = new Date('1970-01-01T' + slot.start_time);
        const existingEndTime = new Date('1970-01-01T' + slot.to_time);
        const newStartTimeDate = new Date('1970-01-01T' + this.slots[day][index].start_time);
        const newEndTimeDate = new Date('1970-01-01T' + this.slots[day][index].to_time);

        // Check if new start time is greater than existing start time and if it falls within the existing slot
        if ((newStartTimeDate == existingStartTime) || (newStartTimeDate > existingStartTime && newStartTimeDate < existingEndTime) || (newEndTimeDate < existingStartTime && newEndTimeDate > existingEndTime)) {
          return true; // Conflict found
        }

        // Check if start time of the selected slot is greater than the start time of any subsequent slot
        // if (newStartTimeDate > existingStartTime) {
        //   return true; // Conflict found
        // }
      }
      return false;
    });

    if (isStartTimePresent) {
      msg = 'start time should be later than previous timeslot';
      const endTime = this.slots[day][index].start_time; // Existing end time

      const [hours, minutes] = endTime.split(':').map(Number); // Split the time into hours and minutes
      let newHours = hours;
      let newMinutes = minutes + 30;

      // Adjust hours and minutes if necessary
      if (newMinutes >= 60) {
        newHours += Math.floor(newMinutes / 60);
        newMinutes %= 60;
      }

      // Ensure hours stay within the 24-hour format
      newHours %= 24;

      // Format the new time
      const newStartTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
      this.slots[day][index].to_time = newStartTime;
      this.slots[day][index].end_time_data = [];
      this.slots[day][index].end_time_data.push(newStartTime);

      // const endTime = this.slots[day][index].start_time;
      // this.slots[day][index].to_time = endTime;
      // //console.log(1)
      // this.slots[day][index].start_time = ''; // Clear the selected start time
      // this.slots[day][index].to_time = ''; // Clear the end time as well
    } else {
      msg = '';

      const endTime = this.slots[day][index].start_time; // Existing end time
      const [hours, minutes] = endTime.split(':').map(Number); // Split the time into hours and minutes
      let newHours = hours;
      let newMinutes = minutes + 30;

      // Adjust hours and minutes if necessary
      if (newMinutes >= 60) {
        newHours += Math.floor(newMinutes / 60);
        newMinutes %= 60;
      }

      // Ensure hours stay within the 24-hour format
      newHours %= 24;

      // Format the new time
      const newEndTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
      this.slots[day][index].to_time = newEndTime;

      const slot = this.slots[day][index];

      if (slot.end_time_data === undefined || !slot.end_time_data.includes(newEndTime)) {
        if (slot.end_time_data === undefined) {
          slot.end_time_data = [];
        }
        slot.end_time_data = [];

        slot.end_time_data.push(slot.start_time);
        slot.end_time_data.push(slot.to_time);
      }



    }

    // Update the error message in the HTML elements with the class "error_{day}_{index}"
    const elements = document.getElementsByClassName(`error_${day}_${index}`);
    for (let i = 0; i < elements.length; i++) {
      elements[i].innerHTML = msg;
    }
  }


  removeSlot(day: string, slot: any): void {
    const index = this.slots[day].indexOf(slot);

    // this.slots[day] = this.slots[day].filter((s: any) => s !== slot);
    // if (index > 0) {
    if (this.slots[day].length !== index + 1) {
      const prevSlot = this.slots[day][index - 1];
      if (prevSlot !== undefined) {
        const startIndex = this.startTimes.findIndex(time => time === prevSlot.start_time);
        const endIndex = this.startTimes.findIndex(time => time === slot.to_time);
        if (startIndex !== -1 && endIndex !== -1) {
          prevSlot.end_time_data = this.endTimes.slice(startIndex, endIndex + 1);
        }
      }
    } else {
      const prevSlot = this.slots[day][index - 1];
      if (prevSlot !== undefined) {
        const startIndex = this.startTimes.findIndex(time => time === prevSlot.start_time);
        if (startIndex !== -1) {
          prevSlot.end_time_data = this.endTimes.slice(startIndex);
        }
      }
    }
    this.slots[day] = this.slots[day].filter((s: any) => s !== slot);

    if (this.slots[day].length === 0) {
      this.toggleDaySelection(day)
    }
  }

  checkDuplicateTimes(data: any[]): boolean {
    // //console.log(data,'dayyyy');
    for (let i = 0; i < data.length; i++) {
      let currentStartTime = data[i].start_time;
      let currentEndTime = data[i].to_time;

      for (let j = 0; j < data.length; j++) {

        // Skip the current entry
        if (i !== j) {
          let startTime = data[j].start_time;
          let toTime = data[j].to_time;
          if ((currentStartTime == startTime) || (currentStartTime > startTime && currentStartTime < toTime) || (currentEndTime < startTime && currentEndTime > toTime)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  submitForm(): void {
    // this.resetErrors();
    this.loadShow()
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let hasErrors = false;
    let check_submit1 = true
    if (this.selectedDays.length == 0) {
      this.check_submit = false;
      // this.check_submit = true;
      check_submit1 = false
      // return;
    } else {
      for (const day in this.slots) {

        if (this.slots.hasOwnProperty(day)) {
          this.slots[day].forEach((slot: any) => {
            if (slot.start_time == '' || slot.to_time == '') {
              hasErrors = true;
            }
          });
          if (this.checkDuplicateTimes(this.slots[day])) {
            hasErrors = true;
          }
          // //console.log(this.slots[day])
        }
      }
    }
    if (!hasErrors) {
      // this.loadShow()
      let payloaddata: any = { slot: '' }
      let payload: { slot: { day: string, start_time: string, to_time: string }[] } = { slot: [] };
      if (check_submit1) {
        for (const day in this.slots) {
          if (this.slots.hasOwnProperty(day)) {
            this.slots[day].forEach((slot: any) => {
              if (slot.start_time && slot.to_time) {
                payload.slot.push({ day, start_time: slot.start_time, to_time: slot.to_time });
              }
            });
          }
        }
        payloaddata = payload
      }
      // return ;
      this.mentorService.slotAddEditMentor(payload).subscribe(resp => {
        if (resp.status == 200) {
          this.successMsg = resp.mesg;
          setTimeout(() => {
            this.successMsg = ''
          }, 5000);

          this.loadHide()
        }
      }, error => {
        this.loadHide()
      });
    }
  }


  slotModel() {
    this.slotList();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

}

