import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class QualificationService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/qualification-create', data);
  }

  getAllQualification(){
    return  this.base.get('/api/all-qualification');
  }

  getQualification(id){
    return this.base.post('/api/get-qualification',{id:id});
  }

  qualificationUpdate(data){
    return this.base.post('/api/qualification-update',data);
  }

  qualificationDelete(id){
    return this.base.post('/api/qualification-delete',{id:id});
  }
}
