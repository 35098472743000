import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AlertService, BannersService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";
import { DataTableDirective } from "angular-datatables";

class Banners {
  id: number;
  name: string;
  position: string;
  image_path: string;
  is_link: string;
  link_url: string;
  start_date: string;
  end_date: string;
  is_active: string;
  mobile_image: string;
}

@Component({
  selector: "app-banners",
  templateUrl: "./banners.component.html",
})
export class BannersComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [8], orderable: false }],
  };
  dtTrigger = new Subject();
  isSubmitted = false;
  croppedImage: any = "";
  mobileCroppedImage: any = "";
  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg, .jpeg, .png, .bmp";

  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  DisplayedText2: string = "";
  fileList: any;
  mobileFileList: any;
  pdf = false;
  exl = false;
  bannersList: Banners[] = []; // Table Data list

  // productAddForm: FormGroup;
  //randomnumber = Math.floor(Math.random() * 100000000);
  getBanners = {
    id: "",
    name: "",
    position: "",
    image_path: "",
    is_link: "",
    link_url: "",
    start_date: "",
    end_date: "",
    is_active: "",
    mobile_image: "",
  };
  closeResult: string; // Modal Close

  constructor(
    public router: Router,
    private dataService: BannersService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  banners = {
    id: "",
    name: "",
    position: "",
    image_path: "",
    is_link: "",
    link_url: "",
    start_date: "",
    end_date: "",
    is_active: "",
    mobile_image: "",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;
  positionChecked: string;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allBanners();
  }

  allBanners() {
    this.loadShow();
    this.dataService
      .getAllBanners()
      .pipe()
      .subscribe((data) => {
        this.bannersList = data["banners"];
        this.pdf = true;
        this.exl = true;
        this.rerender();
        this.loadHide();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  open(content) {
    this.croppedImage = "";
    this.mobileCroppedImage = "";

    this.isSubmitted = false;
    if (this.getBanners.id != null) {
      this.getBanners.id = "";
    }
    this.banners.id = "";
    this.banners.name = "";
    this.banners.position = "";
    this.banners.image_path = "";
    this.banners.is_link = "";
    this.banners.link_url = "";
    this.banners.start_date = "";
    this.banners.end_date = "";
    this.banners.mobile_image = "";
    this.banners.is_active = "Y";
    this.DisplayedText = "";
    this.modalTitle = "Add Banners";
    this.modalReference = this.modalService.open(content);
  }

  save(val, f) {
    //console.log("show");

    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.banners.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.loadShow();
    this.DisplayedText = "";
    this.DisplayedText2 = "";

    this.dataService.getBanners(id).subscribe((data) => {
      this.getBanners = data["banners"];
      //console.log(this.getBanners, "////////");

      if (this.getBanners.position == "TOP") {
        this.positionChecked = "checked";
      } else if (this.getBanners.position == "MIDDLE") {
        this.positionChecked = "checked";
      } else if (this.getBanners.position == "UPPER TOP") {
        this.positionChecked = "checked";
      } else {
        this.positionChecked = "";
      }

      this.croppedImage = this.getBanners.image_path || "";
      this.mobileCroppedImage = this.getBanners.mobile_image || "";

      this.banners = {
        id: this.getBanners.id,
        name: this.getBanners.name,
        position: this.getBanners.position,
        image_path: this.getBanners.image_path,
        is_link: this.getBanners.is_link,
        link_url: this.getBanners.link_url,
        start_date: this.getBanners.start_date,
        end_date: this.getBanners.end_date,
        is_active: this.getBanners.is_active,
        mobile_image: this.getBanners.mobile_image,
      };

      this.loadHide();
      this.modalTitle = "Edit Banners";
      this.modalReference = this.modalService.open(content);
    });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    let hasFile = this.fileList && this.fileList.length > 0;
    this.DisplayedText = this.fileList[0].name
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = ".jpg , .jpeg, .png , .bmp";

      // Validate file type
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "Please select a valid file type. Supported file types are .jpg, .jpeg, .png, .bmp"
        );
        this.fileList = null;
        return false;
      }

      // Validate file size
      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          // Check dimensions
          if (img.height > img.width) {
            alert(
              "Please select a banner image (width should be greater than height)."
            );
            this.fileList = null;
            this.DisplayedText = ""
            this.croppedImage = ""; // Reset the image preview
            event.target.value = ""; // Reset the file input
            return;
          } else {
            this.croppedImage = reader.result as string;
            this.banners.image_path = this.croppedImage;
          }
        };
      };
    }
    return false;
  }

  // onFileChange(event: any) {
  //   this.fileList = event.target.files;
  //   let hasFile = this.fileList && this.fileList.length > 0;
  //   if (hasFile) {
  //     var extension = this.fileList[0].name.substring(
  //       this.fileList[0].name.lastIndexOf(".")
  //     );
  //     var validFileType = this.fileType;
  //     if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
  //       alert(
  //         "please select valid file type. The supported file types are " +
  //           this.fileType
  //       );
  //       this.fileList = null;
  //       this.croppedImage = "";
  //       return false;
  //     }

  //     const reader = new FileReader();
  //     reader.readAsDataURL(this.fileList[0]);
  //     reader.onload = () => {
  //       this.croppedImage = reader.result as string;
  //       this.banners.image_path = this.croppedImage;
  //     };

  //     if (this.fileList[0].size > 5000000) {
  //       alert(`File size is more than 5 MB`);
  //       this.fileList = null;
  //       return false;
  //     }

  //     let multipleFile = this.fileList.length > 1;
  //     if (multipleFile) {
  //     } else {
  //       let file: File = this.fileList[0];
  //     }
  //   }
  //   return false;
  // }

  mobileImageChange(event: any) {
    this.mobileFileList = event.target.files;
    this.DisplayedText2 = this.fileList[0].name
     
    let hasFile = this.mobileFileList && this.mobileFileList.length > 0;
    if (hasFile) {
      var extension = this.mobileFileList[0].name.substring(
        this.mobileFileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.mobileFileList = null;
        this.mobileCroppedImage = "";
        this.DisplayedText2 = ""

        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.mobileFileList[0]);
      reader.onload = () => {
        this.mobileCroppedImage = reader.result as string;
        this.banners.mobile_image = this.mobileCroppedImage;
      };

      if (this.mobileFileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.mobileFileList = null;
        return false;
      }

      let multipleFile = this.mobileFileList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.mobileFileList[0];
      }
    }
    return false;
  }

  insertAction(val) {
    let formData: FormData = new FormData();
   

    formData.append("name", this.banners.name);
    formData.append("position", this.banners.position);
    formData.append("is_link", this.banners.is_link);
    formData.append("link_url", this.banners.link_url);
    formData.append("start_date", this.banners.start_date);
    formData.append("end_date", this.banners.end_date);
    formData.append("is_active", this.banners.is_active);

    if (val.id == "") {
      if (this.fileList !== undefined) {
        formData.append("image_path", this.croppedImage);
      }
  
      if (this.mobileFileList !== undefined) {
        formData.append("mobile_image", this.mobileCroppedImage);
      }

      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              this.alertService.success("Banners created successfully", true);
              this.modalReference.close();
              this.allBanners();
            } else if (data["status"] == 422) {
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.alertService.error(data.mesg);
              this.loadHide();
            } else {
              this.alertService.error(data.message);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.fileList && this.fileList.length > 0) {
        formData.append("image_path", this.croppedImage);
      } else {
        formData.append("image_path", "");
      }

      if (this.mobileFileList && this.mobileFileList.length > 0) {
        formData.append("mobile_image", this.mobileCroppedImage);
      } else {
        formData.append("mobile_image", "");
      }

      this.dataService
        .bannersUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              this.alertService.success("Banners updated successfully", true);
              this.modalReference.close();
              this.allBanners();
            } else if (data["status"] == 422) {
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.alertService.error(data.mesg);
            }
            this.loadHide();
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  // delete(id) {
  //   if (confirm("Are you sure to delete")) {
  //     this.dataService.bannersDelete(id)
  //       .pipe().subscribe(data => {
  //         if (data['status'] == 200) {
  //           this.alertService.success('Banners deleted successfully', true);
  //           this.allBanners();
  //         } else {
  //           this.alertService.error('Banners error');
  //         }
  //       }, error => {
  //         this.alertService.error(error);
  //       });
  //   }
  // }

  delete(item) {
    //console.log(item, "item");

    this.loadShow();
    this.dataService
      .bannersDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Banners deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allBanners();
          } else {
            this.alertService.error("Banners error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  pdfExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-pdf", "_blank");
  }

  xlExport() {
    window.open(appConfig.apiUrl + "/api/blog-list-excel", "_blank");
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
