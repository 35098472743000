
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Schemes</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{totalBlogList}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Scheme
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
					 <div id="table" class="table-responsive">
						<table datatable id="blog"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th >#Sl</th>
										<th style="width :30%">Title</th>
										<th>Category</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th >Status</th>
										<th>Action</th>
									</tr>
								</thead>
									<tbody *ngIf="blogList?.length != 0">
										<tr *ngFor="let item of blogList;let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td style="width : 30%">{{item.title}}</td>
										<td>{{item.category}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name==null || item.created_by_name=='' ">-</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span>
											<span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In
												active</span>
										</td>

										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="edit(this.blogList[i].id,content)"><i
													class="fa fa-edit"></i></button>
										 </td>
								 	</tr>
								</tbody>

								<tbody *ngIf="blogList?.length == 0">
									<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
								
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group ">
										<label for="title" class="col-form-label">Title<span>*</span></label>
										<div class="">
											<input class="form-control" type="title" id="title" name="title" required
												[(ngModel)]="blog.title">
											<span *ngIf="isSubmitted && isFieldsInvalid(blog.title)"
												class="alert-block">This field is required</span>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group ">
										<label for="category_ids" class=" form-control-label">Category
											<span>*</span></label>
										<div class="">
											<select class="form-control" id="category_ids" name="category_ids" required
												[(ngModel)]="blog.category_ids" (ngModelChange)="selectCat($event)">
												<option value="" selected disabled>Please Select</option>
												<option *ngFor="let item of cat" value="{{item.id}}">{{item.name}}
												</option>
											</select>
											<span
												*ngIf="isSubmitted && (blog.category_ids==undefined || blog.category_ids=='')"
												class="alert-block">This field is required</span>
										</div>
									</div>
								</div>

								<div class="col-md-6 ">
									<div class="form-group">
										<label for="sub_category_ids" class="form-control-label">Sub Category </label>
										<div class="">
											<select class="form-control" id="sub_category_ids" name="sub_category_ids"
												[(ngModel)]="blog.sub_category_ids">
												<option value="" selected>Please Select</option>
												<option *ngFor="let item of subCat" value="{{item.id}}">{{item.name}}
												</option>
											</select>
										</div>
									</div>
								</div>

								<div class="col-md-12">
									<div class="form-group ">
										<label for="short_description" class="col-form-label">Short Description
											<span>*</span></label>
										<div class="">
											<textarea class="form-control" type="text" id="short_description"
												name="short_description" required
												[(ngModel)]="blog.short_description"></textarea>
										</div>
										<span
											*ngIf="isSubmitted && isFieldsInvalid(blog.short_description)"
											class="alert-block">This field is required</span>
									</div>
							  </div>

								<div class="col-md-12">
									<div class="form-group ">
										<label for="description" class="col-form-label">Description
											<span>*</span></label>
										<div class="">
												<angular-editor [config]="configdescription" required id='description' [(ngModel)]="blog.description" name="description"></angular-editor>
										</div>
										<span *ngIf="isSubmitted && (blog.description==undefined || blog.description=='')"
											class="alert-block">This field is required</span>
									</div>
							  </div>

							<div class="form-group col-md-6 align-self-end">
									<label for="state" class="form-control-label">State <span>*</span></label>
									<div class="  ">
										<select class="form-control" id="state" name="states" required
											[(ngModel)]="blog.states" (ngModelChange)="selectCityById($event)">
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of states" value="{{item.id}}">{{item.state_name}}
											</option>states
										</select>
										<span *ngIf="isSubmitted && (blog.states==undefined || blog.states=='')"
											class="alert-block">This field is required</span>
									</div>
							 </div>


							 <div class="form-group col-md-6">
									<label for="city" class="form-control-label">City <span>*</span></label>
									<div class="  ">
										<select class="form-control" id="city" name="city" required
											[(ngModel)]="blog.city">
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of cities" value="{{item.id}}">{{item.city_name}}
											</option>
										</select>
										<span
											*ngIf="isSubmitted && (blog.city==undefined || blog.city=='')"
											class="alert-block">This field is required</span>
									  </div>
								</div>


							 <div class="col-md-6">
									<div class="form-group ">
										<label for="sectorofenterprise" class=" form-control-label">Sector of
											Enterprise<span>*</span></label>
										<div class="">
											<ng-select [items]="sectorofenterprise" name="sectorofenterprise"
												[multiple]="true" required bindLabel="name" bindValue="id"
												[(ngModel)]="selectedToEnterprise" (change)="changeToEnterprise()">
											</ng-select>
											<span
												*ngIf="isSubmitted && (blog.sectorofenterprise==undefined || blog.sectorofenterprise=='')"
												class="alert-block">This field is required</span>
										</div>
									</div>
								</div>


								<div class="col-md-6">
									<div class="form-group ">
										<label for="areaofinterest" class=" form-control-label">Area of
											Interest<span>*</span></label>
										<div class="">
											<ng-select [items]="areaofinterest" name="areaofinterest" [multiple]="true"
												bindLabel="name" bindValue="id" [(ngModel)]="selectedToInterest"
												(change)="changeToInterest()" required>
											</ng-select>
											<span
												*ngIf="isSubmitted && (blog.areaofinterest==undefined || blog.areaofinterest=='')"
												class="alert-block">This field is required</span>
										</div>
									</div>
								</div>


							 <div class="col-md-6">
									<div class="form-group ">
										<label for="areaofstudy" class=" form-control-label">Area of
											Study<span>*</span></label>
										<div class="">
											<ng-select [items]="areaofstudy" name="areaofstudy" [multiple]="true"
												bindLabel="name" bindValue="id" [(ngModel)]="selectedToStudy"
												(change)="changeToStudy()" required>
											</ng-select>
											<span
												*ngIf="isSubmitted && (blog.areaofstudy==undefined || blog.areaofstudy=='')"
												class="alert-block">This field is required</span>
										</div>
									</div>
							 </div>


								<div class="col-md-6">
									<div class="form-group">
										<label for="external_link" class=" col-form-label">External Link</label>
										<div class="">
											<input class="form-control" type="text" id="external_link"
												name="external_link" [(ngModel)]="blog.external_link">
										</div>
									</div>
								</div>


								<div class="col-md-6">
									<div class="form-group">
										<label for="meta_title" class=" col-form-label">Meta Title</label>
										<div class="">
											<input class="form-control" type="text" id="meta_title" name="meta_title"
												[(ngModel)]="blog.meta_title">
										</div>
									</div>
								</div>


								<div class="col-md-6 align-self-end">
									<div class="form-group">
										<label for="meta_keyword" class="col-form-label">Meta Keyword</label>
										<div class="">
											<input class="form-control" type="text" id="meta_keyword"
												name="meta_keyword" [(ngModel)]="blog.meta_keyword">
										</div>
									</div>
								</div>


								<div class="col-md-12">
									<div class="form-group">
										<label for="meta_description" class=" col-form-label">Meta Description</label>
										<div class="">
											<textarea class="form-control" type="text" id="meta_description"
												name="meta_description" [(ngModel)]="blog.meta_description"></textarea>
										</div>
									</div>
								</div>


								<div class="col-md-6">
									<div class="form-group">
										<label for="blog_type" class="col-form-label">Scheme Type
											<span>*</span></label>
										<select class="form-control" id="blog_type" required name="blog_type"
											[(ngModel)]="blog.blog_type" required>
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of blogType" value="{{item}}">{{item}}</option>
											<!-- <option value="GOV. SCHEME">GOV. SCHEME</option>
											<option value="CENTRAL GOV. SCHEME">CENTRAL GOV. SCHEME</option>
											<option value="OTHER SCHEME">OTHER SCHEME</option> -->
										</select>
										<span *ngIf="isSubmitted && (blog.blog_type==undefined || blog.blog_type=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label class=" form-control-label">Image <span>*</span></label>
										<div class="">
											<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
											  [src]="croppedImage" />
											<div class="input-group">
											  <span class="input-group-btn">
												<label class="btn btn-dark">
												  <samp >Browse</samp>...
												  <input type="file" accept="{{fileType}}" style="display: none;"
													(change)="onFileChange($event)" [attr.multiple]="allowMultiple">
												</label>
											  </span>
											  <input type="text" class="form-control" [value]="DisplayedText" disabled=""
												[class.ng-invalid-right]="required && !DisplayedText"
												[class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
											</div>
					  
											<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
											  <div class="modal-content">
												<h3 class="text-center mt-2">Image Cropper</h3>
												<div class="py-4 px-4">
												  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
													[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
													(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
													(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
												  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
													href="javascript::void(0)" (click)="exit($event); closeCropper()"
													style="margin-top: 10px;">Save</button>
												</div>
											  </div>
											</ng-template>
											<span *ngIf="isSubmitted && (blog.image==undefined || blog.image=='')" class="alert-block">This
											  field is required</span>
										  </div>
								  	</div>
								  </div>


								<div class="form-group col-md-6">
									<label for="home_display" class="col-form-label">Home Display <span>*</span></label>
									<div class="">
										<select class="form-control" id="home_display" required name="home_display"
											[(ngModel)]="blog.home_display">
											<option value="N">No</option>
											<option value="Y">Yes</option>
										</select>
									</div>
									<span *ngIf="isSubmitted && (blog.home_display==undefined || blog.home_display=='')"  class="alert-block">This field is required</span>
								</div>
								<div class="col-md-6 align-self-end">
									<div class="form-group">
										<label for="is_active" class="col-form-label">Status</label>
										<div class="">
											<select class="form-control" id="is_active" name="is_active"
												[(ngModel)]="blog.is_active">
												<option value="N">Inactive</option>
												<option value="Y">Active</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
								<button type="submit" class="btn btn-primary savebtn" (click)="save(this.blog,f)">Save</button>
			   		</div>
				  </div>
			  </div>
		</ng-template>

		<!-- <ng-template #bloginfo let-c="close" let-d="dismiss" id="customerinfo">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Blog Information</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-lg-12">
								<table class="table table-bordered">
									<tbody>
										<tr>
											<th>Title</th>
											<td>{{blogInfo.title}}</td>
										</tr>
										<tr>
											<th>Category</th>
											<td>{{blogInfo.category_ids}}</td>
										</tr>
										<tr>
											<th>Sub Category</th>
											<td>{{blogInfo.sub_category_ids}}</td>
										</tr>
										<tr>
											<th>Short Description</th>
											<td>{{blogInfo.short_description}}</td>
										</tr>
										<tr>
											<th>Description</th>
											<td>{{blogInfo.description}}</td>
										</tr>
										<tr>
											<th>External Link</th>
											<td>{{blogInfo.external_link}}</td>
										</tr>
										<tr>
											<th>Meta Title</th>
											<td>{{blogInfo.meta_title}}</td>
										</tr>
										<tr>
											<th>Meta Keyword</th>
											<td>{{blogInfo.meta_keyword}}</td>
										</tr>
										<tr>
											<th>Meta Description</th>
											<td>{{blogInfo.meta_description}}</td>
										</tr>
										<tr>
											<th>Image</th>
											<td><img style="height: 100px;width: 100px;" class="img-thumbnail"
													*ngIf="blogInfo.image !=''"
													[src]="'data:image/jpg;base64,'+blogInfo.image" /></td>
									     </tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template> -->
	</div>
</div>
