import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OtpLogsService {

  constructor(private base: BaseService) { }

  getOtpLogs() {
    return this.base.get('/api/otp-log');
  }
}
