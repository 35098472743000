<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12 headboxes">
      <div class="card p-0">
        <div class="card-block p-0">
          <div class="heading-box" style="background-image: url(assets/img/camp.png);">
            <div class="row align-items-center">
              <div class="col-md-3">
                <div class="pagename">
                  <h3 class="card-title">Partner Offering</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="total-count">
                  <h5 class="m-0">Total</h5>
                  <h2>{{partnerOfferingTotal ?  partnerOfferingTotal : 0}}</h2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="addbtn">
                  <button id="add_btn" type="button" class="btn btn-primary addbtn"
                    (click)="open(content)" style="float: right;">
                    Add Partner Offering
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
          <div id="table" class="table-responsive">
            <table datatable id="partneroffertable"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
              <thead>
                <tr>
                  <th style="width: 5%;">#Sl</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Verticals</th>
                  <th>Partner Name</th>
                  <th>Created Date</th>
                  <th style="width: 5%;">Status</th>
                  <th>Action</th>
                </tr>
              </thead>

        
              
              <tbody *ngIf="partnerOfferingList?.length != 0">
                <tr *ngFor="let item of partnerOfferingList; let i = index" [attr.data-index]="i">
                  <td>{{ getRowNumber(i) }}</td>
                  <td>{{item.title}}</td>
                  <td [innerHtml]="item.service_desc"></td>
                  <td>{{item.verticals_name}}</td>              
                  <td>{{item.partner_name}}</td>
                 
                  <td>{{item.created_at}}</td>
                  <td><span *ngIf="item.status=='Y'" class="badge badge-success badge-pill">Active</span>
                    <span *ngIf="item.status=='N'" class="badge badge-danger badge-pill">Inactive</span>
                  </td>

                  <td class="action">
                    <label class="switch">
                      <input 
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.status === 'Y'">
                      <span class="slider round"></span>
                    </label>
                    
                    <button type="button" class="btn text-dark btn-sm  rounded bg-light"
                      (click)="edit(this.partnerOfferingList[i].id,content)"><i
                        class="fa fa-edit"></i></button>
                    <!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
                      (click)="delete(this.partnerOfferingList[i].id)"><i
                        class="fa fa-trash"></i></button> -->
                    <!-- <ui-switch size="small" defaultBgColor="red" (change)="delete(this.partnerOfferingList[i].id)" *ngIf="item.is_active=='Y'" checked></ui-switch>
                    <ui-switch size="small" defaultBgColor="red" (change)="delete(this.partnerOfferingList[i].id)" *ngIf="item.is_active=='N'"></ui-switch> -->
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="partnerOfferingList?.length == 0">
								<tr>
								  <td colspan="7" class="no-data-available text-center">No data available!</td>
								</tr>
							</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #content let-c="close" let-d="dismiss" id="customer-add" class="">
    <!-- class="modal fade" -->
         <div class="modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{modalTitle}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <alert>
            <div class="alert alert-danger"> <a class="close">×</a></div>
          </alert>
          
          <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
            <div class="row">
            
            <div class="form-group col-md-6">
              <label for="title" class="  col-form-label">Title <span>*</span></label>
              <div class=" ">
                <input class="form-control" type="text" id="title" name="title" required [(ngModel)]="partner_offering.title" >
                <span *ngIf=" isSubmitted && isFieldsInvalid(partner_offering.title)" class="alert-block">This field is required</span>
                 </div>
              </div>

              <div class="form-group col-md-6">
                <label for="partner_name" class="  col-form-label">Partner Name <span>*</span></label>
                <div class=" ">
                  <input class="form-control" type="text" id="partner_name" name="partner_name" required [(ngModel)]="partner_offering.partner_name" >
                  <span *ngIf=" isSubmitted && isFieldsInvalid(partner_offering.partner_name)" class="alert-block">This field is required</span>
                   </div>
                </div>


                <div class="form-group col-md-6">
                  <label for="discount_text" class="  col-form-label">Discount <span>*</span></label>
                  <div class=" ">
                    <input class="form-control" type="text" id="discount_text" name="discount_text" required [(ngModel)]="partner_offering.discount_text" >
                    <span *ngIf=" isSubmitted && isFieldsInvalid(partner_offering.discount_text)" class="alert-block">This field is required</span>
                   </div>
                </div>

             

                  <div class="form-group col-md-6">
                    <label for="validity_text" class="  col-form-label">Validity <span>*</span></label>
                    <div class=" ">
                      <input class="form-control" type="text" id="validity_text" name="validity_text" required [(ngModel)]="partner_offering.validity_text" >
                      <span *ngIf=" isSubmitted && isFieldsInvalid(partner_offering.validity_text)" class="alert-block">This field is required</span>
                    </div>
                  </div>


                  <div class="form-group col-6">
                      <label for="verticals" class=" form-control-label">Verticals<span>*</span></label>
                      <div class="">
                        <ng-select [items]="verticals" name="verticals" [multiple]="true"
                          bindLabel="name" bindValue="id" [(ngModel)]="selectedToVerticals"
                          (change)="changeToVerticals()" required>
                        </ng-select>
                        <span *ngIf=" isSubmitted && (partner_offering.verticals==undefined || partner_offering.verticals=='')" class="alert-block">This field is required</span>
                      </div>
                    </div>
                 

                
                  <div class="form-group col-md-6">
                    <label for="is_home_visible" class="col-form-label">Home Display</label>
                    <div class="  ">
                      <select class="form-control" id="is_home_visible" name="is_home_visible"
                        [(ngModel)]="partner_offering.is_home_visible">
                        <option value="N">No</option>
                        <option value="Y">Yes</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="status" class="col-form-label">Status</label>
                    <div class=" ">
                      <select class="form-control" id="status" name="status" [(ngModel)]="partner_offering.status">
                        <option value="N">Inactive</option>
                        <option value="Y">Active</option>
                      </select>
                    </div>
                  </div>
                    
                  <div class="form-group col-md-6">
                    <label for="social_link" class="  col-form-label">Social Link <span>*</span></label>
                    <div class=" ">
                      <input class="form-control" type="text" id="social_link" name="social_link" required  pattern="https?://.+" [(ngModel)]="partner_offering.social_link" #socialLink="ngModel">
                      <span *ngIf=" isSubmitted && isFieldsInvalid(partner_offering.social_link)" class="alert-block">This field is required</span>
                      <span *ngIf="isSubmitted && socialLink?.errors?.pattern" class="alert-block">
                        Please enter a valid URL
                      </span>
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                      <label for="access_link" class="  col-form-label">Access Link <span>*</span></label>
                      <div class=" ">
                        <input class="form-control" type="text" id="access_link" name="access_link" required  pattern="https?://.+" [(ngModel)]="partner_offering.access_link" #accessLink="ngModel">
                        <span *ngIf=" isSubmitted && isFieldsInvalid(partner_offering.access_link)" class="alert-block">This field is required</span>
                        <span *ngIf="isSubmitted && accessLink?.errors?.pattern" class="alert-block">
                          Please enter a valid URL
                        </span>
                      </div>
                  </div>
         
                  <div class="form-group col-md-6">
                    <label class=" form-control-label">Image <span>*</span></label>
                    <div class="">
                    <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
                      [src]="croppedImage" />
                    <div class="input-group">
                      <span class="input-group-btn">
                      <label class="btn btn-dark">
                        <samp >Browse</samp>...
                        <input type="file" accept="{{fileType}}" style="display: none;"
                        (change)="onFileChange($event)" [required]="!isEditMode" [attr.multiple]="allowMultiple">
                      </label>
                      </span>
                      <input type="text" class="form-control" [value]="DisplayedText" disabled=""
                      [class.ng-invalid-right]="required && !DisplayedText"
                      [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                    </div>
                    
                    <span *ngIf="isSubmitted && !isEditMode && !fileList" class="alert-block">
                     This field is required
                    </span>
                    </div>
                  </div>

                  
                  <div class="form-group col-md-12">
                    <label for="access_email" class="col-form-label">Access/Redemption<span>*</span> </label>
                    <div class=" ">
      
                      <angular-editor required [config]="configbody" id='access_email' [(ngModel)]="partner_offering.access_email" name="access_email"></angular-editor>
      
                      <span *ngIf=" isSubmitted && (partner_offering.access_email==undefined || partner_offering.access_email=='')" class="alert-block">This field is required</span>
                    </div>
                  </div>


                  <div class="form-group col-md-12">
                    <label for="service_desc" class="col-form-label">Service Desc<span>*</span> </label>
                    <div class=" ">
      
                      <angular-editor required [config]="configbody"   id='service_desc' [(ngModel)]="partner_offering.service_desc" name="service_desc"></angular-editor>
      
                      <span *ngIf=" isSubmitted && (partner_offering.service_desc==undefined || partner_offering.service_desc=='')" class="alert-block">This field is required</span>
                    </div>
                  </div>
                  

                  <div class="form-group col-md-12">
                    <label for="who_is_service_for" class="col-form-label">Target audience for this service <span>*</span> </label>
                    <div class=" ">
      
                      <angular-editor required [config]="configbody"   id='who_is_service_for' [(ngModel)]="partner_offering.who_is_service_for" name="who_is_service_for"></angular-editor>
      
                      <span *ngIf=" isSubmitted && (partner_offering.who_is_service_for==undefined || partner_offering.who_is_service_for=='')" class="alert-block">This field is required</span>
                    </div>
                  </div>

                  
                  <div class="form-group col-md-12">
                    <label for="eligibility_condition" class="col-form-label">Eligibility Condition<span>*</span> </label>
                    <div class=" ">
      
                      <angular-editor required [config]="configbody"   id='eligibility_condition' [(ngModel)]="partner_offering.eligibility_condition" name="eligibility_condition"></angular-editor>
      
                      <span *ngIf=" isSubmitted && (partner_offering.eligibility_condition==undefined || partner_offering.eligibility_condition=='')" class="alert-block">This field is required</span>
                    </div>
                  </div>

                  
                  <div class="form-group col-md-12">
                    <label for="term_and_condition" class="col-form-label">Terms and Conditions <span>*</span> </label>
                    <div class=" ">
      
                      <angular-editor required [config]="configbody"   id='term_and_condition' [(ngModel)]="partner_offering.term_and_condition" name="term_and_condition"></angular-editor>
      
                      <span *ngIf=" isSubmitted && (partner_offering.term_and_condition==undefined || partner_offering.term_and_condition=='')" class="alert-block">This field is required</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
        
         <div class="modal-footer">
          <button type="button" class="btn btn-secondary d-none" aria-label="Close"
            (click)="d('Cross click')">Close</button>
            <button type="submit" class="btn btn-primary savebtn" (click)="save(this.partner_offering,f)">Save</button>
           </div>
            </div>
           </div>
          </ng-template>
         </div>
       </div>