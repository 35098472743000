import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class StateService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/state-create', data);
  }

  getAllCountry(){
    return  this.base.get('/api/all-country');
  }
  
  getAllState(){
    return  this.base.get('/api/all-state');
  }

  getState(id){
    return this.base.post('/api/get-state',{id:id});
  }

  stateUpdate(data){
    return this.base.post('/api/state-update',data);
  }

  stateDelete(id){
    return this.base.post('/api/state-delete',{id:id});
  }
  
}
