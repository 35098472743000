<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12 headboxes">
      <div class="card p-0">
        <div class="card-block p-0">
          <div class="heading-box" style="background-image: url(assets/img/camp.png);">
            <div class="row align-items-center">
              <div class="col-md-3">
                <div class="pagename">
                  <h3 class="card-title">Partner Offering</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="total-count">
                  <h5 class="m-0">Total</h5>
                  <h2>{{partnerOfferingTotal ?  partnerOfferingTotal : 0}}</h2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="addbtn">
                  <button id="add_btn" type="button" class="btn btn-primary addbtn"
                    (click)="open(content)" style="float: right;">
                    Add Partner Offering
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
          <div id="table" class="table-responsive">
            <table datatable id="partneroffertable"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
              <thead>
                <tr>
                  <th style="width: 5%;">#Sl</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Verticals</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Created Date</th>
                  <th style="width: 5%;">Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              
              <tbody *ngIf="partnerOfferingList?.length != 0">
                <tr *ngFor="let item of partnerOfferingList; let i = index" [attr.data-index]="i">
                  <td>{{ getRowNumber(i) }}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.description}}</td>
                  <td>{{item.verticals_name}}</td>              
                  <td>{{item.offer_start_date}}</td>
                  <td>{{item.offer_end_date}}</td>
                  <td>{{item.created_at}}</td>
                  <td><span *ngIf="item.status=='Y'" class="badge badge-success badge-pill">Active</span>
                    <span *ngIf="item.status=='N'" class="badge badge-danger badge-pill">Inactive</span>
                  </td>

                  <td class="action">
                    <label class="switch">
                      <input 
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.status === 'Y'">
                      <span class="slider round"></span>
                    </label>
                    
                    <button type="button" class="btn text-dark btn-sm  rounded bg-light"
                      (click)="edit(this.partnerOfferingList[i].id,content)"><i
                        class="fa fa-edit"></i></button>
                    <!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
                      (click)="delete(this.partnerOfferingList[i].id)"><i
                        class="fa fa-trash"></i></button> -->
                    <!-- <ui-switch size="small" defaultBgColor="red" (change)="delete(this.partnerOfferingList[i].id)" *ngIf="item.is_active=='Y'" checked></ui-switch>
                    <ui-switch size="small" defaultBgColor="red" (change)="delete(this.partnerOfferingList[i].id)" *ngIf="item.is_active=='N'"></ui-switch> -->
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="partnerOfferingList?.length == 0">
								<tr>
								  <td colspan="7" class="no-data-available text-center">No data available!</td>
								</tr>
							</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-c="close" let-d="dismiss" id="customer-add" class="">
    <!-- class="modal fade" -->
         <div class="modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{modalTitle}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <alert>
            <div class="alert alert-danger"> <a class="close">×</a></div>
          </alert>
          
          <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
            <div class="row">
            
            <div class="form-group col-md-6">
              <label for="title" class="  col-form-label">Title <span>*</span></label>
              <div class=" ">
                <input class="form-control" type="text" id="title" name="title" required [(ngModel)]="partner_offering.title" >
                <span *ngIf=" isSubmitted && (partner_offering.title==undefined || partner_offering.title=='')" class="alert-block">This field is required</span>
                 </div>
              </div>

              <!-- <div class="form-group col-md-6">
                <label for="offer_date" class="col-form-label">Offer Date <span>*</span></label>
                <div class="  ">
                  <input class="form-control" type="date" id="offer_date"
                    name="offer_date" required [(ngModel)]="partner_offering.offer_date">
                  <span *ngIf="isSubmitted && (partner_offering.offer_date==undefined || partner_offering.offer_date=='')"
                    class="alert-block">This field is required</span>
                 </div>
                </div> -->

                <div class="form-group col-6">
                  <label for="verticals" class=" col-form-label">Verticals<span>*</span></label>
                  <div class="">
                    <select class="form-control" id="verticals" name="verticals" required [(ngModel)]="partner_offering.verticals">
                      <option value="">Please Select</option>
                      <option *ngFor="let item of verticalArr" value="{{item.id}}">{{item.name}}</option>
                    </select>
                    <span *ngIf="isSubmitted && (partner_offering.verticals==undefined || partner_offering.verticals=='')" class="alert-block">This field is required</span>
                  </div>
                </div>


                <div class="form-group col-md-6">
                  <label for="offer_start_date" class="col-form-label">Start Date <span>*</span></label>
                  <div class="  ">
                    <input class="form-control" type="datetime-local" id="offer_start_date"
                      name="offer_start_date" (change)="validateDates()" required [(ngModel)]="partner_offering.offer_start_date">
                    <span *ngIf="isSubmitted && (partner_offering.offer_start_date==undefined || partner_offering.offer_start_date=='')"
                      class="alert-block">This field is required</span>
                   </div>
                  </div>

  
                  <div class="form-group col-md-6">
                    <label for="offer_end_date" class="col-form-label">End Date <span>*</span></label>
                    <div class="  ">
                      <input class="form-control" type="datetime-local" id="offer_end_date"
                        name="offer_end_date" (change)="validateDates()" required [(ngModel)]="partner_offering.offer_end_date">
                      <span *ngIf="isSubmitted && (partner_offering.offer_end_date==undefined || partner_offering.offer_end_date=='')"
                        class="alert-block">This field is required</span>
                         <span *ngIf="isEndDateInvalid" class="alert-block">End date cannot be earlier than the start date</span>
                     </div>
                  </div>

                  <div class="form-group col-md-6 align-self-end">
                    <label for="state" class="form-control-label">State <span>*</span></label>
                    <div class="  ">
                      <select class="form-control" id="state" name="states" required
                        [(ngModel)]="partner_offering.state" (ngModelChange)="selectCityById($event)">
                        <option value="" selected disabled>Please Select</option>
                        <option *ngFor="let item of states" value="{{item.id}}">{{item.state_name}}
                        </option>states
                      </select>
                      <span *ngIf="isSubmitted && (partner_offering.state==undefined || partner_offering.state=='')"
                        class="alert-block">This field is required</span>
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="city" class="form-control-label">City <span>*</span></label>
                    <div class="  ">
                      <select class="form-control" id="city" name="city" required
                        [(ngModel)]="partner_offering.city">
                        <option value="" selected disabled>Please Select</option>
                        <option *ngFor="let item of cities" value="{{item.id}}">{{item.city_name}}
                        </option>
                      </select>
                      <span
                        *ngIf="isSubmitted && (partner_offering.city==undefined || partner_offering.city=='')"
                        class="alert-block">This field is required</span>
                      </div>
                    </div>
              

                  <div class="form-group col-md-6">
                    <label for="is_home_visible" class="col-form-label">Home Display</label>
                    <div class="  ">
                      <select class="form-control" id="is_home_visible" name="is_home_visible"
                        [(ngModel)]="partner_offering.is_home_visible">
                        <option value="N">No</option>
                        <option value="Y">Yes</option>
                      </select>
                    </div>
                  </div>


                  <div class="form-group col-md-6">
                    <label for="status" class="col-form-label">Status</label>
                    <div class=" ">
                      <select class="form-control" id="status" name="status" [(ngModel)]="partner_offering.status">
                        <option value="N">Inactive</option>
                        <option value="Y">Active</option>
                      </select>
                    </div>
                  </div>

               
                  <div class="form-group col-md-6">
                    <label for="discount" class="  col-form-label">Discount <span>*</span></label>
                    <div class=" ">
                      <input class="form-control" type="number" id="discount" name="discount" required [(ngModel)]="partner_offering.discount" >
                      <span *ngIf=" isSubmitted && (partner_offering.discount==undefined || partner_offering.discount=='')" class="alert-block">This field is required</span>
                    </div>
                    </div>

                    
                  <div class="form-group col-md-6">
                    <label for="avail_offer_link" class="  col-form-label">Avail Offer Link <span>*</span></label>
                    <div class=" ">
                      <input class="form-control" type="text" id="avail_offer_link" name="avail_offer_link" required  pattern="https?://.+" [(ngModel)]="partner_offering.avail_offer_link" #availOfferLink="ngModel">
                      <span *ngIf=" isSubmitted && (partner_offering.avail_offer_link==undefined || partner_offering.avail_offer_link=='')" class="alert-block">This field is required</span>
                      <span *ngIf="isSubmitted && availOfferLink?.errors?.pattern" class="alert-block">
                        Please enter a valid URL
                      </span>
                    </div>
                    </div>

                    


                      <div class="form-group col-6">
                        <label for="description" class=" col-form-label">Description <span>*</span></label>
                        <div class="">
                          <textarea class="form-control" type="text" id="description" required name="description" [(ngModel)]="partner_offering.description"></textarea>
                        </div>
                        <span *ngIf="isSubmitted && (partner_offering.description==undefined || partner_offering.description=='')" class="alert-block">This field is required</span>
                      </div>
            
        
              <div class="col-md-6">
                <div class="form-group">
                  <label class=" form-control-label">Image <span>*</span></label>
                  <div class="">
                  <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
                    [src]="croppedImage" />
                  <div class="input-group">
                    <span class="input-group-btn">
                    <label class="btn btn-dark">
                      <samp >Browse</samp>...
                      <input type="file" accept="{{fileType}}" style="display: none;"
                      (change)="onFileChange($event)" [attr.multiple]="allowMultiple">
                    </label>
                    </span>
                    <input type="text" class="form-control" [value]="DisplayedText" disabled=""
                    [class.ng-invalid-right]="required && !DisplayedText"
                    [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                  </div>
                  
                  <span *ngIf="isSubmitted && (partner_offering.image==undefined || partner_offering.image=='')" class="alert-block">This
                    field is required</span>
                  </div>
                </div>
                </div>
            
            <div class="form-group col-md-12">
              <label for="key_highlights" class="col-form-label">Key Highlights  <span>*</span> </label>
              <div class=" ">

                <angular-editor required [config]="configbody"  #keyHighlights="ngModel" id='key_highlights' [(ngModel)]="partner_offering.key_highlights" name="key_highlights"></angular-editor>

                <span *ngIf=" isSubmitted && (partner_offering.key_highlights==undefined || partner_offering.key_highlights=='')" class="alert-block">This field is required</span>
              </div>
            </div>
            

            <div class="form-group col-md-12">
              <label for="what_you_will_learn" class="col-form-label">What you will learn  <span>*</span></label>
              <div class=" ">
         
                <angular-editor required [config]="configbody"  id='what_you_will_learn' [(ngModel)]="partner_offering.what_you_will_learn" name="what_you_will_learn"></angular-editor>
             
                <span *ngIf=" isSubmitted && (partner_offering.what_you_will_learn==undefined || partner_offering.what_you_will_learn=='')" class="alert-block">This field is required</span>
              </div>
            </div>


            <div class="form-group col-md-12">
              <label for="how_should_attend" class="col-form-label">How Should Attend  <span>*</span></label>
              <div class=" ">
         
                <angular-editor required [config]="configbody"  id='how_should_attend' [(ngModel)]="partner_offering.how_should_attend" name="how_should_attend"></angular-editor>

                <span *ngIf=" isSubmitted && (partner_offering.how_should_attend==undefined || partner_offering.how_should_attend=='')" class="alert-block">This field is required</span>
              </div>
            </div>
          </div>

           </form>
          </div>

         <div class="modal-footer">
          <button type="button" class="btn btn-secondary d-none" aria-label="Close"
            (click)="d('Cross click')">Close</button>
            <button type="submit" class="btn btn-primary savebtn" (click)="save(this.partner_offering,f)">Save</button>
           </div>
            </div>
           </div>
          </ng-template>
         </div>
       </div>