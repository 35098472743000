import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class SettingService {

    constructor(private base: BaseService) { }

    getSettingData() {
        return this.base.get_with_header('/api/get-setting-data');
    }
    settingUpdate(data){
    	return this.base.post_with_header('/api/setting-data-update',data);
    }

    
}