<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/banner.png);">
							<div class="row align-items-center">
								<div class="col-md-6">
									<div class="pagename">
										<h3 class="card-title">{{atrname }}</h3>
									</div>
								</div>
								<div class="col-md-6">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{(registeredListArr?.length > 0) ? registeredListArr.length : 0}}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

      <div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Name</th>
										<th>Email</th>
										<th>Mobile</th>
										<th>Gender</th>
                    <th>Username</th>
										<th>Age</th>
                    <th>PAN no.</th>
										<th>Country</th>
										<th>State</th>
										<th>City</th>
                    <th>Address</th>
										<th>Pincode</th>
                    <th>Designation</th>
									</tr>
								 </thead>
								<tbody>
									<tr *ngFor="let item of registeredListArr; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.first_name}}{{item.middle_name}}</td>
										<td>{{item.email}}</td>
										<td>{{item.mobile}}</td>
										<td>{{item.gender}}</td>
                    <td>{{item.user_name}}</td>
										<td>{{item.age}}</td>
										<td>{{item.pan_no}}</td>
                    <td>{{item.country}}</td>
										<td>{{item.state}}</td>
										<td>{{item.city}}</td>
                    <td>{{item.address}}</td>
										<td>{{item.pin_code}}</td>
										<td>{{item.designation}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</div>