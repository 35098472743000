<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12 headboxes">
      <div class="card p-0">
        <div class="card-block p-0">
          <div class="heading-box" style="background-image: url(assets/img/camp.png);">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="pagename">
                  <h3 class="card-title">Community Posts</h3>
                </div>
              </div>
              <div class="col-md-6">
                <div class="total-count">
                  <h5 class="m-0">Total</h5>
                  <h2>{{totalNewsFeeds ?  totalNewsFeeds : 0}}</h2>
                </div>
              </div>
             <!--  <div class="col-md-6">
                <div class="addbtn">
                  <button id="add_btn" type="button" class="btn btn-primary addbtn"
                    (click)="open(content)" style="float: right;">
                    Add Opinion Polls
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class="col-lg-12">
      <div class="card">
        <div class="card-block">

          <div id="table" class="table-responsive">

              <table datatable id="wecommunity"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
              <thead>
                <tr>
                  <th>#Sl</th>
                  <th>Title</th>
                  <!-- <th>Comment</th> -->
                  <th>Like Count</th>
                  <th>Comment Count</th>
                  <th>Report Post</th>
                  <th>Report User</th>
                  <th>Created At</th>
                  <!-- <th>Status</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="newsFeedList?.length != 0">
                <tr *ngFor="let item of newsFeedList;let i = index" [attr.data-index]="i">
                  <td>{{ getRowNumber(i) }}</td>
                  <td>{{item.title}}</td>
                  <!-- <td style="width: 15%">{{item.Comments}}</td> -->
                  <td>{{item.LikeCount}} <button *ngIf="item.LikeCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewLikes(item,viewlikes)"><i
                      class="fa fa-solid fa fa-list"></i></button>&nbsp;
                   </td>

                  <td>{{item.CommentCount}}&nbsp;
                    <button *ngIf="item.CommentCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewComments(item,viewcomments)"><i
                    class="fa fa-solid fa fa-list"></i></button></td>
                  <!-- <td>{{item.TagCategory}}</td> -->

                  <td>{{item.ReportCount}}&nbsp;
                    <button *ngIf="item?.ReportCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewReportPost(item,reportpost)"><i
                     class="fa fa-solid fa fa-list"></i></button>
                  </td>

                  <td>{{item.ReportUserCount}}&nbsp;
                    <button *ngIf="item?.ReportUserCount > 0" type="button" class="btn text-dark btn-sm  rounded bg-light"
                    (click)="onViewReportUser(item,reportuser)"><i
                     class="fa fa-solid fa fa-list"></i></button>
                  </td>
                  <td>{{item.created_date}}</td>

                 <!-- <td>
                    <span *ngIf="item.inactive =='Y'"
                      class="badge badge-success font-weight-normal badge-pill">Active</span>
                     <span *ngIf="item.inactive =='N'" class="badge badge-danger badge-pill">In
                      active</span>
                 </td> -->

                  <td class="action">
                   <label class="switch">
                      <input
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.inactive === 'Y'">
                      <span class="slider round"></span>
                     </label>
                   </td>

                <!--  <td
                    <button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(item,content)"><i class="fa fa-edit"></i></button> -->
                    <!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
                      (click)="delete(this.newsFeedList[i].id)"><i
                      class="fa fa-trash"></i></button>
                  </td> -->
                </tr>
              </tbody>
              <tbody *ngIf="newsFeedList?.length == 0">
                <tr>
                  <td colspan="4" class="no-data-available text-center">No data available!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


   <!--ViewLikes modal-->
   <ng-template #viewlikes let-c="close" let-d="dismiss" id="customer-add" class="">
    <div class="modal-info" role="document">
   <div class="modal-content">
    <div class="modal-header">
     <h4 class="modal-title">{{modalTitle}}</h4>
     <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
       <span aria-hidden="true">×</span>
     </button>
    </div>
   <div class="modal-body">
     <alert>
       <div class="alert alert-danger"> <a class="close">×</a></div>
     </alert>

     <div id="table" class="table-responsive">

      <table id="email_noti_table" datatable [dtOptions]="dtOptions" class="row-border hover">
       <thead class="bg-light">
         <tr>
           <th>#Sl</th>
           <th>Name</th>
         </tr>
       </thead>


       <tbody *ngIf="likeListArr?.length != 0">
         <tr *ngFor="let item of likeListArr;let i = index" [attr.data-index]="i">
           <td>{{i + 1}}</td>
            <td>{{item.authorName ?  item.authorName : '-'}}</td>
         </tr>
       </tbody>

       <tbody *ngIf="likeListArr?.length == 0">
        <tr>
          <td colspan="7" class="no-data-available text-center">No data available!</td>
        </tr>
      </tbody>
      </table>
     </div>
     </div>

    <div class="modal-footer">
     <button type="button" class="btn btn-secondary d-none" aria-label="Close"
       (click)="d('Cross click')">Close</button>

      </div>
       </div>
      </div>
</ng-template>

<!-- Report Post modal -->
<ng-template #reportpost let-c="close" let-d="dismiss" id="view-report" class="">
  <div class="modal-info" role="document">
<div class="modal-content">
 <div class="modal-header">
   <h4 class="modal-title">{{modalTitle}}</h4>
   <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
     <span aria-hidden="true">×</span>
   </button>
 </div>
 <div class="modal-body">
   <alert>
     <div class="alert alert-danger"> <a class="close">×</a></div>
   </alert>

   <div id="table" class="table-responsive">
    <table datatable id="view-report"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
    <thead class="bg-light">
      <tr>
        <th>#Sl</th>
        <th>Name</th>
        <th>Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of reportPostArr;let i = index" [attr.data-index]="i">
        <td>{{i + 1}}</td>
         <td>{{item.authorName}}</td>
         <td>{{item.report_reason ? item.report_reason :'-'}}</td>
      </tr>
    </tbody>
   </table>
  </div>
</div>

  <div class="modal-footer">
   <button type="button" class="btn btn-secondary d-none" aria-label="Close"
     (click)="d('Cross click')">Close</button>
    </div>
     </div>
    </div>
 </ng-template>


 <!-- Report User modal -->
<ng-template #reportuser let-c="close" let-d="dismiss" id="view-report" class="">
  <div class="modal-info" role="document">
<div class="modal-content">
 <div class="modal-header">
   <h4 class="modal-title">{{modalTitle}}</h4>
   <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
     <span aria-hidden="true">×</span>
   </button>
 </div>
 <div class="modal-body">
   <alert>
     <div class="alert alert-danger"> <a class="close">×</a></div>
   </alert>

   <div id="table" class="table-responsive">
    <table datatable id="view-report"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
    <thead class="bg-light">
      <tr>
        <th>#Sl</th>
        <th>Name</th>
        <th>Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of reportUserArr;let i = index" [attr.data-index]="i">
        <td>{{i + 1}}</td>
         <td>{{item.authorName}}</td>
         <td>{{item.report_reason ? item.report_reason :'-'}}</td>
      </tr>
    </tbody>
   </table>
  </div>
</div>

  <div class="modal-footer">
   <button type="button" class="btn btn-secondary d-none" aria-label="Close"
     (click)="d('Cross click')">Close</button>
    </div>
     </div>
    </div>
 </ng-template>

<!--ViewComments modal-->
<ng-template #viewcomments let-c="close" let-d="dismiss" id="customer-add" class="">
 <div class="modal-info" role="document">
<div class="modal-content">
<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <alert>
    <div class="alert alert-danger"> <a class="close">×</a></div>
  </alert>

  <div id="table" class="table-responsive">

   <table datatable id="view-comments"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table" width="100%">
   <thead class="bg-light">
     <tr>
       <th>#Sl</th>
       <th>Name</th>
       <th>Comment</th>
       <th>Action</th>

     </tr>
   </thead>
   <tbody>
     <tr *ngFor="let item of commentListArr;let i = index" [attr.data-index]="i">
       <td>{{i + 1}}</td>
        <td>{{item.authorName}}</td>
        <td>{{item.comments_text}}</td>
        <td class="action">
          <label class="switch">
             <input
               type="checkbox"
               (change)="deleteComment(item)"
               [checked]="item.isActive === 'Y'">
             <span class="slider round"></span>
            </label>
          </td>

     </tr>
   </tbody>
  </table>
 </div>
  </div>

 <div class="modal-footer">
  <button type="button" class="btn btn-secondary d-none" aria-label="Close"
    (click)="d('Cross click')">Close</button>

   </div>
    </div>
   </div>
</ng-template>

   </div>
   </div>
