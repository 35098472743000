import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';


@Injectable()
export class PurchaseService {

  constructor(private base: BaseService) { }

  save(data){
  	return this.base.post('/api/product-save', data);
  }

  getAllSupplier(){
    return this.base.get('/api/get-all-supplier-by-purchases');
  }

  getAllProduct()
  {
    return this.base.get('/api/get-all-purchasesProduct');
  }

  getCategoryByPurchases(id){
    return this.base.post('/api/get-productForPurchases',{productId:id});
  }

  createNewPurchase(data){
    return this.base.post('/api/create-new-purchase',data);
  }
}