<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12" style="margin: auto;">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title">Contact</h5>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
                            <div class="form-group row">
								<label for="address" class="col-md-12 col-form-label">Address <span>*</span></label>
								<div class="col-md-12">
									<input class="form-control" type="text" id="address" name="address" required [(ngModel)]="contact.address">
								</div>
							</div>
							<div class="form-group row">
								<label for="email" class="col-md-12 col-form-label">Email <span>*</span></label>
								<div class="col-md-12">
									<input class="form-control" type="text" id="email" name="email" required [(ngModel)]="contact.email">
								</div>
							</div>
							<div class="form-group row">
								<label for="lat" class="col-md-12 col-form-label">Latitude <span>*</span> </label>
								<div class="col-md-12">
									<input class="form-control" type="text" id="lat" name="lat" required [(ngModel)]="contact.lat">
								</div>
							</div>
							<div class="form-group row">
								<label for="long" class="col-md-12 col-form-label">Longitude <span>*</span></label>
								<div class="col-md-12">
									<input class="form-control" type="text" id="long" name="long" required [(ngModel)]="contact.long">
								</div>
							</div>  
							
								<button type="submit" class="btn btn-primary rounded savebtn" [disabled]="!f.form.valid" (click) ="Update()">Update</button>
							                    							
						</form>
					</div>
				</div>
			</div>
			<!--/.col-->
		</div>
	</div>
</div>


