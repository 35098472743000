import { Injectable, OnInit } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { FilesService, SelectedFiles } from "./files.service";
import { appConfig } from "../app.config";

@Injectable({
  providedIn: "root",
})
export class EncryptionService implements OnInit {
  selectedFiles: File[] = [];
  base64Files: string[] = [];
  public selectedFiles1: SelectedFiles[] = [];
  constructor(private files: FilesService) {}

  ngOnInit(): void {}

  public decrypt(response: string): string {
    var encrypted = atob(response);
    const parsedEncrypted = JSON.parse(encrypted);
    const iv = CryptoJS.enc.Base64.parse(parsedEncrypted.iv);
    const cryptoKey = CryptoJS.enc.Base64.parse(appConfig.secret_key);
    const decrypted = CryptoJS.AES.decrypt(parsedEncrypted.value, cryptoKey, {
      iv: iv,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public encrypt(response: string): string {
    //console.log(response, "response");
    const cryptoKey = CryptoJS.enc.Base64.parse(appConfig.secret_key);

    const iv = CryptoJS.lib.WordArray.random(16);

    const encryptedValue = CryptoJS.AES.encrypt(response, cryptoKey, {
      iv: iv,
    });
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const valueBase64 = encryptedValue.ciphertext.toString(CryptoJS.enc.Base64);
    const encryptedObject = {
      iv: ivBase64,
      value: valueBase64,
    };
    const encryptedString = JSON.stringify(encryptedObject);
    //console.log(btoa(encryptedString), "btoa");
    return btoa(encryptedString);
  }

  public formDataToObject(formData: FormData): string {
    const object: { [key: string]: any } = {};
    formData.forEach((value, key) => {
      if (object.hasOwnProperty(key)) {
        if (Array.isArray(object[key])) {
          object[key].push(value);
        } else {
          object[key] = [object[key], value];
        }
      } else {
        object[key] = value;
      }
    });
    return JSON.stringify(object);
  }

  // promises
  public async formDataToObject_file2(
    formData: FormData,
    additionalParams: { [key: string]: any } = {}
  ): Promise<string> {
    const object: { [key: string]: any } = { ...additionalParams };

    const filePromises = [];

    formData.forEach(async (value, key) => {
      if (value instanceof File) {
        const file = value as File;
        const reader = new FileReader();

        const promise = new Promise<void>((resolve) => {
          reader.onload = () => {
            if (object.hasOwnProperty(key)) {
              if (Array.isArray(object[key])) {
                object[key].push(reader.result);
              } else {
                object[key] = [object[key], reader.result];
              }
            } else {
              object[key] = reader.result;
            }
            resolve();
          };
        });

        reader.readAsDataURL(file);
        filePromises.push(promise);
      } else {
        if (object.hasOwnProperty(key)) {
          if (Array.isArray(object[key])) {
            object[key].push(value);
          } else {
            object[key] = [object[key], value];
          }
        } else {
          object[key] = value;
        }
      }
    });

    await Promise.all(filePromises);

    return JSON.stringify(object);
  }

  public formDataToObject_file(
    formData: FormData,
    additionalParams: { [key: string]: any } = {}
  ): any {
    const object: { [key: string]: any } = { ...additionalParams };

    formData.forEach((value, key) => {
      if (value instanceof File) {
        // Check if the value is a File object
        const file = value as File;

        // Synchronously read the file and convert to Base64
        const reader = new FileReader();
        reader.readAsDataURL(file);

        if (object.hasOwnProperty(key)) {
          if (Array.isArray(object[key])) {
            object[key].push(reader.result);
          } else {
            object[key] = [object[key], reader.result];
          }
        } else {
          object[key] = reader.result;
        }
      } else {
        if (object.hasOwnProperty(key)) {
          if (Array.isArray(object[key])) {
            object[key].push(value);
          } else {
            object[key] = [object[key], value];
          }
        } else {
          object[key] = value;
        }
      }
    });

    return object;
  }

  public formDataHasFile(formData) {
    let hasFile = false;

    formData.forEach((value) => {
      if (value.constructor === File) {
        hasFile = true;
      }
    });

    return hasFile;
  }
}
