import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class VerticalsService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/create-vertical', data);
  }

  getAllVertical(){
    return  this.base.get('/api/get-all-vertical');
  }

  editVertical(id){
    return this.base.post('/api/edit-vertical',{id:id});
  }

  verticalUpdate(data){
    return this.base.post('/api/update-vertical',data);
  }

  verticalDelete(id){
    return this.base.post('/api/delete-vertical',{id:id});
  }
}
