import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AlertService, MemberListService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../_services/encryption.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.sass']
})
export class InviteMembersComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; 
  dtTrigger = new Subject(); 
  inviteMemberList: any[] = []; 
  closeResult: string; 
  inviteMemberListTotal: any;
  start: number = 0;
  pageLength: number = 10;
  selectedFile: File;
  emailArray:any
  notMatchData:any
  msg :any
  is_already_exist_email_user:any=''
  is_already_invited_email:any=''
  is_email_validation:any=''
  is_already_exist_mobile_user:any=''
  is_already_invited_mobile:any=''
  is_mobile_validation:any=''

  constructor(public router: Router,
    private dataService:MemberListService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService) { }

    modalReference:NgbActiveModal;
    modalTitle:string;
    showloding = true;
    lodingImage = false;

  ngOnInit() {
    this.getAllInviteMember();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loadHide();
  }


  getAllInviteMember() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      order: [[4, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http.post<DataTablesResponse>(
          '/api/member-invitation-list',
          { 'req_data': this.enc_dec.encrypt(JSON.stringify(Object.assign(dataTablesParameters))) }, {}
        ).subscribe((response: any) => {
          const output = this.enc_dec.decrypt(response.res_data);
          const resp = JSON.parse(output);
          //console.log(resp, "get all invite member");

          this.inviteMemberList = resp['data'];
          this.inviteMemberListTotal = resp['recordsTotal'];

          callback({
            recordsTotal: resp['recordsTotal'],
            recordsFiltered: resp['recordsFiltered'],
            data: []
          });
        });
      },
      columns: [{ data: 'id' }, { data: 'first_name' }, { data: 'last_name' }, { data: 'email' }, { data: 'created_at' }, { data: 'action_date' }, { data: 'status' }]
    };
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }
  
  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  
  delete(id){
    if(confirm("Are you sure you want to active/inactive the member")) {
      this.dataService.deleteMember(id)
          .pipe().subscribe(data =>{
            if(data['status']==200){
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('Member status updated successfully', true);
         
            }else{
              this.alertService.error('Member error');
            }
          },error =>{
            this.alertService.error(error);
         });
      }
   }

      
 onCloseButtonClick(){
      this.modalService.dismissAll('Cross click');  
  }

  onModalOpen(content){
        const modalOptions: NgbModalOptions = {
          backdrop: 'static', 
          keyboard: false,   
        };

        this.modalTitle = "Invite members"; 
        this.modalReference = this.modalService.open(content,modalOptions);
   }

     
     onFileSelected(event): void {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.csv')) {
          this.selectedFile = file;
        } else {
          console.error('Please select a CSV file');
        }
      }
    
      convertToBase64(): void {
        if (!this.selectedFile) {
          console.error('No file selected');
          return;
        }
    
        const reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = () => {
          const base64String = reader.result.toString().split(',')[1];
          const excelUrl = `data:text/csv;base64,${base64String}`; 
          //console.log(excelUrl,"import"); 
          const data = {
            "image" : excelUrl
          }
          
          this.loadShow()
          this.dataService.importMemberCSV(data).subscribe((res:any)=>{
            if(res.status == 200){
              //console.log(res,"resssssss");
              const successMessage = res.mesg.replace('Member successfully Invited', '').trim(); 
              this.msg = successMessage;

               if(res.AlreadyExistEmailUserError.length > 0){
                  this.is_already_exist_email_user = res.AlreadyExistEmailUserError
               } 
                if(res.AlreadyInvitedEmailError.length > 0){
                this.is_already_invited_email = res.AlreadyInvitedEmailError
               }
                if(res.EmailvalidationError.length > 0){
                this.is_email_validation = res.EmailvalidationError
               } 
                if(res.AlreadyExistPhoneUserError.length > 0){
                this.is_already_exist_mobile_user = res.AlreadyExistPhoneUserError
               } 
                if(res.AlreadyInvitedPhoneError.length > 0){
                this.is_already_invited_mobile = res.AlreadyInvitedPhoneError
               }
                if(res.PhonevalidationError.length > 0){
                this.is_mobile_validation = res.PhonevalidationError
               } 
             this.loadHide();
             }
           })
          };
        reader.onerror = (error) => {
          console.error('Error occurred while reading the file:', error);
        };
      }
    }
