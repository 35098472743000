import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { DataTableDirective } from "angular-datatables";
import { AlertService, FaqService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularEditorConfig } from "@kolkov/angular-editor";

class Faq {
  id: number;
  faq_category: number;
  type: string;
  question: string;
  answer: string;
  is_active: string;
}

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
})
export class FaqComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [7], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable

  faqList: Faq[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getFaq = {
    id: "",
    faq_category: "",
    type: "",
    question: "",
    answer: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  cat: any[] = [];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: FaqService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}
  faq = {
    id: "",
    faq_category: "",
    type: "",
    question: "",
    answer: "",
    is_active: "Y",
  };
  showloding = true;
  lodingImage = true;
  ngOnInit() {
    this.allFaq();
    // this.dataService.getFaqCategory()
    //   .pipe().subscribe(data => { this.cat = data['faqcategory'];
    // });
    this.dataService
      .getBlogCategory()
      .pipe()
      .subscribe((data) => {
        this.cat = Array.isArray(data["cat"]) ? data["cat"] : [];
      });
  }

  allFaq() {
    this.loadShow();
    this.dataService
      .getAllFaq()
      .pipe()
      .subscribe((data) => {
        this.faqList = data["faq"];
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    this.faq.id = "";
    (this.faq.faq_category = ""),
      (this.faq.type = ""),
      (this.faq.question = ""),
      (this.faq.answer = ""),
      (this.faq.is_active = "Y");
    this.modalTitle = "Add FAQ";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getFaqById(id)
      .pipe()
      .subscribe((data) => {
        this.getFaq = data["faq"];
        this.faq = {
          id: this.getFaq.id,
          faq_category: this.getFaq.faq_category,
          type: this.getFaq.type,
          question: this.getFaq.question,
          answer: this.getFaq.answer,
          is_active: this.getFaq.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit FAQ";
        this.modalReference = this.modalService.open(content);
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.faq.question)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.faq)
          .pipe()
          .subscribe(
            (data) => {
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success("FAQ saved successfully", true);
              // this.isSubmitted = false;
              this.allFaq();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
            }
          );
      } else {
        this.dataService
          .faqUpdate(this.faq)
          .pipe()
          .subscribe(
            (data) => {
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success("FAQ updated successfully", true);
              // this.isSubmitted = false;
              this.allFaq();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
            }
          );
      }
      this.loadHide();
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .faqDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "FAQ active/inactive deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allFaq();
          } else {
            this.alertService.error("FAQ active/inactive error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  // delete(id){
  //     this.dataService.faqDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('FAQ active/inactive successfully', true);
  //             this.allFaq();
  //           }else{
  //             this.alertService.error('FAQ error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  // }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
