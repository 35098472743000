import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PublicationService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/publications-create', data);
  }

  publicationUpdate(data){
    return this.base.post('/api/publications-update',data);
  }

  publicationDelete(id){
    return this.base.post('/api/publications-delete',{id:id});
  }

  getAllPublication(){
    return  this.base.get('/api/get-publications');
  }

  getPublication(id){
    return this.base.post('/api/publications-edit',{id:id});
  }

}
