import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContactService {
    
    constructor(private base: BaseService) { }

    getContactData() {
        return this.base.get('/api/get-contact');
    }
    contactUpdate(data){
    	return this.base.post('/api/contact-update',data);
    }

    
}