<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
      <div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/enter.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Mentor Slots</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<!-- <h5 class="m-0">Total</h5> -->
										<!-- <h2>{{communityList?.length}}</h2> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


      <div class="col-lg-12">
				<div class="card">
					<div class="card-block">
            <div class="mento-profile py-3 ps-md-5">
      
              <div class="bg-white static-bg">
                <h5 *ngIf="successMsg" style="color:#dc6273" class="mb-3 text-center">{{successMsg}}</h5>
                <div class="set-week static-heading">
                  <h5 class="mb-4 ">Select your available Days</h5>

                  <div class="row ml-2">
    
                    <div class="col-md-1 col-3" *ngFor="let day of days">
                      <div class="form-group">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" [id]="day" [checked]="isDaySelected(day)"
                            (change)="toggleDaySelection(day)">
                          <label [for]="day" class="radio_label text-dark">{{ day }}</label>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-auto">
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" name="days" id="days2"
                                                    checked>
                                                <label for="days2" class="radio_label text-dark">Tue</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" name="days" id="days3">
                                                <label for="days3" class="radio_label text-dark">Wed</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" name="days" id="days4">
                                                <label for="days4" class="radio_label text-dark">Thr</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" name="days" id="days5">
                                                <label for="days5" class="radio_label text-dark">Fri</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" name="days" id="days6">
                                                <label for="days6" class="radio_label text-dark">Sat</label>
                                            </div>
                                        </div>
                                    </div> -->
                  </div>
                </div>
                <div class="text-danger" *ngIf="check_submit">Please select atleast one Slot.</div>
                <div class="selecttime static-body">
                  <div *ngFor="let day of days; let j = index">
                    <div class="time_tile" *ngIf="slots[day] && slots[day].length > 0">
                      <div class="day-text mb-3 d-flex bg-light p-2 justify-content-between align-items-center">
                        <span class="font-weight-bold">{{ day }}</span>
                        <button class="btn btn-primary addbtn" (click)="addSlot(day)">ADD <i class="fa fa-plus "
                            aria-hidden="true"></i></button>
                      </div>
                      <div class="set_time_value w-100">
    
                        <div class="row ml-1 margin-bt align-items-center"
                          *ngFor="let slot of getTimeSlots(day,j); let i = index">
                          <div class="col-md-3 col-5 px-1">
                            <div class="form-group">
                              <select class="form-control" [(ngModel)]="slot.start_time"
                                (change)="onStartTimeChange(day, i)">
                                <option *ngFor="let time of slot.start_time_data" [value]="time">{{ time }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-1 text-center px-0 px-md-1">
                            <p class="fw-bold mb-1 ">to</p>
                          </div>
                          <div class="col-md-3 col-5 px-1">
                            <div class="form-group">
                              <select class="form-control" [(ngModel)]="slot.to_time">
                                <option *ngFor="let time of slot.end_time_data" [value]="time">
                                  {{ time }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-1 text-center px-1">
                            <div class="time_set_button">
    
                              <button class="btn btn-outline-dangers border-0" (click)="removeSlot(day, slot)"><i
                                  class="fa fa-trash text-danger" aria-hidden="true"></i></button>
    
    
                            </div>
    
                          </div>
    
                        </div>
                        <div class="text-danger" *ngIf="checkDuplicateTimes(slots[day])">Start time
                          should be later than previous timeslot</div>                     
                      </div>
                    </div>
                  </div>
                </div>
    
                <div class="">
                  <button class="btn btn-primary addbtn" (click)="submitForm()">SUBMIT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
   
  </div>