import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AlertService, MentorListService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-mentors',
  templateUrl: './mentors.component.html',
  // styleUrls: ['./mentors.component.sass']
})
export class MentorsComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable

  mentorList: any[] = []; // Table Data list
  closeResult: string; // Modal Close

  mentorInfo = {
    type:'',
    organization_name:'',
    age_of_organization:'',
    first_name:'',
    last_name:'',
    email: '',
    mobile:'',
    type_of_organization:'',
    birth_yr:'',
    gender:'',
    address:'',
    country: '',
    state:'',
    state_name:'',
    city:'',
    city_name:'',
    qualification:'',
    qualification_name:'',
    spoken_lang: '',
    spoken_lang_names:'',
    primary_industry:'',
    primary_industry_name:'',
    stage_of_enterprise:'',
    stage_of_enterprise_names:'',
    industry_experience:'',
    industry_experience_name: '',
    area_of_functional_expertise:'',
    area_of_functional_expertise_name:'',
    provide_mentoring:'',
    kind_of_mentoring_name:'',
    preferred_track_of_mentorship:'',
    track_of_mentorship_names:'',
    ecosystem_key: '',
    ecosystem_key_names:'',
    preference_across:'',
    preference_across_name:'',
    mode_of_engagement:'',
    mode_of_engagement_name:'',
    willing_no_of_mentees:'',
    willing_no_of_mentees_name: '',
    no_of_hours_per_month:'',
    no_of_hours_per_month_name:'',
    linkedin_profile_url:''
  };

  constructor(
              public router: Router,
              private dataService:MentorListService,
              private alertService: AlertService,
              private AppService: AppService,
              private modalService: NgbModal
            ) { }
    
  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;
  ngOnInit() {
    this.allMentor();
  }

  allMentor(){
    this.loadShow();
    this.dataService.getAllMentor()
        .pipe().subscribe(
          data => { this.mentorList = data['mentors'];
          //console.log(data['mentors']);
          // this.dtTrigger.next(); // Data Table          
          this.rerender();
          this.loadHide();
        });
     }

  openMentorInfoMdalOrg(id,mentorinfo_org){
    this.loadShow();
    this.dataService.getMentorInfo(id)
        .pipe().subscribe(data => {
              let mentors = data['data'];
              this.mentorInfo = {
                type:mentors.type,
                organization_name:mentors.organization_name,
                age_of_organization:mentors.age_of_organization,
                first_name:mentors.first_name,
                last_name: mentors.last_name,
                email:mentors.email,
                mobile:mentors.mobile,
                type_of_organization:mentors.type_of_organization,
                birth_yr:mentors.birth_yr,
                gender: mentors.gender,
                address:mentors.address,
                country:mentors.country,
                state:mentors.state,
                state_name:mentors.state_name,
                city: mentors.city,
                city_name:mentors.city_name,
                qualification:mentors.qualification,
                qualification_name:mentors.qualification_name,
                spoken_lang:mentors.spoken_lang,
                spoken_lang_names: mentors.spoken_lang_names,
                primary_industry:mentors.primary_industry,
                primary_industry_name:mentors.primary_industry_name,
                stage_of_enterprise:mentors.stage_of_enterprise,
                stage_of_enterprise_names:mentors.stage_of_enterprise_names,
                industry_experience: mentors.industry_experience,
                industry_experience_name: mentors.industry_experience_name,
                area_of_functional_expertise: mentors.area_of_functional_expertise,
                area_of_functional_expertise_name: mentors.area_of_functional_expertise_name,
                provide_mentoring: mentors.provide_mentoring,
                kind_of_mentoring_name: mentors.kind_of_mentoring_name,
                preferred_track_of_mentorship: mentors.preferred_track_of_mentorship,
                track_of_mentorship_names: mentors.track_of_mentorship_names,
                ecosystem_key: mentors.ecosystem_key,
                ecosystem_key_names: mentors.ecosystem_key_names,
                preference_across: mentors.preference_across,
                preference_across_name: mentors.preference_across_name,
                mode_of_engagement: mentors.mode_of_engagement,
                mode_of_engagement_name: mentors.mode_of_engagement_name,
                willing_no_of_mentees: mentors.willing_no_of_mentees,
                willing_no_of_mentees_name: mentors.willing_no_of_mentees_name,
                no_of_hours_per_month: mentors.no_of_hours_per_month,
                no_of_hours_per_month_name: mentors.no_of_hours_per_month_name,
                linkedin_profile_url: mentors.linkedin_profile_url
              };
        });
        this.loadHide();

        this.modalReference = this.modalService.open(mentorinfo_org);
     }
  
  openMentorInfoMdalInd(id,mentorinfo_ind){
    this.loadShow();
    this.dataService.getMentorInfo(id)
        .pipe().subscribe(data => {
              let mentors = data['data'];
              this.mentorInfo = {
                type:mentors.type,
                organization_name:mentors.organization_name,
                age_of_organization:mentors.age_of_organization,
                first_name:mentors.first_name,
                last_name: mentors.last_name,
                email:mentors.email,
                mobile:mentors.mobile,
                type_of_organization:mentors.type_of_organization,
                birth_yr:mentors.birth_yr,
                gender: mentors.gender,
                address:mentors.address,
                country:mentors.country,
                state:mentors.state,
                state_name:mentors.state_name,
                city: mentors.city,
                city_name:mentors.city_name,
                qualification:mentors.qualification,
                qualification_name:mentors.qualification_name,
                spoken_lang:mentors.spoken_lang,
                spoken_lang_names: mentors.spoken_lang_names,
                primary_industry:mentors.primary_industry,
                primary_industry_name:mentors.primary_industry_name,
                stage_of_enterprise:mentors.stage_of_enterprise,
                stage_of_enterprise_names:mentors.stage_of_enterprise_names,
                industry_experience: mentors.industry_experience,
                industry_experience_name: mentors.industry_experience_name,
                area_of_functional_expertise: mentors.area_of_functional_expertise,
                area_of_functional_expertise_name: mentors.area_of_functional_expertise_name,
                provide_mentoring: mentors.provide_mentoring,
                kind_of_mentoring_name: mentors.kind_of_mentoring_name,
                preferred_track_of_mentorship: mentors.preferred_track_of_mentorship,
                track_of_mentorship_names: mentors.track_of_mentorship_names,
                ecosystem_key: mentors.ecosystem_key,
                ecosystem_key_names: mentors.ecosystem_key_names,
                preference_across: mentors.preference_across,
                preference_across_name: mentors.preference_across_name,
                mode_of_engagement: mentors.mode_of_engagement,
                mode_of_engagement_name: mentors.mode_of_engagement_name,
                willing_no_of_mentees: mentors.willing_no_of_mentees,
                willing_no_of_mentees_name: mentors.willing_no_of_mentees_name,
                no_of_hours_per_month: mentors.no_of_hours_per_month,
                no_of_hours_per_month_name: mentors.no_of_hours_per_month_name,
                linkedin_profile_url: mentors.linkedin_profile_url
              };
        });
        this.loadHide();

        this.modalReference = this.modalService.open(mentorinfo_ind);
  }
  
  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
