import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AreaOfStudyService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/create-area-of-study', data);
  }

  getAllAreaOfStudy(){
    return  this.base.get('/api/get-all-area-of-study');
  }

  editAreaOfStudy(id){
    return this.base.post('/api/edit-area-of-study',{id:id});
  }

  areaOfStudyUpdate(data){
    return this.base.post('/api/update-area-of-study',data);
  }

  areaOfStudyDelete(id){
    return this.base.post('/api/delete-area-of-study',{id:id});
  }
}
