import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {

  constructor(private base: BaseService) { }

  totalCount(){
    return  this.base.get('/api/get-all-total-count');
    // return  this.base.get('/api/get-all-total-count-v1');
  }
  totalCountV1(){
    return  this.base.get('/api/get-all-total-count-v1');
  }

  getChartData(){
    return  this.base.get('/api/get-chart-data');
  }

  sumAllData(){
    return this.base.get('/api/get-all-dashboard-data');
  }

  getLastProduct(){
    return this.base.get('/api/get-latestProduct');
  }

  filterByDate(data){
    return this.base.post('/api/filter-by-date-user-by-state',data);
  }

}
