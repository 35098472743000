import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { EncryptionService } from './encryption.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    headers = new HttpHeaders().set('X-API-KEY', 'wepu4ff4f8fufh3ehdehd');

    constructor(private http: BaseService,public router: Router, private encryptionService: EncryptionService) { }

    login(data) {
        return this.http.post_with_header('/api/user-login', data)
            .pipe(map((response:any) => {

                // response = this.encryptionService.decrypt(response.res_data);
                // login successful if there's a jwt token in the response
                const tokens = response.token ?response.token: '';

                if (tokens != '') {
                    localStorage.setItem('currentUser', JSON.stringify(response));
                 }
                return response;
            }));
        }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
    }
}
