import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import {
  AlertService,
  QualificationService,
  AppService,
} from "../_services/index";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { sequence } from "@angular/animations";

class Qualification {
  id: number;
  name: string;
  description: string;
  is_active: string;
  sequence: number;
}

@Component({
  selector: "app-qualification",
  templateUrl: "./qualification.component.html",
})
export class QualificationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [7], orderable: false }],
  };
  dtTrigger = new Subject();
  qualificationList: Qualification[] = []; // Table Data list
  isSubmitted = false;
  is_sequence: any;
  // countryAddForm: FormGroup;
  getQualification = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
    sequence: "",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;

  constructor(
    public router: Router,
    private dataService: QualificationService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  qualification = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
    sequence: "",
  };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allQualification();
  }

  allQualification() {
    this.loadShow();
    this.dataService
      .getAllQualification()
      .pipe()
      .subscribe((data) => {
        this.qualificationList = data["qualification"];
        //console.log(this.qualificationList, "qualificationList");

        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    if (this.getQualification.id != null) {
      this.getQualification.id = "";
    }
    this.isSubmitted = false;
    (this.qualification.name = ""),
      (this.qualification.description = ""),
      (this.qualification.is_active = "Y");
    this.modalTitle = "Add Qualification";
    this.qualification.sequence = "";
    this.modalReference = this.modalService.open(content);
  }

  preventEKey(event: KeyboardEvent) {
    const invalidKeys = ["e", "E"];
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getQualification(id)
      .pipe()
      .subscribe((data) => {
        this.getQualification = data["qualification"];
        this.qualification = {
          id: this.getQualification.id,
          name: this.getQualification.name,
          description: this.getQualification.description,
          is_active: this.getQualification.is_active,
          sequence: this.getQualification.sequence,
        };
        this.loadHide();
        this.modalTitle = "Edit Qualification";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.qualification.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.qualification)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Qualification saved successfully",
                  true
                );
                this.allQualification();
              } else if (data["status"] == 422) {
                this.loadHide();
                this.alertService.error(data.mesg.name);
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
              }
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);

              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .qualificationUpdate(this.qualification)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] == 200) {
                this.loadHide();
                this.modalReference.close();
                this.alertService.success(
                  "Qualification updated successfully",
                  true
                );
                this.allQualification();
              } else if (data["status"] == 300) {
                document
                  .querySelector(".modal")
                  .scrollTo({ top: 0, behavior: "smooth" });
                this.alertService.error(data.mesg);
                this.loadHide();
              }
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.qualificationDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Qualification deleted successfully', true);
  //             this.allQualification();
  //           }else{
  //             this.alertService.error('Qualification error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //   }
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .qualificationDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Qualification deleted successfully",
              true
            );
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allQualification();
          } else {
            this.alertService.error("Qualification error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
