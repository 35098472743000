import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';


@Injectable()
export class PurchasesDetailsService {

  constructor(private base: BaseService) { }

  getPurchasesDetails(val){
    return this.base.post('/api/get-purchases-details',{id:val});
  }
}