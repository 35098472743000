import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private base :BaseService) { }

  getAllCountry(){
    return  this.base.get('/api/admin/country/all-country');
  }


  getAllState(){
    return  this.base.get('/api/admin/state/all-indian-state');
  }

  getAllCity(){
    return  this.base.get('/api/admin/city/all-city');
  }

  getCityById(id:any){
    return this.base.post('/api/admin/city/all-city-by-id',{id:id});
  }
}

