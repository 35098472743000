import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class StageOfBusinessService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/stageofbusiness-create', data);
  }

  getAllStageofbusiness(){
    return  this.base.get('/api/all-stageofbusiness');
  }

  getStageofbusiness(id){
    return this.base.post('/api/get-stageofbusiness',{id:id});
  }

  stageofbusinessUpdate(data){
    return this.base.post('/api/stageofbusiness-update',data);
  }

  stageofbusinessDelete(id){
    return this.base.post('/api/stageofbusiness-delete',{id:id});
  }
}
