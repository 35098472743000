import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { appConfig } from '../app.config';
import { AlertService, WtiAwardService,AppService } from '../_services/index';

@Component({
  selector: 'app-wti-award',
  templateUrl: './wti-award.component.html',
  styles: []
})
export class WtiAwardComponent implements OnInit {

  @Input() allowMultiple: boolean;
  @Input() fileType = ".mp4";
  @Input() fileType1 = ".jpg , .png , .bmp";
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList:any;
  fileList1:any;

  // settingAddForm: FormGroup;
	wtiaward = {
    id:'',
    wti_award:'',
    video_type: '',
    videos: '',    
    video_thumb: '',
    is_active : ''
  };

constructor(
          public router: Router,
          private dataService:WtiAwardService,
          private alertService: AlertService,
          private AppService: AppService,
         ) { }
showloding = true;
lodingImage = false;
ngOnInit() {
  this.wtiAwardData();
}

wtiAwardData(){
    this.loadShow();
    this.dataService.getAllWtiAward()
        .pipe().subscribe(data => {
            let wtiaward = data['wti_award'][0];
            let video_urls;
            if(wtiaward.video_type=='1'){
              video_urls = wtiaward.videos;
            }else{
              video_urls = appConfig.apiUrl+'/'+wtiaward.videos;
            }
            ////console.log(wtiaward);
            this.wtiaward = {
              id:wtiaward.id,
              wti_award:wtiaward.wti_award,                
              video_type:wtiaward.video_type,
              videos:video_urls,
              video_thumb:appConfig.apiUrl+'/'+wtiaward.video_thumb,
              is_active:wtiaward.is_active
            }
            this.loadHide();         
        });
  }  

  Update(){
    this.insertAction();
  }

insertAction(){
  this.loadShow();
  let formData:FormData = new FormData();

  if(this.fileList !=undefined){
    let file: File = this.fileList[0];
    formData.append('videos', file, file.name);
  } else {
    formData.append('videos', this.wtiaward.videos);
  }

  if(this.fileList1 !=undefined){
    let file1: File = this.fileList1[0];
    formData.append('video_thumb', file1, file1.name);
  }
  
  formData.append('id', this.wtiaward.id);
  formData.append('wti_award', this.wtiaward.wti_award);
  formData.append('video_type', this.wtiaward.video_type);  
  // formData.append('videos', this.wtiaward.videos);
  // formData.append('video_thumb', this.wtiaward.video_thumb);
  formData.append('is_active', this.wtiaward.is_active);

  this.dataService.wtiAwardUpdate(formData)
  .pipe().subscribe(data =>{
        this.alertService.success('Wti award updated successfully', true);
        this.wtiAwardData();
        this.loadHide();
      },error =>{
        this.alertService.error(error);
        this.loadHide();
  });
}

fileChange(event: any) {
  this.fileList = event.target.files;
  // let filetypeToCompare = this.fileType.replace('*','');
  let hasFile = this.fileList && this.fileList.length > 0;
  if (hasFile) {
      var extension = this.fileList[0].name.substring(this.fileList[0].name.lastIndexOf('.'));
      // Only process image files.
      var validFileType = ".mp4";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
          alert("please select valid file type. The supported file types are .mp4");
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }
 
      if (this.fileList[0].size > 5000000) {
          alert(`File size is more than 5 MB`);
          this.fileList = null;
          this.DisplayedText = "";
          return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
          this.DisplayedText = this.fileList.length + ' file(s) has been selected';
      }
      else {
          let file: File = this.fileList[0];
          this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList);
  }
}

fileChange1(event: any) {
  this.fileList1 = event.target.files;
  // let filetypeToCompare = this.fileType.replace('*','');
  let hasFile = this.fileList1 && this.fileList1.length > 0;
  if (hasFile) {
      var extension = this.fileList1[0].name.substring(this.fileList1[0].name.lastIndexOf('.'));
      // Only process image files.
      var validFileType = ".jpg , .png , .bmp";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
          alert("please select valid file type. The supported file types are .jpg , .png , .bmp");
        this.fileList1 = null;
        this.DisplayedText = "";
        return false;
      }
 
      if (this.fileList1[0].size > 1000000) {
          alert(`File size is more than 1 Mb`);
          this.fileList1 = null;
          this.DisplayedText = "";
          return false;
      }

      let multipleFile = this.fileList1.length > 1;
      if (multipleFile) {
          this.DisplayedText = this.fileList1.length + ' file(s) has been selected';
      }
      else {
          let file: File = this.fileList1[0];
          this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList1);
  }
}

loadShow(){
  this.showloding = true;
  this.lodingImage = false;
}

loadHide(){
    this.showloding = false;
  this.lodingImage = true;
}

}
