<div class="container">
    <div class="row justify-content-end align-items-center mb-3">
        <div class="col-md-12">
            <div class="d-flex justify-content-end">
                <div class="date-selector">
                    <label class="mb-0" for="from-month">From:</label>
                    <div class="bg-white pr-2" style="border-radius: 8px;">
                        <select id="from-month" name="from-month">
                            <option value>Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <select id="from-year" name="from-year">
                            <option value>Select Year</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                    </div>
 
                    <label class="mb-0" for="to-month">To:</label>
                    <div class="bg-white pr-2" style="border-radius: 8px;">
                        <select id="to-month" name="to-month">
                            <option value>Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <select id="to-year" name="to-year">
                            <option value>Select Year</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                    </div>
                </div>
                <button class="btn btn-date">Submit</button>
            </div>
        </div>
    </div>
 

    <!-- bar and line graph -->
    <div class="row">
        <div class="col-md-6">
            <div class="card p-3 chart-container position-relative">
                <p>Registration Frequency</p>
                <div style="display: flex;justify-content: space-around;">
                    <div class="chart-container"
                      style="height:300px; width: 600px;">
                      <app-scroll-bar [step]="8" [axis]="'x'">
                        <canvas
                          baseChart
                          [datasets]="barChartData"
                          [labels]="barChartLabels"
                          [options]="barChartOptions"
                          [plugins]="barChartPlugins"
                          [legend]="barChartLegend"
                          [chartType]="'bar'"
                        >
                        </canvas>
                      </app-scroll-bar>
                    </div>
                  </div>
                <!-- Loader -->
                <div class="loader-overlay">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card p-3 chart-container position-relative">
                <p>Login Frequency</p>
                <div style="display: flex;justify-content: space-around;">
                    <div class="chart-container"
                      style="height:300px; width: 600px;">
                        <app-scroll-bar [step]="6" [axis]="'x'" >
                        <canvas baseChart
                            [datasets]="chartDataL1"
                            [labels]="chartLabelsL1"
                            [options]="chartOptionsL1"
                            [legend]="chartLegendL1"
                            [chartType]="chartTypeL1"
                            [colors]="chartColorsL1">
                        </canvas>
                    </app-scroll-bar>
                    </div>
                </div>
                <!-- Loader -->
                <div class="loader-overlay">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- 4 tiles -->
    <div class="row">
        <div class="col-md-6">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block">Number of Connection Request
                            Sent by Mentee</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-1">15</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block">Number of Connection Accepted by
                            Mentor</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-2">10</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block">Number of Meeting Request Sent
                            by Mentee</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-1">12</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block">Number of Meeting Accepted by
                            Mentor</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-2">7</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- line and line graph -->
    <div class="row">
        <div class="col-md-6">
            <div class="card p-3 chart-container position-relative">
                <p>Connection Request</p>
                <div style="display: flex;justify-content: space-around;">
                    <div class="chart-container"
                      style="height:300px; width: 600px;">
                        <app-scroll-bar [step]="6" [axis]="'x'">
                        <canvas baseChart
                            [datasets]="chartDataL2"
                            [labels]="chartLabelsL2"
                            [options]="chartOptionsL2"
                            [legend]="chartLegendL2"
                            [chartType]="chartTypeL2"
                            [colors]="chartColorsL2">
                        </canvas>
                    </app-scroll-bar>
                    </div>
                </div>
                <!-- Loader -->
                <div class="loader-overlay">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card p-3 chart-container position-relative">
                <p>Meeting request</p>
                <div style="display: flex;justify-content: space-around;">
                    <div class="chart-container"
                      style="height:300px; width: 600px;">
                        <app-scroll-bar [step]="6" [axis]="'x'">
                        <canvas baseChart
                            [datasets]="chartDataL3"
                            [labels]="chartLabelsL3"
                            [options]="chartOptionsL3"
                            [legend]="chartLegendL3"
                            [chartType]="chartTypeL3"
                            [colors]="chartColorsL3">
                        </canvas>
                    </app-scroll-bar>
                    </div>
                </div>
                <!-- Loader -->
                <div class="loader-overlay">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- 3 tiles -->
    <div class="row">
        <div class="col-md-4">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block"><span>Mentee</span></p>
                        <p class="mb-0 d-block">Retaintion Rate</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-1">58%</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block"><span>Mentor</span></p>
                        <p class="mb-0 d-block">Retaintion Rate</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-2">65%</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card p-3">
                <div
                    class="d-flex justify-content-between align-items-center dbbox">
                    <div>
                        <p class="mb-0 d-block"><span>Feedback</span></p>
                        <!-- <p class="mb-0 d-block">Retaintion Rate</p> -->
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <h6 class="tile-count-1">17</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- table tiles -->
    <div class="row mt-5">
        <div class="col-md-4">
            <a href="mentorship-provided-by-mentor"><div class="card px-3 py-4">
                    <div
                        class="d-flex justify-content-between align-items-center dbbox">
                        <div>
                            <p class="mb-0 d-block">Total Hours of Mentorship Provided by Mentor</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <i
                                class="fa fa-arrow-right font-2xl ml-3 c1"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div></a>
        </div>
        <div class="col-md-4">
            <a href="mentorship-provided-by-mentee"><div class="card px-3 py-4">
                    <div
                        class="d-flex justify-content-between align-items-center dbbox">
                        <div>
                            <p class="mb-0 d-block">Total Hours of Mentorship Received by Mentee</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <i
                                class="fa fa-arrow-right font-2xl ml-3 c2"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div></a>
        </div>
        <div class="col-md-4">
            <a href="connection-requests"><div class="card px-3 py-4">
                    <div
                        class="d-flex justify-content-between align-items-center dbbox">
                        <div>
                            <p class="mb-0 d-block">Connection Request Received</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <i
                                class="fa fa-arrow-right font-2xl ml-3 c3"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div></a>
        </div>
        <div class="col-md-4">
            <a href="mentorship-session"><div class="card px-3 py-4">
                    <div
                        class="d-flex justify-content-between align-items-center dbbox">
                        <div>
                            <p class="mb-0 d-block">Average Mentorship Session</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <i
                                class="fa fa-arrow-right font-2xl ml-3 c4"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div></a>
        </div>
        <div class="col-md-4">
            <a href="mentor-availability-slots"><div class="card px-3 py-4">
                    <div
                        class="d-flex justify-content-between align-items-center dbbox">
                        <div>
                            <p class="mb-0 d-block">Mentor Availablity Slots</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <i
                                class="fa fa-arrow-right font-2xl ml-3 c5"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div></a>
        </div>
    </div>

</div>