import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MemberBlogsViewService {

  constructor(private base: BaseService) { }  

  getMemberBlog(){
    return  this.base.get('/api/get-member-blog');
  }

  viewBlog(id: any) {
    return this.base.post('/api/blog-view',{id:id});
  }  

  updateMemberBlogStatus(data: any) {
    return this.base.post('/api/blog-update-status',data);
  }

}
