import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CityService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/city-create', data);
  }

  getAllCountry(){
    return  this.base.get('/api/all-country');
  }

  getAllState(){
    return  this.base.get('/api/all-state');
  }

  getStateById(id){
    return this.base.post('/api/all-state-by-id',{id:id});
  }

  getAllCity(){
    return  this.base.get('/api/all-city');
  }

  getCity(id){
    return this.base.post('/api/get-city',{id:id});
  }

  cityUpdate(data){
    return this.base.post('/api/city-update',data);
  }

  cityDelete(id){
    return this.base.post('/api/city-delete',{id:id});
  }
  
}
