import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AlertService, VerticalsService, AppService } from "../_services/index";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Subject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { WeCOpinionPollsService } from "../_services/we-c-opinion-polls.service";
import { EncryptionService } from "../_services/encryption.service";
import { HttpClient } from "@angular/common/http";
import { colorPickerChanged } from "@syncfusion/ej2-angular-richtexteditor";
import { take } from "rxjs/operators";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class OpinionPolls {
  id: number;
  Option1: any;
  Option2: any;
  Option3: any;
  Option4: any;
  ClosingDate: any;
  AnswerTypeID: any;
  Question: any;
  isSponsered: any;
}

@Component({
  selector: "app-we-community-opinion-polls",
  templateUrl: "./we-community-opinion-polls.component.html",
  styleUrls: [
    "./we-community-opinion-polls.component.sass",
    "./we-community-opinion-polls.component.css",
  ],
})
export class WeCommunityOpinionPollsComponent implements OnInit {
  public doughnutChartLabels: any[] = [];
  public doughnutChartData: any[] = [0, 0, 0, 0];
  public doughnutChartType: any = "doughnut";
  minValue: any = 0; // Initial minimum
  maxValue: any = 100; // Initial maximum
  public doughnutChartOptions: any = {
    responsive: true,
    cutoutPercentage: 100,
    legend: {
      position: "right",
    },
  };

  private subscription: Subscription;  
  chartData: any = [];

  @ViewChild(DataTableDirective, { static: false })
  isEditMode: boolean = false;
  sliderValue: any;
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  start: number = 0;
  pageLength: number = 10;
  commentListArr: any = [];
  likeListArr: any = [];
  opinionPollList: OpinionPolls[] = [];
  isSubmitted = false;
  today: any;
  sliderQuestion: any;
  totalOpinionPolls: any;
  reportPostArr: any = [];
  commentViewArr: any = [];
  reportUserArr: any = [];

  getOpinionPolls = {
    id: "",
    Option1: "",
    Option2: "",
    Option3: "",
    Option4: "",
    ClosingDate: "",
    AnswerTypeID: "",
    Question: "",
    isSponsered: "N",
  };
  isSliderDisabled: boolean = false;
  modalReference: NgbActiveModal;
  modalTitle: string;
  answerTypeArr: any = [];
  showPastDateError: boolean = false;
  showOption3 = false;
  showOption4 = false;
  optionCount: any = 2;
  showloding = true;
  lodingImage = false;
  polls_data: any;
  commentQues: any;
  constructor(
    public router: Router,
    private dataService: WeCOpinionPollsService,
    private alertService: AlertService,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {
    const currentDate = new Date();
    this.today = currentDate.toISOString().split("T")[0];
  }

  opinion_polls = {
    id: "",
    Option1: "",
    Option2: "",
    Option3: "",
    Option4: "",
    ClosingDate: "",
    AnswerTypeID: "",
    Question: "",
    isSponsered: "N",
  };

  ngOnInit() {
    window.scroll(0, 0);
    this.getAnswerType();
    this.getOpinionPollListing();
    this.loadHide();
  }

  getOpinionPollListing() {
    // console.log("call");
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/we-community-opinion-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            // console.log(resp, "resppppp ajax datatable");

            this.opinionPollList = resp["data"];
            this.totalOpinionPolls = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: this.opinionPollList,
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "Question" },
        { data: "LikeCount" },
        { data: "CommentCount" },
        { data: "ReportCount" },
        
        { data: "ReportUserCount" },
        { data: "AnswerCount" },
        { data: "ClosingDate" },
        { data: "CreatedDate" },
      ],
    };
  }

  addMore() {
    if (this.optionCount < 4) {
      this.optionCount++;
    }
  }

  validateDate(control: any) {
    if (control.value != "") {
      const selectedDate = new Date(control.value).toISOString().split("T")[0];
      if (selectedDate < this.today) {
        return { dateError: true };
      }
    }
    return null;
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  getAnswerType() {
    this.dataService
      .getAnswerType()
      .pipe()
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.answerTypeArr = res["data"];
        }
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  open(content) {
    this.isSubmitted = false;
    this.opinion_polls.id = "";
    (this.opinion_polls.Option1 = ""),
      (this.opinion_polls.Option2 = ""),
      (this.opinion_polls.Option3 = ""),
      (this.opinion_polls.Option4 = ""),
      (this.opinion_polls.ClosingDate = ""),
      (this.opinion_polls.Question = ""),
      (this.opinion_polls.AnswerTypeID = "");
    this.modalTitle = "Add Opinion Polls";
    this.modalReference = this.modalService.open(content);
  }

  getMinDate(): string {
    const today = new Date();
    const dd: string = String(today.getDate()).padStart(2, "0");
    const mm: string = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy: string = today.getFullYear().toString();
    return `${yyyy}-${mm}-${dd}`; // Format: YYYY-MM-DD
  }

  edit(item: any, content) {
    // console.log(item, "content");
    this.loadHide();
    this.modalTitle = "Edit Opinion Polls";
    this.modalReference = this.modalService.open(content);

    this.getOpinionPolls = item;
    this.opinion_polls = {
      id: this.getOpinionPolls.id,
      Question: this.getOpinionPolls.Question,
      Option1: this.getOpinionPolls.Option1,
      Option2: this.getOpinionPolls.Option2,
      Option3: this.getOpinionPolls.Option3,
      Option4: this.getOpinionPolls.Option4,
      ClosingDate: this.formatDate(this.getOpinionPolls.ClosingDate),
      AnswerTypeID: this.getOpinionPolls.AnswerTypeID,
      isSponsered: this.getOpinionPolls.isSponsered,
    };

    this.optionCount = 2; // Start with 2 options

    if (this.opinion_polls.Option3) {
      this.optionCount = 3; // Increment if Option3 exists
    }

    if (this.opinion_polls.Option4) {
      this.optionCount = 4; // Increment if Option4 exists
    }
  }

  formatDate(dateTimeString: any) {
    return dateTimeString.split(" ")[0];
  }

  insertAction(val, f) {
    let formData: FormData = new FormData();

    formData.append("Option1", this.opinion_polls.Option1);
    formData.append("Option2", this.opinion_polls.Option2);
    formData.append("Option3", this.opinion_polls.Option3);
    formData.append("Option4", this.opinion_polls.Option4);
    formData.append("ClosingDate", this.opinion_polls.ClosingDate);
    formData.append("AnswerTypeID", this.opinion_polls.AnswerTypeID);
    formData.append("Question", this.opinion_polls.Question);
    formData.append("isSponsered", this.opinion_polls.isSponsered);

    if (val.id == "") {
      this.dataService
        .createOpinionPolls(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.loadHide();
            this.modalReference.close();
            this.alertService.success(
              "We community opinion polls saved successfully",
              true
            );
            const table = $("#wecommunity").DataTable();
            table.ajax.reload(null, false);
          },
          (error) => {
            this.loadHide();
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      this.dataService
        .updateOpinionPoll(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            const table = $("#wecommunity").DataTable();
            table.ajax.reload(null, false);
            this.alertService.success(
              "Opinion Polls updated successfully",
              true
            );
            this.loadHide();
          },
          (error) => {
            this.loadHide();
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  // onViewLikes(item, viewlikes) {
  //   this.commentListArr = [];
  //   this.modalTitle = "Like List";
  //   this.modalReference = this.modalService.open(viewlikes);
  //   this.likeListArr = item.LikeCountArray;
  // }

  // onViewComments(item, viewcomments) {
  //   this.likeListArr = []
  //   this.modalTitle = "Comment List";
  //   this.modalReference = this.modalService.open(viewcomments);
  //   this.commentListArr = item.CommentArray;
  // }

  onViewLikes(item, viewlikes) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.commentListArr = [];
    this.modalTitle = "Like List";
    this.modalReference = this.modalService.open(viewlikes);
    this.likeListArr = item.LikeCountArray;
  }

  onViewComments(item, viewcomments) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.likeListArr = [];
    this.modalTitle = "Comment List";
    this.modalReference = this.modalService.open(viewcomments);
    this.commentListArr = item.CommentArray;
  }

  ngOnDestroy(): void {
    // Unsubscribe from the DataTable trigger when the component is destroyed
    this.dtTrigger.unsubscribe();
  }

  onCloseModal() {
    this.modalReference.close();
  }

  onViewReportPost(item, reportpost) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.modalTitle = "Report Post List";
    this.modalReference = this.modalService.open(reportpost);
    this.reportPostArr = item.ReportArray;
  }

  onViewReportUser(item, reportuser) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.modalTitle = "Report User List";
    this.modalReference = this.modalService.open(reportuser);
    this.reportUserArr = item.ReportUserArray;
  }

  // onView(item, modalTemplate, answerType) {
  //   const table = $("#wecommunity").DataTable();
  //   table.ajax.reload(null, false);
  //   this.modalReference = this.modalService.open(modalTemplate);

  //   this.dataService.weCommunityView(item.id).subscribe((res: any) => {
  //     if (res["status"] === 200) {
  //       // console.log("res", res);

  //       if (answerType == 1 || answerType == 2) {
  //         this.loadShow();
  //         this.chartData = res.data;
  //         this.polls_data = res.data.opinionanswers;
  //         this.doughnutChartData = [
  //           this.polls_data.Option1,
  //           this.polls_data.Option2,
  //           this.polls_data.Option3,
  //           this.polls_data.Option4,
  //         ];
  //         this.doughnutChartLabels = [
  //           this.chartData.Option1,
  //           this.chartData.Option2,
  //           this.chartData.Option3,
  //           this.chartData.Option4,
  //         ];
  //         this.loadHide();
  //       } else if (answerType == 3) {
  //         this.sliderQuestion = res.data.Question;
  //         this.sliderValue = res.data.opinionanswers.AverageRating;
  //         this.isSliderDisabled = true;
  //       } else if (answerType == 4) {
  //         this.commentQues = item.Question;
  //         this.commentViewArr = res.data.opinionanswers.Comments;
  //       }
  //     }
  //   });
  // }

  onView(item, modalTemplate, answerType) {
    this.loadShow()
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    const modalOptions: NgbModalOptions = {
      backdrop: 'static', 
      keyboard: false,   
    };
    this.modalReference = this.modalService.open(modalTemplate,modalOptions);
  
    this.subscription =  this.dataService.weCommunityView(item.id).pipe(
      take(1)  // Automatically unsubscribe after one emission
    ).subscribe((res: any) => {
      if (res["status"] === 200) {
       this.loadHide();
        // Handle the response based on the answerType
        if (answerType == 1 || answerType == 2) {
          this.chartData = res.data;
          this.polls_data = res.data.opinionanswers;
          this.doughnutChartData = [
            this.polls_data.Option1,
            this.polls_data.Option2,
            this.polls_data.Option3,
            this.polls_data.Option4,
          ];
          this.doughnutChartLabels = [
            this.chartData.Option1,
            this.chartData.Option2,
            this.chartData.Option3,
            this.chartData.Option4,
          ];
        } else if (answerType == 3) {
          this.sliderQuestion = res.data.Question;
          this.sliderValue = res.data.opinionanswers.AverageRating;
          this.isSliderDisabled = true;
        } else if (answerType == 4) {
          this.commentQues = item.Question;
          this.commentViewArr = res.data.opinionanswers.Comments;
        }
      }
    });
  }

  closeModal() {

    if (this.subscription) {
      this.subscription.unsubscribe();  // Unsubscribe from the observable
    }

    if (this.modalReference) {
      this.modalReference.close();  // Close the modal
    }
  }

  save(val, f) {
    this.isSubmitted = true;

    if (this.isFieldsInvalid(this.opinion_polls.Question)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  checkClosingDate(event: string) {
    const selectedDate = new Date(event);
    const currentDate = new Date(this.today);

    // Check if the selected date is before today
    if (selectedDate < currentDate) {
      // Reset the ClosingDate if it's in the past
      this.opinion_polls.ClosingDate = "";
      this.showPastDateError = true; // Show error
    } else {
      this.showPastDateError = false; // No error if valid
    }
  }

  deleteComment(item){
    this.loadShow();
    this.dataService
      .deleteComments(item.id)
      .pipe()
      .subscribe(
        (data) => {
          // console.log(data, "response");
          if (data["status"] == 200) {
            // this.alertService.success("Comments Deleted successfully", true);
            item.isActive = item.isActive === "Y" ? "N" : "Y";
            const table = $("#wecommunity").DataTable();
            table.ajax.reload(null, false);
            // this.allVerticals();
            this.loadHide();
          } else {
            this.alertService.error("Error");
            this.loadHide();
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
    }



  delete(item) {
    // console.log(item, "item");

    this.loadShow();
    this.dataService
      .deleteOpinionPolls(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Partner deleted successfully", true);
            item.isActive = item.isActive === "Y" ? "N" : "Y";
          } else {
            this.alertService.error("Partner error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  removeOption(optionNumber: any) {
    if (this.optionCount > 2) {
      this.optionCount--;
      if (optionNumber === 3) {
        this.opinion_polls.Option3 = "";
      } else if (optionNumber === 4) {
        this.opinion_polls.Option4 = "";
      }
    }
  }
}
