<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/camp.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Email Campaign</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{campaignList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Campaign
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Name</th>
										<th>Subject</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of campaignList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<td>{{item.subject}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>

										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span>
											<span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">Inactive</span>
										</td>

										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="edit(this.campaignList[i].id,content)"><i
													class="fa fa-edit"></i></button>
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
												(click)="delete(this.campaignList[i].id)"><i
													class="fa fa-trash"></i></button> -->
											<!-- <ui-switch size="small" defaultBgColor="red" (change)="delete(this.campaignList[i].id)" *ngIf="item.is_active=='Y'" checked></ui-switch>
											<ui-switch size="small" defaultBgColor="red" (change)="delete(this.campaignList[i].id)" *ngIf="item.is_active=='N'"></ui-switch> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add" class="">
			<!-- class="modal fade" -->
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">

							
							<div class="form-group col-md-6">
								<label for="name" class="  col-form-label">Name <span>*</span></label>
								<div class=" ">
									<input class="form-control" type="text" id="name" name="name" required [(ngModel)]="campaign.name" >
									<span *ngIf=" isSubmitted &&  isFieldsInvalid(campaign.name)" class="alert-block">This field is required</span>
								</div>
							  </div>

                            <div class="form-group col-md-6">
								<label for="user_type" class="  col-form-label">User Type <span>*</span></label>
								<div class=" ">
									<select class="form-control" id="user_type" name="user_type" required [(ngModel)]="campaign.user_type" (change)="changeType()">
										<option value="2">Customer</option>
										<option value="1">Partner</option>
										<span *ngIf=" isSubmitted && (campaign.user_type==undefined || campaign.user_type=='')" class="alert-block">This field is required</span>
									</select>
								</div>
							</div>
                           
							<div class="form-group col-md-6">
								<label for="customer" class="  col-form-label">Users <span>*</span> </label>
								<div class=" " *ngIf="campaign.user_type=='2'">
									<ng-select [items]="allCustomer" name="user_ids" [multiple]="true" bindLabel="name" bindValue="id"
										[(ngModel)]="selectedTo" (change)="changeTo()" [maxSelectedItems]="10">
									</ng-select>
								</div>
								<div class=" " *ngIf="campaign.user_type=='1'">
									<ng-select [items]="allPartner" name="user_ids" [multiple]="true" bindLabel="name" bindValue="id"
										[(ngModel)]="selectedTo" (change)="changeTo()">
									</ng-select>
								</div>
								<span *ngIf="isSubmitted && (!campaign.user_ids || campaign.user_ids.length === 0)" class="alert-block">
									This field is required
								  </span>
							</div>
							  
                            <div class="form-group col-md-6">
								<label for="subject" class="col-form-label">Subject <span>*</span></label>
								<div class=" ">
									<input class="form-control" type="text" id="subject" name="subject" required [(ngModel)]="campaign.subject">
									<span *ngIf=" isSubmitted && (campaign.subject==undefined || campaign.subject=='')" class="alert-block">This field is required</span>
								</div>
							</div>
							
							<div class="form-group col-md-12">
								<label for="body" class="col-form-label">Body <span>*</span></label>
								<div class=" ">
									<!-- <ejs-richtexteditor id='body' [toolbarSettings]='tools' name="body" required [(ngModel)]="campaign.body"></ejs-richtexteditor> -->
									<angular-editor [config]="configbody"  id='body' [(ngModel)]="campaign.body" name="body"></angular-editor>

									<span *ngIf=" isSubmitted && (campaign.body==undefined || campaign.body=='')" class="alert-block">This field is required</span>
								</div>
							</div>
                            <div class="form-group col-md-6">
								<label for="schedule_type" class="col-form-label">Schedule Type <span>*</span></label>
								<div class=" ">
									<select class="form-control" id="schedule_type" name="schedule_type" required [(ngModel)]="campaign.schedule_type">
										<option value="1">Now</option>
										<option value="2">Schedule</option>
									</select>
									<span *ngIf=" isSubmitted && (campaign.schedule_type==undefined || campaign.schedule_type=='')" class="alert-block">This field is required</span>
								</div>
							</div>
							<div class="form-group col-md-6" *ngIf="campaign.schedule_type=='2'">
								<label for="schedule_date" class="  col-form-label">Schedule Date</label>
								<div class=" ">
									<input class="form-control" type="date" id="schedule_date" name="schedule_date" [(ngModel)]="campaign.schedule_date">
									<span *ngIf=" isSubmitted && campaign.schedule_type=='2' && (campaign.schedule_date==undefined || campaign.schedule_date=='')" class="alert-block">This field is required</span>
								</div>
							</div>							
							<div class="form-group col-md-6">
								<label for="is_active" class="col-form-label">Status</label>
								<div class=" ">
									<select class="form-control" id="is_active" name="is_active" [(ngModel)]="campaign.is_active">
										<option value="N">Inactive</option>
										<option value="Y">Active</option>
									</select>
								</div>
							</div>
						</div>

					   </form>
					  </div>
					 <div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
							<button type="submit" class="btn btn-primary savebtn" (click)="save(this.campaign,f)">Save</button>
					   </div>
				      </div>
			         </div>
		       </ng-template>
         	</div>
         </div>