import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CampaignService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/email-create', data);
  }

  campaignUpdate(data){
    return this.base.post('/api/email-update',data);
  }

  campaignDelete(id){
    return this.base.post('/api/email-delete',{id:id});
  }

  getAllCampaign(){
    return  this.base.get('/api/get-all-email');
  }

  getCampaign(id){
    return this.base.post('/api/email-edit',{id:id});
  }
  
  getAllCustomer(id) {
    return this.base.post('/api/get-all-customer',{id:id});
  }

}
