import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AreaOfSpecializationService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');

  save(data){
  	return this.base.post('/api/areaofspecialization-create', data);
  }

  getAllAreaofspecialization(){
    return  this.base.get('/api/all-areaofspecialization');
  }

  getAreaofspecialization(id){
    return this.base.post('/api/get-areaofspecialization',{id:id});
  }

  areaofspecializationUpdate(data){
    return this.base.post('/api/areaofspecialization-update',data);
  }

  areaofspecializationDelete(id){
    return this.base.post('/api/areaofspecialization-delete',{id:id});
  }
}
