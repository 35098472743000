import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { JioMeetingsService } from "../_services/jio-meetings.service";
import { AlertService } from "../_services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { ExcelService } from "../_services/excel.service";
import { EncryptionService } from "../_services/encryption.service";
import { ReportsService } from "../_services/reports.service";

declare const $: any;
import * as _moment from "moment";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from "moment";
import { MatDateFormats } from "@angular/material/core";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  @ViewChild("datePicker", { static: false }) datePicker: ElementRef;
  today: Date = new Date();
  date = new FormControl();
  showloding = true;
  dtOptions: DataTables.Settings = {};
  lodingImage = false;
  dataSend: any;
  errorMsg: any;
  isSubmittedMember: boolean = false;
  isSubmittedMonthWise: boolean = false;
  isSubmittedCommunity: boolean = false;
  isSubmittedrepititiveMember: boolean = false;
  reportList: any[] = []; //
  totalReports: any;
  start: number = 0;
  maxMonth: string = "";
  maxDate: string = "";
  showDatePicker: boolean = false;
  memberDetailsForm: any;
  monthWiseForm: any;
  repititiveMemberForm: any;
  communityPostForm: any;

  constructor(
    public router: Router,
    private dataService: ReportsService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private excelService: ExcelService,
    private cdr: ChangeDetectorRef,
    private enc_dec: EncryptionService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.loadHide();

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    this.maxMonth = `${year}-${month}`;
    this.maxDate = `${year}-${month}-${day}`;

    this.allReports();

    this.memberDetailsForm = this.fb.group({
      mStartDate: ["", [Validators.required]],
      mEndDate: ["", [Validators.required]],
    });

    this.monthWiseForm = this.fb.group({
      monthWiseStartDate: ["", [Validators.required]],
      monthWiseEndDate: ["", [Validators.required]],
    });

    this.repititiveMemberForm = this.fb.group({
      repititiveStartDate: ["", [Validators.required]],
      repititiveEndDate: ["", [Validators.required]],
    });

    this.communityPostForm = this.fb.group({
      communityStartDate: ["", [Validators.required]],
      communityEndDate: ["", [Validators.required]],
    });
  }

  get member() {
    return this.memberDetailsForm.controls;
  }

  get month_wise() {
    return this.monthWiseForm.controls;
  }

  get repititive_member() {
    return this.repititiveMemberForm.controls;
  }

  get community_post() {
    return this.communityPostForm.controls;
  }

  allReports() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/report-ajax",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);

            // this.reportList = resp["data"];

            this.reportList = this.formatDates(resp["data"]);
            //console.log(this.reportList, "reportList");

            this.totalReports = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "report_name" },
        { data: "start_date" },
        { data: "end_date" },
        { data: "created_at" },
        { data: "status" },
      ],
      columnDefs: [
        { targets: [6], orderable: false }, // Disable sorting for the "Action" column (index 6)
      ],
      order: [[4, "desc"]],
    };
  }

  formatDates(dataArray: any[]) {
    return dataArray.map((item) => {
      const startDate = new Date(item.start_date);
      const endDate = new Date(item.end_date);

      // Define formats
      const format1 = (date: Date) =>
        `${date.getDate().toString().padStart(2, "0")}-${
          date
            .toLocaleString("en", { month: "short" })
            .charAt(0)
            .toUpperCase() +
          date.toLocaleString("en", { month: "short" }).slice(1).toLowerCase()
        }-${date.getFullYear()}`;

      // const format2 = (date: Date) =>
      //   `${
      //     date
      //       .toLocaleString("en", { month: "short" })
      //       .charAt(0)
      //       .toUpperCase() +
      //     date.toLocaleString("en", { month: "short" }).slice(1).toLowerCase()
      //   }-${date.getFullYear()}`;

      // Apply conditions based on type
        item.start_date = format1(startDate);
        item.end_date = format1(endDate);
        item.start_date = format1(startDate);
        item.end_date = format1(endDate);

      return item;
    });
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  onSaveMemberDetails(type: any) {
    this.isSubmittedMember = true;
    this.loadShow();

    if (
      this.memberDetailsForm.value.mStartDate &&
      this.memberDetailsForm.value.mEndDate &&
      this.memberDetailsForm.value.mStartDate >
        this.memberDetailsForm.value.mEndDate
    ) {
      this.errorMsg = "Start date cannot be later than end date.";
      setTimeout(() => {
        this.errorMsg = "";
      }, 3000);

      this.loadHide();
      return;
    }

    this.dataSend = {
      type: type,
      start_date: this.memberDetailsForm.value.mStartDate,
      end_date: this.memberDetailsForm.value.mEndDate,
    };

    //console.log(this.dataSend, "dataSend");

    if (this.memberDetailsForm.valid) {
      this.dataService.save(this.dataSend).subscribe(
        (res: any) => {
          this.loadHide();

          if (res["status"] == 200) {
            this.loadHide();
            const table = $("#reports").DataTable();
            table.ajax.reload(null, false);
            this.isSubmittedMember = false;
            this.memberDetailsForm.reset();
          } else if (res["status"] == 422) {
            if (res && res["mesg"] && res["mesg"]["start_date"]) {
              this.errorMsg = res["mesg"]["start_date"];
            } else if (res && res["mesg"] && res["mesg"]["end_date"]) {
              this.errorMsg = res["mesg"]["end_date"];
            } else {
              this.errorMsg = "Unknown Error";
            }
            setTimeout(() => {
              this.errorMsg = "";
            }, 3000);
          }
        },
        (err) => {
          this.loadHide();
          console.error("Error during save:", err);
        }
      );
    } else {
      this.loadHide();
    }
  }

  onSaveMonthWise(type: any) {
    this.isSubmittedMonthWise = true;
    this.loadShow();
    if (
      this.monthWiseForm.value.monthWiseStartDate &&
      this.monthWiseForm.value.monthWiseEndDate &&
      this.monthWiseForm.value.monthWiseStartDate >
        this.monthWiseForm.value.monthWiseEndDate
    ) {
      this.errorMsg = "Start date cannot be later than end date.";
      setTimeout(() => {
        this.errorMsg = "";
      }, 3000);
      this.loadHide();
      return;
    }

    this.dataSend = {
      type: type,
      start_date: this.monthWiseForm.value.monthWiseStartDate,
      end_date: this.monthWiseForm.value.monthWiseEndDate,
    };
    //console.log(this.dataSend, "dataSend");

    if (this.monthWiseForm.valid) {
      this.dataService.save(this.dataSend).subscribe(
        (res: any) => {
          this.loadHide();
          if (res["status"] == 200) {
            this.loadHide();
            const table = $("#reports").DataTable();
            table.ajax.reload(null, false);
            this.isSubmittedMonthWise = false;
            this.monthWiseForm.reset();
          } else if (res["status"] == 422) {
            if (res && res["mesg"] && res["mesg"]["start_date"]) {
              this.errorMsg = res["mesg"]["start_date"];
            } else if (res && res["mesg"] && res["mesg"]["end_date"]) {
              this.errorMsg = res["mesg"]["end_date"];
            } else {
              this.errorMsg = "Unknown Error";
            }
            setTimeout(() => {
              this.errorMsg = "";
            }, 3000);
          }
        },
        (err) => {
          this.loadHide();
          console.error("Error during save:", err);
        }
      );
    } else {
      this.loadHide();
    }
  }

  onSaveRepititiveMember(type: any) {
    this.isSubmittedrepititiveMember = true;
    this.loadShow();

    if (
      this.repititiveMemberForm.value.repititiveStartDate &&
      this.repititiveMemberForm.value.repititiveEndDate &&
      this.repititiveMemberForm.value.repititiveStartDate >
        this.repititiveMemberForm.value.repititiveEndDate
    ) {
      this.errorMsg = "Start date cannot be later than end date.";
      setTimeout(() => {
        this.errorMsg = "";
      }, 3000);
      this.loadHide();
      return;
    }

    this.dataSend = {
      type: type,
      start_date: this.repititiveMemberForm.value.repititiveStartDate,
      end_date: this.repititiveMemberForm.value.repititiveEndDate,
    };
    //console.log(this.dataSend, "dataSend");

    if (this.repititiveMemberForm.valid) {
      this.dataService.save(this.dataSend).subscribe(
        (res: any) => {
          this.loadHide();
          if (res["status"] == 200) {
            this.loadHide();
            const table = $("#reports").DataTable();
            table.ajax.reload(null, false);
            this.isSubmittedrepititiveMember = false;
            this.repititiveMemberForm.reset();
          } else if (res["status"] == 422) {
            if (res && res["mesg"] && res["mesg"]["start_date"]) {
              this.errorMsg = res["mesg"]["start_date"];
            } else if (res && res["mesg"] && res["mesg"]["end_date"]) {
              this.errorMsg = res["mesg"]["end_date"];
            } else {
              this.errorMsg = "Unknown Error";
            }
            setTimeout(() => {
              this.errorMsg = "";
            }, 3000);
          }
        },
        (err) => {
          this.loadHide();
          console.error("Error during save:", err);
        }
      );
    } else {
      this.loadHide();
    }
  }

  onSaveCommunityPost(type: any) {
    this.isSubmittedCommunity = true;
    this.loadShow();

    if (
      this.communityPostForm.value.communityStartDate &&
      this.communityPostForm.value.communityEndDate &&
      this.communityPostForm.value.communityStartDate >
        this.communityPostForm.value.communityEndDate
    ) {
      this.errorMsg = "Start date cannot be later than end date.";
      setTimeout(() => {
        this.errorMsg = "";
      }, 3000);
      this.loadHide();
      return;
    }

    this.dataSend = {
      type: type,
      start_date: this.communityPostForm.value.communityStartDate,
      end_date: this.communityPostForm.value.communityEndDate,
    };
    //console.log(this.dataSend, "dataSend");

    if (this.communityPostForm.valid) {
      this.dataService.save(this.dataSend).subscribe(
        (res: any) => {
          this.loadHide();
          if (res["status"] == 200) {
            this.loadHide();
            const table = $("#reports").DataTable();
            table.ajax.reload(null, false);
            this.isSubmittedCommunity = false;
            this.communityPostForm.reset();
          } else if (res["status"] == 422) {
            if (res && res["mesg"] && res["mesg"]["start_date"]) {
              this.errorMsg = res["mesg"]["start_date"];
            } else if (res && res["mesg"] && res["mesg"]["end_date"]) {
              this.errorMsg = res["mesg"]["end_date"];
            } else {
              this.errorMsg = "Unknown Error";
            }
            setTimeout(() => {
              this.errorMsg = "";
            }, 3000);
          }
        },
        (err) => {
          this.loadHide();
          console.error("Error during save:", err);
        }
      );
    } else {
      this.loadHide();
    }
  }

  onResetMemberDetails() {
    this.memberDetailsForm.reset();
  }

  onResetMonthWise() {
    this.monthWiseForm.reset();
  }

  onResetCommunity() {
    this.communityPostForm.reset();
  }

  onResetRepititive() {
    this.repititiveMemberForm.reset();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
