<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/part.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">ATR Responses</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{(bodyDataArr?.length > 0) ? bodyDataArr?.length : 0 }}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead class="">
                                    <tr>
                                        <th style="width: 5%;">#Sl</th>
                                        <th style="width: 20%;">Action</th>
                                        <th *ngFor="let item of headDataArr" style="width: 20%;">{{item.title}}
                                        </th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr *ngFor="let item of bodyDataArr; let i = index" [attr.data-index]="i">
                                        <td>{{i+1}}</td>
                                        <td scope="row">
                                            <div class="d-flex">
                                                <span class="badge bg-dark text-white"
                                                    *ngIf="item.active_status == 'SHORT LIST'">Short
                                                    Listed</span>
                                                <span class="badge bg-warning text-white"
                                                    *ngIf="item.active_status == 'HOLD'">Hold</span>
                                                <span class="badge bg-success text-white"
                                                    *ngIf=" item.active_status == 'WINNER'">Winner</span>
                                            </div>
                                          </td>
                                        <td *ngFor="let item1 of headDataArr" style="width: 20%;">
                                            {{item[item1.column]}}</td>
                                    </tr>
                                </tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>