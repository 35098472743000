import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { AlertService, OrgListService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseService } from "../_services/base.service";
import { EncryptionService } from "../_services/encryption.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-organisation-list",
  templateUrl: "./organisation-list.component.html",
})
export class OrganisationListComponent implements OnInit {
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [4], orderable: false }],
  };

  start: number = 0;
  pageLength: number = 10;
  orgList: any[] = [];
  orgTotal: any;
  closeResult: string; // Modal Close
  filterData = { status: "ALL" };
  isSubmitted: boolean = false;

  orgInfo = {
    id: "",
    name: "",
    age: "",
    address: "",
    contact_no: "",
    email: "",
    type_of_organization: "",
    organization_exp_desc: "",
    organization_website: "",
    approve_status: "",
    remark: "",
    country_names: "",
    state_names: "",
    city_names: "",
    exp_in_providing_mentorship_name: "",
    no_of_entrepreneurs_name: "",
  };

  constructor(
    public router: Router,
    private dataService: OrgListService,
    private enc_dec: EncryptionService,
    private http: HttpClient,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    if (localStorage.getItem("org_approve_status") != null) {
      this.filterData.status = localStorage.getItem("org_approve_status");
    }
    this.getData();
  }

  getData() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/all-organization-by-status",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(
                  Object.assign(dataTablesParameters, this.filterData)
                )
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "resp");

            this.orgList = resp["aaData"];
            //console.log(this.orgList, "orgList");

            this.orgTotal = resp["iTotalRecords"];
            //console.log(this.orgTotal, "orgTotal");

            callback({
              iTotalRecords: resp["iTotalRecords"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "email" },
        { data: "approve_status" },
        { data: "action" },
      ],
    };

    this.loadHide();
    window.scroll(0, 0);
  }

  openOrgInfo(id, org_info) {
    this.loadShow();
    this.dataService
      .getOrgInfo(id)
      .pipe()
      .subscribe((data) => {
        let organisation = data["organization"];
        // //console.log(organisation);
        this.orgInfo = {
          id: organisation.id,
          name: organisation.name,
          age: organisation.age,
          address: organisation.address,
          contact_no: organisation.contact_no,
          email: organisation.email,
          type_of_organization: organisation.type_of_organization,
          organization_exp_desc: organisation.organization_exp_desc,
          organization_website: organisation.organization_website,
          approve_status: organisation.approve_status,
          remark: organisation.remark,
          country_names: organisation.country_names,
          state_names: organisation.state_names,
          city_names: organisation.city_names,
          exp_in_providing_mentorship_name:
            organisation.exp_in_providing_mentorship_name,
          no_of_entrepreneurs_name: organisation.no_of_entrepreneurs_name,
        };
        this.loadHide();
      });
    this.modalReference = this.modalService.open(org_info);
  }

  orgStatusUpdate(item: any, org_info) {
    this.isSubmitted = true;
    if (
      this.orgInfo.approve_status === "REJECTED" &&
      (!this.orgInfo.remark || this.orgInfo.remark.trim() === "")
    ) {
      return;
    }

    this.loadShow();
    var data = {
      organization_id: this.orgInfo.id,
      status: this.orgInfo.approve_status,
      remarks: this.orgInfo.remark ? this.orgInfo.remark : "",
    };
    this.dataService
      .updateOrgStatus(data)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(data["mesg"], true);
            const table = $("#organisationtable").DataTable();
            table.ajax.reload(null, false);
            this.orgList = this.orgList.map((organization: any) =>
              organization.id === data.organization_id
                ? {
                    ...organization,
                    approve_status: data.status,
                    remark: data.remarks,
                  }
                : organization
            );
          } else if (data["status"] == 422) {
            this.alertService.error(data["mesg"], true);
          }
          this.loadHide();
          this.modalService.dismissAll(org_info);
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  onChangeStatus(): void {
    if (this.orgInfo.approve_status !== "REJECTED") {
      this.orgInfo.remark = "";
    }
  }

  checkRemarks() {
    if (this.orgInfo.remark) {
      this.orgInfo.remark = this.orgInfo.remark.trim();
    }
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  setFilterData(e: any) {
    this.filterData.status = e.target.value;
    localStorage.setItem("org_approve_status", this.filterData.status);
    const table = $("#organisationtable").DataTable();
    table.ajax.reload(null, false);
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
