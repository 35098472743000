import {
  AfterViewInit,
  OnDestroy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import {
  AlertService,
  PublicationService,
  AppService,
} from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";

class Publications {
  id: number;
  heading: string;
  publish_date: string;
  publication_type: string;
  html_url: string;
  html_thumbnail: string;
  is_active: string;
}

@Component({
  selector: "app-publications",
  templateUrl: "./publications.component.html",
})
export class PublicationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".html, .htm, .pdf";
  @Input() imageType: string = ".png, .jpg, jpeg";
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  DisplayedText1: string = "";
  fileList: any;
  imageList: any;
  html_url1: string = "";
  html_thumbnail1: string = "";
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  publicationsList: Publications[] = []; // Table Data list
  isSubmitted = false;

  getPublications = {
    id: "",
    heading: "",
    publish_date: "",
    publication_type: "",
    html_url: "",
    html_thumbnail: "",
    is_active: "",
  };
  closeResult: string; // Modal Close
  constructor(
    public router: Router,
    private dataService: PublicationService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  publications = {
    id: "",
    heading: "",
    publish_date: "",
    publication_type: "",
    html_url: "",
    html_thumbnail: "",
    is_active: "",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allPublications();
  }

  allPublications() {
    this.loadShow();
    this.dataService
      .getAllPublication()
      .pipe()
      .subscribe((data) => {
        this.publicationsList = data["publications"];
        // //console.log(this.publicationsList);
        //this.dtTrigger.next(); // Data Table
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    if (this.getPublications.id != null) {
      this.getPublications.id = "";
    }

    this.publications.id = "";
    this.publications.heading = "";
    this.publications.publish_date = "";
    this.publications.publication_type = "";
    this.publications.html_url = "";
    this.publications.html_thumbnail = "";
    this.publications.is_active = "Y";

    this.DisplayedText = "";
    this.modalTitle = "Add Publication";
    this.modalReference = this.modalService.open(content);
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;

    if (this.isFieldsInvalid(this.publications.heading)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.getPublication(id).subscribe((data) => {
      this.getPublications = data["publications"];
      //console.log(this.getPublications);
      // this.DisplayedText1 = this.getPublications.html_url.split('admin/')[1];
      let html_urls;
      if (this.getPublications.publication_type == "1") {
        html_urls = this.getPublications.html_url;
      } else {
        html_urls = appConfig.apiUrl + "/" + this.getPublications.html_url;
      }
      this.publications = {
        id: this.getPublications.id,
        heading: this.getPublications.heading,
        html_url: html_urls,
        publish_date: this.getPublications.publish_date,
        publication_type: this.getPublications.publication_type,
        // image:appConfig.apiUrl+'/'+this.getPublications.image,
        html_thumbnail: this.getPublications.html_thumbnail,
        is_active: this.getPublications.is_active,
      };
      this.loadHide();
      this.modalTitle = "Edit Publication";
      this.modalReference = this.modalService.open(content);
    });
  }

  insertAction(val) {
    let formData: FormData = new FormData();
    // if(this.fileList !=undefined){
    if (this.publications.publication_type == "2") {
      if (this.fileList != undefined) {
        let file: File = this.fileList[0];
        // formData.append('html_url', file, file.name);
        formData.append("html_url", this.html_url1);
        // //console.log('html_url1 : ', this.html_url1);
      } else {
        formData.append("html_url", "");
      }
    } else {
      formData.append("html_url", this.publications.html_url);
    }

    if (this.imageList != undefined) {
      let file1: File = this.imageList[0];
      // formData.append('html_thumbnail', file1, file1.name);
      formData.append("html_thumbnail", this.html_thumbnail1);
      // //console.log('html_thumbnail1 :', this.html_thumbnail1);
    }
    // else {
    //   formData.append('html_thumbnail', this.publications.html_thumbnail);
    // }

    formData.append("heading", this.publications.heading);
    formData.append("publication_type", this.publications.publication_type);
    formData.append("publish_date", this.publications.publish_date);
    formData.append("is_active", this.publications.is_active);

    // //console.log(this.publications.html_url);

    if (val.id == "") {
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            // this.dtTrigger = new Subject(); //  DataTable
            this.allPublications();

            this.alertService.success("Publication created successfully", true);
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      this.dataService
        .publicationUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            // this.dtTrigger = new Subject(); //  DataTable
            this.allPublications();
            this.alertService.success("Publication updated successfully", true);
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  // delete(id) {
  //   if (confirm("Are you sure to delete")) {
  //     this.dataService.publicationDelete(id)
  //       .pipe().subscribe(data => {
  //         if (data['status'] == 200) {
  //           // this.dtTrigger = new Subject(); //  DataTable
  //           this.alertService.success('Publication deleted successfully', true);
  //           this.allPublications();
  //         } else {
  //           this.alertService.error('Publication error');
  //         }
  //       }, error => {
  //         this.alertService.error(error);
  //       });
  //   }
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .publicationDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Publication deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allPublications();
          } else {
            this.alertService.error("Publication error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  imageChange(event: any) {
    this.imageList = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.imageList && this.imageList.length > 0;
    if (hasFile) {
      var extension = this.imageList[0].name.substring(
        this.imageList[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = this.imageType;
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.imageType
        );
        this.imageList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.imageList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.imageList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.imageList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.imageList.length + " file(s) has been selected";
      } else {
        let file: File = this.imageList[0];
        this.DisplayedText = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.imageList[0]);
        reader.onload = () => {
          this.html_thumbnail1 = reader.result as string;
          this.publications.html_thumbnail = this.html_thumbnail1;
        };
      }
      this.onSelection.emit(this.imageList);
    }
  }

  fileChange(event: any) {
    this.fileList = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = this.fileType;
      /*if (validFileType.toLowerCase().indexOf(extension) < 0) {
          alert("please select valid file type. The supported file types are "+this.fileType);
        this.fileList = null;
        this.DisplayedText1 = "";
        return false;
      }*/

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText1 = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText1 =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        this.DisplayedText1 = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
          this.html_url1 = reader.result as string;
          this.publications.html_url = this.html_url1;
        };
      }
      this.onSelection.emit(this.fileList);
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
