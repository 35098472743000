<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
  <div class="animated fadeIn">
      <div class="row">
          <div class="col-lg-12">
            <div class="card">
                <div class="card-block">
                  <div class="card-block-title">
                    <h5 class="card-title">Users</h5>
                    <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="open(content)" style="float: right;"> Add User</button>
                  </div>
                  <p><button type="button" *ngIf="pdf" class="btn btn-primary btn-sm rounded" title="PDF" (click)="exportToPdf()"><i class="fa fa-file-pdf-o"></i> PDF</button> 
                    <button type="button" *ngIf="exl" title="XL" class="btn btn-success rounded btn-sm ml-3" (click)="exportToExcel()"><i class="fa fa-file-excel-o"></i> Excel</button></p>
                    <div class="table-responsive">
                      <table id="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
                          <thead>
                              <tr>
                                  <th>#Sl</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Phone</th>
                                  <!-- <th>Address</th> -->
                                  <th>Role</th>
                                  <th>Status</th>
                                  <th style="width: 180px;" class="action">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                             <tr *ngFor="let item of userList; let i = index" [attr.data-index]="i">
                                  <td>{{i+1}}</td>
                                  <td>{{item.name}}</td>
                                  <td>{{item.email}}</td>
                                  <td>{{item.phone}}</td>
                                  <!-- <td>{{item.address}}</td> -->
                                  <td>{{item.role_name}}</td>
                                  <td><span *ngIf="item.status==1" class="badge badge-success font-weight-normal badge-pill">Active</span> <span *ngIf="item.status==0" class="badge badge-danger badge-pill">In active</span></td>
                                  <td class="action">
                                    <button *ngIf="item.type !=1" class="btn btn-dark btn-disabled btn-sm" (click)="viewUserPermission(this.userList[i].id)">Permission</button>
                                    <button *ngIf="item.type==1" class="btn btn-danger btn-sm"><i class="fa fa-user-secret"></i> All Assing</button>
                                    <button type="button" class="btn text-dark btn-sm ml-2 rounded bg-light" (click)="edit(this.userList[i].id,content)"><i class="fa fa-edit"></i></button>
                              
                                    <button *ngIf="item.type !=1" class="ml-1 btn text-danger btn-sm rounded bg-light" (click)="delete(this.userList[i].id)"><i class="fa fa-trash"></i></button>
                                    <!-- <ui-switch size="small" defaultBgColor="red" (change)="delete(this.userList[i].id)" *ngIf="item.type !=1 && item.status=='1'" checked></ui-switch>
											              <ui-switch size="small" defaultBgColor="red" (change)="delete(this.userList[i].id)" *ngIf="item.type !=1 && item.status=='0'"></ui-switch> -->
                                  </td>
                              </tr>
                          </tbody>
                       </table>
                    </div>
                  </div>
                </div>
              </div>
          <!--/.col-->
      </div>
  </div>
  <!-- class="modal fade" -->
  <ng-template #content let-c="close" let-d="dismiss" id="user-add"> 
      <div class="modal-info" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">{{modalTitle}}</h4>
                  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                <alert>
                  <div class="alert alert-danger"> <a class="close">×</a></div>
                </alert>
                <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
                 
                    <div class="row">                      
                      <div class="form-group col-6">
                        <label for="name" class=" col-form-label">Name <span>*</span></label>
                        <div class="">
                          <input class="form-control" type="text" id="name" name="name" required [(ngModel)]="user.name" >
                          <div class="alert-block">
                            <span *ngIf="isSubmitted && isFieldsInvalid(user.name)" >This field is required</span>
                          </div>    
                        </div>
                     </div>

                      <div class="form-group col-6">
                        <label for="email" class=" col-form-label">Email <span>*</span></label>
                        <div class="">
                          <input *ngIf="user.id" class="form-control" type="email" id="email" name="email" required readonly [(ngModel)]="user.email">
                          <input *ngIf="!user.id" class="form-control" type="email" id="email" name="email" required [(ngModel)]="user.email">
                        </div>
                        <span *ngIf="isSubmitted && (user.email==undefined || user.email=='')" class="alert-block">This field is required</span>
                      </div>
                      <div class="form-group col-6">
                        <label for="phone" class=" col-form-label">Phone <span>*</span></label>
                        <div class="">
                          <input class="form-control" type="text" id="phone" name="phone" required [(ngModel)]="user.phone" maxlength="10"  pattern="^\d{10}$">
                        </div>
                        <span *ngIf="isSubmitted && (user.phone==undefined || user.phone=='')" class="alert-block">This field is required</span>
                        <span class="alert-block" *ngIf="f.controls.phone?.errors?.pattern">Invalid mobile number format. Please enter a 10-digit number.</span>
                      </div>
                      <div class="form-group col-6">
                        <label *ngIf="user.id" for="password" class=" col-form-label">Password</label>
                        <label *ngIf="!user.id" for="password" class=" col-form-label">Password <span>*</span></label>
                        <div class="">
                          <input *ngIf="user.id" class="form-control" type="password" id="password" name="password" [(ngModel)]="user.password">
                          <input *ngIf="!user.id" class="form-control" type="password" id="password" name="password" required [(ngModel)]="user.password" >
                        </div>
                        <span *ngIf="isSubmitted && (user.password==undefined || user.password=='') && !user.id" class="alert-block">This field is required</span>
                      </div>
                     
                      <div class="form-group col-6">
                        <label for="type" class=" col-form-label">Type <span>*</span></label>
                        <div class="">
                          <select class="form-control" id="type" name="type" required [(ngModel)]="user.type">
                            <option value="">Please Select</option>
                            <option *ngFor="let item of userType" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <span *ngIf="isSubmitted && (user.type==undefined || user.type=='')" class="alert-block">This field is required</span>
                        </div>
                      </div>

                      <div class="form-group col-6">
                        <label for="status" class=" col-form-label">Status</label>
                        <div class="">
                          <select class="form-control" id="status" name="status" [(ngModel)]="user.status">
                            <option value="0" selected>Inactive</option>
                            <option value="1">Active</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-group col-6">
                        <label for="address" class=" col-form-label">Address <span>*</span></label>
                        <div class="">
                          <textarea class="form-control" type="text" id="address" required name="address" [(ngModel)]="user.address"></textarea>
                        </div>
                        <span *ngIf="isSubmitted && isFieldsInvalid(user.address)" class="alert-block">This field is required</span>
                      </div>

                      <div class="form-group col-6">
                        <label class=" form-control-label">Image</label>
                         <div class="">
                           <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="imageSrc !=''"
                           [src]="imageSrc" />
                          <div class="input-group">
                               <span class="input-group-btn">
                                      <label class="btn btn-secondary text-white">
                                        <samp>Browse</samp>...
                                          <input type="file" accept="{{fileType}}"  style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple" >
                                      </label>
                                  </span>
                              <input type="text" class="form-control" [value]="DisplayedText"  [class.ng-invalid-right]="required && !DisplayedText"  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;" >
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              <div class="modal-footer">
                  <button type="submit" class="btn btn-primary addbtn" (click) ="save(this.user,f)">Save</button>
              </div>
          </div>
      </div>
  </ng-template>
</div>



