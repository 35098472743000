import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, DamagedProductService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from '../app.config';

class product {
  id: number;
  category: number;
  purchase_price: number;
  sub_category: number;
  stock_quantity: number;
  name: string;
  note: string;
  date: string;
}

@Component({
  selector: 'app-damaged-product',
  templateUrl: './damaged-product.component.html'
})

export class DamagedProductComponent implements OnInit {
	pdf = false;
	exl = false;
	dtOptions: DataTables.Settings = {}; //  DataTable
	dtTrigger = new Subject(); //  DataTable
	DamagedProductList: product[] = []; // Table Data list
	// damagedProductAddForm: FormGroup;
	getProduct = {
	    id:'',
	    name: '',
	    quantity: '',
	    note: ''
	  };
	product = {
		id:'',
	    allProduct: '',
	    quantity: '',
	    note: '',
	};
	modalReference:NgbActiveModal;
	modalTitle:string;
	productList:any[]=[];
	typeList = [{id:1,name:'Yes'},{id:0,name:'No'}];
	setting = {currency:''};
	showloding = true;
  	lodingImage = false;

  	constructor(
  				public router: Router,
  				private dataService:DamagedProductService,
					private alertService: AlertService,
					private AppService: AppService,
					private modalService: NgbModal
        ) { }

	ngOnInit() {

        // this.damagedProductAddForm = new FormGroup({
	    //   allProduct : new FormControl("", Validators.compose([Validators.required])),
	    //   quantity: new FormControl("", Validators.compose([Validators.required])),
	    //   note: new FormControl("")
	    // });

	    this.allDamagedProduct();
	   	let settingData = JSON.parse(localStorage.getItem('setting'));
    	this.setting = {currency:settingData.currency};
  	}

  	allDamagedProduct(){
		this.loadShow();
    	this.dataService.allDamagedProduct()
          .pipe().subscribe(
			data => { this.DamagedProductList = data['product']; 
			this.dtTrigger.next(); // Data Table
			this.pdf = true;
			this.exl = true;
			this.loadHide();
		});
  	}

  	getAllproduct(type){
		this.dataService.getAllProduct(type)
				.pipe().subscribe(data => { 
    				this.productList = data['product'];
      	});
	}

  	open(content) {
  		this.product = {id:'',allProduct: '',quantity: '',note: '',};
		if (this.getProduct.id !=null) {
			this.getProduct.id='';
		}
		this.modalTitle = "Add Damaged Product";
		this.getAllproduct(0);
		this.modalReference = this.modalService.open(content);
	}

	save(val){
		if(val.id==""){
			this.dataService.save(this.product)
					.pipe().subscribe(data =>{
			      this.modalReference.close();
			      this.dtTrigger = new Subject(); //  DataTable
			      this.allDamagedProduct();
			      this.alertService.success('Damaged Product Create successful', true);
			  },error =>{
			    this.alertService.error(error);
			  });
	    }else{
	      	this.dataService.damagedProductUpdate(this.product).pipe().subscribe(data =>{
	            this.modalReference.close();
	            this.dtTrigger = new Subject(); //  DataTable
	            this.allDamagedProduct();
	            this.alertService.success('Damaged Product update successful', true);
	          },error =>{
	            this.alertService.error(error);
	          });
    	   }
	    }


	edit(id,content){
    	this.loadShow();
    	this.getAllproduct(1);
    	this.dataService.getDamagedProduct(id)
						.pipe().subscribe(data => { 
							let product = data['product'];
              this.product = {
                    id:product.id,
                    allProduct:product.product_id,
                    quantity:product.quantity,
                    note:product.note,
              };
              this.loadHide();
              this.modalTitle = "Edit Damaged Product";
              this.modalReference = this.modalService.open(content);
            });
       }

	pdfExport(){
		window.open(appConfig.apiUrl+"/api/damaged-product-list-pdf", "_blank");
	}
	
	xlExport(){
		window.open(appConfig.apiUrl+"/api/damaged-product-list-excel", "_blank");
	}

	loadShow(){
	    this.showloding = true;
	    this.lodingImage = false;
	}

	loadHide(){
	    this.showloding = false;
		this.lodingImage = true;
	}

}
