<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">WEP Mentorship</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{mentorList.length}}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Name</th>
										<th>Email</th>
										<th>Mobile</th>
										<th>State</th>
										<th>City</th>
										<th style="width: 5%;">Type</th>
										<!-- <th>Image/Logo</th> -->
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of mentorList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td *ngIf="item.type=='I'">{{item.first_name}} {{item.last_name}}</td>
										<td *ngIf="item.type=='O'">{{item.organization_name}}</td>
										<td>{{item.email}}</td>
										<td>{{item.mobile}}</td>
										<td>{{item.state_name}}</td>
										<td>{{item.city_name}}</td>
										<td *ngIf="item.type=='I'">Individual</td>
										<td *ngIf="item.type=='O'">Organisation</td>
										<!-- <td></td> -->
										<td class="action">
											<button *ngIf="item.type=='O'" type="button" class="btn btn-eye" (click)="openMentorInfoMdalOrg(this.mentorList[i].id,mentorinfo_org)">
												<i class="fa fa-eye"></i></button>&nbsp;
											<button *ngIf="item.type=='I'" type="button" class="btn btn-eye" (click)="openMentorInfoMdalInd(this.mentorList[i].id,mentorinfo_ind)">
													<i class="fa fa-eye"></i></button>&nbsp;
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #mentorinfo_org let-c="close" let-d="dismiss" id="mentorinfo_org">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Mentor Information</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-lg-12">
								<table class="table table-bordered">
									<tbody>
										<tr>
											<th>Organisation Name</th>
											<td>{{mentorInfo.organization_name}}</td>
											<th>Age of Organisation</th>
											<td>{{mentorInfo.age_of_organization}}</td>
										</tr>
										<tr>
											<th>Email</th>
											<td>{{mentorInfo.email}}</td>
											<th>Mobile</th>
											<td>{{mentorInfo.mobile}}</td>
										</tr>
										<tr>
											<th>Type of organization</th>
											<td>{{mentorInfo.type_of_organization}}</td>
											<th>Address</th>
											<td>{{mentorInfo.address}}</td>
										</tr>
										<tr>
											<th>Country</th>
											<td>India</td>
											<th>State</th>
											<td>{{mentorInfo.state_name}}</td>
										</tr>
										<tr>
											<th>City</th>
											<td>{{mentorInfo.city_name}}</td>
											<th>Linkedin profile url</th>
											<td>{{mentorInfo.linkedin_profile_url}}</td>
										</tr>
										<tr>
											<th>Spoken Language</th>
											<td>{{mentorInfo.spoken_lang_names}}</td>
											<th>Primary Industry</th>
											<td>{{mentorInfo.primary_industry_name}}</td>
										</tr>
										<tr>
											<th>Stage of Enterprise</th>
											<td>{{mentorInfo.stage_of_enterprise_names}}</td>
											<th>Industry Experience</th>
											<td>{{mentorInfo.industry_experience_name}}</td>
										</tr>
										<tr>
											<th>Area of functional expertise</th>
											<td>{{mentorInfo.area_of_functional_expertise_name}}</td>
											<th>Provide Mentoring</th>
											<td>{{mentorInfo.kind_of_mentoring_name}}</td>
										</tr>
										<tr>
											<th>Preferred Track of Mentorship</th>
											<td>{{mentorInfo.track_of_mentorship_names}}</td>
											<th>Ecosystem Key</th>
											<td>{{mentorInfo.ecosystem_key_names}}</td>
										</tr>
										<tr>
											<th>Preference Across</th>
											<td>{{mentorInfo.preference_across_name}}</td>
											<th>Mode of Engagement</th>
											<td>{{mentorInfo.mode_of_engagement_name}}</td>
										</tr>
										<tr>
											<th>Willing no of mentees</th>
											<td>{{mentorInfo.willing_no_of_mentees_name}}</td>
											<th>No of hours per month</th>
											<td>{{mentorInfo.no_of_hours_per_month_name}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #mentorinfo_ind let-c="close" let-d="dismiss" id="mentorinfo_ind">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Mentor Information</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-lg-12">
								<table class="table table-bordered">
									<tbody>
										<tr>
											<th>First Name</th>
											<td>{{mentorInfo.first_name}}</td>
											<th>Last Name</th>
											<td>{{mentorInfo.last_name}}</td>
										</tr>
										<tr>
											<th>Email</th>
											<td>{{mentorInfo.email}}</td>
											<th>Mobile</th>
											<td>{{mentorInfo.mobile}}</td>
										</tr>
										<tr>
											<th>Birth Year</th>
											<td>{{mentorInfo.birth_yr}}</td>
											<th>Gender</th>
											<td>{{mentorInfo.gender}}</td>
										</tr>
										<tr>
											<th>Country</th>
											<td>India</td>
											<th>State</th>
											<td>{{mentorInfo.state_name}}</td>
										</tr>
										<tr>
											<th>City</th>
											<td>{{mentorInfo.city_name}}</td>
											<th>Linkedin profile url</th>
											<td>{{mentorInfo.linkedin_profile_url}}</td>
										</tr>
										<tr>
											<th>Spoken Language</th>
											<td>{{mentorInfo.spoken_lang_names}}</td>
											<th>Primary Industry</th>
											<td>{{mentorInfo.primary_industry_name}}</td>
										</tr>
										<tr>
											<th>Stage of Enterprise</th>
											<td>{{mentorInfo.stage_of_enterprise_names}}</td>
											<th>Industry Experience</th>
											<td>{{mentorInfo.industry_experience_name}}</td>
										</tr>
										<tr>
											<th>Area of functional expertise</th>
											<td>{{mentorInfo.area_of_functional_expertise_name}}</td>
											<th>Provide Mentoring</th>
											<td>{{mentorInfo.kind_of_mentoring_name}}</td>
										</tr>
										<tr>
											<th>Preferred Track of Mentorship</th>
											<td>{{mentorInfo.track_of_mentorship_names}}</td>
											<th>Ecosystem Key</th>
											<td>{{mentorInfo.ecosystem_key_names}}</td>
										</tr>
										<tr>
											<th>Preference Across</th>
											<td>{{mentorInfo.preference_across_name}}</td>
											<th>Mode of Engagement</th>
											<td>{{mentorInfo.mode_of_engagement_name}}</td>
										</tr>
										<tr>
											<th>Willing no of mentees</th>
											<td>{{mentorInfo.willing_no_of_mentees_name}}</td>
											<th>No of hours per month</th>
											<td>{{mentorInfo.no_of_hours_per_month_name}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>