import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { map } from "rxjs/operators";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, CountryService, AppService } from "../_services/index";
import $ from "jquery/dist/jquery";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class Country {
  id: number;
  sortname: string;
  country_name: string;
  phonecode: string;
  created_by_name: string;
  created_at: string;
  is_active: string;
}

@Component({
  selector: "app-country",
  templateUrl: "./country.component.html",
})
export class CountryComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject(); //  DataTable
  countryList: Country[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getCountry = {
    id: "",
    sortname: "",
    country_name: "",
    phonecode: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;

  constructor(
    public router: Router,
    private dataService: CountryService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  country = {
    id: "",
    sortname: "",
    country_name: "",
    phonecode: "",
    is_active: "Y",
  };

  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allCountry();
  }

  allCountry() {
    this.loadShow();
    this.dataService
      .getAllCountry()
      .pipe()
      .subscribe((data) => {
        this.countryList = data["country"];
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.isSubmitted = false;
    if (this.getCountry.id != null) {
      this.getCountry.id = "";
    }
    (this.country.sortname = ""),
      (this.country.country_name = ""),
      (this.country.phonecode = ""),
      (this.country.is_active = "Y");
    this.modalTitle = "Add Country";
    this.modalReference = this.modalService.open(content);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .getCountry(id)
      .pipe()
      .subscribe((data) => {
        this.getCountry = data["country"];
        this.country = {
          id: this.getCountry.id,
          sortname: this.getCountry.sortname,
          country_name: this.getCountry.country_name,
          phonecode: this.getCountry.phonecode,
          is_active: this.getCountry.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit Country";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;

    if (
      this.isFieldsInvalid(this.country.country_name) ||
      this.isFieldsInvalid(this.country.sortname) ||
      this.isFieldsInvalid(this.country.phonecode)
    ) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.country)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("Country saved successfully", true);
              this.allCountry();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .countryUpdate(this.country)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("Country updated successfully", true);
              this.allCountry();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .countryDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Country deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allCountry();
          } else {
            this.alertService.error("Country error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
