import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/';
import { map } from 'rxjs/operators';
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, FaqCategoryService, AppService } from '../_services/index';
import $ from 'jquery/dist/jquery';
import {NgbModal, NgbActiveModal,NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

class FaqCategory {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: 'app-faq-category',
  templateUrl: './faq-category.component.html'
})
export class FaqCategoryComponent implements OnInit {

  dtOptions: DataTables.Settings = {}; //  DataTable
    dtTrigger = new Subject(); //  DataTable
    faqcategoryList: FaqCategory[] = []; // Table Data list
    // countryAddForm: FormGroup;
    getFaqCategory = {
      id:'',
      name: '',
      description: '',
      is_active: 'N'
    };
    modalReference:NgbActiveModal;
    modalTitle:string;
    constructor(
                public router: Router,
                private dataService:FaqCategoryService,
                private alertService: AlertService,
                private AppService: AppService,
                private modalService: NgbModal
               ) { }
  faqcategory = {
      id:'',
      name: '',      
      description: '',
      is_active: 'Y'
    };
    showloding = true;
    lodingImage = false;
    ngOnInit() {  
      this.allFaqCategory();
    }
  
    allFaqCategory(){
      this.loadShow();
      this.dataService.getAllFaqCategory()
          .pipe().subscribe(data => { 
            this.faqcategoryList = data['faqcategory']; 
            this.dtTrigger.next(); // Data Table
            this.loadHide();
          });
    }
  
    open(content) {      
      this.faqcategory.id='';
      this.faqcategory.name = '',
      this.faqcategory.description = '',
      this.faqcategory.is_active = 'Y';
      this.modalTitle = "Add FAQ Category";
      this.modalReference = this.modalService.open(content);
    }
  
    edit(id,content){
      this.loadShow();
      this.dataService.getFaqCategoryById(id)
          .pipe().subscribe(data => { 
                  this.getFaqCategory = data['faqcategory']; 
                  this.faqcategory = {
                        id:this.getFaqCategory.id,
                        name:this.getFaqCategory.name,                        
                        description:this.getFaqCategory.description,
                        is_active:this.getFaqCategory.is_active
                  };
                  this.loadHide();
                  this.modalTitle = "Edit FAQ Category";
                  this.modalReference = this.modalService.open(content);
          });
  
    }
  
    save(val){
  
      if(val.id == ''){
        this.dataService.save(this.faqcategory)
            .pipe().subscribe(data =>{
              this.modalReference.close();
              this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('FAQ category saved successfully', true);
              this.allFaqCategory();
            },error =>{
              this.alertService.error(error);
            });
      }else{
        this.dataService.faqCategoryUpdate(this.faqcategory)
            .pipe().subscribe(data =>{
              this.modalReference.close();
              this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('FAQ category updated successfully', true);
              this.allFaqCategory();
            },error =>{
              this.alertService.error(error);
            });
          }
       }
  
    delete(id){
      if(confirm("Are you sure to delete")) {
        this.dataService.faqCategoryDelete(id)
            .pipe().subscribe(data =>{
              if(data['status']==200){
                this.dtTrigger = new Subject(); //  DataTable
                this.alertService.success('FAQ category deleted successfully', true);
                this.allFaqCategory();
              }else{
                this.alertService.error('FAQ category error');
              }
            },error =>{
              this.alertService.error(error);
            });
      }
    }
  
    loadShow(){
      this.showloding = true;
      this.lodingImage = false;
    }
  
    loadHide(){
      this.showloding = false;
      this.lodingImage = true;
    }

}
