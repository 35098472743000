
  // $(document).ready(function(){
  //   $('#demo_123').daterangepicker({
  //     "startDate": "04/20/2024",
  //     "endDate": "04/26/2024"
  //   }, function (start, end, label) {
  //     //console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
  //   });
  // })


  var barChart_data_1 = null;
  function myInitFunctionBar() {
     
    var users_by_state1 = JSON.parse(localStorage.getItem('users_by_state1')) || [];
    var users_by_state_registered_users1 = JSON.parse(localStorage.getItem('users_by_state_registered_users1')) || [];
    var users_by_state_logedin_users1 = JSON.parse(localStorage.getItem('users_by_state_logedin_users1')) || [];

    var ctx = document.getElementById("barChart").getContext('2d');

    if (barChart_data_1) {
      //console.log('destroy')
      barChart_data_1.destroy();
    }
    barChart_data_1 = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: users_by_state1,
            datasets: [{
                label: 'Register',
                data: users_by_state_registered_users1,
                backgroundColor: "#58508d"
            }, {
                label: 'Login',
                data: users_by_state_logedin_users1,
                backgroundColor: "#bc5090"
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    });
}


function myInitFunction() {



    // Registered users By profession
    var ctx = document.getElementById("barChart1").getContext('2d');
    var barChart1 = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ["Data science", "Accounting", "Adverstising", "Banking", "BPO", "Coaching", "Fashion", "Energy", "Blockchain","Green Technology","Graphic Design", "Healthcare","IT services","Insurance", "Legal Services"],
        datasets: [{
          label: 'Register',
          data: [12, 19, 3, 17, 28, 24, 7,12, 19, 3, 17, 28, 24, 7,2],
          backgroundColor: "#58508d"
        }, {
          label: 'Login',
          data: [30, 29, 5, 5, 20, 3, 10,30, 29, 5, 5, 20, 3, 10,3],
          backgroundColor: "#bc5090"
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }

    });

    //Register Users By Age
    var users_by_age = JSON.parse(localStorage.getItem('users_by_age'));
    var users_by_age_registered_users = JSON.parse(localStorage.getItem('users_by_age_registered_users'));

    var ctx = document.getElementById("barchart3").getContext('2d');
    var barchart3 = new Chart(ctx, {
      type: 'bar',
      data: {
        //labels: ["20", "30", "40", "50", "60", "70", "70>"],
        labels:users_by_age,
        datasets: [{
          label: 'Age',
          //data: [12, 19, 3, 17, 28, 24, 7],
          data:users_by_age_registered_users,
          backgroundColor: "#e85285"
        }]
      }
    });

    //Total Views By Page
    var total_views_by_pages = JSON.parse(localStorage.getItem('total_views_by_pages'));
    var total_views_by_pages_views = JSON.parse(localStorage.getItem('total_views_by_pages_views'));

    var ctx = document.getElementById("barchart4").getContext('2d');
    var barchart4 = new Chart(ctx, {
      type: 'bar',
      data: {
        //labels: ["Home", "Access to finance", "Schemes", "Incubators & Accelerators", "Event", "Videos", "Newsletters", "Publications", "FAQs", "Feedback"],
        labels:total_views_by_pages,
        datasets: [{
          label: 'Page View',
          //data: [12, 19, 3, 17, 28, 24, 7, 50, 35, 15],
          data: total_views_by_pages_views,
          backgroundColor: "#ea7369"
        }]
      }
    });

    //Total Views By Verticals
    var total_views_by_verticles = JSON.parse(localStorage.getItem('total_views_by_verticles'));
    var total_views_by_verticles_views = JSON.parse(localStorage.getItem('total_views_by_verticles_views'));

    var ctx = document.getElementById("barchart5").getContext('2d');
    var barchart5 = new Chart(ctx, {
      type: 'bar',
      data: {
        //labels: ['Funding', 'Incubation and Acceleration', 'Mentoring and Networking', 'Market Linkages', 'Business Development Services', 'Research & Innovation'],
        labels: total_views_by_verticles,
        datasets: [{
          label: 'Page Views Vertical ',
          //data: [12, 19, 3, 17, 28, 24],
          data: total_views_by_verticles_views,
          backgroundColor: "#1a69ac"
        }]
      }
    });

    //Register Users By sector of business
    var sector_of_business = JSON.parse(localStorage.getItem('sector_of_business'));
    var sector_of_business_registered_users = JSON.parse(localStorage.getItem('sector_of_business_registered_users'));
    var businessColours = [];
    // for (let i = 0; i < sector_of_business.length; i++) {
    //   businessColours.push(colors.getRandom());
    // }
    // //console.log(JSON.stringify(businessColours));

    var ctx = document.getElementById("piechart").getContext('2d');
    var piechart = new Chart(ctx, {
      type: 'pie',
      data: data = {
        // labels: [
        //   'IT',
        //   'Financial Services',
        //   'Enterpreneureship Development',
        //   'Advisory & Support',
        //   'Social media',
        //   'Technology business incubation',
        //   'Digital Payments',
        //   'Advisory & Support',
        //   'Social media',
        //   'Technology business incubation',
        // ],
        labels : sector_of_business,
        datasets: [{
          label: 'Sector of Business',
          // data: [300, 50, 100, 167, 234, 200, 170, 167, 234, 200,],
          data: sector_of_business_registered_users,
          backgroundColor: [
            'teal',
            'cyan',
            'purple',
            'yellow',
            'red',
            'blue',
            'green',
            'orange',
            'pink',
            'brown',
          ],
          // backgroundColor: JSON.stringify(businessColours),
          hoverOffset: 4
        }]
      },
      options: {
        responsive: true,
        legend: {
          position: 'right',
        }
      }
    });

    //Register Users By Area of Interest
    var area_of_interest = JSON.parse(localStorage.getItem('area_of_interest'));
    var area_of_interest_registered_users = JSON.parse(localStorage.getItem('area_of_interest_registered_users'));
    var interestColours = [];
    // for (let i = 0; i < area_of_interest.length; i++) {
    //   interestColours.push(colors.getRandom());
    // }
    ////console.log(JSON.stringify(interestColours))

    var ctx = document.getElementById("piechart1").getContext('2d');
    var piechart1 = new Chart(ctx, {
      type: 'pie',
      data: data = {
        // labels: [
        //   'Funding',
        //   'Incubation and Acceleration',
        //   'Mentoring and Networking',
        //   'Market Linkages',
        //   'Business Development Services',
        //   'Research & Innovation'
        // ],
        labels : area_of_interest,
        datasets: [{
          label: 'Area of Interest ',
          // data: [300, 450, 500, 700, 400, 278],
          data: area_of_interest_registered_users,
          backgroundColor: [
            'teal',
            'cyan',
            'purple',
            'yellow',
            'red',
            'blue',
            'green',
            'orange',
            'pink',
            'brown',
          ],
          // backgroundColor: JSON.stringify(interestColours),
          hoverOffset: 4
        }]
      },
      options: {
        responsive: true,
        legend: {
          position: 'right',
        }
      }
    });

    // chart.render();
  }

