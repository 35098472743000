<div class="app flex-row align-items-center loginbanner">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-7">
                <div class="cardlogin mx-4">
                    <form method="post" (ngSubmit)="f.form.valid && onLoggedin()" #f="ngForm" novalidate>
                        <div class="card-body px-4">
                            <div class="card-block">
                                <div id="brand-logo" *ngIf="setting.image">
                                    <img src="assets/img/logo.png">
                                </div>
                                <h1 class="text-center"
                                    style=" text-transform: uppercase; color:#b4b4b4; margin: 45px 0;">Admin panel</h1>
                                <p class="text-muted text-center">Sign In to your account</p>
                                <span class="help-block alert-text" *ngIf="show">{{textMesg}}</span>
                                <div class="form-group">
                                    <div class="input-group mb-2"
                                        [ngClass]="{'has-danger': f.submitted && model.username==undefined || model.username==''}">
                                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                        <input type="text" class="form-control" placeholder="Email"
                                            (keydown.space)="$event.preventDefault();" required name="username"
                                            [(ngModel)]="model.username" #username="ngModel">
                                    </div>
                                    <span *ngIf="f.submitted && model.username==undefined || model.username==''"
                                        class="help-block alert-text">Email is required</span>
                                </div>
                                <div class="form-group">
                                    <div class="input-group mb-2">
                                        <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                        <input [type]="fieldTextTypeLogin ? 'text' : 'password'" class="form-control" placeholder="Password"
                                               (keydown.space)="$event.preventDefault();" required name="password" [(ngModel)]="model.password">
                                        <span (click)="toggleFieldTextType()" class="input-group-text">
                                            <i class="fa" [ngClass]="passwordIconClass"></i>
                                        </span>
                                    </div>
                                    
                                    <span *ngIf="f.submitted && model.password==undefined || model.password=='' "
                                        class="help-block alert-text">Password is required</span>
                                </div>
                                <div class="form-group text-center mt-4">
                                    <button type="submit" class="btn btn-primary">Login</button>
                                    <img *ngIf="loading" src="assets/img/loding-btn.gif">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>