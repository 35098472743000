import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterAnalyticalService {

  constructor(private base: BaseService) { }

  getNewsLetterAnalytical(){
    return  this.base.get('/api/newsletter-analytic-list');
  }

}
