import {
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { appConfig } from "../app.config";
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, AppService, UserService } from "../_services/index";
import { EncryptionService } from "../_services/encryption.service";
import { HttpHeaders } from "@angular/common/http";
import { PdfService } from "../_services/pdf.service";
import { ExcelService } from "../_services/excel.service";

class User {
  id: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  type: number;
  status: number;
}
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
})
export class UserComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg, .jpeg, .png, .bmp";
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  imageSrc: string = "";
  fileList: any;
  pdf = false;
  exl = false;
  isSubmitted = false;
  mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  fieldsToIncludePdf: any = [];
  fieldsToIncludeExcel: any = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  userList: User[] = []; // Table Data list
  // userAddForm :FormGroup;
  getUser = {
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    type: "",
    status: "",
    image: "",
  };
  //userType = [{id:1,name:'Admin'},{id:2,name:'user'}];
  userType: any[] = [];

  constructor(
    private pdfService: PdfService,
    public router: Router,
    private dataService: UserService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    private excelService: ExcelService,
    private encryptionService: EncryptionService
  ) {}

  user = {
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    type: "",
    status: "",
    file: "",
  };
  modalReference: NgbActiveModal;
  options: NgbModalOptions = { size: "lg" };
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.allUser();
    this.dataService
      .getRoleList()
      .pipe()
      .subscribe((data) => {
        this.userType = data["roles"];
      });
  }

  allUser() {
    this.loadShow();
    this.dataService
      .getAlluser()
      .pipe()
      .subscribe((data) => {
        this.userList = data["user"].map((user, index) => ({
          ...user,
          serialNo: index + 1,
        }));
        //console.log(this.userList, "testttt");

        this.fieldsToIncludePdf = ["serialNo", "name", "email", "phone"];
        this.fieldsToIncludeExcel = ["name", "email", "phone"];
        //this.dtTrigger.next(); // Data Table
        this.rerender();
        this.pdf = true;
        this.exl = true;
        this.loadHide();
      });
  }

  exportToPdf() {
    this.pdfService.generatePdf(this.userList, this.fieldsToIncludePdf);
  }

  exportToExcel() {
    this.excelService.generateExcelUsers(
      this.userList,
      this.fieldsToIncludeExcel
    );
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (
      this.isFieldsInvalid(this.user.name) ||
      this.isFieldsInvalid(this.user.address)
    ) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  viewUserPermission(id) {
    this.router.navigate(["user-role/" + id]);
  }

  edit(id, content) {
    this.imageSrc = "";
    this.loadShow();
    this.modalTitle = "Edit User";

    this.dataService
      .getUser(id)
      .pipe()
      .subscribe((data) => {
        if (data["status"] == 200) {
          this.getUser = data["user"];
          //console.log(this.getUser, "Edit");

          this.imageSrc = this.getUser.image;
          //console.log(this.imageSrc, "imagesrc");

          this.user = {
            id: this.getUser.id,
            name: this.getUser.name,
            email: this.getUser.email,
            phone: this.getUser.phone,
            address: this.getUser.address,
            password: "",
            type: this.getUser.type,
            status: this.getUser.status,
            file: this.getUser.image,
          };
        }
        this.loadHide();
        this.modalReference = this.modalService.open(content);
      });
  }

  open(content) {
    this.imageSrc = "";
    this.isSubmitted = false;
    this.DisplayedText = "";
    this.user = {
      id: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      type: "",
      status: "0",
      file: "",
    };
    this.modalTitle = "Add User";
    this.modalReference = this.modalService.open(content);
  }

  fileChange(event: any) {
    this.fileList = event.target.files;
    this.DisplayedText = this.fileList[0].name
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = ".jpg , .png , .bmp";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are .jpg , .png , .bmp"
        );
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        this.DisplayedText = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
          this.user.file = this.imageSrc;
        };
      }
      this.onSelection.emit(this.fileList);
    }
  }

  insertAction(val) {
    this.loadShow();
    let formData: FormData = new FormData();

    formData.append("name", this.user.name);
    formData.append("email", this.user.email);
    formData.append("phone", this.user.phone);
    formData.append("address", this.user.address);
    formData.append("password", this.user.password);
    formData.append("type", this.user.type);
    formData.append("status", this.user.status);

    if (val.id == undefined || val.id == "") {
      if (this.fileList !== undefined) {
        formData.append("file", this.imageSrc);
      }

      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.loadHide();
            if (data["status"] == 200) {
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.allUser();
              this.alertService.success("User Created successfully", true);
              this.imageSrc = "";
            } else if (data["status"] == 300) {
              this.alertService.success("User already exists", true);
            } else if (data["status"] == 422) {
              document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
              if(data && data.mesg && data.mesg.email){
                this.alertService.error(data.mesg.email[0]);
                this.loadHide();
              }else if(data && data.mesg && data.mesg.password){
                this.alertService.error(data.mesg.password[0]);
                this.loadHide();
              }
              
            }
          },
          (error) => {
            this.alertService.error(error);
            document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
            this.loadHide();
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.fileList !== undefined) {
        formData.append("file", this.imageSrc);
      }
      this.dataService
        .userUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.loadHide();
            if (data["status"] == 200) {
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.allUser();
              this.alertService.success("User Updated successfully", true);
            } else if (data["status"] == 300) {
              this.alertService.error("User already exists", true);
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
            this.loadHide();
          }
        );
    }
  }

  delete(id) {
    this.loadShow();
    this.dataService
      .userDelete(id)
      .pipe()
      .subscribe(
        (data) => {
          this.loadHide();
          this.allUser();
          this.alertService.success("User Delete successful", true);
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  xlExport() {
    window.open(appConfig.apiUrl + "/api/user-list-excel", "_blank");
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
