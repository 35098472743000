import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AlertService, ContactFormService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styles: []
})
export class ContactFormComponent implements OnInit {

  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  contactFormList: any[] = []; // Table Data list
  closeResult: string; // Modal Close

  constructor(
      public router: Router,
      private dataService:ContactFormService,
      private alertService: AlertService,
      private AppService: AppService,
      private modalService: NgbModal
  ) { }

  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    this.allContactForm();
  }

  allContactForm(){
    this.loadShow();
    this.dataService.getContactFormData()
        .pipe().subscribe(
          data => { this.contactFormList = data['data'];
          // //console.log(data['data']);
          this.dtTrigger.next(); // Data Table          
          this.loadHide();
        });
      }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

}
