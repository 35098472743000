import { Component, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { AlertService, PartnerListService } from "../_services";
import { Router } from "@angular/router";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { JioMeetingsService } from "../_services/jio-meetings.service";
import { DataTableDirective } from "angular-datatables";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../_services/encryption.service";
import { ExcelService } from "../_services/excel.service";

declare const $: any;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-jio-meetings",
  templateUrl: "./jio-meetings.component.html",
  styleUrls: [],
})
export class JioMeetingsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  closeResult: string;
  menteeName: any;
  start: number = 0;
  pageLength: number = 10;
  mentorName: any;
  dynamicRating: any;
  dynamic_rating: any;
  feedBackList: any = [];
  jioMeetList: any[] = [];
  exprtJioMeetList: any = [];
  jioMeetListTotal: any;
  fieldsToIncludeExcel: any = [];
  exl: boolean = false;
  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  constructor(
    public router: Router,
    private dataService: JioMeetingsService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private excelService: ExcelService,
    private enc_dec: EncryptionService
  ) {}

  menteeFeedBack = {
    created_at: "",
    created_by: "",
    feedback: "",
    feedback_by: "",
    feedback_by_id: "",
    id: "",
    meeting_id: "",
    rating: "",
  };

  mentorFeedBack = {
    created_at: "",
    created_by: "",
    feedback: "",
    feedback_by: "",
    feedback_by_id: "",
    id: "",
    meeting_id: "",
    rating: "",
  };

  ngOnInit() {
    this.getAllInviteMember();
    window.scrollTo({ top: 0, behavior: "smooth" });
    // this.loadHide();
    this.jiomeetList();
  }

  getAllInviteMember() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      order: [[7, "desc"]], // Default sorting on "status"
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;
        this.http
          .post<DataTablesResponse>(
            "/api/jio-meet-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            const resp = JSON.parse(output);

            resp.data.forEach((item: any) => {
              item.Mentor_Meeting_Duration = this.convertTime(
                item.Mentor_Meeting_Duration
              );
              item.Mentee_Meeting_Duration = this.convertTime(
                item.Mentee_Meeting_Duration
              );
            });

            this.jioMeetList = resp["data"];
            this.jioMeetListTotal = resp["recordsTotal"];

            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [], // Data binding happens in Angular, so we don't need to return data here
            });
          });
      },
      columns: [
        { data: "id" }, // SL column remains sortable
        { data: "topic" },
        { data: "mentor_name" },
        { data: "mentee_name" },
        { data: "Mentor_Meeting_Duration" },
        { data: "Mentee_Meeting_Duration" },
        { data: "created_at" },
        { data: "status" },
      ],
    };
  }

  convertTime(timeString: any) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  jiomeetList() {
    this.loadShow();
    this.dataService
      .exportJiomeetList()
      .pipe()
      .subscribe((res) => {
        if (res.status == 200) {
          this.exprtJioMeetList = res["data"];
          //console.log(this.exprtJioMeetList, "exprtJioMeetList");
          this.fieldsToIncludeExcel = [
            "topic",
            "mentor_name",
            "mentee_name",
            "Mentor_Meeting_Duration",
            "Mentee_Meeting_Duration",
            "created_at",
            "status",
          ];
          this.exl = true;
        }
        this.loadHide();
      });
  }

  exportToExcel() {
    this.excelService.generateExcel(
      this.exprtJioMeetList,
      this.fieldsToIncludeExcel
    );
  }

  openFeedbackModel(slug, item, content) {
    const modalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.menteeName = item.mentee_name;
    this.mentorName = item.mentor_name;

    this.loadShow();
    const data = {
      slug: slug,
    };
    this.dataService.getFeedbackList(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.feedBackList = res["data"]["feedback"];

        this.feedBackList.filter((feed: any) => {
          if (feed.feedback_by == "MENTOR") {
            this.mentorFeedBack = feed;
            this.dynamicRating = this.mentorFeedBack.rating;
            this.getMentorStars(this.dynamicRating);
          } else {
            this.menteeFeedBack = feed;
            this.dynamic_rating = this.menteeFeedBack.rating;
            this.getMenteeStars(this.dynamic_rating);
          }
        });
      }
      this.loadHide();
      this.modalTitle = "JioMeeting Feedback";
      this.modalReference = this.modalService.open(content, modalOptions);
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  onCloseButtonClick() {
    this.getMentorStars(0);
    this.getMenteeStars(0);
    this.modalService.dismissAll("Cross click");
  }

  getMentorStars(rating: any): any[] {
    return Array.from({ length: rating }, (_, index) => index + 1);
  }

  getMenteeStars(rating: any): any[] {
    return Array.from({ length: rating }, (_, index) => index + 1);
  }

  openJioMeetViewPage(slug) {
    this.router.navigate(["jio-meet-view/" + slug]);
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
