import {Component,OnInit} from "@angular/core";

@Component({
  selector: "app-mentorship-provided-by-mentee",
  templateUrl: "./mentorship-provided-by-mentee.component.html",
})


export class MentorshipProvidedByMenteeComponent implements OnInit{
    ngOnInit(): void {
        
    }

}