<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/enter.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Community</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{communityList?.length}}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<!-- <th>Question</th> -->
										<th>Topic</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of communityList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<!-- <td>{{item.question}}</td> -->
										<td>{{item.topic_name}}</td>
										<td><span>{{item.first_name}} {{item.last_name}}</span></td>
										<td>{{item.publish_date}}</td>
										<td class="action">
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
												(click)="delete(this.communityList[i].slug)"><i
													class="fa fa-trash"></i></button>&nbsp; -->
											<button *ngIf="item.count_comments > 0" type="button"
												class="btn text-dark btn-sm  rounded bg-light"
												(click)="view(this.communityList[i].slug,content)"><i
													class="fa fa-eye"></i></button>&nbsp;
											<button *ngIf="item.reportStatus" type="button"
												class="btn text-dark btn-sm  rounded bg-light"
												(click)="report(this.communityList[i].question_id,this.communityList[i].slug,reportmodal)"><i
													class="fa fa-file-text-o" aria-hidden="true"></i></button>
									   </td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Community List</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="table-responsive">
							<table class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Comment</th>
										<th>Comment By</th>
										<th>Comment Date</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of commentList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<!-- <td>{{item.thread_answer}}</td> -->
										<td>{{item.thread_answer.child_thread ? item.thread_answer.child_thread : item.thread_answer}}</td>
										<td>{{item.first_name}} {{item.last_name}}</td>
										<td>{{item.publish_date}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #reportmodal let-c="close" let-d="dismiss" id="customer-add">
			<div class="modal-info" role="document">
				<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">Report List</h4>
							<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="table-responsive">
								<table class="table" width="100%">
									<thead>
										<tr>
											<th>#Sl</th>
											<th>Report</th>
											<th>Report By</th>
											<th>Report Date</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let item of reportList; let i = index" [attr.data-index]="i">
											<td>{{i+1}}</td>
											<td>{{item.description}}</td>
											<td>{{item.first_name}} {{item.last_name}}</td>
											<td>{{item.created_at}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="remarkara bg-light p-3">
								<div class="form-group mb-0">
									<label for="">Remark</label>
									<textarea class="form-control" name="report_remarks"
									id="report_remarks" [(ngModel)]="community.report_remarks"rows="3"></textarea>
									<span *ngIf="f.submitted && (community.report_remarks==undefined || community.report_remarks=='')" 
									class="alert-block">This field is required</span>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" (click)="keepPost(this.community)" 
							class="btn btn-dark mr-2 addbtn" [disabled]="!f.form.valid">Keep Post</button>
							<button type="submit" (click)="deletePost(this.community)" 
							class="btn btn-primary mr-2 addbtn" [disabled]="!f.form.valid">Delete Post</button>
						</div>
					</div>
				</form>
			</div>
		</ng-template>
	</div>
</div>