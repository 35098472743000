import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { AlertService, CampaignService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { PartnerOfferringService } from "../_services/partner-offerring-old.service";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../_services/encryption.service";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { NgForm, NgModel } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class PartnerOffering {
  id: number;
  title: string;
  offer_start_date: any;
  offer_end_date: any;
  image: any;
  discount: string;
  state: any;
  city: any;
  key_highlights: any;
  how_should_attend: any;
  what_you_will_learn: any;
  description: any;
  status: any;
  verticals: any;
  is_home_visible: any;
  avail_offer_link: any;
}

@Component({
  selector: "app-partner-offerring-old",
  templateUrl: "./partner-offerring-old.component.html",
  styleUrls: ["./partner-offerring-old.component.sass"],
})
export class PartnerOfferringOldComponent implements OnInit {
  @Input() allowMultiple: boolean;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  partnerOfferingList: PartnerOffering[] = [];
  partnerOfferingTotal: any;
  isSubmitted = false;
  fileList: any;
  croppedImage: any = "";
  @Input() fileType: string = ".jpg , .png , .bmp";
  DisplayedText: string = "";
  @Input() required: boolean;
  start: number = 0;
  pageLength: number = 10;
  formattedStartDate: any;
  formattedEndDate: any;
  errorShow: any;

  getPartnerOffering = {
    id: "",
    title: "",
    offer_start_date: "",
    offer_end_date: "",
    image: "",
    discount: "",
    state: "",
    city: "",
    key_highlights: "",
    how_should_attend: "",
    what_you_will_learn: "",
    description: "",
    status: "",
    verticals: "",
    is_home_visible: "",
    avail_offer_link: "",
  };

  closeResult: string; // Modal Close
  myString: any;
  myArray: any;
  verticalArr = [];

  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: PartnerOfferringService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private sanitizer: DomSanitizer
  ) {}

  partner_offering = {
    id: "",
    title: "",
    offer_start_date: "",
    offer_end_date: "",
    image: "",
    discount: "",
    state: "",
    city: "",
    key_highlights: "",
    how_should_attend: "",
    what_you_will_learn: "",
    description: "",
    status: "",
    verticals: "",
    is_home_visible: "",
    avail_offer_link: "",
  };

  states = [];
  cities = [];
  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;
  isEndDateInvalid: boolean = false;
  ngOnInit() {
    // this.allPartnerOffering();
    this.getPartnerOfferingListing();
    this.loadHide();
    this.dataService
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.states = data["state"];
      });

    this.dataService
      .getAllCity()
      .pipe()
      .subscribe((data) => {
        this.cities = data["state"];
      });

    this.dataService
      .getCategoryList()
      .pipe()
      .subscribe((data) => {
        this.verticalArr = data["verticals"];
      });
  }

  getPartnerOfferingListing() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      order: [[4, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/get-list-partner-offering",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            const resp = JSON.parse(output);
            //console.log(resp, "get all partner offering");

            this.partnerOfferingList = resp["data"];
            this.partnerOfferingTotal = resp["recordsTotal"];

            this.partnerOfferingList = resp["data"].map((item: any) => {
              item.offer_start_date = this.convertStartDate(
                item.offer_start_date
              );
              item.description = this.truncate(item.description, 80);
              return item;
            });

            this.partnerOfferingList = resp["data"].map((item: any) => {
              item.offer_end_date = this.convertEndDate(item.offer_end_date);
              return item;
            });

            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "title" },
        { data: "description" },
        { data: "created_at" },
        { data: "status" },
      ],
    };
  }

  truncate(value: any, limit: any) {
    if (!value) return "";
    if (value.length > limit) {
      return value.substring(0, limit) + "...";
    }
    return value;
  }

  validateDates() {
    if (
      this.partner_offering.offer_start_date &&
      this.partner_offering.offer_end_date
    ) {
      const startDate = new Date(this.partner_offering.offer_start_date);
      const endDate = new Date(this.partner_offering.offer_end_date);

      this.isEndDateInvalid = endDate < startDate;
    } else {
      this.isEndDateInvalid = false;
    }
  }

  cleanHtml(input: any) {
    // Regular expression to match <font> tags with content inside
    const fontTagPattern = /<font[^>]*>(.*?)<\/font>/g;
    //console.log(fontTagPattern, "fontTagPattern");

    let output = input.replace(fontTagPattern, (match, content) => {
      // Clean the content by removing spaces and non-breaking spaces
      const cleanedContent = content.replace(/&#160;/g, "").replace(/\s/g, "");

      // If the cleaned content is empty, return an empty string
      if (cleanedContent === "") {
        return ""; // Replace the entire <font>...</font> tag with an empty string
      }
      // If there is actual content, return the original match
      return match;
    });

    //console.log(output, "output");
    // Return the sanitized output (remove script tags, etc.)
    return this.sanitizer.sanitize(1, output) || "";
  }

  processContent(content: any) {
    // Replace all non-breaking spaces with regular spaces
    let cleanedContent = content
      .replace(/&#160;/g, " ") // Replace non-breaking spaces
      .replace(/&nbsp;/g, " "); // Replace HTML non-breaking spaces

    // Remove all HTML tags
    cleanedContent = cleanedContent.replace(/<[^>]*>/g, "");

    // Trim leading and trailing spaces
    cleanedContent = cleanedContent.trim();

    // If the remaining content is empty, return an empty string
    return cleanedContent === "";
  }

  formatDate(dateString: any) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  convertStartDate(datetimeString: any) {
    const date = new Date(datetimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  convertEndDate(datetimeString: any) {
    const date = new Date(datetimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  open(content) {
    this.croppedImage = "";
    this.isSubmitted = false;
    if (this.getPartnerOffering.id != null) {
      this.getPartnerOffering.id = "";
    }
    this.partner_offering.id = "";
    this.partner_offering.title = "";
    this.partner_offering.offer_start_date = "";
    this.partner_offering.offer_end_date = "";
    this.partner_offering.image = "";
    this.partner_offering.discount = "";
    this.partner_offering.state = "";
    this.partner_offering.city = "";
    this.partner_offering.key_highlights = "";
    this.partner_offering.how_should_attend = "";
    this.partner_offering.what_you_will_learn = "";
    this.partner_offering.description = "";
    this.partner_offering.status = "Y";
    this.partner_offering.verticals = "";
    this.partner_offering.is_home_visible = "Y";
    this.partner_offering.avail_offer_link = "";

    this.modalTitle = "Add Partner Offering";
    this.modalReference = this.modalService.open(content);
  }

  selectCityById(id) {
    this.dataService
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    this.validateDates();
    const key_highlights = this.processContent(
      this.partner_offering.key_highlights
    );
    const what_you_will_learn = this.processContent(
      this.partner_offering.what_you_will_learn
    );
    const how_should_attend = this.processContent(
      this.partner_offering.how_should_attend
    );
    if (key_highlights) {
      this.partner_offering.key_highlights = "";
    }
    if (what_you_will_learn) {
      this.partner_offering.what_you_will_learn = "";
    }

    if (how_should_attend) {
      this.partner_offering.how_should_attend = "";
    }

    if (
      f.form.valid &&
      !this.isEndDateInvalid &&
      this.partner_offering.offer_start_date &&
      this.partner_offering.offer_end_date
    ) {
      // Proceed with form submission {
      //console.log(val, "Partner Offering Form");
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.isEndDateInvalid = false;
    this.loadShow();
    this.dataService.getPartnerOffering(id).subscribe((res) => {
      this.getPartnerOffering = res["data"];
      //console.log(this.getPartnerOffering, "editttt");

      if (this.getPartnerOffering.state != null) {
        this.selectCityById(this.getPartnerOffering.state);
      }
      this.croppedImage = this.getPartnerOffering.image;

      this.partner_offering = {
        id: this.getPartnerOffering.id,
        title: this.getPartnerOffering.title,
        offer_start_date: this.getPartnerOffering.offer_start_date,
        offer_end_date: this.getPartnerOffering.offer_end_date,
        state: this.getPartnerOffering.state,
        discount: this.getPartnerOffering.discount,
        city: this.getPartnerOffering.city,
        image: this.getPartnerOffering.image,
        description: this.getPartnerOffering.description,
        what_you_will_learn: this.getPartnerOffering.what_you_will_learn,
        how_should_attend: this.getPartnerOffering.how_should_attend,
        key_highlights: this.getPartnerOffering.key_highlights,
        verticals: this.getPartnerOffering.verticals,
        is_home_visible: this.getPartnerOffering.is_home_visible,
        status: this.getPartnerOffering.status,
        avail_offer_link: this.getPartnerOffering.avail_offer_link,
      };
      this.loadHide();
      this.modalTitle = "Edit Partner Offering";
      this.modalReference = this.modalService.open(content);
    });
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = ".jpg , .jpeg, .png , .bmp";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are .jpg, .jpeg , .png , .bmp"
        );
        this.fileList = null;
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.partner_offering.image = this.croppedImage;
        //console.log(this.croppedImage, "croppp");
      };
      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        return false;
      }
      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList[0];
      }
    }
    return false;
  }

  insertAction(val, f) {
    let formData: FormData = new FormData();
    formData.append("title", this.partner_offering.title);
    this.formattedStartDate = this.formatDate(
      this.partner_offering.offer_start_date
    );
    this.formattedEndDate = this.formatDate(
      this.partner_offering.offer_end_date
    );

    formData.append("offer_start_date", this.formattedStartDate);
    formData.append("offer_end_date", this.formattedEndDate);
    formData.append("discount", this.partner_offering.discount);
    formData.append("state", this.partner_offering.state);
    formData.append("city", this.partner_offering.city);
    formData.append("key_highlights", this.partner_offering.key_highlights);
    formData.append(
      "how_should_attend",
      this.partner_offering.how_should_attend
    );
    formData.append(
      "what_you_will_learn",
      this.partner_offering.what_you_will_learn
    );
    formData.append("description", this.partner_offering.description);
    formData.append("status", this.partner_offering.status);
    formData.append("verticals", this.partner_offering.verticals);
    formData.append("is_home_visible", this.partner_offering.is_home_visible);
    formData.append("avail_offer_link", this.partner_offering.avail_offer_link);

    if (val.id == "") {
      if (this.fileList) {
        formData.append("image", this.croppedImage);
      }
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.loadShow();
            if (data["status"] == 200) {
              this.alertService.success("Campaign created successfully", true);
              const table = $("#partneroffertable").DataTable();
              table.ajax.reload(null, false);
              this.modalReference.close();
              this.loadHide();
            } else if (data["status"] == 422) {
              if (
                data.mesg &&
                data.mesg.offer_end_date &&
                data.mesg.offer_end_date[0]
              ) {
                this.alertService.error(data.mesg.offer_end_date[0]);
              } else if (
                data.mesg &&
                data.mesg.offer_start_date &&
                data.mesg.offer_start_date[0]
              ) {
                this.alertService.error(data.mesg.offer_start_date[0]);
              } else if (data.mesg && data.mesg.title && data.mesg.title[0]) {
                this.alertService.error(data.mesg.title[0]);
              } else if (
                data.mesg &&
                data.mesg.description &&
                data.mesg.description[0]
              ) {
                this.alertService.error(data.mesg.description[0]);
              } else if (
                data.mesg &&
                data.mesg.key_highlights &&
                data.mesg.key_highlights[0]
              ) {
                this.alertService.error(data.mesg.key_highlights[0]);
              } else if (
                data.mesg &&
                data.mesg.what_you_will_learn &&
                data.mesg.what_you_will_learn[0]
              ) {
                this.alertService.error(data.mesg.what_you_will_learn[0]);
              } else if (
                data.mesg &&
                data.mesg.how_should_attend &&
                data.mesg.how_should_attend[0]
              ) {
                this.alertService.error(data.mesg.how_should_attend[0]);
              } else {
                this.alertService.error("An error occurred");
              }
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.fileList) {
        formData.append("image", this.croppedImage);
      }

      this.dataService
        .partnerOfferingUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.loadShow();
            if (data["status"] == 200) {
              this.modalReference.close();
              this.alertService.success(
                "Partner Offering updated successfully",
                true
              );
              const table = $("#partneroffertable").DataTable();
              table.ajax.reload(null, false);
              this.loadHide();
            } else if (data["status"] == 422) {
              if (
                data.mesg &&
                data.mesg.offer_end_date &&
                data.mesg.offer_end_date[0]
              ) {
                this.alertService.error(data.mesg.offer_end_date[0]);
              } else if (
                data.mesg &&
                data.mesg.offer_start_date &&
                data.mesg.offer_start_date[0]
              ) {
                this.alertService.error(data.mesg.offer_start_date[0]);
              } else if (data.mesg && data.mesg.title && data.mesg.title[0]) {
                this.alertService.error(data.mesg.title[0]);
              } else if (
                data.mesg &&
                data.mesg.description &&
                data.mesg.description[0]
              ) {
                this.alertService.error(data.mesg.description[0]);
              } else if (
                data.mesg &&
                data.mesg.key_highlights &&
                data.mesg.key_highlights[0]
              ) {
                this.alertService.error(data.mesg.key_highlights[0]);
              } else if (
                data.mesg &&
                data.mesg.what_you_will_learn &&
                data.mesg.what_you_will_learn[0]
              ) {
                this.alertService.error(data.mesg.what_you_will_learn[0]);
              } else if (
                data.mesg &&
                data.mesg.how_should_attend &&
                data.mesg.how_should_attend[0]
              ) {
                this.alertService.error(data.mesg.how_should_attend[0]);
              } else {
                this.alertService.error("An error occurred");
              }

              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  delete(item) {
    this.loadShow();
    this.dataService
      .partnerOfferingDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Partner Offering deleted successfully",
              true
            );
            item.status = item.status === "Y" ? "N" : "Y";
            this.loadHide();
          } else {
            this.alertService.error("Partner Offering error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
