<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/banner.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Banner</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{bannersList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Banner
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
						<div id="table" class="table-responsive">
							<table datatable style="white-space: nowrap;" [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th >#Sl</th>
										<th style="width: 5%;">Name</th>
										<th>Postion</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of bannersList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<td>{{item.position}}</td>
										<td>{{item.start_date}}</td>
										<td>{{item.end_date}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'"
												class="badge badge-success badge-pill">Active</span> <span
												*ngIf="item.is_active=='N'"
												class="badge badge-danger badge-pill">Inactive</span></td>
										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="edit(this.bannersList[i].id,content)"><i
													class="fa fa-edit"></i></button>
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
												(click)="delete(this.bannersList[i].id)"><i
													class="fa fa-trash"></i></button> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
			<!-- class="modal fade" -->
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>

						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="form-group col-md-6">
									<label for="name" class="col-form-label">Name <span>*</span></label>
									<div class=" ">
										<input class="form-control" type="text" id="name" name="name" required
											[(ngModel)]="banners.name">
										<span *ngIf="isSubmitted && isFieldsInvalid(banners.name)"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="is_link" class="col-form-label">Is Link <span>*</span></label>
									<div class=" ">
										<select class="form-control" id="is_link" name="is_link"
											[(ngModel)]="banners.is_link" >
											<option value="N">No</option>
											<option value="Y">Yes</option>
										</select>
										<!-- <span *ngIf="isSubmitted && (banners.is_link==undefined || banners.is_link=='')"
											class="alert-block">This field is required</span> -->
									</div>
								</div>

								<div class="form-group col-md-6" *ngIf="banners.is_link =='Y'">
									<label for="link_url" class="  col-form-label">Link Url <span>*</span></label>
									<div class=" ">
										<textarea class="form-control" id="link_url" name="link_url"
											[(ngModel)]="banners.link_url" *ngIf="banners.is_link !== 'Y'"></textarea>
										<textarea class="form-control" id="link_url" name="link_url"
											[(ngModel)]="banners.link_url" *ngIf="banners.is_link === 'Y'"
											required></textarea>
										<span
											*ngIf="isSubmitted && isFieldsInvalid(banners.link_url)"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="start_date" class="  col-form-label">Start Date <span>*</span></label>
									<div class=" ">
										<input class="form-control" type="date" id="start_date" name="start_date"
											required [(ngModel)]="banners.start_date">
										<span
											*ngIf="isSubmitted && (banners.start_date==undefined || banners.start_date=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="end_date" class="  col-form-label">End Date <span>*</span></label>
									<div class=" ">
										<input class="form-control" type="date" id="end_date" name="end_date" required
											[(ngModel)]="banners.end_date">
										<span
											*ngIf="isSubmitted && (banners.end_date==undefined || banners.end_date=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="is_active" class="  col-form-label">Status </label>
									<div class=" ">
										<select class="form-control" id="is_active" name="is_active"
											[(ngModel)]="banners.is_active">
											<option value="N">Inactive</option>
											<option value="Y">Active</option>
										</select>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="is_link" class="col-form-label">Postion<span>*</span></label>
									<div class=" ">
										<input type="radio" value="TOP" name="position" id="top_banner"
											[(ngModel)]="banners.position" positionChecked required> Top Banner
										<input type="radio" value="MIDDLE" name="position" id="middle_banner"
											[(ngModel)]="banners.position" positionChecked required> Middle Banner
										<input type="radio" value="UPPERTOP" name="position" id="upper_top_banner"
											[(ngModel)]="banners.position" positionChecked required> Upper Top Banner
									</div>
									<span *ngIf="isSubmitted && (banners.position==undefined || banners.position=='')"
										class="alert-block">This field is required</span>
								</div>

								<div class="form-group col-md-6">
									<label class=" form-control-label">Image <span>*</span></label>
									<div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
										  [src]="croppedImage" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText"
											[class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
										</div>
				  
										<span *ngIf="isSubmitted && (banners.image_path==undefined || banners.image_path=='')" class="alert-block">This
										  field is required</span>
									  </div>
								</div>

								<div class="form-group col-md-6">
									<label class=" form-control-label">Mobile Image <span>*</span></label>
									<div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="mobileCroppedImage !=''"
										  [src]="mobileCroppedImage" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="mobileImageChange($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText2" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText2"
											[class.ng-valid-right]="required && DisplayedText2" style="height: 35px;">
										</div>
				  
										
										<span *ngIf="isSubmitted && (banners.mobile_image==undefined || banners.mobile_image=='')" class="alert-block">This
										  field is required</span>
									  </div>
								</div>

							</div>
						</form>
					</div>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
						<button type="submit" class="btn btn-primary rounded savebtn"
							(click)="save(this.banners,f)">Save</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
