import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AwardToRewardListService {

  constructor(private base: BaseService) { }

  viewAwardToReward(id) {
    return this.base.post('/api/admin-atr-view',{id:id});
  }

  viewAwardToRewardV1(id) {
    return this.base.post('/api/admin-atr-view-v1',{id:id});
  }

  getAllAwardToReward(){
    return this.base.get('/api/admin-atr-list');
  }

  getAllAwardToRewardV1(){
    return this.base.get('/api/admin-atr-list-v1');
  }

  updateAwardToReward(data) {
    return this.base.post('/api/admin-atr-update-status',data);
  }

  deleteAwardToReward(id){
    return this.base.post('/api/admin-atr-delete',{id:id});
  }
}
