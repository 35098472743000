import {Component,OnInit} from "@angular/core";

@Component({
  selector: "app-connection-requests",
  templateUrl: "./connection-requests.component.html",
})


export class ConnectionRequestsComponent implements OnInit{
    ngOnInit(): void {
        
    }

}