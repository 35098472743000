<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Community Group</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{totalGroups}}</h2>
									</div>
								</div>

                <div class="col-md-3">
									<div class="total-count">
										<select class="form-control" id="approve_status"
										[(ngModel)]="filterData.group_id" (change)="onSetGroup($event)">
											<option *ngFor="let item of grpMasterArr" [value]="item.id">{{item.title}}</option>
										</select>
									</div>
								</div>

								<div class="col-md-3">
									<div class="total-count">
										<select class="form-control" id="approve_status"
										[(ngModel)]="filterData.group_status" (change)="onSetStatus($event)">
											<option value="ALL">All</option>
											<option value="REQUESTED">Pending</option>
											<option value="JOIN">Approved</option>
											<option value="REJECTED">Rejected</option>

										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table id="grouptable" datatable [dtOptions]="dtOptions" class="row-border hover">
								<thead>
								  <tr>
									<th >#Sl</th>
									<th style="width:25%">Title</th>
									<th>Username</th>
									<th>Requested Date</th>
									<th>Status</th>
                  <th>Action</th>
							
								  </tr>
								</thead>
								<tbody *ngIf="groupList?.length != 0">
								  <tr *ngFor="let item of groupList; let i = index" [attr.data-index]="i">
									<td>{{ getRowNumber(i) }}</td>
									<td  style="width:25%">{{ item.title }}</td>
									<td>{{ item.username }}</td>
									<td>{{ item.requested_date }}</td>
									<td *ngIf="item.group_status == 'JOIN'">Approved</td>
                  <td *ngIf="item.group_status == 'REQUESTED'">Pending</td>
                  <td *ngIf="item.group_status == 'REJECTED'">Rejected</td>

                  <td class="action">
										<button *ngIf="item.usertype == 3" type="button" class="btn text-dark btn-sm  rounded bg-light"
										(click)="viewMentorDetails(item.id,mentorDetails)">
										<i class="fa fa-eye"></i></button>

										<button *ngIf="item.usertype == 5" type="button" class="btn text-dark btn-sm  rounded bg-light"
										(click)="viewMenteeDetails(item.id,memberDetails)">
										<i class="fa fa-eye"></i></button>

										<button *ngIf="item.usertype == 4" type="button" class="btn text-dark btn-sm  rounded bg-light"
										(click)="viewPartnerDetails(item.id,partnerDetails)">
										<i class="fa fa-eye"></i></button>

									</td>

								  </tr>
								</tbody>
								<tbody *ngIf="groupList?.length == 0">
								  <tr>
									<td colspan="6" class="no-data-available">No data!</td>
								  </tr>
								</tbody>
							  </table>
						</div>
					</div>
				</div>
			</div>
		</div>


		<ng-template #mentorDetails let-c="close" let-d="dismiss" id="mentor_details">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Mentor Details</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-bordered">
										<tbody>
											<tr>
												<th>First Name</th>
												<td>{{mentorInfo.first_name}}</td>
												<th>Last Name</th>
												<td>{{mentorInfo.last_name}}</td>
											</tr>
										
											<tr>
												<th>Profile Image</th>
												<td><a *ngIf="mentorInfo.profileimage" href="{{mentorInfo.profileimage}}" target="_blank"><img  src="{{mentorInfo.profileimage}}" height="40px" width="50px"></a>
													<img *ngIf="!mentorInfo.profileimage" src="assets/img/default-profile.png" height="40px" width="50px"></td>
												<th>Birth Year</th>
												<td>{{mentorInfo.birth_yr}}</td>
												
											</tr>
											<tr>
												<th>Gender</th>
												<td>{{mentorInfo.gender}}</td>
												<th>Country</th>
												<td>{{mentorInfo.country_name}}</td>
												
											</tr>
											<tr>
												<th>State</th>
												<td>{{mentorInfo.state_name}}</td>
												<th>City</th>
												<td>{{mentorInfo.city_name? mentorInfo.city_name : mentorInfo.city_others}}</td>
												
											</tr>
											<tr>
												<th>Spoken Language</th>
												<td>{{ getFilteredSpokenLanguages(mentorInfo)}}<span *ngIf="containsOthers(mentorInfo.spoken_lang_names)">, {{mentorInfo.spoken_lang_others}}</span></td>

												<th>Primary Industry</th>

												<td *ngIf="mentorInfo.primary_industry_name == 'Others'" ><span >
													{{ mentorInfo.primary_industry_others }}</span>
												</td>
												<td *ngIf="mentorInfo.primary_industry_name != 'Others'"><span >
													{{ mentorInfo.primary_industry_name }}</span></td>
											</tr>
											<tr>
												<th>Stage of Enterprise</th>
												<td>{{mentorInfo.stage_of_enterprise_names}}</td>
												<th>Industry Experience</th>
												<td>{{mentorInfo.industry_experience_name}}</td>
											</tr>
											<tr>
												<th>Area of functional expertise</th>
												<td>{{mentorInfo.area_of_functional_expertise_name}}</td>
												<th>Qualification</th>
												<td  *ngIf="mentorInfo.qualification_name != 'Other'"><span>{{mentorInfo.qualification_name}}</span></td>
												<td *ngIf="mentorInfo.qualification_name == 'Other'"><span >{{mentorInfo.qualification_others}}</span></td>
											</tr>

											<tr>
												<th>Preferred Track of Mentorship</th>
												<td>{{mentorInfo.track_of_mentorship_names}}</td>
												<th>Ecosystem Key</th>
												<td *ngIf="mentorInfo.ecosystem_key_names != 'Others'"><span>{{mentorInfo.ecosystem_key_names}}</span></td>
												<td *ngIf="mentorInfo.ecosystem_key_names == 'Others'"><span>{{mentorInfo.ecosystem_key_other}}</span></td>
											</tr>

											<tr>
												<th>Preference Across</th>
												<td>{{mentorInfo.preference_across_name}}</td>
												<th>Mode of Engagement</th>
												<td>{{mentorInfo.mode_of_engagement_name}}</td>
											</tr>

											<tr>
												<th>Willing no of mentees</th>
												<td>{{mentorInfo.willing_no_of_mentees_name}}</td>
												<th>No of hours per month</th>
												<td>{{mentorInfo.no_of_hours_per_month_name}}</td>
											</tr>
										
											<tr>
												<th>Status</th>
												<td>
													<select class="form-control" name="approve_status" id="approve_status" [(ngModel)]="group_details.status" (ngModelChange)="onChangeStatus()">
														<option disabled value="">Choose...</option>
														<option value="JOIN">Approve</option>
														<option value="REJECTED">Reject</option>
													</select>
												</td>
												<th>Remarks <span *ngIf=" group_details.status === 'REJECTED'" class="text-danger">*</span></th>
												<td>

													<input type="hidden" id="id" name="id" [(ngModel)]="group_details.id" >
													<textarea class="form-control" name="remarks" [required]="group_details.status === 'REJECTED'" id="remarks" [(ngModel)]="group_details.reject_reason" (ngModelChange)="checkRemarks()"></textarea>
													<div *ngIf="isSubmitted && group_details.status === 'REJECTED' && (!group_details.reject_reason || group_details.reject_reason.trim() === '')" class="text-danger">
														This field is required
													</div>
												</td>
											</tr>

										</tbody>
									</table>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						
						<button type="submit" class="btn btn-primary addbtn" aria-label="Close"
							(click)="grpStatusUpdate(this.group_details)">Submit</button>
							<button type="button" class="btn btn-dark text-white" aria-label="Close" style="border-radius: 9px"  
						(click)="d('Cross click')">Close</button>
						
					</div>
				</div>
			</div>
		</ng-template>


		<ng-template #memberDetails let-c="close" let-d="dismiss" id="member_detailss">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Member Details</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-bordered">
										<tbody>
											<tr>
												<th>Name</th>
												<td>{{member_personal_details.name}}</td>
												<th>Image</th>
												<td><a *ngIf="member_personal_details.image " href="{{member_personal_details.image}}" target="_blank"><img  src="{{member_personal_details.image}}" height="40px" width="50px"></a>
													<img *ngIf="!member_personal_details.image" src="assets/img/default-profile.png" height="40px" width="50px"></td>
											</tr>
										
											<tr>
												<th>Date of Birth</th>
												<td>{{member_profile_details.dob}}</td>
												<th>Gender</th>
												<td>{{member_profile_details.gendr}}</td>
											</tr>

											<tr>
												<th>Country</th>
												<td>{{member_profile_details.country_name}}</td>
												<th>State</th>
												<td>{{member_profile_details.state_name}}</td>
											</tr>

											<tr>
												<th>City</th>
												<td>{{member_profile_details.city_name? member_profile_details.city_name : member_profile_details.other_city}}</td>
												<th>Linkedin profile url</th>
												<td>{{member_profile_details.linkedin}}</td>
											</tr>
										
												<tr>
													<th>Are you Student</th>
													<td>{{member_profile_details.are_u_student}}</td>
													<th>Are you Working Professional</th>
													<td>{{member_profile_details.are_working_professional}}</td>
												</tr>
											

													<tr>
														<th>Year of Work Experience</th>
														<td>{{member_profile_details.year_of_work_exp}}</td>
														<th>Area of Interest</th>
														<td>{{member_business_details.area_of_interest}}</td>
													</tr>
											
											
														<tr>
															<th>Enterprise Name</th>
															<td>{{member_business_details.enterprise_name}}</td>
															<th>Sector of Enterprise</th>
															<td>{{member_business_details.sectorofenterprise}}</td>
														</tr>
													
														
														<!-- <tr>
															<th>Country</th>
															<td>{{member_business_details.country_name}}</td>
															<th>State</th>
															<td>{{member_business_details.state_name}}</td>
														</tr>
			
														<tr>
															<th>City</th>
															<td>{{member_business_details.city_name? member_business_details.city_name : member_business_details.other_city}}</td>
															<th>Linkedin profile url</th>
															<td>{{member_business_details.linkedin_url}}</td>
														</tr> -->

														<tr>
															<th>No of employees</th>
															<td>{{member_business_details.no_of_employees}}</td>
															<th>Linkedin profile url</th>
															<td>{{member_business_details.linkedin_url}}</td>
														</tr>

														<tr>
															<th>Status</th>
															<td>
																<select class="form-control" name="approve_status" id="approve_status" [(ngModel)]="group_details.status" (ngModelChange)="onChangeStatus()">
																	<option disabled value="">Choose...</option>
																	<option value="JOIN">Approve</option>
																	<option value="REJECTED">Reject</option>
																</select>
															</td>
															<th>Remarks <span *ngIf=" group_details.status === 'REJECTED'" class="text-danger">*</span></th>
															<td>
			
																<input type="hidden" id="id" name="id" [(ngModel)]="group_details.id" >
																<textarea class="form-control" name="remarks" [required]="group_details.status === 'REJECTED'" id="remarks" [(ngModel)]="group_details.reject_reason" (ngModelChange)="checkRemarks()"></textarea>
																<div *ngIf="isSubmitted && group_details.status === 'REJECTED' && (!group_details.reject_reason || group_details.reject_reason.trim() === '')" class="text-danger">
																	This field is required
																</div>
															</td>
														</tr>

										</tbody>
									</table>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						
						<button type="submit" class="btn btn-primary addbtn" aria-label="Close"
							(click)="grpStatusUpdate(this.group_details)">Submit</button>
							<button type="button" class="btn btn-dark text-white" aria-label="Close" style="border-radius: 9px"  
						(click)="d('Cross click')">Close</button>

					
					</div>
				</div>
			</div>
		</ng-template>


   <!-- Partner Details -->
		<ng-template #partnerDetails let-c="close" let-d="dismiss" id="partner" data-bs-keyboard="false">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Partner Details</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-bordered">
										<tbody>
											<tr>
												<th>Name</th>
												<td>{{partnerInfo.fullname}}</td>
												<th>Type</th>
												<td>{{partnerInfo.type}}</td>
											</tr>

											<tr>
												<th>Gender</th>
												<td>{{partnerInfo.gender}}</td>
												
												<th>Image</th>
												<td><a *ngIf="partnerInfo.image" href="{{partnerInfo.image}}" target="_blank"><img  src="{{partnerInfo.image}}" height="40px" width="50px"></a>
													<img *ngIf="!partnerInfo.image" src="assets/img/default-profile.png" height="40px" width="50px"></td>
											</tr>

											<tr>
												<th>Date of Birth</th>
												<td>{{partnerInfo.year_of_birth}}</td>
												<th>Country</th>
												<td>{{partnerInfo.state_name}}</td>
											</tr>

											<tr>
												<th>State</th>
												<td>{{partnerInfo.state_name}}</td>
												<th>City</th>
												<td>{{partnerInfo.city_name? partnerInfo.city_name : ''}}</td>
											</tr>


											<tr>
												<th>occuption</th>
												<td>{{partnerInfo.occuption}}</td>
												<th>Industry Name</th>
												<td>{{partnerInfo.industry_name}}</td>
											</tr>

											<tr>
												<th>Organization name</th>
												<td>{{partnerInfo.organization_name}}</td>
												<th>Qualification</th>
												<td>{{partnerInfo.year_of_establishment}}</td>
											</tr>

											<tr>
												<th>Status</th>
												<td>
													<select class="form-control" name="approve_status" id="approve_status" [(ngModel)]="group_details.status" (ngModelChange)="onChangeStatus()">
														<option disabled value="">Choose...</option>
														<option value="JOIN">Approve</option>
														<option value="REJECTED">Reject</option>
													</select>
												</td>
												<th>Remarks <span *ngIf=" group_details.status === 'REJECTED'" class="text-danger">*</span></th>
												<td>

													<input type="hidden" id="id" name="id" [(ngModel)]="group_details.id" >
													<textarea class="form-control" name="remarks" [required]="group_details.status === 'REJECTED'" id="remarks" [(ngModel)]="group_details.reject_reason" (ngModelChange)="checkRemarks()"></textarea>
													<div *ngIf="isSubmitted && group_details.status === 'REJECTED' && (!group_details.reject_reason || group_details.reject_reason.trim() === '')" class="text-danger">
														This field is required
													</div>
												</td>
											</tr>
											<tr>

											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary addbtn" aria-label="Close"
							(click)="grpStatusUpdate(this.group_details)">Submit</button>
						<button type="button" class="btn btn-dark text-white" aria-label="Close" style="border-radius: 9px"  
						(click)="d('Cross click')">Close</button>
						
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>

