import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PastEventService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/event-create', data);
  }

  eventUpdate(data){
    return this.base.post('/api/event-update',data);
  }

  eventDelete(id){
    return this.base.post('/api/event-delete',{id:id});
  }

  getAllEvent(data){
    return  this.base.post('/api/get-event',data);
  }

  getEvent(id){
    return this.base.post('/api/event-edit',{id:id});
  }

  getSectorofEnterprise()
  {
    return this.base.get('/api/get-active-sectorofenterprise');
  }

  getAreaofInterest()
  {
    return this.base.get('/api/get-active-areaofinterest');
  }

  getAreaofStudy()
  {
    return this.base.get('/api/get-all-active-area-of-study');
  }

  getAssociatePartners()
  {
    return this.base.get('/api/associate-partners');
  }

  getEventCategory () {
    return  this.base.get('/api/all-blogcategory');
  }

  getAllState(){
    return  this.base.get('/api/all-indian-state');
  }

  getAllCity(){
    return  this.base.get('/api/all-city');
  }

  getCityById(id){
    return this.base.post('/api/all-city-by-id',{id:id});
  }

  eventMediaUpload(data){
    return this.base.post('/api/store-event-media',data);
  }

  getEventMediaById(data) {
    return this.base.post('/api/get-event-media',data);
  }

  eventMediaDelete(data) {
    return this.base.post('/api/delete-event-media',data);
  }

}
