import { Injectable } from "@angular/core";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor() {}

  generatePdf(data: any[], fields: string[]): void {
    const filteredData = this.filterDataFields(data, fields);
    const documentDefinition = this.getDocumentDefinition(filteredData, fields);
    pdfMake.createPdf(documentDefinition).download("users.pdf");
  }

  private filterDataFields(data: any[], fields: string[]): any[] {
    return data.map((row) => {
      const filteredRow: any = {};
      fields.forEach((field) => {
        if (row[field] !== undefined) {
          filteredRow[field] = row[field];
        }
      });
      return filteredRow;
    });
  }

  private getDocumentDefinition(data: any[], fields: string[]): any {
    // Convert the headings to uppercase
    const headings = fields.map((field) => field.toUpperCase());

    // Set specific width for the "S. No" column
    const serialNoIndex = fields.indexOf("serialNo");
    const widths = fields.map((field, index) => {
      return index === serialNoIndex ? 40 : "*";
    });

    return {
      content: [
        {
          table: {
            headerRows: 1,
            widths: widths,
            body: [
              headings, // Use the uppercase headings
              ...data.map((row) => fields.map((field) => row[field] || "")),
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
    };
  }

  private getColumnWidths(data: any[]): any[] {
    const keys = Object.keys(data[0]);
    const widths = keys.map((key) => "*"); // Adjust as needed for specific widths
    return widths;
  }
}
