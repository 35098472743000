import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { AlertService, AppService } from "../_services/index";

@Component({
  selector: "app-profile-settings",
  templateUrl: "./profile-settings.component.html",
})
export class ProfileSettingsComponent implements OnInit {
  profileAddForm: FormGroup;
  croppedImage: any;
  @Input() fileType: string = ".jpg, .jpeg, .png, .bmp";
  showError: any;
  isSubmitted = false;
  update_profile: any;
  update_pass: any;
  isError: boolean = false;
  profile = {
    id: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    image: "",
  };
  password = {
    id: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  @Input() allowMultiple: boolean;
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList: any;
  image1: string = "";
  getToken: string;
  showloding = true;
  lodingImage = false;
  constructor(
    private dataService: AppService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.profileAddForm = new FormGroup({
      name: new FormControl("", Validators.compose([Validators.required])),
      phone: new FormControl("", Validators.compose([Validators.required])),
      email: new FormControl(""),
      address: new FormControl("", Validators.compose([Validators.required])),
      currentPassword: new FormControl(""),
      newPassword: new FormControl(""),
      confirmPassword: new FormControl(""),
    });
    this.profileData();
  }

  profileData() {
    this.loadShow();
    this.getToken = window.localStorage.getItem("currentUser");
    //console.log(this.getToken, "this.getToken");

    this.dataService
      .checkLogin(this.getToken)
      .pipe()
      .subscribe((data) => {
        let user = data["user"];
        //console.log(user, "userreerr");

        this.profile = {
          id: user.id,
          name: user.name,
          phone: user.phone,
          email: user.email,
          address: user.address,
          image: user.image,
        };
        this.password.id = user.id;
        this.loadHide();
      });
  }

  UpdateProfile(val, f) {
    this.isSubmitted = true;
    if (f.form.valid) {
      this.insertAction(val);
    } else {
      //console.log("All fields are required");
    }
  }

  
  UpdatePassword(val, f) {
    this.isSubmitted = true;

    if (f.form.valid) {
      if (this.password.newPassword != this.password.confirmPassword) {
        this.isError = true;
        this.showError = "New Password and Confirm Password doesn't match";
      } else {
        this.dataService.updatePassword(this.password).subscribe(
          (data) => {
            if (data["status"] == 500) {
              this.isError = true;
              this.showError =
                "Current Password doesn't match with our records";
            } else {
              this.alertService.success("Password Update successful", true);
              this.profileData();
              this.update_pass = "Your password has been updated successfully";
              setTimeout(() => {
                this.update_pass = "";
              }, 3000);
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
      }
    } else {
      //console.log("All Fields are required");
    }
  }

  fileChange(event: any) {
    this.fileList = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = ".jpg , .png , .bmp";
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are .jpg , .png , .bmp"
        );
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }
      if (this.fileList[0].size > 65535) {
        alert(`File size is more than 65 Kb`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }
      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        this.DisplayedText = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
          this.image1 = reader.result as string;
          this.profile.image = this.image1;
        };
      }
      this.onSelection.emit(this.fileList);
    }
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.croppedImage = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.profile.image = this.croppedImage;
      };

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList[0];
      }
    }
    return false;
  }

  insertAction(val) {
    this.loadShow();

    let formData: FormData = new FormData();
    if (this.fileList != undefined) {
      let file: File = this.fileList[0];
      // formData.append('file', file, file.name);
      formData.append("file", this.croppedImage);
    }
    if (val.id != undefined) {
      formData.append("id", val.id);
    }

    formData.append("name", this.profile.name);
    formData.append("phone", this.profile.phone);
    formData.append("address", this.profile.address);
    //console.log(this.image1, "imgeeee");

    this.dataService.profileUpdate(formData).subscribe(
      (data) => {
        if (data["status"] == 200) {
          this.update_profile = "Your profile has been updated successfully.";
          this.alertService.success("Profile Update successful", true);
          this.profileData();
          setTimeout(() => {
            this.update_profile = "";
          }, 3000);
        } else if (data["status"] == 300) {
          this.alertService.error("User already exists", true);
        }
        this.loadHide();
      },
      (error) => {
        this.alertService.error(error);
        this.loadHide();
      }
    );
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
