import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { map } from "rxjs/operators";

@Injectable()
export class PartnerListService {
  constructor(private base: BaseService) {}

  deletePartner(slug) {
    return this.base.post("/api/delete-onboard-partner", { slug: slug });
  }

  viewPartner(id) {
    return this.base.post("/api/view-partner", { id: id });
  }

  getAllPartner() {
    return this.base.get("/api/get-all-onboard-partner");
  }

  // deletePartner(id){
  //   return this.base.post('/api/delete-partner',{id:id});
  // }

  // viewPartner(id) {
  //   return this.base.post('/api/view-partner',{id:id});
  // }

  // getAllPartner(){
  //   return  this.base.get('/api/get-all-partner');
  // }
}
