<style>
	@media only screen and (min-width: 1200px) {
		.newsletterbox .modal .modal-dialog {
			max-width: 1026px !important;
			margin: 30px auto;
		}
	}
</style>

<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn newsletterbox">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/news.png);">
							<div class="row align-items-center">
								<div class="col-md-3 ">
									<div class="pagename">
										<h3 class="card-title">Newsletter</h3>
									</div>
								</div>
								<div class="col-md-3 ">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{newslettersList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Newsletter
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<!-- <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p> -->
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Heading</th>
										<th>Newletter Date</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of newslettersList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.heading}}</td>
										<td>{{item.newsletter_date}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span>
											<span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">Inactive</span>
										</td>
										<td class="action">
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="edit(this.newslettersList[i].id,content)"><i
													class="fa fa-edit"></i></button>
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light"
												(click)="delete(this.newslettersList[i].id)"><i
													class="fa fa-trash"></i></button> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add" class="">
			<!-- class="modal fade" -->
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>

					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="form-group col-md-12">
									<label for="heading" class="  col-form-label">Heading <span>*</span></label>
									<div class="  ">
										<input class="form-control" type="text" id="heading" name="heading" required [(ngModel)]="newsletter.heading">
										<span *ngIf="isSubmitted && isFieldsInvalid(newsletter.heading)" class="alert-block">This field is required</span>
									</div>
								</div>

							
									
								<div class="form-group col-md-6">
									<label class="  form-control-label">Upload Html <span>*</span></label>
									<div class=" ">
										<div class="input-group">
											<span class="input-group-btn">
												<label class="btn btn-dark">
													<samp>Browse</samp>...
													
														<input type="file" accept="{{htmlType}}" style="display: none;"
												(change)="onHtmlchange($event)" [attr.multiple]="allowMultiple">
												</label>
											</span>
											<input type="text" class="form-control" [value]="DisplayedText1" disabled=""
												[class.ng-invalid-right]="!DisplayedText1"
												[class.ng-valid-right]="DisplayedText1" style="height: 35px;">
									</div>
										<span *ngIf="isSubmitted && (newsletter.html_path==undefined || newsletter.html_path=='')" class="alert-block">This field is required</span>
									</div>
								  </div>

								<div class="form-group col-md-6">
									<label for="newsletter_date" class="col-form-label">Newsletter Date<span>*</span></label>
									<div class="">
										<input class="form-control" type="date" id="newsletter_date" name="newsletter_date" required [(ngModel)]="newsletter.newsletter_date">
										<span *ngIf="isSubmitted && (newsletter.newsletter_date==undefined || newsletter.newsletter_date=='')" class="alert-block">This field is required</span>
									</div>
								</div>

								
								<div class="form-group col-md-6">
									<label class="form-control-label">Upload Image <span>*</span></label>
									<div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
										  [src]="croppedImage" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText" disabled=""
											[class.ng-invalid-right]="!DisplayedText"
											[class.ng-valid-right]="DisplayedText" style="height: 35px;">
										</div>

										<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
										  <div class="modal-content">
											<h3 class="text-center mt-2">Image Cropper</h3>
											<div class="py-4 px-4">
											  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
												[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
												(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
												(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
											  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
												href="javascript::void(0)" 
												style="margin-top: 10px;">Save</button>
											</div>
										  </div>
										</ng-template>
										<span *ngIf="isSubmitted && (newsletter.image==undefined || newsletter.image=='')" class="alert-block">This
										  field is required</span>
									  </div>
								  </div>

								<div class="form-group col-md-6">
									<label for="is_active" class="col-form-label">Status</label>
									<div class="  ">
										<select class="form-control" id="is_active" name="is_active"
											[(ngModel)]="newsletter.is_active">
											<option value="N">Inactive</option>
											<option value="Y">Active</option>
										</select>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-light rounded d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
							<button type="submit" class="btn btn-primary savebtn rounded" (click)="save(this.newsletter,f)">Save</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
