import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //console.log("JWT::",localStorage.getItem('currentUser'));
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // //console.log("JWT1::",JSON.parse(currentUser));
        if(currentUser != null){
            //console.log("JWT2::",currentUser.token);
            if (currentUser && currentUser.token) {
                //console.log(currentUser , 'current')
                request = request.clone({
                    setHeaders: {
                        Authorization: `token ${currentUser.token}`,
                        // 'Content-Type': 'application/json'
                        'X-API-KEY' : "wepu4ff4f8fufh3ehdehd"
                    }
                });
            }
        }

        return next.handle(request);
    }
}
