<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<!-- <div class="d-flex align-item-center justify-content-center">
    <div class="d-block">
        <div class="d-flex justify-content-center">
            <img src="assets/img/logo.png">
        </div>
        <h1>Welcome to WEP Admin portal</h1>
    </div>
</div> -->

<div class="card">
    <div class="card-block p-3 clearfix dbbox d-flex align-item-center justify-content-center" style="height: 100vh; vertical-align: middle;">
        <div class="d-block" style="position: absolute; top: 25%;">
            <div class="d-flex justify-content-center mb-5">
                <img src="assets/img/logo.png">
            </div>
            <h1 style="font-weight: bolder; color: #000;">Welcome to WEP Admin portal</h1>
            <!-- <p style="">Flup - Admin panel for furniture ordering Webservice designed by Phenomenon Studio. Connect with them on Dribbble; the global community for designers and creative professionals.</p> -->
        </div>  
    </div>
</div>