import {
  AfterViewInit,
  ViewChild,
  OnDestroy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { AlertService, BlogService, AppService } from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { appConfig } from "../app.config";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../_services/encryption.service";

class Blog {
  id: number;
  title: string;
  category_ids: string;
  sub_category_ids: string;
  short_description: string;
  description: string;
  external_link: string;
  city: string;
  image: string;
  states: string;
  sectorofenterprise: string;
  areaofinterest: string;
  areaofstudy: string;
  meta_title: string;
  meta_keyword: string;
  meta_description: string;
  blog_type: string;
  home_display: string;
  is_active: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
})
export class BlogComponent implements OnInit, AfterViewInit, OnDestroy {
  configdescription: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  @Input() allowMultiple: boolean;
  @Input() fileType: string = ".jpg , .png , .bmp";
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList: any;
  isSubmitted = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  blogList: Blog[] = []; // Table Data list
  // productAddForm: FormGroup;
  //randomnumber = Math.floor(Math.random() * 100000000);
  getBlog = {
    id: "",
    title: "",
    category_ids: "",
    sub_category_ids: "",
    short_description: "",
    description: "",
    image: "",
    external_link: "",
    city: "",
    states: "",
    sectorofenterprise: "",
    areaofinterest: "",
    areaofstudy: "",
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
    blog_type: "",
    home_display: "",
    is_active: "",
  };

  closeResult: string; // Modal Close

  selectedToEnterprise: any;
  myStringEnterprise: any;
  myArrayEnterprise: any;

  selectedToInterest: any;
  myStringInterest: any;
  myArrayInterest: any;

  selectedToStudy: any;
  myStringStudy: any;
  myArrayStudy: any;

  imageChangedEvent: any = "";
  croppedImage: any = "";
  image_cropper: "";

  constructor(
    public router: Router,
    private dataService: BlogService,
    private alertService: AlertService,
    private AppService: AppService,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {}

  blog = {
    id: "",
    title: " ",
    category_ids: "",
    sub_category_ids: "",
    short_description: "",
    description: "",
    image: "",
    external_link: "",
    city: "",
    states: "",
    sectorofenterprise: "",
    areaofinterest: "",
    areaofstudy: "",
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
    blog_type: "",
    home_display: "",
    is_active: "",
  };

  totalBlogList: any;
  modalReference: NgbActiveModal;
  modalCropper: NgbActiveModal;
  modalTitle: string;
  cat = {};
  subCat = [];
  states = {};
  cities = {};
  blogType = {};
  sectorofenterprise = {};
  areaofinterest = {};
  areaofstudy = {};
  setting = { currency: "" };
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.allBlog();
    this.dataService
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.states = data["state"];
      });
    this.dataService
      .getAllCity()
      .pipe()
      .subscribe((data) => {
        this.cities = data["state"];
      });

    this.dataService
      .getCategory()
      .pipe()
      .subscribe((data) => {
        this.cat = Array.isArray(data["cat"]) ? data["cat"] : []; // Ensure it's an array
      });
    this.dataService
      .getSectorofEnterprise()
      .pipe()
      .subscribe((data) => {
        this.sectorofenterprise = data["sectorofenterprise"];
      });
    this.dataService
      .getAreaofInterest()
      .pipe()
      .subscribe((data) => {
        this.areaofinterest = data["areaofinterest"];
      });
    this.dataService
      .getAreaofStudy()
      .pipe()
      .subscribe((data) => {
        this.areaofstudy = data["area_of_study"];
      });
    this.dataService
      .getBlogType()
      .pipe()
      .subscribe((data) => {
        if (typeof data["blog-type"] === "object") {
          this.blogType = Object.values(data["blog-type"]); // Get array of values
        } else {
          this.blogType = []; // Default to an empty array in case of error
        }

        ////console.log(this.blogType);
      });

    let settingData = JSON.parse(localStorage.getItem("setting"));
    this.setting = { currency: settingData.currency };
  }

  allBlog() {
    this.getAllBlogList();
  }

  getAllBlogList() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/get-blog",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            //console.log(resp, "newsFeeds");

            this.blogList = resp["data"];
            this.totalBlogList = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },

      columns: [
        { data: "id" },
        { data: "title" },
        { data: "category" },
        { data: "created_by_name" },
        { data: "created_at" },
      ],
    };
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  changeToEnterprise() {
    this.blog.sectorofenterprise = this.selectedToEnterprise;
  }

  changeToInterest() {
    this.blog.areaofinterest = this.selectedToInterest;
  }

  changeToStudy() {
    this.blog.areaofstudy = this.selectedToStudy;
  }

  selectCat(id) {
    this.dataService
      .getSubCategory(id)
      .pipe()
      .subscribe((data) => {
        this.subCat = data["subCat"];
      });
  }

  selectCityById(id) {
    this.dataService
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  save(val, f) {
    this.isSubmitted = true;
    if (
      this.isFieldsInvalid(this.blog.title) ||
      this.isFieldsInvalid(this.blog.short_description)
    ) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (f.form.valid) {
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.isSubmitted = true;
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.getBlog(id).subscribe((data) => {
      this.getBlog = data["blog"];
      //console.log(this.getBlog, "edittt");

      if (this.getBlog.category_ids != null) {
        this.selectCat(this.getBlog.category_ids);
      }
      if (this.getBlog.states != null) {
        this.selectCityById(this.getBlog.states);
      }
      this.myStringEnterprise = this.getBlog.sectorofenterprise;
      if (this.myStringEnterprise) {
        this.myArrayEnterprise = this.myStringEnterprise
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        this.selectedToEnterprise = this.myArrayEnterprise;
      }

      this.myStringInterest = this.getBlog.areaofinterest;
      if (this.myStringInterest) {
        this.myArrayInterest = this.myStringInterest
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        //console.log(this.myArrayInterest);
        this.selectedToInterest = this.myArrayInterest;
      }

      this.myStringStudy = this.getBlog.areaofstudy;
      if (this.myStringStudy) {
        this.myArrayStudy = this.myStringStudy
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        this.selectedToStudy = this.myArrayStudy;
      }

      this.croppedImage = this.getBlog.image;

      this.blog = {
        id: this.getBlog.id,
        title: this.getBlog.title,
        category_ids: this.getBlog.category_ids,
        sub_category_ids: this.getBlog.sub_category_ids,
        short_description: this.getBlog.short_description,
        description: this.getBlog.description,
        image: this.getBlog.image,
        external_link: this.getBlog.external_link,
        sectorofenterprise: this.getBlog.sectorofenterprise,
        areaofinterest: this.getBlog.areaofinterest,
        areaofstudy: this.getBlog.areaofstudy,
        states: this.getBlog.states,
        city: this.getBlog.city,
        meta_title: this.getBlog.meta_title,
        meta_keyword: this.getBlog.meta_keyword,
        meta_description: this.getBlog.meta_description,
        blog_type: this.getBlog.blog_type,
        home_display: this.getBlog.home_display,
        is_active: this.getBlog.is_active,
      };
      this.loadHide();
      this.isSubmitted = false;
      this.modalTitle = "Edit Scheme";
      this.modalReference = this.modalService.open(content);
    });
  }

  fileChange(event: any) {
    this.fileList = event;
    this.DisplayedText = this.fileList[0].name;
    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;

      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        this.DisplayedText = "";
        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
        this.DisplayedText =
          this.fileList.length + " file(s) has been selected";
      } else {
        let file: File = this.fileList[0];
        this.DisplayedText = file.name;
      }
      this.onSelection.emit(this.fileList);
    }
  }

  open(content) {
    this.isSubmitted = false;
    this.selectedToEnterprise = "";
    this.selectedToStudy = "";
    this.selectedToInterest = "";
    this.blog.id = "";
    (this.blog.title = ""),
      (this.blog.category_ids = ""),
      (this.blog.sub_category_ids = ""),
      (this.blog.short_description = ""),
      (this.blog.description = ""),
      (this.blog.image = ""),
      (this.blog.external_link = ""),
      (this.blog.city = ""),
      (this.blog.states = ""),
      (this.blog.sectorofenterprise = ""),
      (this.blog.areaofinterest = ""),
      (this.blog.areaofstudy = ""),
      (this.blog.meta_title = ""),
      (this.blog.meta_keyword = ""),
      (this.blog.meta_description = ""),
      (this.blog.blog_type = ""),
      (this.blog.home_display = ""),
      (this.blog.is_active = "Y");
    this.modalTitle = "Add Scheme";
    this.modalReference = this.modalService.open(content);
  }

  insertAction(val, f) {
    this.loadShow();
    let formData: FormData = new FormData();
    formData.append("title", this.blog.title);
    formData.append("category_ids", this.blog.category_ids);
    formData.append("sub_category_ids", this.blog.sub_category_ids);
    formData.append("short_description", this.blog.short_description);
    formData.append("description", this.blog.description);
    formData.append("external_link", this.blog.external_link);
    formData.append("city", this.blog.city);
    formData.append("states", this.blog.states);
    formData.append("sectorofenterprise", this.selectedToEnterprise.join(","));
    formData.append("areaofinterest", this.selectedToInterest.join(","));
    formData.append("areaofstudy", this.selectedToStudy.join(","));
    formData.append("meta_title", this.blog.meta_title);
    formData.append("meta_keyword", this.blog.meta_keyword);
    formData.append("meta_description", this.blog.meta_description);
    formData.append("blog_type", this.blog.blog_type);
    formData.append("home_display", this.blog.home_display);
    formData.append("is_active", this.blog.is_active);

    if (val.id == "") {
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data.status == 200) {
              this.croppedImage = "";
              this.allBlog();
              this.alertService.success("Blog created successfully", true);
              this.modalReference.close();
            } else {
              //console.log("error");
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }

      this.dataService
        .blogUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            this.modalReference.close();
            this.allBlog();
            this.alertService.success("Blog updated successfully", true);
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    this.DisplayedText = this.fileList[0].name;

    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.DisplayedText = "";
        this.croppedImage = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.blog.image = this.croppedImage;
      };

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;

        return false;
      }

      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList[0];
      }
    }
    return false;
  }

 

  delete(item) {
    this.loadShow();
    this.dataService
      .blogDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Blog deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allBlog();
          } else {
            this.alertService.error("Blog error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    // this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.blog.image = file;
      this.croppedImage = "";
    }
    this.imageChangedEvent = event;
    //console.log(file, "files");
  }

  imageCropped(event: ImageCroppedEvent) {
    // this.blog.image = '';
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  exit(event: any) {
    this.fileList = event.exitTransform;
  }

  openCropper(contentCrop) {
    this.modalCropper = this.modalService.open(contentCrop);
  }

  closeCropper() {
    this.modalCropper.close();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
