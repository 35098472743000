<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/contact.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Feedback</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{contactFormList.length}}</h2>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Name</th>
										<th>Email</th>                                        
										<th>Query</th>
										<th>Created Date</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of contactFormList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<td>{{item.email}}</td>										
										<td>{{item.query}}</td>
										<td>{{item.created_at.split(' ')[0]}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>			
		</div>

	</div>
</div>