<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes" >
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/faq.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">FAQ</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{faqList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="open(content)" style="float: right;"> 
											Add FAQ
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>										
										<th>Category</th>
										<th>Type</th>
										<th>Question</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of faqList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>										
										<td>{{item.category}}</td>
										<td>{{item.type}}</td>
										<td>{{item.question}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success font-weight-normal badge-pill">Active</span> <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In active</span></td>
										<td class="action">		
											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											
																				
											<button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="edit(this.faqList[i].id,content)"><i class="fa fa-edit"></i></button>
											<!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light" (click)="delete(this.faqList[i].id)"><i class="fa fa-trash"></i></button>&nbsp; -->
											<!-- <ui-switch size="small" defaultBgColor="red" (change)="delete(this.faqList[i].id)" *ngIf="item.is_active=='Y'" checked></ui-switch>
											<ui-switch size="small" defaultBgColor="red" (change)="delete(this.faqList[i].id)" *ngIf="item.is_active=='N'"></ui-switch> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #content let-c="close" let-d="dismiss" id="faqcategory-add">			
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
                            <div class="form-group row">
								<label for="faq_category" class="col-md-12 form-control-label">Category <span>*</span></label>
								<div class="col-md-12">
									<select class="form-control" id="faq_category" name="faq_category" required [(ngModel)]="faq.faq_category">
										<option value="" selected disabled>Please Select</option>
										<option *ngFor="let item of cat" value="{{item.id}}">{{item.name}}</option>
									</select>
									<span *ngIf="isSubmitted && (faq.faq_category==undefined || faq.faq_category=='')" class="alert-block">This field is required</span>
								</div>
							</div>
							<div class="form-group row">
								<label for="type" class="col-md-12 col-form-label">FAQ For <span>*</span></label>
								<div class="col-md-12">
									<select class="form-control" id="type" required name="type" [(ngModel)]="faq.type">
										<option value="GENERAL">General</option>
										<option value="WEP">Entrepreneurs/Mentees</option>
										<option value="MENTORS">Mentors</option>
										<option value="PARTNER">Partners</option>
										<!-- <option value="WTI AWARD">WTI AWARD</option> -->
									</select>
									<span *ngIf="isSubmitted && (faq.type==undefined || faq.type=='')" class="alert-block">This field is required</span>
								</div>
							</div>
							<div class="form-group row">
								<label for="question" class="col-md-12 col-form-label">Question <span>*</span> </label>
								<div class="col-md-12">
									<textarea class="form-control" type="text" id="question" name="question" required [(ngModel)]="faq.question"> </textarea>
									<span *ngIf="isSubmitted && isFieldsInvalid(faq.question)" class="alert-block">This field is required</span>
								</div>
							</div>
							
							<div class="form-group row">
								<label for="answer" class="col-md-12 col-form-label">Answer<span>*</span> </label>
								<div class="col-md-12">
                                    <!-- <ejs-richtexteditor id='answer' required [toolbarSettings]='tools' name="answer" [(ngModel)]="faq.answer"></ejs-richtexteditor> -->
									<angular-editor [config]="config" required id='answer' [(ngModel)]="faq.answer" name="answer"></angular-editor>

									<span *ngIf="isSubmitted && (faq.answer==undefined || faq.answer=='')" class="alert-block">This field is required</span>
								</div>								
							</div>
							<div class="form-group row">
								<label for="is_active" class="col-md-12 col-form-label">Status</label>
								<div class="col-md-12">
									<select class="form-control" id="is_active" name="is_active" [(ngModel)]="faq.is_active">
										<option value="Y">Active</option>
										<option value="N">Inactive</option>
									</select>
								</div>
							</div>
						</form>
					</div>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close" (click)="d('Cross click')">Close</button>
						
							<button type="submit" class="btn btn-primary savebtn rounded" (click) ="save(this.faq,f)">Save</button>
						

						
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>