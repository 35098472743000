import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class OfferingVerticalsService {
  constructor(private base: BaseService) {}

  save(data) {
    return this.base.post("/api/offering-vertical-create", data);
  }

  getAllVertical() {
    return this.base.get("/api/offering-vertical-ajax-list");
  }

  editVertical(id) {
    return this.base.post("/api/offering-vertical-edit", { id: id });
  }

  verticalUpdate(data) {
    return this.base.post("/api/offering-vertical-update", data);
  }

  verticalDelete(id) {
    return this.base.post("/api/offering-vertical-update-status", { id: id });
  }
}
