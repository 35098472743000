import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class VideoService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/video-create', data);
  }

  videoUpdate(data){
    return this.base.post('/api/video-update',data);
  }

  videoDelete(id){
    return this.base.post('/api/video-delete',{id:id});
  }

  getAllVideo(){
    return  this.base.get('/api/get-all-video');
  }

  getVideo(id){
    return this.base.post('/api/video-edit',{id:id});
  }

  getActiveVertical () {
    return  this.base.get('/api/get-all-active-vertical');
  }

  getActiveAreaSectorOfBusiness () {
    return  this.base.get('/api/get-all-active-area-sector-of-business');
  }

  getSectorofEnterprise()
  {
    return this.base.get('/api/get-active-sectorofenterprise');
  }

  getAreaofInterest()
  {
    return this.base.get('/api/get-active-areaofinterest');
  }

  getCategory()
  {
    return this.base.get('/api/all-blogcategory');
  }
  
  getAreaofStudy()
  {
    return this.base.get('/api/get-all-active-area-of-study');
  }

  getAllState(){
    return  this.base.get('/api/all-indian-state');
  }

  getAllCity(){
    return  this.base.get('/api/all-city');
  }

  getCityById(id){
    return this.base.post('/api/all-city-by-id',{id:id});
  }

}
