<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
 <!--    <p class="line-1 anim-typewriter animated-text delay-1" style="color: #ff727d;">Loading dashboard. Wait a moment...</p><br>
    <p class="line-1 anim-typewriter animated-text delay-2" style="color: #ff727d;">Data and graphs are being fetched...</p><br>
    <p class="line-1 anim-typewriter animated-text delay-3" style="color: #ff727d;">We are almost done. Here is your data...</p> -->
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">

            <div class="col-sm-6 col-lg-4 d-none">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-building text-primary p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Total Member</div>
                        <div class="h5">3000</div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 col-lg-4">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-users text-primary p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Total Registered Member</div>
                        <div class="h5">{{total_registered_member.total}}</div>
                        <div class="d-flex justify-content-around">
                            <div class="last-week">
                                <div><small>Last Week</small></div>
                                <div><b>{{total_registered_member.last_week}}</b></div>
                            </div>
                            <div class="last-month">
                                <div><small>Last Month</small></div>
                                <div><b>{{total_registered_member.last_month}}</b></div>
                            </div>
                            <div class="last-year">
                                <div><small>Last Year</small></div>
                                <div><b>{{total_registered_member.last_year}}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 col-lg-4">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-user-circle text-success p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">TOTAL LOGIN/ACTIVE MEMBER</div>
                        <div class="h5">{{total_loggedin_member.total}}</div>
                        <div class="d-flex justify-content-around">
                            <div class="last-week">
                                <div><small>Last Week</small></div>
                                <div><b>{{total_loggedin_member.last_week}}</b></div>
                            </div>
                            <div class="last-month">
                                <div><small>Last Month</small></div>
                                <div><b>{{total_loggedin_member.last_month}}</b></div>
                            </div>
                            <div class="last-year">
                                <div><small>Last Year</small></div>
                                <div><b>{{total_loggedin_member.last_year}}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 col-lg-4 ">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <!-- <i class="fa fa-user-circle text-success p-3 font-2xl mr-3 float-left"></i> -->
                        <i class="fa fa-address-card text-danger p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">TOTAL COMMUNITY POST COUNT </div>
                        <div class="h5">{{total_community_post_count.total}}</div>
                        <div class="d-flex justify-content-around">
                            <div class="last-week">
                                <div><small>Last Week</small></div>
                                <div><b>{{total_community_post_count.last_week}}</b></div>
                            </div>
                            <div class="last-month">
                                <div><small>Last Month</small></div>
                                <div><b>{{total_community_post_count.last_month}}</b></div>
                            </div>
                            <div class="last-year">
                                <div><small>Last Year</small></div>
                                <div><b>{{total_community_post_count.last_year}}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-12">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <!-- <button class="btn btn-dark" (click)="filterByDate()">Date</button> -->

                        <div class="d-flex justify-content-between">
                            <p>Registered and Login users By States</p>

                            <div class="d-flex justify-content-between">
                                <input class="form-control pt-3" type="text" #datePicker name="dates_123" >

                                <button class="border-0 cross-icon" (click)="onReset()">X</button>
                            </div>
                        </div>

                        <div class="chart-container">
                            <canvas id="barChart" height="150px" style="height: 250px !important;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-12 d-none">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <p> Registered users By profession</p>
                        <div class="chart-container">
                            <canvas id="barChart1" height="150px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <p>Registered Users By Sector of Business</p>
                        <div class="chart-container">
                            <canvas id="piechart" height="150px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 col-lg-6">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <p>Registered Users By Area of Interest</p>
                        <div class="chart-container">
                            <canvas id="piechart1" height="150px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <p>Registered Users By Age</p>
                        <div class="chart-container">
                            <canvas id="barchart3" height="150px"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <p>Page wise total views</p>
                        <div class="chart-container">
                            <canvas id="barchart4" height="150px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-12">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <p>Verticals wise total views</p>
                        <div class="chart-container">
                            <canvas id="barchart5" height="100px"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-building text-primary p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Central Schemes Count</div>
                        <div class="h5">{{totalCount.centralschemes}}</div>
                    </div>
                    <div class="card-footer" [ngClass]="{'px-3 py-2': hasName('blog'), 'px-4 py-3': !hasName('blog')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('blog')" routerLink="/blog">View
                            More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>
            

            <!--/.col-->
            <div class="col-sm-6 col-lg-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-building-o text-info p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">SIDBI Schemes Count</div>
                        <div class="h5">{{totalCount.SIDBISchemes}}</div>
                    </div>
                    <div class="card-footer" [ngClass]="{'px-3 py-2': hasName('blog'), 'px-4 py-3': !hasName('blog')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('blog')" href="#"
                            routerLink="/blog">View More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>


             <!--/.col-->
             <div class="col-sm-6 col-lg-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-building-o text-info p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">State Schemes Count</div>
                        <div class="h5">{{totalCount.StateSchemes}}</div>
                    </div>
                    <div class="card-footer" [ngClass]="{'px-3 py-2': hasName('blog'), 'px-4 py-3': !hasName('blog')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('blog')" href="#"
                            routerLink="/blog">View More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <!--/.col-->
            <div class="col-sm-6 col-lg-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-university text-warning p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Knowledge Bank Count</div>
                        <div class="h5">{{totalCount.KBschemes}}</div>
                    </div>
                    <div class="card-footer" [ngClass]="{'px-3 py-2': hasName('blog'), 'px-4 py-3': !hasName('blog')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('blog')" href="#"
                            routerLink="/blog">View More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-users text-danger p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Active Partner Count</div>
                        <div class="h5">{{totalCount.ActivePartner}}</div>
                    </div>
                    <div class="card-footer"
                        [ngClass]="{'px-3 py-2': hasName('partner-list'), 'px-4 py-3': !hasName('partner-list')}">
                        <a class=" dblink font-xs btn-block text-muted" href="#">
                            <span *ngIf="hasName('partner-list')" routerLink="/partner-list">View More <i
                                    class="fa fa-angle-right float-right font-lg"></i></span></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-calendar text-success p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Upcoming Events Count</div>
                        <div class="h5">{{totalCount.UpcomingEvents}}</div>
                    </div>
                    <div class="card-footer "
                        [ngClass]="{'px-3 py-2': hasName('event'), 'px-4 py-3': !hasName('event')}">
                        <a class=" dblink font-xs btn-block text-muted">
                            <span *ngIf="hasName('event')" routerLink="/upcoming-event">View More
                                <i class="fa fa-angle-right float-right font-lg"></i></span></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-calendar-check-o text-primary p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Past Event Count</div>
                        <div class="h5">{{totalCount.PastEvents}}</div>
                    </div>
                    <div class="card-footer "
                        [ngClass]="{'px-3 py-2': hasName('past-event'), 'px-4 py-3': !hasName('past-event')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('past-event')"
                            routerLink="/past-event">View More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-user-o text-danger p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">REGISTERED MEMBER COUNT
                        </div>
                        <div class="h5">{{totalCount.ActiveMember}}</div>
                    </div>
                    <div class="card-footer "
                        [ngClass]="{'px-3 py-2': hasName('member'), 'px-4 py-3': !hasName('member')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('member')"
                            routerLink="/member">View More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox d-none">
                        <i class="fa fa-globe text-info p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Community Post Count</div>
                        <div class="h5">{{totalCount.ForumQuestions}}</div>
                    </div>

                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-globe text-info p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Award To Reward Count</div>
                        <div class="h5">{{totalCount.TotalATR}}</div>
                    </div>

                    <div class="card-footer"
                        [ngClass]="{'px-3 py-2': hasName('award-to-reward'), 'px-4 py-3': !hasName('award-to-reward')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('award-to-reward')"
                            routerLink="/award-to-reward">View
                            More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-address-book text-info p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Member Blogs Count</div>
                        <div class="h5">{{totalCount.MemberBlog}}</div>
                    </div>
                    <div class="card-footer "
                        [ngClass]="{'px-3 py-2': hasName('member-blogs'), 'px-4 py-3': !hasName('member-blogs')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('member-blogs')"
                            routerLink="/member-blogs">View
                            More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class=" fa fa-address-card
                        text-warning p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Member Feedback Count</div>
                        <div class="h5">{{totalCount.contact_form}}</div>
                    </div>
                    <div class="card-footer"
                        [ngClass]="{'px-3 py-2': hasName('contact-form'), 'px-4 py-3': !hasName('contact-form')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('contact-form')"
                            routerLink="/contact-form">View
                            More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-play text-success p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2">Videos Count</div>
                        <div class="h5">{{totalCount.videos}}</div>
                    </div>
                    <div class="card-footer"
                        [ngClass]="{'px-3 py-2': hasName('video'), 'px-4 py-3': !hasName('video')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('video')"
                            routerLink="/video">View
                            More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="card">
                    <div class="card-block p-3 clearfix dbbox">
                        <i class="fa fa-bars text-primary p-3 font-2xl mr-3 float-left"></i>
                        <div class="text-uppercase text-muted  font-xs mb-0 mt-2"> Verticles Count</div>
                        <div class="h5">{{totalCount.verticals}}</div>
                    </div>
                    <div class="card-footer "
                        [ngClass]="{'px-3 py-2': hasName('verticals'), 'px-4 py-3': !hasName('verticals')}">
                        <a class=" dblink font-xs btn-block text-muted" *ngIf="hasName('verticals')"
                            routerLink="/verticals">View
                            More <i class="fa fa-angle-right float-right font-lg"></i></a>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-block">
                        <h6 class="card-title">Pending Partner List</h6>
                        <table class="table  table-striped">
                            <thead class="">
                                <tr>
                                    <th>S.no</th>
                                    <th>Partner Name</th>
                                    <th>Type</th>
                                    <th>Email</th>
                                    <th>Mobile no</th>
                                    <th>Request Date</th>
                                    <th>Status</th>
                                    <th *ngIf="hasName('partner-list')">#Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of pending_partners; let i = index" [attr.data-index]="i">
                                    <td>{{i+1}}</td>
                                    <td  *ngIf="item.type == 'Individual'">{{item.first_name + ' ' + item.last_name}}</td>
                                    <td  *ngIf="item.type == 'Organisation'">{{item.organization_name}}</td>
                                    <td>{{item.type}}</td>
                                    <td>{{item.spoc_email}}</td>
                                    <td>{{item.spoc_country_code}}{{item.spoc_mobile_no}}</td>
                                    <td>{{item.created_at.split(" ")[0]}}</td>
                                    <td><span class="badge badge-danger rounded">PENDING</span></td>
                                    <td *ngIf="hasName('partner-list')">
                                        <button type="button" class="btn text-dark btn-sm  rounded bg-light"
                                            (click)="openPartnerViewPage(this.pending_partners[i].slug)"><i
                                                class="fa fa-eye"></i></button>&nbsp;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 d-none">
                <div class="card">
                    <div class="card-block">
                        <h6 class="card-title">Pending Blog List</h6>
                        <table class="table  table-striped">
                            <thead class="">
                                <tr>
                                    <th>S.no</th>
                                    <th>Member Name</th>
                                    <th>Blog Title</th>
                                    <th>Request Date</th>
                                    <th>Status</th>
                                    <th>#Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of pending_blogs; let i = index" [attr.data-index]="i">
                                    <td>{{i+1}}</td>
                                    <td>{{item.created_by_name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.created_at}}</td>
                                    <td><span class="badge badge-danger rounded">{{item.blog_status}}</span></td>
                                    <td><a href="javascript:void(0);" (click)="memberBlogView(this.pending_blogs[i].id)"
                                            class="text-success">Approve</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

