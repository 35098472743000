<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">WEP Mentorship</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{totalMentors}}</h2>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<select class="form-control" id="approve_status"
										[(ngModel)]="filterData.status" (change)="setFilterData($event)">
											<option value="ALL">ALL</option>
											<option value="PENDING">PENDING</option>
											<option selected value="APPROVED">APPROVED</option>
											<option selected value="REJECTED">REJECTED</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table id="mentortable" style="white-space: nowrap;" datatable [dtOptions]="dtOptions" class="row-border hover">
								<thead>
								  <tr>
									<th >#Sl</th>
									<th>Action</th>
									<th>Status </th>
									<th>Active/Inactive </th>

									<th>Name</th>
									<th>Email</th>
									<th>Mobile</th>
									<th>Onboarded Date</th>
									<th>Created At</th>

									<th>Referral Code</th>
									<th>Activated / Inactivated By</th>
									<th>Inactivated Date</th>
									<th>Approved / Rejected By</th>

								  </tr>
								</thead>
								<tbody *ngIf="mentorList?.length != 0">
								  <tr *ngFor="let mentor of mentorList; let i = index" [attr.data-index]="i">
									<td>{{ getRowNumber(i) }}</td>
									<td class="action">
										<label class="switch">
											<input
												type="checkbox"
												(change)="delete(mentor)"
												[checked]="mentor.status === 'Y'">
											<span class="slider round"></span>
										 </label>
										<button type="button" class="btn text-dark btn-sm  rounded bg-light"
										(click)="openMentorInfo(mentor.action,mentorinfo_ind)">
										<i class="fa fa-eye"></i></button>
									</td>

									<td>{{ mentor.approve_status}}</td>

									<td><span *ngIf="mentor.status=='Y'"
										class="badge badge-success badge-pill">Active</span> <span
										*ngIf="mentor.status=='N'"
										class="badge badge-danger badge-pill">Inactive</span></td>

									<td>{{ mentor.name }}</td>
									<td>{{ mentor.email }}</td>
									<td>{{ mentor.mobile }}</td>
									<td>{{ mentor.approved_rejected_date }}</td>
									<td>{{ mentor.created_at }}</td>

									<td>{{ mentor.referral_code?mentor.referral_code:''  }}</td>
									<td >{{ mentor.inactivated_by_name }}</td>
									<td><span *ngIf="mentor.approve_status == 'REJECTED' || mentor.approve_status == 'APPROVED'">{{ mentor.inactivated_date }}</span></td>
									<td ><span  *ngIf="mentor.approve_status != 'PENDING'">{{ mentor.approved_rejected_by_name }}</span></td>






								  </tr>
								</tbody>
								<tbody *ngIf="mentorList?.length == 0">
								  <tr>
									<td colspan="6" class="no-data-available">No data!</td>
								  </tr>
								</tbody>
							  </table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #mentorinfo_ind let-c="close" let-d="dismiss" id="mentorinfo_ind">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Mentor Information</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-bordered">
										<tbody>
											<tr>
												<th>First Name</th>
												<td>{{mentorInfo.first_name}}</td>
												<th>Last Name</th>
												<td>{{mentorInfo.last_name}}</td>
											</tr>
											<tr>
												<th>Email</th>
												<td>{{mentorInfo.email}}</td>
												<th>Mobile</th>
												<td>{{mentorInfo.mobile}}</td>
											</tr>
											<tr>
												<th>Birth Year</th>
												<td>{{mentorInfo.birth_yr}}</td>
												<th>Gender</th>
												<td>{{mentorInfo.gender}}</td>
											</tr>
											<tr>
												<th>Country</th>
												<td>{{mentorInfo.country_name}}</td>
												<th>State</th>
												<td>{{mentorInfo.state_name}}</td>
											</tr>
											<tr>
												<th>City</th>
												<td>{{mentorInfo.city_name? mentorInfo.city_name : mentorInfo.city_others}}</td>
												<th>Linkedin profile url</th>
												<td>{{mentorInfo.linkedin_profile_url}}</td>
											</tr>
											<tr>
												<th>Spoken Language</th>
												<td>{{ getFilteredSpokenLanguages(mentorInfo)}}<span *ngIf="containsOthers(mentorInfo.spoken_lang_names)">, {{mentorInfo.spoken_lang_others}}</span></td>

												<th>Primary Industry</th>

												<td *ngIf="mentorInfo.primary_industry_name == 'Others'" ><span >
													{{ mentorInfo.primary_industry_others }}</span>
												</td>
												<td *ngIf="mentorInfo.primary_industry_name != 'Others'"><span >
													{{ mentorInfo.primary_industry_name }}</span></td>
											</tr>
											<tr>
												<th>Stage of Enterprise</th>
												<td>{{mentorInfo.stage_of_enterprise_names}}</td>
												<th>Industry Experience</th>
												<td>{{mentorInfo.industry_experience_name}}</td>
											</tr>
											<tr>
												<th>Area of functional expertise</th>
												<td>{{mentorInfo.area_of_functional_expertise_name}}</td>
												<th>Qualification</th>
												<td  *ngIf="mentorInfo.qualification_name != 'Other'"><span>{{mentorInfo.qualification_name}}</span></td>
												<td *ngIf="mentorInfo.qualification_name == 'Other'"><span >{{mentorInfo.qualification_others}}</span></td>


											</tr>
											<tr>
												<th>Preferred Track of Mentorship</th>
												<td>{{mentorInfo.track_of_mentorship_names}}</td>
												<th>Ecosystem Key</th>
												<td *ngIf="mentorInfo.ecosystem_key_names != 'Others'"><span>{{mentorInfo.ecosystem_key_names}}</span></td>
												<td *ngIf="mentorInfo.ecosystem_key_names == 'Others'"><span>{{mentorInfo.ecosystem_key_other}}</span></td>
											</tr>
											<tr>
												<th>Preference Across</th>
												<td>{{mentorInfo.preference_across_name}}</td>
												<th>Mode of Engagement</th>
												<td>{{mentorInfo.mode_of_engagement_name}}</td>
											</tr>
											<tr>
												<th>Willing no of mentees</th>
												<td>{{mentorInfo.willing_no_of_mentees_name}}</td>
												<th>No of hours per month</th>
												<td>{{mentorInfo.no_of_hours_per_month_name}}</td>
											</tr>
											<tr>
												<th>Profile Image</th>
												<td><a *ngIf="mentorInfo.profileimage" href="{{mentorInfo.profileimage}}" target="_blank"><img  src="{{mentorInfo.profileimage}}" height="40px" width="50px"></a>
													<img *ngIf="!mentorInfo.profileimage" src="assets/img/default-profile.png" height="40px" width="50px"></td>
												<th>Resume</th>
												<td><a  href="{{mentorInfo.document}}" target="_blank">Resume</a>
												</td>
											</tr>

											<tr>
												<th>Meeting Link</th>
												<td>
													<a  *ngIf="mentorInfo?.meeting_link" href="{{mentorInfo?.meeting_link}}" target="_blank">Meeting Link</a>

													<i *ngIf="mentorInfo?.meeting_link"   style="padding: 10px;"
													class="fa"
													[ngClass]="isCopied ? 'fa-check' : 'fa-copy' "
													(click)="copyToClipboard(mentorInfo.meeting_link)"
													[style.cursor]="'pointer'"
                          [style.fontSize]="'18px'">
												</i>
												</td>

												<th>Meeting Date</th>
												<td>{{mentorInfo.meeting_date}}</td>
											</tr>

											<tr>
												<th>Meeting Start Time</th>
												<td>{{mentorInfo.meeting_start_time}}	</td>

												<th>Meeting End Time</th>
												<td>{{mentorInfo.meeting_end_time}}</td>
											</tr>
											<tr>
												<th>Status</th>
												<td>
													<select class="form-control" name="approve_status" id="approve_status" [(ngModel)]="mentorInfo.approve_status" (ngModelChange)="onChangeStatus()">
														<option disabled value="">Choose...</option>
														<!-- <option value="PENDING">Pending</option> -->
														<option value="APPROVED">Approve</option>
														<option value="REJECTED">Reject</option>
													</select>
												</td>
												<th>Remarks <span *ngIf=" mentorInfo.approve_status === 'REJECTED'" class="text-danger">*</span></th>
												<td>

													<input type="hidden" id="id" name="id" [(ngModel)]="mentorInfo.id" >
													<textarea class="form-control" name="remarks" [required]="mentorInfo.approve_status === 'REJECTED'" id="remarks" [(ngModel)]="mentorInfo.remark" (ngModelChange)="checkRemarks()"></textarea>
													<div *ngIf="isSubmitted && mentorInfo.approve_status === 'REJECTED' && (!mentorInfo.remark || mentorInfo.remark.trim() === '')" class="text-danger">
														This field is required
													</div>
													<!-- <div *ngIf="isSubmitted && mentorInfo.approve_status === 'REJECTED' && !mentorInfo.remark" class="text-danger">
														This field is required.
													</div> -->
												</td>
											</tr>

											<tr>
												<th>Verify Account</th>
											 <td><input type="checkbox" id="verifyCheckbox" name="verify" [checked]="isChecked" (change)="onCheckboxChange($event)">	</td>

											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary addbtn" aria-label="Close"
							(click)="mentorStatusUpdate(this.mentorInfo,mentorinfo_ind)">Submit</button>
						<button type="button" class="btn btn-secondary addbtn" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template>

	</div>
</div>
