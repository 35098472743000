import {
  AfterViewInit,
  OnDestroy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import {
  AlertService,
  NewsletterService,
  AppService,
} from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from "ngx-image-cropper";

class Newsletters {
  id: number;
  heading: string;
  image: string;
  html_path: string;
  descriptions: string;
  newsletter_date: string;
  is_active: string;
}

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() allowMultiple: boolean;
  @Input() htmlType: string = ".html, .htm";
  @Input() fileType: string = ".png, .jpg, jpeg";
  @Output() onSelection = new EventEmitter<FileList>();

  DisplayedText: string = "";
  DisplayedText1: string = "";
  fileList: any;
  htmlList: any;
  croppedImage: any = "";
  croppedHtml: any = "";

  image_cropper: "";
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  newslettersList: Newsletters[] = []; // Table Data list
  isSubmitted = false;
  imageChangedEvent: any = "";

  getNewsletters = {
    id: "",
    heading: "",
    image: "",
    html_path: "",
    descriptions: "",
    newsletter_date: "",
    is_active: "",
  };
  closeResult: string; // Modal Close
  constructor(
    public router: Router,
    private dataService: NewsletterService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  newsletter = {
    id: "",
    heading: "",
    image: "",
    html_path: "",
    descriptions: "",
    newsletter_date: "",
    is_active: "",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allNewsletters();
  }

  allNewsletters() {
    this.loadShow();
    this.dataService
      .getAllNewsletter()
      .pipe()
      .subscribe((data) => {
        this.newslettersList = data["newsletter"];
        //this.dtTrigger.next(); // Data Table
        this.rerender();
        this.loadHide();
      });
  }

  open(content) {
    this.croppedImage = "";
    this.croppedHtml = "";
    this.isSubmitted = false;

    // Clearing form fields
    this.newsletter.id = "";
    this.newsletter.heading = "";
    this.newsletter.image = "";
    this.newsletter.html_path = ""; // Clear HTML file path
    this.newsletter.descriptions = "";
    this.newsletter.newsletter_date = "";
    this.newsletter.is_active = "Y";
    this.DisplayedText = "";
    this.DisplayedText1 = ""; // Clear displayed file name

    this.modalTitle = "Add Newsletter";
    this.modalReference = this.modalService.open(content);
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.newsletter.heading)) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (f.form.valid) {
      this.loadShow();
      this.insertAction(val);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.loadShow();
    this.DisplayedText = "";
    this.dataService.getNewsletter(id).subscribe((data) => {
      this.getNewsletters = data["newsletter"];
      //console.log(this.getNewsletters, "edittt");

      this.DisplayedText1 = this.getNewsletters.html_path.split("admin/")[1];

      this.croppedImage = this.getNewsletters.image;
      this.croppedHtml = this.getNewsletters.html_path;

      this.newsletter = {
        id: this.getNewsletters.id,
        heading: this.getNewsletters.heading,
        image: this.getNewsletters.image,
        html_path: this.getNewsletters.html_path,
        descriptions: this.getNewsletters.descriptions,
        newsletter_date: this.getNewsletters.newsletter_date,
        is_active: this.getNewsletters.is_active,
      };
      this.loadHide();
      this.modalTitle = "Edit Newsletter";
      this.modalReference = this.modalService.open(content);
    });
  }

  insertAction(val) {
    let formData: FormData = new FormData();
    // if(this.imageList !=undefined){
    //   let file: File = this.imageList[0];
    //   formData.append('image', file, file.name);
    // } else {
    //   formData.append('image', this.newsletter.image);
    // }

    formData.append("heading", this.newsletter.heading);
    //formData.append('descriptions', this.newsletter.descriptions);
    formData.append("newsletter_date", this.newsletter.newsletter_date);
    formData.append("is_active", this.newsletter.is_active);

    if (val.id == "") {
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }

      if (this.htmlList !== undefined) {
        formData.append("html_path", this.croppedHtml);
        //console.log(this.croppedHtml, "cropppeddddhtml");
      }
      this.dataService
        .save(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.allNewsletters();
              this.alertService.success(
                "Newsletter created successfully",
                true
              );
            } else if (data["status"] == 422) {
              this.alertService.error(data.mesg.html_path[0]);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            } else {
              this.alertService.error(data.message);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    } else {
      formData.append("id", val.id);
      if (this.fileList !== undefined) {
        formData.append("image", this.croppedImage);
      }

      if (this.htmlList !== undefined) {
        formData.append("html_path", this.croppedHtml);
      } else {
        formData.append("html_path", "");
      }
      this.dataService
        .newsletterUpdate(formData)
        .pipe()
        .subscribe(
          (data) => {
            if (data["status"] == 200) {
              this.modalReference.close();
              // this.dtTrigger = new Subject(); //  DataTable
              this.allNewsletters();
              this.alertService.success(
                "Newsletter updated successfully",
                true
              );
            } else if (data["status"] == 422) {
              this.alertService.error(data.mesg.html_path[0]);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            } else {
              this.alertService.error(data.message);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
              this.loadHide();
            }
          },
          (error) => {
            this.alertService.error(error);
            document
              .querySelector(".modal")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        );
    }
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.newsletterDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Newsletter deleted successfully', true);
  //             this.allNewsletters();
  //           }else{
  //             this.alertService.error('Newsletter error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //       }
  //     }

  delete(item) {
    this.loadShow();
    this.dataService
      .newsletterDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Newsletter deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allNewsletters();
          } else {
            this.alertService.error("Newsletter error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  onHtmlchange(event: any) {
    this.htmlList = event.target.files;
    let hasFile = this.htmlList && this.htmlList.length > 0;
    if (hasFile) {
      var extension = this.htmlList[0].name.substring(
        this.htmlList[0].name.lastIndexOf(".")
      );
      var validFileType = this.htmlType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "Please select a valid file type. The supported file types are " +
            this.htmlType
        );
        this.htmlList = null;
        this.croppedHtml = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.htmlList[0]);
      reader.onload = () => {
        this.croppedHtml = reader.result as string;
        this.newsletter.html_path = this.croppedHtml;
        //console.log(this.croppedHtml, "cropped html");

        // Set the displayed file name
        this.DisplayedText1 = this.htmlList[0].name;
      };

      if (this.htmlList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.htmlList = null;
        return false;
      }
      let multipleFile = this.htmlList.length > 1;
      if (multipleFile) {
        // Handle multiple files if needed
      } else {
        let file: File = this.htmlList[0];
      }
    }
    return false;
  }

  fileChange(event: any) {
    this.htmlList = event.target.files;
    // let filetypeToCompare = this.fileType.replace('*','');
    let hasFile = this.htmlList && this.htmlList.length > 0;
    if (hasFile) {
      var extension = this.htmlList[0].name.substring(
        this.htmlList[0].name.lastIndexOf(".")
      );
      // Only process image files.
      var validFileType = this.htmlType;
      if (validFileType.toLowerCase().indexOf(extension) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.htmlType
        );
        this.htmlList = null;
        this.DisplayedText1 = "";
        return false;
      }

      if (this.htmlList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.htmlList = null;
        this.DisplayedText1 = "";
        return false;
      }

      let multipleFile = this.htmlList.length > 1;
      if (multipleFile) {
        this.DisplayedText1 =
          this.htmlList.length + " file(s) has been selected";
      } else {
        let file: File = this.htmlList[0];
        this.DisplayedText1 = file.name;
      }
      this.onSelection.emit(this.htmlList);
    }
  }

  onFileChange(event: any) {
    this.fileList = event.target.files;
    this.DisplayedText = this.fileList[0].name

    let hasFile = this.fileList && this.fileList.length > 0;
    if (hasFile) {
      var extension = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".")
      );
      var validFileType = this.fileType;
      if (validFileType.toLowerCase().indexOf(extension.toLowerCase()) < 0) {
        alert(
          "please select valid file type. The supported file types are " +
            this.fileType
        );
        this.fileList = null;
        this.DisplayedText = ''
        this.croppedImage = "";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileList[0]);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.newsletter.image = this.croppedImage;
      };

      if (this.fileList[0].size > 5000000) {
        alert(`File size is more than 5 MB`);
        this.fileList = null;
        return false;
      }
      let multipleFile = this.fileList.length > 1;
      if (multipleFile) {
      } else {
        let file: File = this.fileList[0];
      }
    }
    return false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
