import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AreaOfInterestService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');

  save(data){
  	return this.base.post('/api/admin/areaofinterest/areaofinterest-create', data);
  }

  getAllAreaofinterest(){
    return  this.base.get('/api/admin/areaofinterest/all-areaofinterest');
  }

  getAreaOfInterest(id){
    return this.base.post('/api/admin/areaofinterest/get-areaofinterest',{id:id});
  }

  areaofinterestUpdate(data){
    return this.base.post('/api/admin/areaofinterest/areaofinterest-update',data);
  }

  areaofinterestDelete(id){
    return this.base.post('/api/admin/areaofinterest/areaofinterest-delete',{id:id});
  }
}


 