<div class="row">
    <div class="col-md-10">
        <div class="d-flex justify-content-start align-items-center h-100">
            <h4 class="mb-0">Average Mentorship Session</h4>
        </div>
    </div>
    <div class="col-md-2">
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-primary addbtn">Download</button>
        </div>
    </div>
    
    <div class="col-lg-12">
        <div class="card p-3 mt-3">
            <div class="card-block">
                <div class="table-responsive">
                    <table datatable id="" class="table table-gr"
                        width="100%">
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Mentor Name</th>
                                <th>Average Slot</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td>Brijesh Kumar</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>Rahul Singh</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>Ronak Tapadia</td>
                                <td>15</td>
                            </tr>
                        </tbody>

                        <!-- <tbody>
                            <tr>
                                <td colspan="7"
                                    class="no-data-available text-center">No
                                    data available!</td>
                            </tr>
                        </tbody> -->
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>