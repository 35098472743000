import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerOfferringService {
  
  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/partner-offering-create', data);
  }

  partnerOfferingUpdate(data){
    return this.base.post('/api/partner-offering-update',data);
  }

  partnerOfferingDelete(id){
    return this.base.post('/api/partner-offering-update-status',{id:id});
  }


  getAllPartnerOffering(){
    return  this.base.get('/api/get-list-partner-offering');
  }

  getPartnerOffering(id){
    return this.base.post('/api/edit-partner-offering',{id:id});
  }
  
  getAllState(){
    return  this.base.get('/api/all-indian-state');
  }

  getAllCity(){
    return  this.base.get('/api/all-city');
  }

  getCategoryList(){
    return  this.base.get('/api/get-all-active-vertical');
  }


  getCityById(id){
    return this.base.post('/api/all-city-by-id',{id:id});
  }
 
 
}