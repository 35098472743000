import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptionService } from './encryption.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BaseService {
    headers = new HttpHeaders().set('X-API-KEY', 'wepu4ff4f8fufh3ehdehd');
    constructor(private http: HttpClient,
        private encryptionService: EncryptionService,
    ) { }


    public post(apiURL: string, param: any): Observable<any> {
        if (param instanceof FormData) {
            var body = this.encryptionService.encrypt(this.encryptionService.formDataToObject(param));
            var data = { 'req_data': body };
          //console.log('data : ', this.encryptionService.formDataToObject(param))
            return this.http.post<any>(apiURL, data,{ headers: this.headers }).pipe(
                catchError((error: any) => {
                    console.error("Error:", error);
                    return throwError(error);
                }),
                map((response: any) => {
                    const decrypt = this.encryptionService.decrypt;
                    const output = decrypt(response.res_data);
                    //console.log("post : ", apiURL)
                    //console.log("post : ", JSON.parse(output))
                    var data = JSON.parse(output);

                    if (typeof data.mesg !== undefined || typeof data.mesg !== 'undefined') {
                        if (data.mesg == 'token_expired') {
                            localStorage.removeItem('currentUser')
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000)
                        }
                    }
                    return data;
                })
            );
        } else {
            var body2 = this.encryptionService.encrypt(JSON.stringify(param) as string);
            var data = { 'req_data': body2 };
          //console.log(data, "data2");
          //console.log(body2, "body2");
          //console.log(apiURL, "data2");

            return this.http.post<any>(apiURL, data,{ headers: this.headers }).pipe(

                catchError((error: any) => {
                    console.error("Error:", error);
                    return throwError(error);
                }),
                map((response: any) => {
                  const output = this.encryptionService.decrypt(response.res_data);
                  //console.log("post : ", JSON.parse(output))
                  //console.log(apiURL, "data2");

                    var data1 = JSON.parse(output);

                    if (typeof data1.mesg !== undefined || typeof data1.mesg !== 'undefined') {
                        if (data1.mesg == 'token_expired') {
                            localStorage.removeItem('currentUser')
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000)
                        }
                    }
                    return data1;
                })
            );
        }
    }

    // public post(apiURL: string, param: any): Observable<any> {
    //     if (param instanceof FormData) {
    //         var body = this.encryptionService.encrypt(this.encryptionService.formDataToObject(param));
    //         var data = { 'req_data': body };
    //         // //console.log('data : ', data)
    //         return this.http.post<any>(apiURL, data).pipe(
    //             catchError((error: any) => {
    //                 console.error("Error:", error);
    //                 return throwError(error);
    //             }),
    //             map((response: any) => {
    //                 const output = this.encryptionService.decrypt(response.res_data);
    //                 //console.log("post : ", apiURL)
    //                 //console.log("post : ", JSON.parse(output))
    //                 var data = JSON.parse(output);

    //                 if (typeof data.mesg !== undefined || typeof data.mesg !== 'undefined') {
    //                     if (data.mesg == 'token_expired') {
    //                         localStorage.removeItem('currentUser')
    //                         setTimeout(() => {
    //                             window.location.reload();
    //                         }, 1000)
    //                     }
    //                 }
    //                 return data;
    //             })
    //         );
    //     } else {
    //         var body2 = this.encryptionService.encrypt(JSON.stringify(param) as string);
    //         var data = { 'req_data': body2 };
    //         return this.http.post<any>(apiURL, data).pipe(
    //             catchError((error: any) => {
    //                 console.error("Error:", error);
    //                 return throwError(error);
    //             }),
    //             map((response: any) => {
    //                 const output = this.encryptionService.decrypt(response.res_data);
    //                 //console.log("post 1 : ", apiURL)
    //                 //console.log("post 1: ", JSON.parse(output))
    //                 var data1 = JSON.parse(output);
    //                 // if (typeof data1.token !== undefined || typeof data1.token !== 'undefined') {
    //                 //     if (data1.token != '') {
    //                 //         localStorage.setItem('currentUser', JSON.stringify(output));
    //                 //     }
    //                 //   }
    //                 if (typeof data1.mesg !== undefined || typeof data1.mesg !== 'undefined') {
    //                     if (data1.mesg == 'token_expired') {
    //                         localStorage.removeItem('currentUser')
    //                         setTimeout(() => {
    //                             window.location.reload();
    //                         }, 1000)
    //                     }
    //                 }
    //                 return data1;
    //             })
    //         );
    //     }
    // }

    post_with_header(apiURL: string, param: any) {
        const body1 = this.encryptionService.encrypt(JSON.stringify(param) as string);
      //console.log('post api : ', body1);
      //console.log('post data : ', JSON.stringify(param) as string);

        var data = { 'req_data': body1 };
        return this.http.post<any>(apiURL, data, { headers: this.headers }).pipe(
            catchError((error: any) => {
                console.error("Error:", error);
                return throwError(error);
            }),
            map((response: any) => {
                const output = this.encryptionService.decrypt(response.res_data);
                //console.log("post : ", JSON.parse(output))
                //console.log("post : ", apiURL)
                var data = JSON.parse(output);
                if (typeof data.token !== undefined || typeof data.token !== 'undefined') {
                    const tokens = data.token ? data.token: '';
                    if (tokens != '') {
                        localStorage.setItem('currentUser', JSON.stringify(output));
                    }
                  }
                if (typeof data.mesg !== undefined || typeof data.mesg !== 'undefined') {
                    if (data.mesg == 'token_expired') {
                        window.localStorage.clear();
                        localStorage.removeItem('currentUser')
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    }
                }
                return data;
            })
        );
        // return this.http.post(apiurl, data, { headers: this.headers });
    }

    public get(apiURL: string): Observable<any> {
        //console.log('get api : ', apiURL);
        return this.http.get<any>(apiURL,{ headers: this.headers }).pipe(
            catchError((error: any) => {
                console.error("Error:", error);
                return throwError(error);
            }),
            map((response: any) => {
                const output = this.encryptionService.decrypt(response.res_data);
                //console.log("get : ", JSON.parse(output))
                var data = JSON.parse(output);
                if (apiURL == '/api/get-blog') {
                    data = JSON.parse(data);
                }
                // //console.log("get_blog : ", data)
                // //console.log("get : ", apiURL)

                if (typeof data.mesg !== undefined || typeof data.mesg !== 'undefined') {
                    if (data.mesg == 'token_expired') {
                        window.localStorage.clear();
                        localStorage.removeItem('currentUser')
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    }
                }
                return data;
            })
        );
    }

    get_with_header(apiurl) {
        return this.http.get<any>(apiurl, { headers: this.headers }).pipe(
            catchError((error: any) => {
                console.error("Error:", error);
                return throwError(error);
            }),
            map((response: any) => {
                const output = this.encryptionService.decrypt(response.res_data);
                //console.log("get_header : ", JSON.parse(output))
                //console.log("get_header : ", apiurl)
                var data = JSON.parse(output);

                if (typeof data.mesg !== undefined || typeof data.mesg !== 'undefined') {
                    if (data.mesg == 'token_expired') {
                        // window.localStorage.clear();
                        localStorage.removeItem('currentUser')
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    }
                }
                return data;
            })
        );
    }

}
