import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { map } from "rxjs/operators";

@Injectable()
export class BlogService {
  constructor(private base: BaseService) {}

  save(data) {
    return this.base.post("/api/blog-create", data);
  }

  blogUpdate(data) {
    return this.base.post("/api/blog-update", data);
  }

  blogDelete(id) {
    return this.base.post("/api/blog-delete", { id: id });
  }

  getAllBlog() {
    return this.base.get("/api/get-blog");
  }

  getBlog(id) {
    return this.base.post("/api/blog-edit", { id: id });
  }

  getAllState() {
    return this.base.get("/api/all-indian-state");
  }

  getAllCity() {
    return this.base.get("/api/all-city");
  }

  getCityById(id) {
    return this.base.post("/api/all-city-by-id", { id: id });
  }

  getSectorofEnterprise() {
    return this.base.get("/api/get-active-sectorofenterprise");
  }

  getAreaofInterest() {
    return this.base.get("/api/get-active-areaofinterest");
  }

  getAreaofStudy() {
    return this.base.get("/api/get-all-active-area-of-study");
  }

  getCategory() {
    return this.base.get("/api/all-blogcategory");
  }

  getBlogType() {
    return this.base.get("/api/blog-types");
  }

  getSubCategory(id) {
    return this.base.post("/api/all-blogsubcategory", { id: id });
  }
}
