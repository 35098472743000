import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertService, PartnerListService } from '../_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AwardToRewardListService } from '../_services/award-to-reward-list.service';
import { AwardToRewardService } from '../_services/award-to-reward.service';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-award-to-reward-partner-list',
  templateUrl: './award-to-reward-partner-list.component.html',
  styleUrls: []
})

export class AwardToRewardPartnerListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
    dtTrigger = new Subject(); //  DataTable

  bodyDataArr:any=[]
  headDataArr:any=[]
  atrList: any[] = [];
  closeResult: string;
  selectedStatus:any

  constructor(
              public router: Router,
              private dataService:AwardToRewardListService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private atrService :AwardToRewardService
            ) { }

  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.allAtrList();
  }

  viewResponse(slug){
    this.router.navigate(['atr-response/' + slug]);

  }

  allAtrList(){
    this.loadShow();
      this.dataService.getAllAwardToRewardV1().subscribe((res:any)=>{

      //console.log(res.data,"atr list");
      this.atrList = res.data.sort((a: any, b: any) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return dateB - dateA; 
      });
      
      this.atrList = res.data.map((item: any) => {
        item.objective  = this.truncate(item.objective,60)
        return item;
      });

       this.rerender()  
       this.loadHide();
     })
   }

     
  truncate(value: any, limit: any) {
    if (!value) return '';
    if (value.length > limit) {
      return value.substring(0, limit) + '...';
     }
    return value;
   }



   rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }  

    
   ngAfterViewInit(): void {
      this.dtTrigger.next();
   }
  

  openPartnerViewPage(id) {
    this.router.navigate(['atr-view-list/' + id]);
  }

  updateStatus(item){

  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  // delete(id){
  //   this.loadShow()
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.deleteAwardToReward(id)
  //         .pipe().subscribe(data =>{            
  //           if(data['status']==200){
  //             this.dtTrigger = new Subject(); //  DataTable
  //             this.alertService.success('Atr deleted successfully', true);
  //             this.allAtrList();
  //             this.loadShow()
  //           }else{
  //             this.alertService.error('ATR error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //      }
  // }

  delete(item){
    this.loadShow();
      this.dataService.deleteAwardToReward(item.id)
          .pipe().subscribe(data =>{
            if(data['status']==200){
              this.alertService.success('Award to Reward deleted successfully', true);
              item.is_active = item.is_active === 'Y' ? 'N' : 'Y';
              this.allAtrList();
            }else{
              this.alertService.error('Award to Reward error');
            }
          },error =>{
            this.alertService.error(error);
          });
      this.loadHide();
    }

}
