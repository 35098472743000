import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class WepInAMinuteService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/add-web-in-a-minute', data);
  }

  wepinaminuteUpdate(data){
    return this.base.post('/api/update-web-in-a-minute',data);
  }

  wepinaminuteDelete(id){
    return this.base.post('/api/delete-web-in-a-minute',{id:id});
  }

  getAllWepInAMinute(){
    return  this.base.get('/api/get-all-web-in-a-minute');
  }

  getWepInAMinute(id){
    return this.base.post('/api/edit-web-in-a-minute',{id:id});
  }
}
