import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class BlogSubCategoryService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/blogsubcategory-create', data);
  }

  getBlogSubCategory(id){
    return this.base.post('/api/blogsubcategory-edit',{id:id});
  }

  blogSubCategoryUpdate(data){
    return this.base.post('/api/blogsubcategory-update',data);
  }

  blogSubCategoryDelete(id){
    return this.base.post('/api/blogsubcategory-delete',{id:id});
  }

  getAllBlogSubCategory(){
    return  this.base.get('/api/get-blogsubcategory');
  }

  getAllBlogcategory(){
    return  this.base.get('/api/all-blogcategory');
  }

}
