<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-lg-12 headboxes">
                <div class="card p-0">
                    <div class="card-block p-0">
                        <div class="heading-box" style="background-image:none;">
                            <div class="row align-items-center">
                                <div class="col-md-1">
                                    <div class="partner-iocn">
                                        <img *ngIf="atrDetails?.atr_banner" [src]="atrDetails?.atr_banner"
                                            style="height: 100px;width: 100px;">
                                        <img *ngIf="!atrDetails?.atr_banner" src="assets/img/default-profile.png"
                                            class="img-fluid" alt="">
                                    </div>
                                </div>

                                <div class="col-md-6 align-self-center">
                                    <div class="pagename">

                                        <h3 class="card-title mb-1 ml-5">{{atrDetails?.atr_name}}</h3>
                                        <!-- <p>Established on {{atrDetails.announcement_date}}</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-block partnerview">

                        <div class="row">
                            <div class="col-md-12">
                                <h4>ATR Details</h4>
                                <hr>
                            </div>
                                                                       
                        <!-- <div class="col-md-6">
                                 <div class="form-group">
                                     <label for="">Partner Logo</label>
                                     <img [src]="atrDetails.full_image" style="height: 100px;width: 100px;">
                                 </div>
                             </div> -->

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">ATR Name</label>
                                    <p>{{atrDetails?.atr_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Duration</label>
                                    <p>{{atrDetails?.duration}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Timeframe</label>
                                    <p>{{atrDetails?.timeframe}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Support Area</label>
                                    <p>{{atrDetails?.support_area_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Focus Region</label>
                                    <p>{{atrDetails?.focus_region}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Mode</label>
                                    <p>{{atrDetails?.mode}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Objective</label>
                                    <p>{{atrDetails?.objective}}</p>
                                </div>
                            </div>

                            <div class="col-md-6  ">
                                <div class="form-group">
                                    <label for="">Outcome</label>
                                    <p>{{atrDetails?.outcome}}</p>
                                </div>
                            </div>


                            <!-- ORGANISATION DETAILS -->
                            <div class="col-md-12">
                                <h4>Organisation Details</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Organisation Name</label>
                                    <p>{{atrDetails?.org_name }}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">POC</label>
                                    <p>{{atrDetails?.poc }}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">POC Email</label>
                                    <p>{{atrDetails?.poc_email }}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">POC Contact</label>
                                    <p>{{atrDetails?.poc_contact }}</p>
                                </div>
                            </div>

                            <!-- Capacity Building -->
                            <div class="col-md-12">
                                <h4>Capacity Building</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Capacity Building Support</label>
                                    <p>{{capacity_building?.capacity_building_support?
                                        capacity_building?.capacity_building_support
                                        : capacity_building?.capacity_building_support_other}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Capacity Building Mode</label>
                                    <p>{{capacity_building?.capacity_building_mode}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">capacity building Duration</label>
                                    <p>{{capacity_building?.capacity_building_duration ?
                                        capacity_building?.capacity_building_duration :
                                        capacity_building?.capacity_building_duration_other}}</p>
                                </div>
                            </div>

                            <!-- Competetion -->
                            <div class="col-md-12">
                                <h4>Competetion</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Shortlisting Criteria</label>
                                    <p>{{competetion?.shortlisting_criteria}}</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Application Mode</label>
                                    <p>{{competetion?.application_mode}}</p>
                                </div>
                            </div>


                            <!-- Reward -->
                            <div class="col-md-12">
                                <h4>Reward</h4>
                                <hr>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for=""> Nature of Reward</label>
                                    <p>{{reward?.nature_of_reward}}</p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Reward Receipts</label>
                                    <p>{{reward?.reward_recipients ? reward?.reward_recipients :
                                        reward?.reward_recipients_other}}</p>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for=""> Winner Selection</label>
                                    <p>{{reward?.winner_selection}}</p>
                                </div>
                            </div>

                            <!-- Outreach -->
                            <div class="col-md-12">
                                <h4>Outreach</h4>
                                <hr>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Sector Name </label>
                                        <p>{{outreach?.outreach_plan}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card-block">
                                <div class="partner-action p-3">
                                    <form method="post" enctype="multipart/form-data" class="form-horizontal"
                                        #f="ngForm">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="">
                                                    <label for="atr_remarks">Remarks</label>
                                                    <form [formGroup]="remarkForm">
                                                        <textarea class="form-control my-3" name="atr_remarks"
                                                            id="atr_remarks" formControlName="atr_remarks"
                                                            rows="3"></textarea>
                                                        <div *ngIf="atr_remark.invalid && (atr_remark.dirty || atr_remark.touched)"
                                                            class="alert">
                                                            <div *ngIf="atr_remark.errors && atr_remark.errors['required']"
                                                                class="text-danger">This field is required.</div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div class="col-md-12 align-self-center">
                                                <div class="actiobtn">                                                
                                                    <button type="submit"
                                                        (click)="saveSubmit(remark_val,'APPROVED')"
                                                        class="btn btn-primary mr-2 addbtn">Approve</button>
                                                    <button type="submit"
                                                        (click)="rejectStatus(remark_val,'REJECTED')"
                                                        class="btn btn-success addbtn mr-2">Reject</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>