<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box">
							<div class="row align-items-center">
								<div class="col-md-4">
									<div class="pagename">
										<h3 class="card-title">Invited Members</h3>
									</div>
								</div>

								<div class="col-md-4">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
                                        <h2>{{(inviteMemberList?.length > 0) ?  inviteMemberList?.length : 0 }}</h2>
									</div>
								</div>
                                
                                <div class="col-md-4 d-flex justify-content-end">
                                    <!-- <input type="file" (change)="onFileSelected($event)" accept=".csv"> -->
                               <button class="btn btn-primary addbtn" (click)="onModalOpen(content)">Upload CSV </button>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
							  <thead>
								<tr>
								  <th style="width: 5%;">#Sl</th>
								  <th class="d-none">First Name</th>
								  <th>Name</th>
								  <th>Email</th>
								  <th>Invited Date</th>
								  <th>Accepted/Rejected Date</th>
								  <th>Status</th>
								</tr>
							  </thead>
							  
							  <tbody *ngIf="inviteMemberList?.length != 0">
								<tr *ngFor="let item of inviteMemberList; let i = index" [attr.data-index]="i">
								  <td>{{ getRowNumber(i) }}</td>
								  <td class="d-none">{{item.first_name}}</td>
								  <td>{{item.first_name}} {{item.last_name}}</td>
								  <td>{{item.email}}</td>
								  <td>{{item.created_at | date:'dd-MMM-yyyy HH:mm:ss' }}</td>
								  <td>{{ item.action_date === '0000-00-00 00:00:00' ? '--' : (item.action_date | date:'dd-MMM-yyyy HH:mm:ss') }}</td>
								  <td>{{item.status}}</td>
								</tr>
							  </tbody>
						  
							  <tbody *ngIf="inviteMemberList?.length == 0">
								<tr>
								  <td colspan="7" class="no-data-available text-center">No data available!</td>
								</tr>
							  </tbody>
							</table>
						  </div>
						  
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>



<!-- Modal -->

<ng-template #content let-c="close" let-d="dismiss" id="customer-add" >
	<div class="modal-info" role="document">
		<div class="modal-content ">
			<div class="modal-header">
				<h4 class="modal-title">{{modalTitle}}</h4>
				<button type="button" class="close" aria-label="Close" (click)="onCloseButtonClick()">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			
			<div class="modal-body">
				<div class="d-flex justify-content-around mb-2">
					<a href="../../assets/sample/member_invitation.csv" download>Download Sample CSV</a>
					<input type="file" accept=".csv" (change)="onFileSelected($event)">
					<button class="btn btn-primary addbtn"  (click)="convertToBase64()">Upload CSV</button>		   
				</div>
				<div class="d-block ">

			
					<div class="text-danger" *ngIf="is_already_exist_email_user">
						<span class="fw-bold">This user already exists (email):</span> {{ is_already_exist_email_user }}
					  </div>
					  <div class="text-danger" *ngIf="is_already_invited_email">
						<span class="fw-bold">This email is already invited:</span> {{ is_already_invited_email }}
					  </div>
					  <div class="text-danger" *ngIf="is_email_validation">
						<span class="fw-bold">Incorrect information provided in Email:</span> {{ is_email_validation }}
					  </div>
					  <div class="text-danger" *ngIf="is_already_exist_mobile_user">
						<span class="fw-bold">This user already exists (mobile):</span> {{ is_already_exist_mobile_user }}
					  </div>
					  <div class="text-danger" *ngIf="is_already_invited_mobile">
						<span class="fw-bold">This mobile is already invited:</span> {{ is_already_invited_mobile }}
					  </div>
					  <div class="text-danger" *ngIf="is_mobile_validation">
						<span class="fw-bold">Incorrect information provided in Mobile:</span> {{ is_mobile_validation }}
					  </div>
					  
					<!-- <p class="text-danger" *ngIf="notMatchData"><span class="fw-bold">Incorrect information provided in Mobile :</span> {{ notMatchData}}</p> -->

					<!-- <p class="text-danger" *ngIf="emailArray"></p> -->
					<p class="text-success mt-3">{{ msg}}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary d-none" aria-label="Close"
					(click)="d('Cross click')">Close</button>
			</div>
		</div>
	</div>
</ng-template>