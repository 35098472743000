import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SalesHistoryService {

  constructor(private base: BaseService) { }

  getAllsales(){
  	return this.base.get('/api/get-all-sales');
  }
 

}