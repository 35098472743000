<header class="app-header navbar" *ngIf="showHeaderFooter">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand" routerLink="/user">
      <img src="assets/img/logo.png">
  </a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>&#9776;</button>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" ngbDropdown>
      <a class="nav-link dropdown-toggle" ngbDropdownToggle>
        <img src="assets/img/avatars/user.png" *ngIf="userData.image ==''" class="img-avatar">
        <img *ngIf="userData.image !=''" [src]="userData.image" class="img-avatar">
        <span class="d-md-down-none">{{userData.profileName}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <a class="dropdown-item" routerLink="/profile-settings"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</header>
<div class="app-body" [ngClass]="{'full-width': !showHeaderFooter, 'no-margin': !showHeaderFooter}">
  <div class="sidebar" *ngIf="showHeaderFooter">
    <nav class="sidebar-nav">
      <ul class="nav">
       
        <ng-container *ngFor="let item of nav; let i=index">
          <li class="nav-item"  *ngIf="item.type==null">
              <a class="nav-link" routerLinkActive="active" routerLink="/{{item.route}}"><i class="{{item.icon}}"></i>{{item.name}}</a>
          </li>
          <li class="nav-item nav-dropdown" *ngIf="item.type==1" routerLinkActive="open" appNavDropdown>
              <a *ngIf="item.type==1" class="nav-link nav-dropdown-toggle" appNavDropdownToggle><i class="{{item.icon}}"></i>{{item.name}}</a>
              <ul *ngIf="item.type==1" class="nav-dropdown-items">
                  <li class="nav-item" *ngFor="let sub of nav[i]['children']">
                      <a routerLinkActive="active" routerLink="/{{sub.route}}" class="nav-link"> {{sub.subMenu}}</a>
                  </li>
              </ul>
          </li>
        </ng-container>
      </ul>
    </nav>
  </div>

  <main class="main">
  
    <div class="container-fluid mt-3" >
      <!-- <alert></alert> -->
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->
  </main>
</div>

<footer class="app-footer">
  Copyright &copy; WEP. All rights reserved 2022.
</footer>
