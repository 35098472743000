<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">WEP Organisation</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{orgTotal}}</h2>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<select class="form-control" id="approve_status" 
										[(ngModel)]="filterData.status" (change)="setFilterData($event)">
											<option value="ALL">ALL</option>
											<option value="PENDING">PENDING</option>
											<option selected value="APPROVED">APPROVED</option>								
											<option selected value="REJECTED">REJECTED</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">							
							<table  id="organisationtable" datatable [dtOptions]="dtOptions" class="row-border hover">
								<thead>
								  <tr>
									<th>#Sl</th>
									<th>Name</th>
									<th>Email</th>
									<th>Status</th>
									<th>Action</th>
								  </tr>
								</thead>
								<tbody *ngIf="orgList?.length != 0">
									<tr *ngFor="let org of orgList; let i = index" [attr.data-index]="i">
									<td>{{ getRowNumber(i) }}</td>
									<td>{{ org.name }}</td>
									<td>{{ org.email }}</td>
									<td>{{ org.approve_status}}</td>
									<td><button type="button" class="btn btn-eye" 
										(click)="openOrgInfo(org.action,org_info)">
										<i class="fa fa-eye"></i></button>
									</td>
								  </tr>
								</tbody>
								<tbody *ngIf="orgList?.length == 0">
								  <tr>
									<td colspan="4" class="no-data-available text-center">No data available!</td>
								  </tr>
								</tbody>
						    </table>							  
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #org_info let-c="close" let-d="dismiss" id="org_info">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Organisation Information</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-bordered">
										<tbody>
											<tr>
												<th>Name</th>
												<td>{{orgInfo.name}}</td>
												<th>Age</th>
												<td>{{orgInfo.age}}</td>
											</tr>
											<tr>
												<th>Mobile</th>
												<td>{{orgInfo.contact_no}}</td>
												<th>Email</th>
												<td>{{orgInfo.email}}</td>
											</tr>
											<tr>
												<th>Address</th>
												<td>{{orgInfo.address}}</td>
												<th>Country</th>
												<td>{{orgInfo.country_names}}</td>
											</tr>
											<tr>
												<th>State</th>
												<td>{{orgInfo.state_names}}</td>
												<th>City</th>
												<td>{{orgInfo.city_names}}</td>
											</tr>
											<tr>
												<th>Type of organization</th>
												<td>{{orgInfo.type_of_organization}}</td>
												<th>Approve status</th>
												<td>{{orgInfo.approve_status}}</td>
											</tr>
											<tr>
												<th>Exp in providing mentorship</th>
												<td>{{orgInfo.exp_in_providing_mentorship_name}}</td>
												<th>No of entrepreneurs</th>
												<td>{{orgInfo.no_of_entrepreneurs_name}}</td>
											</tr>
											<tr>
												<th>Status</th>
												<td>
													<select class="form-control" name="approve_status" id="approve_status" [(ngModel)]="orgInfo.approve_status"  (ngModelChange)="onChangeStatus()">
														<option disabled value="">Choose...</option>
														<!-- <option value="PENDING">Pending</option> -->
														<option value="APPROVED">Approve</option>
														<option value="REJECTED">Reject</option>
													</select>
												</td>
												<th>Remarks <span *ngIf=" orgInfo.approve_status === 'REJECTED'" class="text-danger">*</span></th>

												<td>
													<input type="hidden" id="id" name="id" [(ngModel)]="orgInfo.id" >
												<textarea class="form-control" name="remarks" [required]="orgInfo.approve_status === 'REJECTED'" id="remarks" [(ngModel)]="orgInfo.remark" (ngModelChange)="checkRemarks()"></textarea>
												<div *ngIf="isSubmitted && orgInfo.approve_status === 'REJECTED' && (!orgInfo.remark || orgInfo.remark.trim() === '')" class="text-danger">
													This field is required
												</div>
													<!-- <input type="hidden" id="id" name="id" [(ngModel)]="orgInfo.id">
													<textarea class="form-control" name="remarks" id="remarks" [(ngModel)]="orgInfo.remark">{{orgInfo.remark}}</textarea> -->
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</form>
					 </div>
					 
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary" aria-label="Close"
							(click)="orgStatusUpdate(this.orgInfo,org_info)">Submit</button>
						<button type="button" class="btn btn-secondary" aria-label="Close"
							(click)="d('Cross click')">Close</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>