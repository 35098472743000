import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CategoryService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/category-save', data);
  }

  getAllCategory(){
    return  this.base.get('/api/get-all-category-by-grid');
  }

  getCategory(id){
    return this.base.post('/api/get-category',{id:id});
  }

  categoryUpdate(data){
    return this.base.post('/api/category-update',data);
  }

  categoryDelete(id){
    return this.base.post('/api/category-delete',{id:id});
  }
}
