import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertService, VerticalsService, AppService } from "../_services/index";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { WeCOpinionPollsService } from "../_services/we-c-opinion-polls.service";
import { EncryptionService } from "../_services/encryption.service";
import { HttpClient } from "@angular/common/http";
import { WeCNewsfeedsService } from "../_services/we-c-newsfeeds.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-we-community-newsfeeds",
  templateUrl: "./we-community-newsfeeds.component.html",
  styleUrls: ["./we-community-newsfeeds.component.sass"],
})
export class WeCommunityNewsfeedsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  isEditMode: boolean = false;
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  start: number = 0;
  pageLength: number = 10;
  newsFeedList: any[] = [];
  isSubmitted = false;
  totalNewsFeeds: any;
  modalReference: NgbActiveModal;
  modalTitle: string;
  answerTypeArr: any = [];
  likeListArr: any = [];
  commentListArr: any = [];
  reportPostArr: any = [];
  reportUserArr: any = [];

  constructor(
    public router: Router,
    private dataService: WeCNewsfeedsService,
    private alertService: AlertService,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private modalService: NgbModal
  ) {}

  showOption3 = false;
  showOption4 = false;
  optionCount: any = 2;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scroll(0, 0);
    this.getNewsFeedListing();
    this.loadHide();
  }

  getNewsFeedListing() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            "/api/we-community-newsfeed-list",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            var resp = JSON.parse(output);
            // console.log(resp['data'], "resp");
            // console.log(output, "output");

            this.newsFeedList = resp['data'].map((item: any) => ({
              ...item,
              Comments: this.truncate(item.Comments, 80),
            }));

            // console.log(this.newsFeedList,"newssssssssssss")
            this.totalNewsFeeds = resp["recordsTotal"];
            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "title" },
        { data: "LikeCount" },
        { data: "CommentCount" },
        { data: "ReportCount" },
        { data: "ReportUserCount" },
        { data: "CreatedDate" },
        { data: "inactive" },
        // { data: "AnswerTypeID" },
      ],
    };
  }
  

  truncate(value: any, limit: any) {
    if (!value) return "";
    if (value.length > limit) {
      return value.substring(0, limit) + "...";
    }
    return value;
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  deleteComment(item){
    this.loadShow();
    this.dataService
      .deleteComments(item.id)
      .pipe()
      .subscribe(
        (data) => {
          // console.log(data, "response");
          if (data["status"] == 200) {
            // this.alertService.success("Comments Deleted successfully", true);
      
            item.isActive = item.isActive === "Y" ? "N" : "Y";
            const table = $("#wecommunity").DataTable();
            table.ajax.reload(null, false);
            // this.allVerticals();
            this.loadHide();
          } else {
            this.alertService.error("Error");
            this.loadHide();
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
    }


  delete(item) {
    this.loadShow();
    this.dataService
      .deleteNewsFeeds(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("News Feeds Delete successfully", true);
            item.inactive = item.inactive === "Y" ? "N" : "Y";
            // this.allVerticals();
            this.loadHide();
          } else {
            this.alertService.error("News Feeds error");
            this.loadHide();
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loadHide();
        }
      );
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

 

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }

  onViewLikes(item, viewlikes) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.commentListArr = [];
    this.modalTitle = "Like List";
    this.modalReference = this.modalService.open(viewlikes);
    this.likeListArr = item.LikeCountArray;
  }

  onViewComments(item, viewcomments) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.likeListArr = [];
    this.modalTitle = "Comment List";
    this.modalReference = this.modalService.open(viewcomments);
    this.commentListArr = item.CommentArray;
  }

  onViewReportPost(item, reportpost) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.modalTitle = "Report Post List";
    this.modalReference = this.modalService.open(reportpost);
    this.reportPostArr = item.ReportArray;
  }

  onViewReportUser(item, reportuser) {
    const table = $("#wecommunity").DataTable();
    table.ajax.reload(null, false);
    this.modalTitle = "Report User List";
    this.modalReference = this.modalService.open(reportuser);
    this.reportUserArr = item.ReportUserArray;
  }
}
