import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OrgListService {

  constructor(private base: BaseService) { }  

  getOrgInfo(id) {
    return this.base.post('/api/view-organization',{id:id});
  }

  getAllOrg(data){
    return  this.base.post('/api/all-organization-by-status', data);
  }

  updateOrgStatus(data) {
    return  this.base.post('/api/update-organization-status', data);
  }

}
