import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AreaSectorOfBusinessService {

  constructor(private base: BaseService) { }

  save(data){
    return this.base.post('/api/add-area-sector-of-business', data);
  }

  areaSectorOfBusinessEdit(id){
    return this.base.post('/api/edit-area-sector-of-business',{id:id});
  }

  areaSectorOfBusinessUpdate(data){
    return this.base.post('/api/update-area-sector-of-business',data);
  }

  areaSectorOfBusinessDelete(id){
    return this.base.post('/api/delete-area-sector-of-business',{id:id});
  }

  getAllAreaSectorOfBusiness(){
    return  this.base.get('/api/get-all-area-sector-of-business');
  }


}
