<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Member</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{memberList.length}}</h2>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th>Name</th>
										<th>Mobile</th>
                                        <th>Email</th>
										<th>UUID</th>
										<th >Action</th>
									</tr>
								</thead>
								<tbody *ngIf="memberList?.length != 0">
									<tr *ngFor="let item of memberList; let i = index" [attr.data-index]="i">
										<td>{{item.index_id}}</td>
										<td>{{item.name ? item.name : '-'}}</td>
										<td>{{item.phone ? item.phone : '-'}}</td>
										<td>{{item.email ? item.email : '-'}}</td>										
										<td>{{item.UUID ? item.UUID : '-'}}</td>										
										<td class="action">
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
											(click)="openMemberListViewPage(this.memberList[i].id)"><i
												class="fa fa-eye"></i></button>&nbsp;
			
                                        </td>
									</tr>
								</tbody>

								<tbody *ngIf="memberList?.length == 0">
									<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>


