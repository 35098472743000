import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, PartnerViewService } from "../_services";
import { JioMeetingsService } from "../_services/jio-meetings.service";

@Component({
  selector: "app-jio-meetings-view",
  templateUrl: "./jio-meetings-view.component.html",
  styleUrls: [],
})
export class JioMeetingsViewComponent implements OnInit {
  partner_status: any;
  remarkForm: any;
  showloding = true;
  lodingImage = false;
  urlParams: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dataService: JioMeetingsService,
    private fb: FormBuilder
  ) {}

  partner = {
    partner_remarks: "",
  };

  jio_meet_details = {
    content: "",
    created_at: "",
    created_by: "",
    end_to: "",
    feedback: "",
    feedback_status_mentee: "",
    feedback_status_mentor: "",
    from_date: "",
    industry_experience: "",
    host_meeting_link: "",
    id: "",
    jio_meet_id: "",
    meet_json: "",
    meeting_invites: "",
    meeting_link: "",
    meeting_organizer: "",
    mentee_gender: "",
    meeting_type: "",
    mentee_country_name: "",
    mentee_phone_no: "",
    mentee_city_name: "",
    mentee_image: "",
    mentee_name: "",
    mentee_qualification_name: "",
    mentee_state_name: "",
    mentor_gender: "",
    mentor_image: "",
    mentor_industry_experience: "",
    mentor_industry_experience_other: "",
    mentor_name: "",
    mentor_phone_no: "",
    mentor_qualification: "",
    qualification: "",
    remark: "",
    question: [],
    slug: "",
    start_from: "",
    status: "",
    to_date: "",
    topic: "",
    updated_by: "",
    updated_at: "",
  };

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.urlParams = params["slug"];
      //console.log(this.urlParams, "glugg");
    });
    this.getJioMeetDetails();
  }

  getJioMeetDetails() {
    this.loadShow();

    const data = {
      slug: this.urlParams,
    };

    this.dataService
      .viewJioMeet(data)
      .pipe()
      .subscribe((res) => {
        this.jio_meet_details = res.data;
        //console.log(res.data, "viewjiomeet");
        this.loadHide();
      });
  }

  onBack() {
    this.router.navigate(["/jio-meetings"]);
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
