import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class WeCOpinionPollsService {
  constructor(private base: BaseService) {}

  createOpinionPolls(data) {
    return this.base.post("/api/createOpinionPoll", data);
  }

  getAllOpinionPolls() {
    return this.base.get("/api/supplier-save");
  }

  deleteComments(id) {
    return this.base.post("/api/we-community-comment-status", {
      id: id,
    });
  }

  getAllSupplier() {
    return this.base.get("/api/get-all-supplier");
  }

  getSupplier(id) {
    return this.base.post("/api/get-supplier", { id: id });
  }

  deleteOpinionPolls(id) {
    return this.base.post("/api/deleteOpiniumPoll", { opinionpollId: id });
  }

  getAnswerType() {
    return this.base.get("/api/getAnswerType");
  }

  updateOpinionPoll(data) {
    return this.base.post("/api/EditOpinionPoll", data);
  }

  weCommunityView(id) {
    return this.base.post("/api/we-community-view", { id: id });
  }
}
