import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwardToRewardService } from '../_services/award-to-reward.service';
import { AlertService } from '../_services';

@Component({
  selector: 'app-award-to-reward-registered',
  templateUrl: './award-to-reward-registered.component.html',
  styleUrls: []
})
export class AwardToRewardRegisteredComponent implements OnInit {
  atr_slug:any
  showloding = true;
  lodingImage = false;
  registeredListArr:any=[]
  atrname:any
  
  constructor(private route:ActivatedRoute,
              private dataService:AwardToRewardService,
              private alertService: AlertService,
              ) { }


  ngOnInit() {
     this.atrname =localStorage.getItem("atrName")
     this.route.queryParams.subscribe((params:any)=>{
     this.atr_slug = params['slug'];
    })
     this.getRegisteredList()
   }


  getRegisteredList(){
    this.loadShow();
    this.dataService.getRegisteredUser(this.atr_slug)
      .pipe().subscribe(
        (res:any) => {
          this.registeredListArr = res.data['predefined_fields']
          //console.log(this.registeredListArr ,"//////");
          this.loadHide();
      }, error => {
        this.alertService.error(error);
      })
    }


  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }


}
