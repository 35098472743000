import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AtrMiscellaneousService } from '../_services/atr-miscellaneous.service';
import { AlertService } from '../_services';
import { AppService } from '../_services/index';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';

class AtrMiscellaneous {
  name:string;
  description: string;
  is_active:string
  }

@Component({
  selector: 'app-atr-miscellaneous',
  templateUrl: './atr-miscellaneous.component.html',
  styleUrls: []
})

export class AtrMiscellaneousComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  AwardToRewardList:any=[]
  awardtoreward:any
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";

  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  AtrMiscellaneousList: AtrMiscellaneous[] = []; // Table Data list
  isSubmitted = false;

  getAtrCategory = {
    constant: '',
    value:'',
  };
 
  constructor(
    public router: Router,
    private dataService:AtrMiscellaneousService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
   ) { }

  atrmiscellaneous = {
    constant:'',
    value: '',
  };

  modalReference:NgbActiveModal;
  modalTitle:string;
  showloding = true;
  lodingImage = false;
  positionChecked : string;
  
  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // allAtrMiscellaneous(){
  //   this.loadShow();
  //   this.dataService.getAllAtrMiscellaneous()
  //       .pipe().subscribe(
  //         data => { this.AtrMiscellaneousList = data['atr_category'];
  //         //console.log(this.AtrMiscellaneousList)
  //         this.dtTrigger.next(); // Data Table  
  //         this.loadHide();
  //     });
  //   }

  //   open(content) {
  //     this.isSubmitted = false;
  //     this.atrmiscellaneous.value = '';
  //     this.atrmiscellaneous.constant = '';
  //     this.DisplayedText = '';
  //     this.modalTitle = "Add Atr Miscellaneous";
  //     this.modalReference = this.modalService.open(content);
  //    }

  //    save(val,f){
  //     this.isSubmitted = true;
  //      if(f.form.valid) {
  //      this.loadShow()
  //      this.insertAction(val);
  //      } else {
  //      document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
  //      }
  //     }

      
  //   edit(id,content){
  //     this.loadShow();
  //     this.DisplayedText = '';
  //       this.dataService.getAtrMiscellaneous(id)
  //           .subscribe(data => { this.getAtrCategory = data['banners']; 
  //               this.atrcategory = {
  //                     name:this.getAtrCategory.name,
  //                     description:this.getAtrCategory.description,
  //                     is_active:this.getAtrCategory.is_active,
  //                     id:this.getAtrCategory.id,
  //                  };
  //               this.loadHide();
  //               this.modalTitle = "Edit Award To Reward";
  //               this.modalReference = this.modalService.open(content);
  //          });
  //        } 

       
  //        insertAction(val){
  //         let formData:FormData = new FormData();
         
  //         formData.append('name',this.atrcategory.name);
  //         formData.append('description',this.atrcategory.description);
  //         formData.append('is_active', this.atrcategory.is_active);
  
  //         if(val.id!==''){
  //           this.dataService.save(formData)
  //               .pipe().subscribe(data =>{
  //                   this.modalReference.close();
  //                   this.dtTrigger = new Subject(); //  DataTable
  //                   this.allAtrCategory();
  //                   this.alertService.success('ATR created successfully', true);
  //               },error =>{
  //                 this.alertService.error(error);
  //                 document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
  //               });
  //         }else{
  //           formData.append('id', val.id);
  //           this.dataService.atrCategoryUpdate(formData)
  //               .pipe().subscribe(data =>{
  //                 this.modalReference.close();
  //                 this.dtTrigger = new Subject(); //  DataTable
  //                 this.allAtrCategory();
  //                 this.alertService.success('ATR updated successfully', true);
  //               },error =>{
  //                 this.alertService.error(error);
  //                 document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
  //               });
  //             }
  //           }
             }
