<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes"> 
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" > 
							<div class="row align-items-center">
								<div class="col-md-12">

                  <h3 class="card-title">Reports</h3>
									
                  <div class=" mx-0">
                    <!-- Nav Tabs -->
                    <ul class="nav nav-tabs p-0" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="member-details" data-toggle="tab"   href="#member" role="tab" aria-controls="member" aria-selected="true">Member Data Dump </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link"  id="month-wise" data-toggle="tab" href="#month" role="tab" aria-controls="month" aria-selected="false">Page and month wise count</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link"  id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="month" aria-selected="false">Repetitive Login Members</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" id="community-post" data-toggle="tab" href="#community" role="tab" aria-controls="contact" aria-selected="false">Community post</a>
                      </li>
                    </ul>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

    
  <div class="col-lg-12">
    <div class="card">
      <div class="card-block">
         <!-- Tab Content -->
         <div class="tab-content p-0" id="myTabContent">

          <!-- Member Details -->
          <div class="tab-pane fade show active p-0" id="member" role="tabpanel" aria-labelledby="member-details">

            <form [formGroup]="memberDetailsForm">
            <div class="row gy-3">

            <div class="col-lg-4 ">
              <div class="form-group">
                <label for="startDatee">Select Start Date <span class="text-danger">*</span></label>
                <input type="date" [max]="maxDate" required  name="" formControlName="mStartDate" id="startDatee" class="form-control" placeholder="" aria-describedby="helpId">

                <p  class="text-danger mt-2" *ngIf=" isSubmittedMember &&  member['mStartDate'].errors?.required">
                  start date is required.
                </p>
              </div>
            </div>

            <div class="col-lg-4 ">
              <div class="form-group">
                <label for="endDatee">Select End Date <span class="text-danger">*</span></label>
                <input type="date" name="" [max]="maxDate" required formControlName="mEndDate" id="endDatee" class="form-control" placeholder="" aria-describedby="helpId">
                <p  class="text-danger mt-2" *ngIf=" isSubmittedMember &&  member['mEndDate'].errors?.required">
                  end date is required.
                </p>
              </div>
            </div>

            <div class="col-lg-4 align-self-center" >
              <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="onSaveMemberDetails('1')">Generate Report</button>

              <button id="add_btn6" type="button" class="btn btn-dark ml-3" style="border-radius: 10px;" (click)="onResetMemberDetails()">Reset</button>
              </div>

              <div class="col-md-12">
              <p class="text-danger">{{errorMsg}}</p>
              </div>
            </div>
          </form>
          </div>
       
          <!-- Month Wise -->
         <div class="tab-pane fade" id="month" role="tabpanel" aria-labelledby="month-wise">
              <form [formGroup]="monthWiseForm">
              <div class="row gy-3">

                
            <div class="col-lg-4 ">
              <div class="form-group">
                <label for="mstartDatee">Select Start Date <span class="text-danger">*</span></label>
                <input type="date" [max]="maxDate" required  name="" formControlName="monthWiseStartDate" id="mstartDatee" class="form-control" placeholder="" aria-describedby="helpId">

                <p  class="text-danger mt-2" *ngIf=" isSubmittedMonthWise &&  month_wise['monthWiseStartDate'].errors?.required">
                  start date is required.
                </p>
              </div>
            </div>

            <div class="col-lg-4 ">
              <div class="form-group">
                <label for="mendDatee">Select End Date <span class="text-danger">*</span></label>
                <input type="date" name="" [max]="maxDate" required formControlName="monthWiseEndDate" id="mendDatee" class="form-control" placeholder="" aria-describedby="helpId">
                <p  class="text-danger mt-2" *ngIf=" isSubmittedMonthWise &&  month_wise['monthWiseEndDate'].errors?.required">
                  end date is required.
                </p>
              </div>
            </div>

                <!-- <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="monthYearS">Select Start Month and Year</label>
                    <input type="month"  [max]="maxMonth" required formControlName="monthWiseStartDate"  name="monthYearS" id="monthYearS" class="form-control" aria-describedby="monthYearHelp" value="MM/YYYY">
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedMonthWise && month_wise['monthWiseStartDate'].errors?.required">
                      start date is required.
                    </p>
                  </div>
                </div>
    
                <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="monthYearE">Select End Month and Year</label>
                    <input type="month"  [max]="maxMonth" required formControlName="monthWiseEndDate"  name="monthYearE" id="monthYearE" class="form-control" aria-describedby="monthYearHelp" value="MM/YYYY">
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedMonthWise && month_wise['monthWiseEndDate'].errors?.required">
                      end date is required.
                    </p>
                  </div>
                </div>
     -->
                <div class="col-lg-4 align-self-center" >
                  <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="onSaveMonthWise('2')">Generate Report</button>
    
                  <button id="add_btn6" type="button" class="btn btn-dark ml-3" style="border-radius: 10px;" (click)="onResetMonthWise()">Reset</button>
                  </div>
    
                  <div class="col-md-12">
                  <p class="text-danger">{{errorMsg}}</p>
                  </div>
                </div>
              </form>
         </div>

          <!-- Repititive Member Data -->
          <div class="tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">
            <form [formGroup]="repititiveMemberForm">
              <div class="row gy-3">

                <!-- <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="monthYearRS">Select Start Month and Year</label>
                    <input type="month"  [max]="maxMonth" required formControlName="repititiveStartDate"  name="monthYearRS" id="monthYearRS" class="form-control" aria-describedby="monthYearHelp" value="MM/YYYY">
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedrepititiveMember && repititive_member['repititiveStartDate'].errors?.required">
                     start date is required.
                    </p>
                  </div>
                </div>
    
                <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="monthYearRE">Select End Month and Year</label>
                    <input type="month"  [max]="maxMonth" required formControlName="repititiveEndDate"  name="monthYearRE" id="monthYearRE" class="form-control" aria-describedby="monthYearHelp" value="MM/YYYY">
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedrepititiveMember && repititive_member['repititiveEndDate'].errors?.required">
                      end date is required.
                    </p>
                  </div>
                </div> -->
    

                <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="rstartDatee">Select Start Date <span class="text-danger">*</span></label>
                    <input type="date" [max]="maxDate" required  name="" formControlName="repititiveStartDate" id="rstartDatee" class="form-control" placeholder="" aria-describedby="helpId">
    
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedrepititiveMember &&  repititive_member['repititiveStartDate'].errors?.required">
                      start date is required.
                    </p>
                  </div>
                </div>
    
                <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="rendDatee">Select End Date <span class="text-danger">*</span></label>
                    <input type="date" name="" [max]="maxDate" required formControlName="repititiveEndDate" id="rendDatee" class="form-control" placeholder="" aria-describedby="helpId">
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedrepititiveMember &&  repititive_member['repititiveEndDate'].errors?.required">
                      end date is required.
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 align-self-center" >
                  <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="onSaveRepititiveMember('3')">Generate Report</button>
    
                  <button id="add_btn6" type="button" class="btn btn-dark ml-3" style="border-radius: 10px;" (click)="onResetRepititive()">Reset</button>
                  </div>
    
                  <div class="col-md-12">
                  <p class="text-danger">{{errorMsg}}</p>
    
                  </div>
                </div>
              </form>
          </div>

          <!-- Community Post -->
          <div class="tab-pane fade" id="community" role="tabpanel" aria-labelledby="community-post">
            <form [formGroup]="communityPostForm">
              <div class="row gy-3">
                <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="monthYearCS">Select Start Date <span class="text-danger">*</span></label>
                    <input type="date" [max]="maxDate" required  name="monthYearCS" formControlName="communityStartDate" id="monthYearCS" class="form-control" placeholder="" aria-describedby="helpId">
    
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedCommunity &&  community_post['communityStartDate'].errors?.required">
                      start date is required.
                    </p>
                  </div>
                </div>
    
                <div class="col-lg-4 ">
                  <div class="form-group">
                    <label for="monthYearCE">Select End Date <span class="text-danger">*</span></label>
                    <input type="date" name="monthYearCE" [max]="maxDate" required formControlName="communityEndDate" id="monthYearCE" class="form-control" placeholder="" aria-describedby="helpId">
                    <p  class="text-danger mt-2" *ngIf=" isSubmittedCommunity &&  community_post['communityEndDate'].errors?.required">
                      end date is required.
                    </p>
                  </div>
                </div>

    
                <div class="col-lg-4 align-self-center" >
                  <button id="add_btn" type="button" class="btn btn-primary addbtn" (click)="onSaveCommunityPost('4')">Generate Report</button>
    
                  <button id="add_btn6" type="button" class="btn btn-dark ml-3" style="border-radius: 10px;" (click)="onResetCommunity()">Reset</button>
                  </div>
    
                  <div class="col-md-12">
                  <p class="text-danger">{{errorMsg}}</p>
    
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
 </div>

			<div class="col-lg-12">
				<div class="card">
          	<div class="card-block">
          <div id="table" class="table-responsive">

            <table id="reports"  width="100%" datatable [dtOptions]="dtOptions" class="row-border hover">
              <thead>
                <tr>
                  <th style="width: 5%;">#Sl</th>
                  <th style="width: 25%;">Report Name</th>
                  <th style="width: 30%;">Date Filter</th>
                  <th class="d-none">End Date</th>
                  <th style="width: 20%;">Created Date</th>
                  <th >Status</th>
                  <th class="d-none">Action</th>

                </tr>
              </thead>
              <tbody *ngIf="reportList?.length != 0">
                <tr *ngFor="let item of reportList; let i = index" [attr.data-index]="i">
                  <td>{{ getRowNumber(i) }}</td>
                  <td>{{item.report_name}}</td>
                  <td>From {{item.start_date}} to {{item.end_date}}</td>              
                  <td class="d-none">{{item.end_date}}</td>
                  <td>{{item.created_at}}</td>
                  <td>

                    <span class="icondesign" *ngIf="item.status == 'PENDING'">Generating...
                      <i class="fa fa-info-circle custom-tooltip-trigger">
                      </i>
                      <span class="custom-tooltip">Report will generate in 10 to 15 minutes.</span>
                   </span>
                    <span *ngIf="item.status == 'INPROGRESS'">Inprogress</span>
                    <span *ngIf="item.status == 'ERROR'">Error</span>
                    <span *ngIf="item.status == 'COMPLETE'">
                      <a [href]="item.url_path" ><button  type="button" class="btn btn-primary down-btn ">Download</button></a></span>
                  </td>
                  <td><span *ngIf="item.status=='Y'" class="badge badge-success badge-pill">Active</span>
                    <span *ngIf="item.status=='N'" class="badge badge-danger badge-pill">Inactive</span>
                  </td>

                   <!-- <td class="action">
                   <label class="switch">
                      <input 
                        type="checkbox"
                        (change)="delete(item)"
                        [checked]="item.status === 'Y'">
                      <span class="slider round"></span>
                    </label>
                     <button type="button" class="btn text-dark btn-sm  rounded bg-light"
                      (click)="edit(this.reportList[i].id,content)"><i
                        class="fa fa-edit"></i></button>            
                  </td>    -->
                </tr>
              </tbody>

              <tbody *ngIf="reportList?.length == 0">
								<tr>
								  <td colspan="7" class="no-data-available text-center">No data available!</td>
								</tr>
							</tbody>
            </table>
          </div>
				</div>
			</div>
		 </div>
   </div>
	</div>
</div>
