import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, AboutService,AppService } from '../_services/index';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  showloding = true;
  lodingImage = false;
   isSubmitted = false;
   aboutForm:any
   aboutId:any
   configcontent: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ] 
  };

	constructor(
            public router: Router,
            private dataService:AboutService,
            private alertService: AlertService,
            private AppService: AppService,
            private fb : FormBuilder
           ) { }
  
	ngOnInit() {
     this.aboutForm = this.fb.group({
      id : [""],
      content : ["",[Validators.required]]
     })
	  this.aboutData();
	 }


  get content() {
    return this.aboutForm.get('content');
  }

	aboutData(){
      this.loadShow();
    	this.dataService.getAboutData()
          .pipe().subscribe(data => {
              let about = data['about_us'];
              //console.log(about,"about");
              this.aboutId = about.id
              this.aboutForm.setValue({
                id: about.id,
                content: about.content
              });
              //console.log(this.aboutForm);
              this.loadHide();         
           });
       }  

    Update(){
      this.isSubmitted = true;
     if(this.aboutForm.valid){
      this.insertAction();   
     }else{
      this.aboutForm.markAllAsTouched()
     }
    }      

  insertAction(){
    this.loadShow();
    let formData:FormData = new FormData();    
    formData.append('id',  this.aboutForm.value.id);
    formData.append('content', this.aboutForm.value.content);
    //console.log(this.aboutId, this.aboutForm.value.content,"value");

      this.dataService.aboutUpdate(formData)
      .pipe().subscribe(data =>{
        if(data['status'] == 200){
          this.alertService.success('About updated successfully', true);
          this.aboutData();
          this.loadHide();
        }else{
          this.alertService.error(data.message);
          document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'})
          this.loadHide()
        } 
          },error =>{
            this.alertService.error(error);
            this.loadHide();
      });
   }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }
}
