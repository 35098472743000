import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PartnerOfferringService } from "../_services/partner-offerring-old.service";
import { AlertService } from "../_services";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { EncryptionService } from "../_services/encryption.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { PartnerOfferingNewService } from "../_services/partner-offering-new.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class PartnerOffering {
  id: number;
  title: string;
  offer_start_date: any;
  offer_end_date: any;
  image: any;
  discount: string;
  state: any;
  city: any;
  key_highlights: any;
  how_should_attend: any;
  what_you_will_learn: any;
  description: any;
  status: any;
  verticals: any;
  is_home_visible: any;
  avail_offer_link: any;
}

@Component({
  selector: "app-partner-offering",
  templateUrl: "./partner-offering.component.html",
  styleUrls: ["./partner-offering.component.sass"],
})
export class PartnerOfferingComponent implements OnInit {
  @Input() allowMultiple: boolean;
  isEditMode: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  partnerOfferingList: PartnerOffering[] = [];
  partnerOfferingTotal: any;
  isSubmitted = false;
  selectedToVerticals: any;
  verticals: [];
  fileList: any;
  croppedImage: any = "";
  @Input() fileType: string = ".jpg , .jpeg, .png , .bmp";
  DisplayedText: string = "";
  @Input() required: boolean;
  start: number = 0;
  pageLength: number = 10;
  formattedStartDate: any;
  formattedEndDate: any;
  errorShow: any;
  selectedVerticals: any;
  myVerticals: any;
  isUpdateMode: boolean = false; // Set this to true if you're updating an existing item

  getPartnerOffering = {
    id: "",
    title: "",
    discount_text: "",
    validity_text: "",
    verticals: "",
    partner_name: "",
    service_desc: "",
    image: "",
    who_is_service_for: "",
    eligibility_condition: "",
    term_and_condition: "",
    social_link: "",
    access_link: "",
    access_email: "",
    status: "",
    is_home_visible: "",
  };

  closeResult: string; // Modal Close
  myString: any;
  myArray: any;
  verticalArr = [];

  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",

    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    public router: Router,
    private dataService: PartnerOfferingNewService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private http: HttpClient,
    private enc_dec: EncryptionService,
    private sanitizer: DomSanitizer
  ) {}

  partner_offering = {
    id: "",
    title: "",
    discount_text: "",
    validity_text: "",
    verticals: "",
    partner_name: "",
    service_desc: "",
    image: "",
    who_is_service_for: "",
    eligibility_condition: "",
    term_and_condition: "",
    social_link: "",
    access_link: "",
    access_email: "",
    status: "",
    is_home_visible: "",
  };

  states = [];
  cities = [];
  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;
  isEndDateInvalid: boolean = false;
  ngOnInit() {
    // this.allPartnerOffering();
    this.getPartnerOfferingListing();
    this.loadHide();
    this.dataService
      .getAllState()
      .pipe()
      .subscribe((data) => {
        this.states = data["state"];
      });

    this.dataService
      .getAllCity()
      .pipe()
      .subscribe((data) => {
        this.cities = data["state"];
      });

    this.dataService
      .getCategoryList()
      .pipe()
      .subscribe((res) => {
        this.verticals = res["data"];
      });
  }

  getPartnerOfferingListing() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      order: [[4, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.pageLength = dataTablesParameters.length;

        this.http
          .post<DataTablesResponse>(
            "/api/get-list-partner-offering-V1",
            {
              req_data: this.enc_dec.encrypt(
                JSON.stringify(Object.assign(dataTablesParameters))
              ),
            },
            {}
          )
          .subscribe((response: any) => {
            const output = this.enc_dec.decrypt(response.res_data);
            const resp = JSON.parse(output);
            //console.log(resp, "get all partner offering");

            this.partnerOfferingList = resp.data.map((item: any) => ({
              ...item,
              service_desc: this.truncate(item.service_desc, 100),
            }));

            this.partnerOfferingTotal = resp["recordsTotal"];

            callback({
              recordsTotal: resp["recordsTotal"],
              recordsFiltered: resp["recordsFiltered"],
              data: [],
            });
          });
      },
      columns: [
        { data: "id" },
        { data: "title" },
        { data: "service_desc" },
        { data: "verticals_name" },
        { data: "partner_name" },
        { data: "created_at" },
        { data: "status" },
      ],
    };
  }
  

  truncate(value: any, limit: any) {
    if (!value) return "";
    if (value.length > limit) {
      return value.substring(0, limit) + "...";
    }
    return value;
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  open(content) {
    this.croppedImage = "";
    this.isSubmitted = false;

    if (this.getPartnerOffering.id != null) {
      this.getPartnerOffering.id = "";
    }

    this.selectedToVerticals = "";

    this.partner_offering.id = "";
    this.partner_offering.title = "";
    this.partner_offering.validity_text = "";
    this.partner_offering.partner_name = "";
    this.partner_offering.image = "";
    this.partner_offering.discount_text = "";
    this.partner_offering.service_desc = "";
    this.partner_offering.eligibility_condition = "";
    this.partner_offering.term_and_condition = "";
    this.partner_offering.social_link = "";
    this.partner_offering.access_link = "";
    this.partner_offering.access_email = "";
    this.partner_offering.verticals = "";
    this.partner_offering.is_home_visible = "Y";
    this.partner_offering.who_is_service_for = "";
    this.partner_offering.status = "Y";

    this.modalTitle = "Add Partner Offering";
    this.modalReference = this.modalService.open(content);
  }

  selectCityById(id) {
    this.dataService
      .getCityById(id)
      .pipe()
      .subscribe((data) => {
        this.cities = data["city"];
      });
  }

  save(val, f) {
    //console.log(val, f, "ff");
    this.isSubmitted = true;
    this.isEditMode = !!val.id;
    if (
      this.isFieldsInvalid(this.partner_offering.title) ||
      this.isFieldsInvalid(this.partner_offering.access_email) ||
      this.isFieldsInvalid(this.partner_offering.partner_name) ||
      this.isFieldsInvalid(this.partner_offering.discount_text) ||
      this.isFieldsInvalid(this.partner_offering.service_desc) ||
      this.isFieldsInvalid(this.partner_offering.validity_text) ||
      this.isFieldsInvalid(this.partner_offering.social_link) ||
      this.isFieldsInvalid(this.partner_offering.access_link) ||
      this.isFieldsInvalid(this.partner_offering.who_is_service_for) ||
      this.isFieldsInvalid(this.partner_offering.eligibility_condition) ||
      this.isFieldsInvalid(this.partner_offering.term_and_condition)
    ) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (!this.isEditMode && !this.fileList) {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (f.form.valid) {
      this.insertAction(val, f);
    } else {
      document.querySelector(".modal").scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  edit(id, content) {
    this.isEndDateInvalid = false;
    this.isEditMode = true;
    this.fileList = "";
    this.loadShow();
    this.dataService.getPartnerOffering(id).subscribe((res) => {
      this.getPartnerOffering = res["data"];
      //console.log(this.getPartnerOffering, "editttt");

      this.selectedVerticals = this.getPartnerOffering.verticals;
      //console.log(this.selectedToVerticals);

      if (this.selectedVerticals) {
        this.myVerticals = this.selectedVerticals
          .split(",")
          .map(Number)
          .filter((value) => value != 0);
        //console.log(this.myVerticals);
        this.selectedToVerticals = this.myVerticals;
      }

      this.croppedImage = this.getPartnerOffering.image;

      this.partner_offering = {
        id: this.getPartnerOffering.id,
        title: this.getPartnerOffering.title,
        validity_text: this.getPartnerOffering.validity_text,
        partner_name: this.getPartnerOffering.partner_name,
        discount_text: this.getPartnerOffering.discount_text,
        service_desc: this.getPartnerOffering.service_desc,
        image: this.getPartnerOffering.image,
        who_is_service_for: this.getPartnerOffering.who_is_service_for,
        eligibility_condition: this.getPartnerOffering.eligibility_condition,
        access_link: this.getPartnerOffering.access_link,
        social_link: this.getPartnerOffering.social_link,
        verticals: this.getPartnerOffering.verticals,
        is_home_visible: this.getPartnerOffering.is_home_visible,
        status: this.getPartnerOffering.status,
        term_and_condition: this.getPartnerOffering.term_and_condition,
        access_email: this.getPartnerOffering.access_email,
      };
      this.loadHide();
      this.modalTitle = "Edit Partner Offering";
      this.modalReference = this.modalService.open(content);
    });
  }

  // onFileChange(event: any) {
  //   this.fileList = event.target.files;
  //   this.DisplayedText = this.fileList[0].name
  //   let hasFile = this.fileList && this.fileList.length > 0;

  //   if (hasFile) {
  //     var extension = this.fileList[0].name.substring(
  //       this.fileList[0].name.lastIndexOf(".")
  //     );
  //     var validFileType = ".jpg , .jpeg, .png , .bmp";

  //     // Check for valid file type
  //     if (validFileType.toLowerCase().indexOf(extension) < 0) {
  //       alert(
  //         "Please select a valid file type. The supported file types are .jpg, .jpeg, .png, .bmp"
  //       );
  //       this.fileList = null;
  //       this.DisplayedText = ''
  //       this.croppedImage = ""; // Clear image preview
  //       this.partner_offering.image = ""; // Clear the partner offering image
  //       return false;
  //     }

  //     // Check for file size before loading the image
  //     if (this.fileList[0].size > 5000000) {
  //       alert("Image size should not more than 5 mb");
  //       this.fileList = null;
  //       this.DisplayedText =''
  //       this.croppedImage = ""; // Clear image preview
  //       this.partner_offering.image = ""; // Clear the partner offering image
  //       return false;
  //     }

  //     // If the file is valid, read it using FileReader
  //     const reader = new FileReader();
  //     reader.readAsDataURL(this.fileList[0]);
  //     reader.onload = () => {
  //       this.croppedImage = reader.result as string;
  //       this.partner_offering.image = this.croppedImage;
  //       //console.log(this.croppedImage, "croppp");
  //     };

  //     let multipleFile = this.fileList.length > 1;
  //     if (!multipleFile) {
  //       let file: File = this.fileList[0];
  //     }
  //   }

  //   return false;
  // }



  onFileChange(event: any) {
    this.fileList = event.target.files;
    this.DisplayedText = this.fileList[0].name;

    let hasFile = this.fileList && this.fileList.length > 0;

    if (hasFile) {
        var extension = this.fileList[0].name.substring(
            this.fileList[0].name.lastIndexOf(".")
        ).toLowerCase();

        var validFileTypes = [".jpg", ".jpeg", ".png", ".bmp"];

        // Check for valid file type
        if (!validFileTypes.includes(extension)) {
            alert(
                "Please select a valid file type. The supported file types are .jpg, .jpeg, .png, .bmp"
            );
            this.fileList = null;
            this.DisplayedText = '';
            this.croppedImage = ""; // Clear image preview
            this.partner_offering.image = ""; // Clear the partner offering image
            return false;
        }

        // Check for file size before loading the image
        if (this.fileList[0].size > 5000000) {
            alert("Image size should not be more than 5 MB");
            this.fileList = null;
            this.DisplayedText = '';
            this.croppedImage = ""; // Clear image preview
            this.partner_offering.image = ""; // Clear the partner offering image
            return false;
        }

        // If the file is valid, read it using FileReader
        const reader = new FileReader();
        reader.readAsDataURL(this.fileList[0]);
        reader.onload = () => {
            this.croppedImage = reader.result as string;
            this.partner_offering.image = this.croppedImage;
        };

        let multipleFile = this.fileList.length > 1;
        if (!multipleFile) {
            let file: File = this.fileList[0];
        }
    }

    return false;
}


  changeToVerticals() {
    this.partner_offering.verticals = this.selectedToVerticals;
  }

  insertAction(val, f) {
    let formData: FormData = new FormData();

    formData.append("title", this.partner_offering.title);
    formData.append("discount_text", this.partner_offering.discount_text);
    formData.append("validity_text", this.partner_offering.validity_text);
    formData.append("verticals", this.selectedToVerticals.join(","));
    formData.append("partner_name", this.partner_offering.partner_name);
    formData.append("service_desc", this.partner_offering.service_desc);
    formData.append(
      "who_is_service_for",
      this.partner_offering.who_is_service_for
    );
    formData.append(
      "eligibility_condition",
      this.partner_offering.eligibility_condition
    );
    formData.append(
      "term_and_condition",
      this.partner_offering.term_and_condition
    );
    formData.append("is_home_visible", this.partner_offering.is_home_visible);
    formData.append("access_link", this.partner_offering.access_link);
    formData.append("access_email", this.partner_offering.access_email);
    formData.append("social_link", this.partner_offering.social_link);
    formData.append("status", this.partner_offering.status);

    if (val.id === "") {
      this.loadShow();
      if (this.fileList) {
        formData.append("image", this.croppedImage);
      }

      this.dataService.save(formData).subscribe(
        (data) => {
          //console.log(data, "daatttaaaa");

          this.loadHide();
          if (data["status"] === 200) {
            this.alertService.success(
              "Partner Offering created successfully",
              true
            );

            const table = $("#partneroffertable").DataTable();
            table.ajax.reload(null, false);

            this.modalReference.close();
          }else{
            this.alertService.error(data.msg);
            const modalElement = document.querySelector(".modal");
          if (modalElement) {
            modalElement.scrollTo({ top: 0, behavior: "smooth" });
          }
          }

          
        },
        (error) => {
          this.loadHide(); // Hide loader on error
          this.alertService.error(error);
        }
      );
    } else {
      this.loadShow();
      formData.append("id", val.id);
      if (this.fileList) {
        formData.append("image", this.croppedImage);
      }

      this.dataService.partnerOfferingUpdate(formData).subscribe(
        (data) => {
          //console.log(data, "dupdateeataa");
          this.loadHide();
          if (data["status"] === 200) {
            this.alertService.success(
              "Partner Offering updated successfully",
              true
            )
            this.modalReference.close();
            const table = $("#partneroffertable").DataTable();
            table.ajax.reload(null, false);
          }else{
            this.alertService.error(data.msg);
            const modalElement = document.querySelector(".modal");
          if (modalElement) {
            modalElement.scrollTo({ top: 0, behavior: "smooth" });
          }
         }

        },
        (error) => {
          this.loadHide();
          this.alertService.error(error);
        }
      );
    }
  }

  getRowNumber(index: number): number {
    return this.start + index + 1;
  }

  delete(item) {
    this.loadShow();
    //console.log(item.id, "iddddd");

    this.dataService
      .partnerOfferingDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success(
              "Partner Offering deleted successfully",
              true
            );
            item.status = item.status === "Y" ? "N" : "Y";
            this.loadHide();
          } else {
            this.alertService.error("Partner Offering error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
