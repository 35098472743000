import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  DashboardService,
  SettingService,
  AlertService,
  AppService,
} from "../_services/index";
import * as Chart from "chart.js";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
declare const $: any;
export declare function myInitFunction();
export declare function myInitFunctionBar();


@Component({
  templateUrl: "dashboard.component.html",
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  is_data_link: boolean = true;
  @ViewChild("datePicker", { static: false }) datePicker: ElementRef;
  res_name: any = [];
  dataFullLayout: any;
  pending_partners: any;
  pending_blogs: any;
  refresh: boolean = true;
  startDate: any;
  endDate: any;
  chartOnInit: Chart;
  chartAfterViewInit: Chart;
  totalCount = {
    centralschemes: "",
    SIDBISchemes: "",
    StateSchemes: "",
    KBschemes: "",
    schemes: "",
    ActivePartner: "",
    partner: "",
    UpcomingEvents: "",
    PastEvents: "",
    ActiveMember: "",
    ForumQuestions: "",
    MemberBlog: "",
    contact_form: "",
    TotalATR: "",
    videos: "",
    verticals: "",
    BlogCategoryCount: "",
  };

  graphs = {
    users_by_state: [],
    users_by_state_registered_users: [],
    users_by_state_logedin_users: [],
    users_by_age: [],
    users_by_age_registered_users: [],
    total_views_by_pages: [],
    total_views_by_pages_views: [],
    total_views_by_verticles: [],
    total_views_by_verticles_views: [],
    sector_of_business: [],
    sector_of_business_registered_users: [],
    area_of_interest: [],
    area_of_interest_registered_users: [],
  };

  total_registered_member = {
    total: "",
    last_week: "",
    last_month: "",
    last_year: "",
  };

  total_community_post_count = {
    total: "",
    last_week: "",
    last_month: "",
    last_year: "",
  };

  total_loggedin_member = {
    total: "",
    last_week: "",
    last_month: "",
    last_year: "",
  };

  range: any;
  showloding = true;
  lodingImage = false;
  isnewapi: boolean=true;

  constructor(
    private dataService: DashboardService,
    private settingService: SettingService,
    private alertService: AlertService,
    public router: Router,
    private appservice: AppService,
    private fb: FormBuilder
  ) {
    this.loadShow();
  }

  ngOnInit() {
    this.loadInitialData();
  }

  loadInitialData() {
    this.appservice.dataArray$.subscribe((data) => {
      this.dataFullLayout = data;
    });
    this.loadShow();


    if(this.isnewapi){
    this.dataService
      .totalCountV1()
      .pipe()
      .subscribe((data) => {
        this.totalCount = {
          centralschemes: data["centralschemes"].count,
          SIDBISchemes: data["SIDBISchemes"].count,
          StateSchemes: data["StateSchemes"].count,
          KBschemes: data["KBschemes"].count,
          schemes: data["schemes"].count,
          ActivePartner: data["ActivePartner"].count,
          partner: data["partner"].count,
          UpcomingEvents: data["UpcomingEvents"].count,
          PastEvents: data["PastEvents"].count,
          ActiveMember: data["ActiveMember"].count,
          ForumQuestions: data["ForumQuestions"].count,
          MemberBlog: data["MemberBlog"].count,
          contact_form: data["contact_form"].count,
          videos: data["videos"].count,
          verticals: data["verticals"].count,
          TotalATR: data["TotalATR"].count,
          BlogCategoryCount: data["BlogCategoryCount"].count,
        };
        // this.pending_partners = data["PendingPartnersList"];
        // this.pending_blogs = data["PendingMemberBlogsList"];
        this.graphs.users_by_state =
          data["graphs"]["users_by_state"].users_by_state;
        this.graphs.users_by_state_registered_users =
          data["graphs"]["users_by_state"].registered_users;
        this.graphs.users_by_state_logedin_users =
          data["graphs"]["users_by_state"].logedin_users;

        let users_by_state = this.graphs.users_by_state;
        let users_by_state_registered_users =
          this.graphs.users_by_state_registered_users;
        let users_by_state_logedin_users =
          this.graphs.users_by_state_logedin_users;

          localStorage.setItem('users_by_state1',JSON.stringify(users_by_state))
          localStorage.setItem('users_by_state_registered_users1',JSON.stringify(users_by_state_registered_users))
          localStorage.setItem('users_by_state_logedin_users1',JSON.stringify(users_by_state_logedin_users))
          setTimeout(()=>{
            myInitFunctionBar();
          },200)
        // var canvas = document.getElementById("barChart") as HTMLCanvasElement;
        // var ctx = canvas.getContext("2d");
        // this.chartOnInit = new Chart(ctx, {
        //   type: "bar",
        //   data: {
        //     labels: users_by_state,
        //     datasets: [
        //       {
        //         label: "Register",
        //         data: users_by_state_registered_users,
        //         backgroundColor: "#58508d",
        //       },
        //       {
        //         label: "Login",
        //         data: users_by_state_logedin_users,
        //         backgroundColor: "#bc5090",
        //       },
        //     ],
        //   },
        //   options: {
        //     responsive: true,
        //     maintainAspectRatio: false,
        //     scales: {
        //       yAxes: [
        //         {
        //           ticks: {
        //             beginAtZero: true,
        //           },
        //         },
        //       ],
        //     },
        //   },
        // });

        this.graphs.users_by_age =
          data["graphs"]["users_by_age"]["users_by_age"];
        this.graphs.users_by_age_registered_users =
          data["graphs"]["users_by_age"]["registered_users"];
        localStorage.setItem(
          "users_by_age",
          JSON.stringify(this.graphs.users_by_age)
        );
        localStorage.setItem(
          "users_by_age_registered_users",
          JSON.stringify(this.graphs.users_by_age_registered_users)
        );

        // //console.log( data["graphs"]["total_views_by_verticles"].pages)
        this.graphs.total_views_by_pages =
          data["graphs"]["total_views_by_pages"].pages;
        this.graphs.total_views_by_pages_views =
          data["graphs"]["total_views_by_pages"].views;
        localStorage.setItem(
          "total_views_by_pages",
          JSON.stringify(this.graphs.total_views_by_pages)
        );
        localStorage.setItem(
          "total_views_by_pages_views",
          JSON.stringify(this.graphs.total_views_by_pages_views)
        );

        this.graphs.total_views_by_verticles =
          data["graphs"]["total_views_by_verticles"].pages;
        this.graphs.total_views_by_verticles_views =
          data["graphs"]["total_views_by_verticles"].views;
        localStorage.setItem(
          "total_views_by_verticles",
          JSON.stringify(this.graphs.total_views_by_verticles)
        );
        localStorage.setItem(
          "total_views_by_verticles_views",
          JSON.stringify(this.graphs.total_views_by_verticles_views)
        );

        this.graphs.sector_of_business =
          data["graphs"]["registered_users_by_sector_of_business"].sector_of_business;
        this.graphs.sector_of_business_registered_users =
          data["graphs"]["registered_users_by_sector_of_business"].registered_users;
        localStorage.setItem(
          "sector_of_business",
          JSON.stringify(this.graphs.sector_of_business)
        );
        localStorage.setItem(
          "sector_of_business_registered_users",
          JSON.stringify(this.graphs.sector_of_business_registered_users)
        );

        this.graphs.area_of_interest =
          data["graphs"]["registered_users_by_area_of_interest"].area_of_interest;
        this.graphs.area_of_interest_registered_users =
          data["graphs"]["registered_users_by_area_of_interest"].registered_users;
        localStorage.setItem(
          "area_of_interest",
          JSON.stringify(this.graphs.area_of_interest)
        );
        localStorage.setItem(
          "area_of_interest_registered_users",
          JSON.stringify(this.graphs.area_of_interest_registered_users)
        );

        // //console.log(localStorage.getItem('area_of_interest_registered_users'))
        // //console.log(localStorage.getItem('area_of_interest'))

        this.total_registered_member = {
          total: data["total_registered_member"].total,
          last_week: data["total_registered_member"].last_week,
          last_month: data["total_registered_member"].last_month,
          last_year: data["total_registered_member"].last_year,
        };

        this.total_community_post_count = {
          total: data["total_community_post_count"].total,
          last_week: data["total_community_post_count"].last_week,
          last_month: data["total_community_post_count"].last_month,
          last_year: data["total_community_post_count"].last_year,
        };


        this.total_loggedin_member = {
          total: data["total_loggedin_member"].total,
          last_week: data["total_loggedin_member"].last_week,
          last_month: data["total_loggedin_member"].last_month,
          last_year: data["total_loggedin_member"].last_year,
        };
        this.loadHide();
      });
    }else{
      this.dataService
      .totalCount()
      .pipe()
      .subscribe((data) => {
        this.totalCount = {
          centralschemes: data["centralschemes"].count,
          SIDBISchemes: data["SIDBISchemes"].count,
          StateSchemes: data["StateSchemes"].count,
          KBschemes: data["KBschemes"].count,
          schemes: data["schemes"].count,
          ActivePartner: data["ActivePartner"].count,
          partner: data["partner"].count,
          UpcomingEvents: data["UpcomingEvents"].count,
          PastEvents: data["PastEvents"].count,
          ActiveMember: data["ActiveMember"].count,
          ForumQuestions: data["ForumQuestions"].count,
          MemberBlog: data["MemberBlog"].count,
          contact_form: data["contact_form"].count,
          videos: data["videos"].count,
          verticals: data["verticals"].count,
          TotalATR: data["TotalATR"].count,
          BlogCategoryCount: data["BlogCategoryCount"].count,
        };
        // this.pending_partners = data["PendingPartnersList"];
        // this.pending_blogs = data["PendingMemberBlogsList"];
        this.graphs.users_by_state =
          data["graphs"]["users_by_state"]["users_by_state"];
        this.graphs.users_by_state_registered_users =
          data["graphs"]["users_by_state"]["registered_users"];
        this.graphs.users_by_state_logedin_users =
          data["graphs"]["users_by_state"]["logedin_users"];

        let users_by_state = this.graphs.users_by_state;
        let users_by_state_registered_users =
          this.graphs.users_by_state_registered_users;
        let users_by_state_logedin_users =
          this.graphs.users_by_state_logedin_users;

        // var canvas = document.getElementById("barChart") as HTMLCanvasElement;
        // var ctx = canvas.getContext("2d");
        // this.chartOnInit = new Chart(ctx, {
        //   type: "bar",
        //   data: {
        //     labels: users_by_state,
        //     datasets: [
        //       {
        //         label: "Register",
        //         data: users_by_state_registered_users,
        //         backgroundColor: "#58508d",
        //       },
        //       {
        //         label: "Login",
        //         data: users_by_state_logedin_users,
        //         backgroundColor: "#bc5090",
        //       },
        //     ],
        //   },
        //   options: {
        //     responsive: true,
        //     maintainAspectRatio: false,
        //     scales: {
        //       yAxes: [
        //         {
        //           ticks: {
        //             beginAtZero: true,
        //           },
        //         },
        //       ],
        //     },
        //   },
        // });

        this.graphs.users_by_age =
          data["graphs"]["users_by_age"]["users_by_age"];
        this.graphs.users_by_age_registered_users =
          data["graphs"]["users_by_age"]["registered_users"];
        localStorage.setItem(
          "users_by_age",
          JSON.stringify(this.graphs.users_by_age)
        );
        localStorage.setItem(
          "users_by_age_registered_users",
          JSON.stringify(this.graphs.users_by_age_registered_users)
        );

        this.graphs.total_views_by_pages =
          data["graphs"]["total_views_by_pages"]["pages"];
        this.graphs.total_views_by_pages_views =
          data["graphs"]["total_views_by_pages"]["views"];
        localStorage.setItem(
          "total_views_by_pages",
          JSON.stringify(this.graphs.total_views_by_pages)
        );
        localStorage.setItem(
          "total_views_by_pages_views",
          JSON.stringify(this.graphs.total_views_by_pages_views)
        );

        this.graphs.total_views_by_verticles =
          data["graphs"]["total_views_by_verticles"]["pages"];
        this.graphs.total_views_by_verticles_views =
          data["graphs"]["total_views_by_verticles"]["views"];
        localStorage.setItem(
          "total_views_by_verticles",
          JSON.stringify(this.graphs.total_views_by_verticles)
        );
        localStorage.setItem(
          "total_views_by_verticles_views",
          JSON.stringify(this.graphs.total_views_by_verticles_views)
        );

        this.graphs.sector_of_business =
          data["graphs"]["registered_users_by_secor_of_business"][
            "sector_of_business"
          ];
        this.graphs.sector_of_business_registered_users =
          data["graphs"]["registered_users_by_secor_of_business"][
            "registered_users"
          ];
        localStorage.setItem(
          "sector_of_business",
          JSON.stringify(this.graphs.sector_of_business)
        );
        localStorage.setItem(
          "sector_of_business_registered_users",
          JSON.stringify(this.graphs.sector_of_business_registered_users)
        );

        this.graphs.area_of_interest =
          data["graphs"]["registered_users_by_area_of_interest"][
            "area_of_interest"
          ];
        this.graphs.area_of_interest_registered_users =
          data["graphs"]["registered_users_by_area_of_interest"][
            "registered_users"
          ];
        localStorage.setItem(
          "area_of_interest",
          JSON.stringify(this.graphs.area_of_interest)
        );
        localStorage.setItem(
          "area_of_interest_registered_users",
          JSON.stringify(this.graphs.area_of_interest_registered_users)
        );

        this.total_registered_member = {
          total: data["total_registered_member"].total,
          last_week: data["total_registered_member"].last_week,
          last_month: data["total_registered_member"].last_month,
          last_year: data["total_registered_member"].last_year,
        };

        this.total_community_post_count = {
          total: data["total_community_post_count"].total,
          last_week: data["total_community_post_count"].last_week,
          last_month: data["total_community_post_count"].last_month,
          last_year: data["total_community_post_count"].last_year,
        };

        this.total_loggedin_member = {
          total: data["total_loggedin_member"].total,
          last_week: data["total_loggedin_member"].last_week,
          last_month: data["total_loggedin_member"].last_month,
          last_year: data["total_loggedin_member"].last_year,
        };
        this.loadHide();
      });
    }

    if (localStorage.hasOwnProperty("users_by_state")) {
      setTimeout(() => {
        myInitFunction();
        // myInitFunctionBar()
      }, 7000);
    } else {
      setTimeout(() => {
        myInitFunction();
        // myInitFunctionBar()
      }, 7000);
    }
  }

  hasName(route: string) {
    if (!this.dataFullLayout || !Array.isArray(this.dataFullLayout)) {
      return false;
    }
    return this.dataFullLayout.some((category) => {
      if (category.route === route) {
        return true;
      }
      if (category.children && category.children.length > 0) {
        return category.children.some((item) => item.route === route);
      }
      return false;
    });
  }

  ngAfterViewInit() {
    this.onFilter();
  }

  onFilter() {
    // //console.log("hhh");
    const maxDate = moment().format("MM/DD/YYYY");
    const datePicker = $(this.datePicker.nativeElement);

    datePicker.attr("placeholder", "Select Date Range");

    datePicker.daterangepicker(
      {
        // "startDate": "04/20/2024",
        // "endDate": "04/26/2024",
        autoUpdateInput: false,
        showDropdowns: true,
        maxDate: maxDate,
      },
      (start, end, label) => {
        // //console.log(
        //   "New date range selected: " +
        //     start.format("YYYY-MM-DD") +
        //     " to " +
        //     end.format("YYYY-MM-DD") +
        //     " (predefined range: " +
        //     label +
        //     ")"
        // );

        this.startDate = start.format("YYYY-MM-DD");
        this.endDate = end.format("YYYY-MM-DD");

        // //console.log(this.startDate, this.endDate, "endaaaaa");

        const data = {
          from_date: this.startDate,
          to_date: this.endDate,
        };
        this.loadShow();
        this.dataService.filterByDate(data).subscribe((res: any) => {
          // //console.log(res["data"], "resssss");

          if (res["status"] == 200) {
            let users_by_state = res["data"].users_by_state;
            let users_by_state_registered_users = res["data"].registered_users;
            let users_by_state_logedin_users = res["data"].logedin_users;

          localStorage.setItem('users_by_state1',JSON.stringify(users_by_state))
          localStorage.setItem('users_by_state_registered_users1',JSON.stringify(users_by_state_registered_users))
          localStorage.setItem('users_by_state_logedin_users1',JSON.stringify(users_by_state_logedin_users))
            if (this.chartOnInit) {
              // this.chartOnInit.destroy();
            }
            setTimeout(()=>{
              myInitFunctionBar()
              // myInitFunction();
            },100)
            // var canvas = document.getElementById(
            //   "barChart"
            // ) as HTMLCanvasElement;
            // var ctx = canvas.getContext("2d");
            // this.chartAfterViewInit = new Chart(ctx, {
            //   type: "bar",
            //   data: {
            //     labels: users_by_state,
            //     datasets: [
            //       {
            //         label: "Register",
            //         data: users_by_state_registered_users,
            //         backgroundColor: "#58508d",
            //       },
            //       {
            //         label: "Login",
            //         data: users_by_state_logedin_users,
            //         backgroundColor: "#bc5090",
            //       },
            //     ],
            //   },
            //   options: {
            //     responsive: true,
            //     maintainAspectRatio: false,
            //     scales: {
            //       yAxes: [
            //         {
            //           ticks: {
            //             beginAtZero: true,
            //           },
            //         },
            //       ],
            //     },
            //   },
            // });
            datePicker.val(
              start.format("MM/DD/YYYY") + " - " + end.format("MM/DD/YYYY")
            );
            // Manually trigger change event to make sure Angular detects the change
            // datePicker.trigger('change');
            this.loadHide();
          }
        });
      }
    );
  }

  onClear() {
    const data = {
      from_date: "",
      to_date: "",
    };
    this.loadShow();
    this.dataService.filterByDate(data).subscribe((res: any) => {
      // //console.log(res["data"], "resssss");

      if (res["status"] == 200) {
        let users_by_state = res["data"].users_by_state;
            let users_by_state_registered_users = res["data"].registered_users;
            let users_by_state_logedin_users = res["data"].logedin_users;
          localStorage.setItem('users_by_state1',JSON.stringify(users_by_state))
          localStorage.setItem('users_by_state_registered_users1',JSON.stringify(users_by_state_registered_users))
          localStorage.setItem('users_by_state_logedin_users1',JSON.stringify(users_by_state_logedin_users))

            setTimeout(()=>{
              myInitFunctionBar()
            },100)
        this.loadHide();
      }
    });
  }

  onReset() {
    if (this.datePicker.nativeElement.value) {
      this.onClear();
      this.datePicker.nativeElement.value = "";
    } else {
      this.datePicker.nativeElement.value = "";
    }
  }

  ngOnDestroy(): void {
    if (this.chartOnInit) {
      this.chartOnInit.destroy();
    }
    if (this.chartAfterViewInit) {
      this.chartAfterViewInit.destroy();
    }
  }

  openPartnerViewPage(slug) {
    this.router.navigate(["partner-view/" + slug]);
  }

  memberBlogView(id) {
    this.router.navigate(["member-blogs-view/" + id]);
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
