<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
	<img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes" >
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/event.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Past Event</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{eventList.length}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Event
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table  style="white-space: nowrap;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 3%;">#Sl</th>
										<th style="width: 20%;">Name</th>
										<th>Event Type</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th style="width: 5%;">Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of eventList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<td>{{item.event_type}}</td>
										<td>{{item.from_date}}</td>
										<td>{{item.to_date}}</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span>
											<span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">Inactive</span>
										</td>
										<td class="action">

											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
											
											<button type="button" class="btn text-dark btn-sm  rounded bg-light"
												(click)="edit(this.eventList[i].id,content)"><i
													class="fa fa-edit"></i></button>
											<button class="ml-1 btn text-dark btn-sm rounded bg-light d-none"
												(click)="upload(this.eventList[i].id,media)"><i
													class="fa fa-upload"></i></button>
<!-- 
											<button class="ml-1 btn text-danger btn-sm rounded bg-light"
												(click)="delete(this.eventList[i].id)"><i
													class="fa fa-trash"></i></button> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
			<!-- class="modal fade" -->
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="form-group col-md-12">
									<label for="name" class="col-form-label">Name <span>*</span></label>
									<div class="  ">
										<input class="form-control" type="text" id="name" name="name" required
											[(ngModel)]="event.name"  >
										<span *ngIf="isSubmitted && isFieldsInvalid(event.name)"
											class="alert-block">This field is required</span>
										<!-- <span  *ngIf="f.controls.name?.errors?.pattern" class="alert-block">Invalid name format</span> -->

									</div>
								  </div>
								<div class="form-group col-md-12">
									<label for="about" class="col-form-label">About <span>*</span></label>
									<div class="  ">
									<angular-editor [config]="configbody" required  id='about' [(ngModel)]="event.about" name="about"></angular-editor>
										<span *ngIf="isSubmitted && (event.about==undefined || event.about=='')"
											class="alert-block">This field is required</span>
									</div>
								  </div>

								<div class="form-group col-md-6">
									<label for="from_date" class="col-form-label">From Date <span>*</span></label>
									<div class="  ">
										<input class="form-control" type="datetime-local" id="from_date"
											name="from_date" required [(ngModel)]="event.from_date">
										<span *ngIf="isSubmitted && (event.from_date==undefined || event.from_date=='')"
											class="alert-block">This field is required</span>
									 </div>
								  </div>


								<div class="form-group col-md-6">
									<label for="to_date" class="col-form-label">To Date <span>*</span></label>
									<div class="  ">
										<input class="form-control" type="datetime-local" id="to_date" name="to_date"
											required [(ngModel)]="event.to_date">
										<span *ngIf="isSubmitted && (event.to_date==undefined || event.to_date=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>


								<div class="col-md-6">
									<div class="form-group">
									  <label class=" form-control-label">Image <span>*</span></label>
									  <div class="">
										<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
										  [src]="croppedImage" />
										<div class="input-group">
										  <span class="input-group-btn">
											<label class="btn btn-dark">
											  <samp >Browse</samp>...
											  <input type="file" accept="{{fileType}}" style="display: none;"
												(change)="onFileChange($event)" [attr.multiple]="allowMultiple">
											</label>
										  </span>
										  <input type="text" class="form-control" [value]="DisplayedText" disabled=""
											[class.ng-invalid-right]="required && !DisplayedText"
											[class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
										</div>
				  
										<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
										  <div class="modal-content">
											<h3 class="text-center mt-2">Image Cropper</h3>
											<div class="py-4 px-4">
											  <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
												[maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
												(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
												(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
											  <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
												href="javascript::void(0)" (click)="exit($event); closeCropper()"
												style="margin-top: 10px;">Save</button>
											</div>
										  </div>
										</ng-template>
										<span *ngIf="isSubmitted && (event.image==undefined || event.image=='')" class="alert-block">This
										  field is required</span>
									  </div>
									</div>
								  </div>
								<!-- <div class="col-md-6">
									<div class="form-group">
										<label class=" form-control-label">Image <span>*</span></label>
										<div class="">
											<img style="height: 100px;width: 100px;" class="img-thumbnail mb-3"
												*ngIf="croppedImage !=''" [src]="croppedImage" />
											<div class="input-group">
												<span class="input-group-btn">
													<label class="btn btn-dark">
														<samp (click)="openCropper(contentCrop)">Browse</samp>...
														<input type="file" accept="{{fileType}}" style="display: none;"
															(change)="onFileChange($event)"
															[attr.multiple]="allowMultiple">
													</label>
												</span>
												<input type="text" class="form-control" [value]="DisplayedText"
													disabled="" [class.ng-invalid-right]="required && !DisplayedText"
													[class.ng-valid-right]="required && DisplayedText"
													style="height: 35px;">
											</div>

											<ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
												<div class="modal-content">
													<h3 class="text-center mt-2">Image Cropper</h3>
														<div class="py-4 px-4">
															<image-cropper [imageChangedEvent]="imageChangedEvent"
															[(ngModel)]="image_cropper" [maintainAspectRatio]="true"
															[resizeToWidth]="416" [resizeToHeight]="200" format="png"
															(imageCropped)="imageCropped($event)"
															(imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
															(loadImageFailed)="loadImageFailed()"></image-cropper>
															<button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
																href="javascript::void(0)" (click)="exit($event); closeCropper()"
																style="margin-top: 10px;">Save</button>
															</div>
														</div>
													</ng-template>
													<span *ngIf="isSubmitted && (event.image==undefined || event.image=='')"
														class="alert-block">This field is required</span>
										</div>
									</div>
								</div> -->


								<div class="form-group col-md-6">
									<label for="event_type" class="col-form-label">Event Type <span>*</span></label>
									<div class="">
										<select class="form-control" id="event_type" name="event_type" required
											[(ngModel)]="event.event_type">
											<option value="online">online</option>
											<option value="offline">offline</option>
										</select>
										<span *ngIf="isSubmitted && (event.event_type==undefined || event.event_type=='')"
											class="alert-block">This field is required</span>
									</div>
								  </div>
								<div class="form-group col-md-6 align-self-end">
									<label for="event_state" class="   form-control-label">State </label>
									<div class="  ">
										<select class="form-control" id="event_state" name="event_state"
											[(ngModel)]="event.event_state" (ngModelChange)="selectCityById($event)">
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of states" value="{{item.id}}">{{item.state_name}}
											</option>
										</select>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="event_city" class="form-control-label">City </label>
									  <div class="">
										<select class="form-control" id="event_city" name="event_city"
											[(ngModel)]="event.event_city">
											<option value="" selected>Please Select</option>
											<option *ngFor="let item of cities" value="{{item.id}}">{{item.city_name}}
											</option>
										</select>
									</div>
								</div>

							<div class="form-group col-md-6 d-none">
									<label for="event_category" class="form-control-label">Category</label>
									<div class="  ">
										<select class="form-control" id="event_category" name="event_category" [(ngModel)]="event.event_category">
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of cat" value="{{item.id}}">{{item.name}}</option>
										</select>
										<span
											*ngIf="f.submitted && isSubmitted && (event.event_category==undefined || event.event_category=='')"
											class="alert-block">This field is required</span>
									</div>
								</div> 

								<div class="form-group col-md-6">
									<label for="event_vertical" class="form-control-label">Category<span>*</span></label>
									<div class="  ">
										<select class="form-control" id="event_vertical" name="event_vertical" [(ngModel)]="event.event_vertical" required>
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of cat" value="{{item.id}}">{{item.name}}</option>
										</select>
										<span
											*ngIf="isSubmitted && (event.event_vertical==undefined || event.event_vertical=='')"
											class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
									  <label for="sectorofenterprise" class="form-control-label">Sector of Enterprise<span>*</span></label>
									  <div>
										<ng-select [items]="sectorofenterprise" name="sectorofenterprise"
												   [multiple]="true" bindLabel="name" bindValue="id"
												   [(ngModel)]="selectedToEnterprise" (change)="changeToEnterprise()" required>
										  <!-- Display options dynamically -->
										  <ng-option *ngFor="let sector of sectorofenterprise" [value]="sector.id">
											{{ sector.name }}
										  </ng-option>
										</ng-select>
										<span *ngIf="isSubmitted && (event.sectorofenterprise==undefined || event.sectorofenterprise=='')"
											  class="alert-block">This field is required</span>
									  </div>
									</div>
								  </div>
								  

								<div class="col-md-6">
									<div class="form-group ">
										<label for="areaofinterest" class=" form-control-label">Area of Interest</label>
										<div class="">
											<ng-select [items]="areaofinterest" name="areaofinterest" [multiple]="true"
												bindLabel="name" bindValue="id" [(ngModel)]="selectedToInterest"
												(change)="changeToInterest()">
											</ng-select>
										</div>
									</div>
								</div>
								
								<div class="col-md-6">
									<div class="form-group ">
										<label for="areaofstudy" class=" form-control-label">Area of
											Study</label>
										<div class="">
											<ng-select [items]="areaofstudy" name="areaofstudy" [multiple]="true"
												bindLabel="name" bindValue="id" [(ngModel)]="selectedToStudy"
												(change)="changeToStudy()">
											</ng-select>
										</div>
									</div>
								</div>
								<div class="form-group col-md-12">
									<label for="event_address" class="col-form-label">Event Address</label>
									<div class="">
										<textarea class="form-control" type="text" id="event_address"
											name="event_address" [(ngModel)]="event.event_address"></textarea>
									</div>
								</div>
								<div class="form-group col-md-6">
									<label for="oraganised_by" class="col-form-label">Oraganised By<span>*</span></label>
									<div class="  ">
										<input class="form-control" type="text" id="oraganised_by" name="oraganised_by" required
											[(ngModel)]="event.oraganised_by">
										<span *ngIf="isSubmitted && isFieldsInvalid(event.oraganised_by)"
											class="alert-block">This field is required</span>
									</div>
								</div>
								<div class="form-group col-md-6">


									<label for="event_language" class="col-form-label">Event Language<span>*</span></label>
									<div class="  ">
										<input class="form-control" type="text" id="event_language"
											name="event_language" required [(ngModel)]="event.event_language">
										<span *ngIf="isSubmitted && isFieldsInvalid(event.event_language)"
												class="alert-block">This field is required</span>
									</div>
								</div>

								<div class="form-group col-md-6">
									<label for="who_is_relevant_for" class="col-form-label">Who Is Relevant For</label>
									<div class="  ">
										<input class="form-control" type="text" id="who_is_relevant_for"
											name="who_is_relevant_for" [(ngModel)]="event.who_is_relevant_for">
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group ">
										<label for="associate_partners" class=" form-control-label">Associate
											Partners</label>
										<div class="">
											<ng-select [items]="associatepartners" name="associate_partners"
												[multiple]="true" bindLabel="name" bindValue="id"
												[(ngModel)]="selectedToPartners" (change)="changeToPartners()">
											</ng-select>
										</div>
									</div>
								</div>
								<div class="form-group col-md-12">
									<label for="registration_details_link" class="col-form-label">Registration Details
										Link </label>
									<div class="">
										<textarea class="form-control" type="text" id="registration_details_link"
											name="registration_details_link"
											[(ngModel)]="event.registration_details_link"></textarea>
									</div>
								</div>
								<div class="form-group col-md-12">
									<label for="keywords" class="col-form-label">Keywords </label>
									<div class="">
										<textarea class="form-control" type="text" id="keywords" name="keywords"
											[(ngModel)]="event.keywords"></textarea>
									</div>
								</div>


								<!-- <div class="form-group col-md-12">
									<table   class="table table-striped table-bordered">
										<thead>
											<tr>
												<th>Event Type</th>
												<th>Event Label</th>
												<th>Is Mandatory</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let dynamic of dynamicArray; let j = index"
												[attr.data-index]="j">
												<td>
													<select [(ngModel)]="dynamic.event_type" name="event_type[j]"
														class="form-control" [ngModelOptions]="{standalone: true}">
														<option value="TEXTAREA">TEXTAREA</option>
														<option value="TEXTBOX">TEXTBOX</option>
														<option value="NUMBER">NUMBER</option>
													</select>
												</td>
												<td>
													<input [(ngModel)]="dynamic.event_label" name="event_label[j]"
														class="form-control" type="text"
														[ngModelOptions]="{standalone: true}">
												</td>
												<td>
													<select [(ngModel)]="dynamic.is_mandatory" name="is_mandatory[j]"
														class="form-control" [ngModelOptions]="{standalone: true}">
														<option value="Y">Yes</option>
														<option value="N">No</option>
													</select>
												</td>
												<td (click)="deleteRow(j)">
													<i class="fa fa-trash fa-2x"></i>
												</td>
											</tr>
											<tr>
												<td colspan="3"></td>
												<td (click)="addRow()">
													<i class="fa fa-plus fa-2x"></i>
												</td>
											</tr>
										</tbody>
									</table>
								</div> -->

								<div class="form-group col-md-6">
									<label for="home_display" class="col-form-label">Home Display</label>
									<div class="  ">
										<select class="form-control" id="home_display" name="home_display"
											[(ngModel)]="event.home_display">
											<option value="N">No</option>
											<option value="Y">Yes</option>
										</select>
									</div>
								</div>
								<div class="form-group col-md-6">
									<label for="is_active" class="col-form-label">Status</label>
									<div class="  ">
										<select class="form-control" id="is_active" name="is_active"
											[(ngModel)]="event.is_active">
											<option value="N">Inactive</option>
											<option value="Y">Active</option>
										</select>
									</div>
								</div>
							</div>

						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>

							<button type="submit" class="btn btn-primary savebtn" (click)="save(this.event,f)">Save</button>

					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #media let-c="close" let-d="dismiss" id="uploadmedia">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<div class="modal-body">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label for="" class="form-label">Upload Image</label>
										<input type="file" class="form-control" name="Upload Image" accept="{{fileTypeImage}}" (change)="fileChangeImage($event)" [attr.multiple] ="allowMultipleImage">
									</div>

									<div class="medialist">
										<div class="row gy-3">
											<div class="col-md-4" *ngFor="let media of eventMediaListImage">
												<a (click)="removeEventMedia(media.id)" class="remove-image" href="javascript:void(0);">×</a>
												<img [src]="apiUrl+media.image" class="img-fluid" alt="">
											</div>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label for="" class="form-label">Upload Video</label>
										<input type="file" class="form-control" name="Upload Video" accept="{{fileTypeVideo}}" (change)="fileChangeVideo($event)" [attr.multiple] ="allowMultipleVideo">
									</div>
									<div class="row gy-3">
										<div class="col-md-4" *ngFor="let media of eventMediaListVideo">
											<a (click)="removeEventMedia(media.id)" class="remove-image" href="javascript:void(0);">×</a>
											<video [src]="apiUrl+media.image" class="w-100" controls></video>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary rounded savebtn" (click)="uploadmedia()">Save</button>
					</div>
				</div>
			</div>
		</ng-template>


	</div>
</div>
