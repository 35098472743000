import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
// import  { AuthenticationService } from '../service/authentication/authentication.data.service';
import {
  AlertService,
  AuthenticationService,
  RoleService,
  SettingService,
} from "../_services/index";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
// import $ from 'jquery/dist/jquery';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  fieldTextTypeLogin: boolean = false;
  passwordIconClass: string = " fa-eye-slash";

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private dataService: AuthenticationService,
    private dataServiceRole: RoleService,
    private setingService: SettingService,
    private alertService: AlertService
  ) {}

  username: string;
  password: string;
  token: string;
  textMesg: any;
  show: boolean = false;
  user: any;
  roleData: any;
  menu: any;
  model: any = {};
  loading = false;
  returnUrl: string;
  setting = { image: "" };

  ngOnInit() {
    if (localStorage.getItem("currentUser") != null) {
      this.router.navigate(["/dashboard-blank"]);
    }
    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.setingService
      .getSettingData()
      .pipe()
      .subscribe((data) => {
        this.setting = { image: data["setting"].image };
      });
  }

  onLoggedin() {
    this.loading = true;

    var UserInput = {
      email: this.model.username,
      password: this.model.password,
    };

    if (this.model.username != undefined && this.model.password != undefined) {
      this.dataService
        .login(UserInput)
        .pipe()
        .subscribe(
          (data) => {
            if (data.status == 200) {
              this.show = true;
              this.textMesg = data.mesg;
              this.loading = false;
            } else if (data.status == 300) {
              this.show = true;
              this.textMesg = "Your account is Inactive"
              this.loading = false;
            } else if (data.status == 400) {
              this.show = true;
              this.textMesg = data.mesg;
              this.loading = false;
            } else if (data.status == 500) {
              this.show = true;
              this.textMesg = data.mesg;
              this.loading = false;
            } else if (data.status == 422) {
              this.show = true;
              this.textMesg = data.mesg.email[0];
              this.loading = false;
            } else {
              this.router.navigate(["/dashboard-blank"]);
              // this.router.navigate(['/dashboard']);
              // this.router.navigate(['/user']);
            }
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }

  toggleFieldTextType() {
    this.fieldTextTypeLogin = !this.fieldTextTypeLogin;
    this.passwordIconClass = this.fieldTextTypeLogin
      ? "fa-eye"
      : "fa-eye-slash";
  }
}
