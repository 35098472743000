import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MemberListService {

  constructor(private base: BaseService) { }  

  deleteMember(id){
    return this.base.post('/api/member-delete',{id:id});
  }

  viewMember(id) {
    return this.base.post('/api/view-member',{id:id});
  }

  getAllMember(){
    return  this.base.get('/api/all-member');
  }

  importMemberCSV(data:any){
    return this.base.post('/api/import-member-invitation',data);
  }

  memeberViewList(data:any){
    return this.base.post('/api/view-member',data);
  }

}
