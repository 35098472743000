import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import {
  AlertService,
  MemberBlogsService,
  AppService,
} from "../_services/index";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

@Component({
  selector: "app-member-blogs",
  templateUrl: "./member-blogs.component.html",
  styles: [],
})
export class MemberBlogsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  blogList: any[] = [];
  closeResult: string;

  constructor(
    public router: Router,
    private dataService: MemberBlogsService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  modalReference: NgbActiveModal;
  modalTitle: string;
  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allMemberBlog();
  }

  allMemberBlog() {
    this.loadShow();
    this.dataService
      .getMemberBlog()
      .pipe()
      .subscribe((data) => {
        this.blogList = data["blog"];
        ////console.log(data['blog']);
        this.rerender();
        this.loadHide();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  memberBlogView(id) {
    this.router.navigate(["member-blogs-view/" + id]);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.blogDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Blog deleted successfully', true);
  //             this.allMemberBlog();
  //           }else{
  //             this.alertService.error('Blog error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //       }
  //    }

  delete(item) {
    this.loadShow();
    this.dataService
      .blogDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Blog deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allMemberBlog();
          } else {
            this.alertService.error("Blog deleted  error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    this.loadHide();
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
