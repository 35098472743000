import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchaseReportService {
    
    constructor(private base: BaseService) { }

    getReportData(data) {
        return this.base.post('/api/get-purchase-report-data',data);
    }    
}