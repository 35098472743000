import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityGroupsService {

 constructor(private base: BaseService) { }  
 
   getGroupDetails(id) {
     return this.base.post('/api/we-community-groups-details',{id:id});
   }
 
  
   getMaster(){
    return  this.base.get('/api/we-community-groups-list-master')
   }

   updateStatus(data) {
     return  this.base.post('/api/we-community-group-status ', data);
   }
 
  //  deleteMentor(id){
  //    return this.base.post('/api/delete-mentor',{id:id});
  //  }
  }