import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/";
import { AlertService, VerticalsService, AppService } from "../_services/index";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";

class Verticals {
  id: number;
  name: string;
  description: string;
  is_active: string;
}

@Component({
  selector: "app-verticals",
  templateUrl: "./verticals.component.html",
  styles: [],
})
export class VerticalsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    columnDefs: [{ targets: [6], orderable: false }],
  };
  dtTrigger = new Subject();
  verticalsList: Verticals[] = []; // Table Data list
  isSubmitted = false;
  // countryAddForm: FormGroup;
  getVerticals = {
    id: "",
    name: "",
    description: "",
    is_active: "N",
  };
  modalReference: NgbActiveModal;
  modalTitle: string;

  constructor(
    public router: Router,
    private dataService: VerticalsService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) {}

  verticals = {
    id: "",
    name: "",
    description: "",
    is_active: "Y",
  };

  showloding = true;
  lodingImage = false;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.allVerticals();
  }

  allVerticals() {
    this.loadShow();
    this.dataService
      .getAllVertical()
      .pipe()
      .subscribe((data) => {
        this.verticalsList = data["verticals"];
        this.rerender();
        this.loadHide();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  open(content) {
    this.isSubmitted = false;
    this.verticals.id = "";
    (this.verticals.name = ""),
      (this.verticals.description = ""),
      (this.verticals.is_active = "Y");
    this.modalTitle = "Add Verticals";
    this.modalReference = this.modalService.open(content);
  }

  edit(id, content) {
    this.loadShow();
    this.dataService
      .editVertical(id)
      .pipe()
      .subscribe((data) => {
        this.getVerticals = data["verticals"];
        this.verticals = {
          id: this.getVerticals.id,
          name: this.getVerticals.name,
          description: this.getVerticals.description,
          is_active: this.getVerticals.is_active,
        };
        this.loadHide();
        this.modalTitle = "Edit Verticals";
        this.modalReference = this.modalService.open(content);
      });
  }

  isFieldsInvalid(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  save(val, f) {
    this.isSubmitted = true;
    if (this.isFieldsInvalid(this.verticals.name)) {
      return;
    }
    if (f.form.valid) {
      this.loadShow();
      if (val.id == "") {
        this.dataService
          .save(this.verticals)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("Verticals saved successfully", true);
              this.allVerticals();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      } else {
        this.dataService
          .verticalUpdate(this.verticals)
          .pipe()
          .subscribe(
            (data) => {
              this.loadHide();
              this.modalReference.close();
              this.alertService.success("Verticals updated successfully", true);
              this.allVerticals();
            },
            (error) => {
              this.loadHide();
              this.alertService.error(error);
              document
                .querySelector(".modal")
                .scrollTo({ top: 0, behavior: "smooth" });
            }
          );
      }
    }
  }

  // delete(id){
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.verticalDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Verticals deleted successfully', true);
  //             this.allVerticals();
  //           }else{
  //             this.alertService.error('Verticals error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //   }
  // }

  delete(item) {
    this.loadShow();
    this.dataService
      .verticalDelete(item.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data["status"] == 200) {
            this.alertService.success("Verticals deleted successfully", true);
            item.is_active = item.is_active === "Y" ? "N" : "Y";
            this.allVerticals();
            this.loadHide();
          } else {
            this.alertService.error("Verticals error");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  loadShow() {
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide() {
    this.showloding = false;
    this.lodingImage = true;
  }
}
