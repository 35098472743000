<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Blogs</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{totalBlogList}}</h2>
									</div>
								</div>
								<div class="col-md-6">
									<div class="addbtn">
										<button id="add_btn" type="button" class="btn btn-primary addbtn"
											(click)="open(content)" style="float: right;">
											Add Blog
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
					 <div id="table" class="table-responsive">
						<table datatable id="schemes_table"  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
                                                        <th style="width:50%">Title</th>
                                                        <th>Blog Type</th>
                                                        <th>Created Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
									</tr>
								</thead>
									<tbody *ngIf="blogList?.length != 0">
                    <tr *ngFor="let item of blogList; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.blog_type}}</td>
                     
                      <td>{{item.created_at}}</td>


                      <td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span>
                        <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In
                          active</span>
                      </td>
                      <td class="action">
                        <label class="switch">
                          <input 
                            type="checkbox"
                            (change)="delete(item)"
                            [checked]="item.is_active === 'Y'">
                          <span class="slider round"></span>
                        </label>
                        <button type="button" class="btn text-dark btn-sm  rounded bg-light"
                          (click)="edit(this.blogList[i].id,content)"><i
                            class="fa fa-edit"></i></button>
                       </td>
                  </tr>
								</tbody>

								<tbody *ngIf="blogList?.length == 0">
									<tr>
										<td colspan="4" class="no-data-available text-center">No data available!</td>
									</tr>
								</tbody>
								
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<alert>
							<div class="alert alert-danger"> <a class="close">×</a></div>
						</alert>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group ">
										<label for="title" class="col-form-label">Title<span>*</span></label>
										<div class="">
											<input class="form-control" type="title" id="title" name="title" required
												[(ngModel)]="blog.title">
											<span *ngIf="isSubmitted && isFieldsInvalid(blog.title)"
												class="alert-block">This field is required</span>
										</div>
									</div>
								</div>



                <div class="col-md-6">
									<div class="form-group ">
										<label for="name_of_department" class="col-form-label">Name of Department
										</label>
										<div class="">
											<input class="form-control" type="text" id="name_of_department"
												name="name_of_department" 
												[(ngModel)]="blog.name_of_department">
										</div>
										
									</div>
							  </div>

               

							

								<div class="col-md-12">
									<div class="form-group ">
										<label for="short_description" class="col-form-label">Short Description
											<span>*</span></label>
										<div class="">
											<textarea class="form-control" type="text" id="short_description"
												name="short_description" required
												[(ngModel)]="blog.short_description"></textarea>
										</div>
										<span
											*ngIf="isSubmitted && isFieldsInvalid(blog.short_description)"
											class="alert-block">This field is required</span>
									</div>
							  </div>






                <div class="form-group col-md-6 align-self-end">
									<label for="gender" class="form-control-label">Gender <span>*</span></label>
									<div class="  ">
										<select class="form-control" id="gender" name="gender" required
											[(ngModel)]="blog.gender">
											<option value="" selected disabled>Select Gender</option>
                      <option value="male" >Male</option>
											<option value="female" >Female</option>

										</select>
										<span *ngIf="isSubmitted && (blog.gender==undefined || blog.gender=='')"
											class="alert-block">This field is required</span>
									</div>
							 </div>


               <div class="form-group col-md-6 align-self-end">
                <label for="target_audiences" class="form-control-label">Target Audience <span>*</span></label>
                <div class="  ">
                  <select class="form-control" id="target_audiences" name="target_audiences" required
                    [(ngModel)]="blog.target_audiences" >
                    <option value="" selected disabled>Select </option>
                    <option *ngFor="let item of targetaudience" [value]="item.id">{{item.name}}</option>

                  </select>
                  <span *ngIf="isSubmitted && (blog.target_audiences==undefined || blog.target_audiences=='')"
                    class="alert-block">This field is required</span>
                </div>
             </div>


               <div class="col-md-6">
                <div class="form-group ">
                  <label for="areaofinterest" class=" form-control-label">Area of
                    Interest<span>*</span></label>
                  <div class="">
                    <ng-select [items]="areaofinterest" name="areaofinterest" [multiple]="true"
                      bindLabel="name" bindValue="id" [(ngModel)]="selectedToInterest"
                      (change)="changeToInterest()" required>
                    </ng-select>
                    <span
                      *ngIf="isSubmitted && (blog.areaofinterest==undefined || blog.areaofinterest=='')"
                      class="alert-block">This field is required</span>
                  </div>
                </div>
              </div>


              
							 <div class="col-md-6">
                <div class="form-group ">
                  <label for="sectorofenterprise" class=" form-control-label">Sector of
                    Enterprise</label>
                  <div class="">
                    <ng-select [items]="sectorofenterprise" name="sectorofenterprise"
                      [multiple]="true" bindLabel="name" bindValue="id"
                      [(ngModel)]="selectedToEnterprise" (change)="changeToEnterprise()">
                    </ng-select>
                    
                  </div>
                </div>
              </div>


              <div class="col-md-6">
                <div class="form-group">
                  <label for="link_for_government_site" class=" col-form-label">Link for government site</label>
                  <div class="">
                    <textarea class="form-control" type="text" id="link_for_government_site"
                      name="link_for_government_site" [(ngModel)]="blog.link_for_government_site"></textarea>
                  </div>
                </div>
              </div>


              
            <div class="col-md-6">
              <div class="form-group">
                <label for="link_for_guideline" class=" col-form-label">Link for guideline</label>
                <div class="">
                  <textarea class="form-control" type="text" id="link_for_guideline"
                    name="link_for_guideline" [(ngModel)]="blog.link_for_guideline"></textarea>
                </div>
              </div>
            </div>
								

             
      <!-- <div class="form-group col-md-6">
        <label for="timeline" class="  col-form-label">Timeline </label>
        <div class=" ">
          <input class="form-control" type="date" id="timeline" name="timeline"
             [(ngModel)]="blog.timeline">
          
        </div>
      </div> -->

              <div class="col-md-6">
                <div class="form-group">
                  <label for="applicable_for" class=" col-form-label">Applicable for</label>
                  <div class="">
                    <textarea class="form-control" type="text" id="applicable_for"
                      name="applicable_for" [(ngModel)]="blog.applicable_for"></textarea>
                  </div>
                </div>
              </div>



               
                
								

								<div class="col-md-6">
									<div class="form-group">
										<label for="blog_type" class="col-form-label">Blog Type
											<span>*</span></label>
										<select class="form-control" id="blog_type" required name="blog_type"
											[(ngModel)]="blog.blog_type" required>
											<option value="" selected disabled>Please Select</option>
											<option *ngFor="let item of blogType" value="{{item}}">{{item}}</option>
											
										</select>
										<span *ngIf="isSubmitted && (blog.blog_type==undefined || blog.blog_type=='')"
											class="alert-block">This field is required</span>
									</div>
								</div> 


								

                  <div class="form-group col-md-6">
                    <label for="home_display" class="col-form-label">Home Display</label>
                    <div class="">
                      <select class="form-control" id="home_display"  name="home_display"
                        [(ngModel)]="blog.home_display">
                        <option value="N">No</option>
                        <option value="Y">Yes</option>
                      </select>
                    </div>
                  </div>
        
        
                  <div class="col-md-6 align-self-end">
                    <div class="form-group">
                      <label for="is_active" class="col-form-label">Status</label>
                      <div class="">
                        <select class="form-control" id="is_active" name="is_active"
                          [(ngModel)]="blog.is_active">
                          <option value="N">Inactive</option>
                          <option value="Y">Active</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class=" form-control-label">Image</label>
                      <div class="">
                        <img style="height: 100px;width: 100px;" class="img-thumbnail mb-3" *ngIf="croppedImage !=''"
                          [src]="croppedImage" />
                        <div class="input-group">
                          <span class="input-group-btn">
                          <label class="btn btn-dark">
                            <samp >Browse</samp>...
                            <input type="file" accept="{{fileType}}" style="display: none;"
                            (change)="onFileChange($event)" [attr.multiple]="allowMultiple">
                          </label>
                          </span>
                          <input type="text" class="form-control" [value]="DisplayedText" disabled=""
                          [class.ng-invalid-right]="required && !DisplayedText"
                          [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                        </div>
              
                        <ng-template #contentCrop let-c="close" let-d="dismiss" id="image_cropper">
                          <div class="modal-content">
                          <h3 class="text-center mt-2">Image Cropper</h3>
                          <div class="py-4 px-4">
                            <image-cropper [imageChangedEvent]="imageChangedEvent" [(ngModel)]="image_cropper"
                            [maintainAspectRatio]="true" [resizeToWidth]="416" [resizeToHeight]="200" format="png"
                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                            <button type="button" class="btn btn-dark form-btn" *ngIf="croppedImage"
                            href="javascript::void(0)" (click)="exit($event); closeCropper()"
                            style="margin-top: 10px;">Save</button>
                          </div>
                          </div>
                        </ng-template>
                        <!-- <span *ngIf="isSubmitted && (blog.image==undefined || blog.image=='')" class="alert-block">This
                          field is required</span> -->
                        </div>
                      </div>
                    </div>
        
                  <div class="col-md-12">
                    <div class="row gy-3">
                      <div class="col-md-12">
                        <label for="question" class="col-form-label">Blog FAQs</label>
                    
                      </div>
                      <div class="col-md-12">
                                <div *ngFor="let faq of faqs; let i = index" class="row mb-2">
                                 <div class="col-md-10">
                                  <div class="row align-items-end">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="question{{ i }}" class="col-form-label">Question {{ i + 1 }}</label>
                                        <textarea
                                          class="form-control"
                                          id="question{{ i }}"
                                          type="text"
                                          [(ngModel)]="faq.question"
                                          name="question{{ i }}"
                                          required
                                          #question="ngModel"
                                        ></textarea>
                                        
                                    </div>
                                  </div>
                  
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="answer{{ i }}" class="col-form-label">Answer {{ i + 1 }}</label>
                                        <textarea
                                          class="form-control"
                                          id="answer{{ i }}"
                                          type="text"
                                          [(ngModel)]="faq.answer"
                                          name="answer{{ i }}"
                                          required
                                          #answer="ngModel"
                                        ></textarea>
                  
                                      </div>
                                    </div>
                                  </div>
                                 </div>
                              
                                  <div class="col-md-2 d-flex align-items-center">
                                    <button
                                      *ngIf="faqs.length > 1"
                                      type="button"
                                      class="btn btn-danger fw-bold"
                                      (click)="removeFaq(i)"
                                    >
                                     Remove
                                    </button>
                                    <button
                                      *ngIf="i === faqs.length - 1"
                                      type="button"
                                      class="btn btn-dark fw-bold ml-2"
                                      (click)="addFaq()"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>      
                          </div>


                  <div class="col-md-12">
                    <div class="form-group ">
                        <label for="application_process" class="col-form-label">Application Process
                           </label>
                        <div class="">
                            <angular-editor [config]="configdescription"  id='application_process'
                                [(ngModel)]="blog.application_process" name="application_process"></angular-editor>
                           </div>
                    </div>
                </div>  
                   
                <div class="col-md-12">
                  <div class="form-group ">
                      <label for="description" class="col-form-label">Description <span>*</span>
                         </label>
                      <div class="">
                          <angular-editor [config]="configdescription" required  id='description'
                              [(ngModel)]="blog.description" name="description"></angular-editor>
                         </div>

                         <span *ngIf="isSubmitted && (blog.description==undefined || blog.description=='')"
                         class="alert-block">This field is required</span>
                  </div>
              </div>
                  
                  <div class="col-md-12">
                    <div class="form-group ">
                        <label for="documnets_required" class="col-form-label">Documents required
                           </label>
                        <div class="">
                            <angular-editor [config]="configdescription"  id='documnets_required'
                                [(ngModel)]="blog.documnets_required" name="documnets_required"></angular-editor>
                           </div>
                    </div>
                </div>
    
    
                <div class="col-md-12">
                  <div class="form-group ">
                      <label for="eligibility" class="col-form-label">Eligibility
                         </label>
                      <div class="">
                          <angular-editor [config]="configdescription"  id='eligibility'
                              [(ngModel)]="blog.eligibility" name="eligibility"></angular-editor>
                         </div>
                  </div>
              </div>
    
    
              <div class="col-md-12">
                <div class="form-group ">
                    <label for="terms_and_conditions" class="col-form-label">Repayment terms and conditions
                       </label>
                    <div class="">
                        <angular-editor [config]="configdescription"  id='terms_and_conditions'
                            [(ngModel)]="blog.terms_and_conditions" name="terms_and_conditions"></angular-editor>
                       </div>
                </div>
            </div>
    
    
            <div class="col-md-12">
              <div class="form-group ">
                  <label for="implementing_agency" class="col-form-label">Implementing agency
                     </label>
                  <div class="">
                      <angular-editor [config]="configdescription"  id='implementing_agency'
                          [(ngModel)]="blog.implementing_agency" name="implementing_agency"></angular-editor>
                     </div>
                </div>
             </div>

          
                      


          <!-- <div class="col-md-6">
            <div class="form-group">
              <label for="faq_link" class=" col-form-label">FAQ Link</label>
              <div class="">
                <textarea class="form-control" type="text" id="faq_link"
                  name="faq_link" [(ngModel)]="blog.faq_link"></textarea>
              </div>
            </div>
          </div> -->


							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary d-none" aria-label="Close"
							(click)="d('Cross click')">Close</button>
								<button type="submit" class="btn btn-primary savebtn" (click)="save(this.blog,f)">Save</button>
			   		</div>
				  </div>
			  </div>
		</ng-template>

  </div>
</div>
