import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SectorOfEnterpriseService {

  constructor(private base: BaseService) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.base.post('/api/sectorofenterprise-create', data);
  }

  getAllSectorofenterprise(){
    return  this.base.get('/api/all-sectorofenterprise');
  }

  getSectorofenterprise(id){
    return this.base.post('/api/get-sectorofenterprise',{id:id});
  }

  sectorofenterpriseUpdate(data){
    return this.base.post('/api/sectorofenterprise-update',data);
  }

  sectorofenterpriseDelete(id){
    return this.base.post('/api/sectorofenterprise-delete',{id:id});
  }
}
