<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>

<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12 headboxes">
				<div class="card p-0">
					<div class="card-block p-0">
						<div class="heading-box" style="background-image: url(assets/img/blog.png);">
                            <div class="row align-items-center">
								<div class="col-md-3">
									<div class="pagename">
										<h3 class="card-title">Member Blog</h3>
									</div>
								</div>
								<div class="col-md-3">
									<div class="total-count">
										<h5 class="m-0">Total</h5>
										<h2>{{blogList.length}}</h2>
									</div>
								</div>			
										
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div id="table" class="table-responsive">
							<table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" width="100%">
								<thead>
									<tr>
										<th style="width: 5%;">#Sl</th>
										<th style="width: 20%;">Title</th>
                                        <th>Approval Status</th>
										<th>Created By</th>
										<th>Created Date</th>
										<th >Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of blogList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.title}}</td>
										<td><span *ngIf="item.blog_status=='PENDING'">Approval Pending</span>
											<span *ngIf="item.blog_status=='SUBMITTED'">Approved</span>
											<span *ngIf="item.blog_status=='REJECTED'">Rejected</span>
											<span *ngIf="item.blog_status=='DRAFT'">Draft</span>
										</td>
										<td><span *ngIf="item.created_by_name=='admin'">WEP-Admin</span>
											<span *ngIf="item.created_by_name!='admin'">{{item.created_by_name}}</span>
										</td>
										<td>{{item.created_at}}</td>
										<td><span *ngIf="item.is_active=='Y'" class="badge badge-success badge-pill">Active</span> <span *ngIf="item.is_active=='N'" class="badge badge-danger badge-pill">In active</span></td>
										<td class="action">

											<label class="switch">
												<input 
													type="checkbox"
													(change)="delete(item)"
													[checked]="item.is_active === 'Y'">
												<span class="slider round"></span>
											</label>
                                            <button type="button" class="btn text-dark btn-sm  rounded bg-light" (click)="memberBlogView(this.blogList[i].id)"><i class="fa fa-eye"></i></button>&nbsp;
                                            <!-- <button class="ml-1 btn text-danger btn-sm rounded bg-light" (click)="delete(this.blogList[i].id)"><i class="fa fa-trash"></i></button> -->
                                        </td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>			
		</div>

	</div>
</div>