import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MentorListService {

  constructor(private base: BaseService) { }  

  getMentorInfo(id) {
    return this.base.post('/api/view-mentor',{id:id});
  }

  getAllMentor(){
    return  this.base.get('/api/get-mentor-list');
  }
  
  updateMentorStatus(data) {
    return  this.base.post('/api/update-mentor-status', data);
  }

  deleteMentor(id){
    return this.base.post('/api/delete-mentor',{id:id});
  }

}
