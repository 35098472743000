import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AlertService, ContactService,AppService } from '../_services/index';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {
	// settingAddForm: FormGroup;
	contact = {
	    id:'',
	    address: '',
	    email: '',
      lat: '',
	    long: ''
	 };

	constructor(
            public router: Router,
            private dataService:ContactService,
            private alertService: AlertService,
            private AppService: AppService,
           ) { }
  showloding = true;
  lodingImage = false;
	ngOnInit() {
	  this.contactData();
	}

	contactData(){
      this.loadShow();
    	this.dataService.getContactData()
          .pipe().subscribe(data => {
              let contact = data['contact_us'];
              this.contact = {
                id:contact.id,
                address:contact.address,                
                email:contact.email,
                lat:contact.lat,
                long:contact.long
              }
              this.loadHide();         
          });
    }  

  

    Update(){
      this.insertAction();
    }
  
  insertAction(){
    this.loadShow();
    let formData:FormData = new FormData();
    
    formData.append('id', this.contact.id);
    formData.append('address', this.contact.address);
    formData.append('email', this.contact.email);
    formData.append('lat', this.contact.lat);
    formData.append('long', this.contact.long);

    this.dataService.contactUpdate(formData)
    .pipe().subscribe(data =>{
          this.alertService.success('Contact updated successfully', true);
          this.contactData();
          this.loadHide();
        },error =>{
          this.alertService.error(error);
          this.loadHide();
    });
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
      this.showloding = false;
    this.lodingImage = true;
  }
}
