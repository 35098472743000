import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AtrCategoryService } from '../_services/atr-category.service';
import { AlertService } from '../_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../_services/index';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DataTableDirective } from 'angular-datatables';

class AtrCategory {
  id:number;
  name:string;
  description: string;
  is_active:string
  }

@Component({
  selector: 'app-atr-category',
  templateUrl: './atr-category.component.html',
  styleUrls: []
})

export class AtrCategoryComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";

  dtOptions: DataTables.Settings = {}; //  DataTable
  dtTrigger = new Subject(); //  DataTable
  AtrCategoryList: AtrCategory[] = []; // Table Data list
  isSubmitted = false;

  getAtrCategory = {
    name:'',
    description: '',
    is_active:'',
    id:1
 };
 
  closeResult: string;
  configbody: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ] 
  };
  constructor(
    public router: Router,
    private dataService:AtrCategoryService,
    private alertService: AlertService,
    private AppService: AppService,
    private modalService: NgbModal
  ) { }

  atrcategory = {
    name:'',
    description: '',
    is_active:'',
    id:1
 };

 modalReference:NgbActiveModal;
 modalTitle:string;
 showloding = true;
 lodingImage = false;
 positionChecked : string;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.allAtrCategory()
  }

  allAtrCategory(){
    this.loadShow();
    this.dataService.getAllAtrCategory()
        .pipe().subscribe(
          data => { 
          this.AtrCategoryList = data['atr_category'];
          //console.log(this.AtrCategoryList)
          this.rerender(); // Data Table  
          this.loadHide();
      });
    }



    rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }  

    ngAfterViewInit(): void {
      this.dtTrigger.next();
    }
  


     open(content) {
      this.isSubmitted = false;
      this.atrcategory.name = '';
      this.atrcategory.description = '';
      this.atrcategory.is_active = '';      

      this.DisplayedText = '';
      this.modalTitle = "Add Atr Category";
      this.modalReference = this.modalService.open(content);
     }

    
	  save(val,f){
     this.isSubmitted = true;
    
      if(f.form.valid) {
      this.loadShow()
		  this.insertAction(val);
      } else {
      document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
      }
	   }


   edit(id,content){
      this.loadShow();
      this.DisplayedText = '';
        this.dataService.getAtrCategory(id)
            .subscribe(data => { this.getAtrCategory = data['banners']; 
                this.atrcategory = {
                      name:this.getAtrCategory.name,
                      description:this.getAtrCategory.description,
                      is_active:this.getAtrCategory.is_active,
                      id:this.getAtrCategory.id,
  
                   };
                this.loadHide();
                this.modalTitle = "Edit Award To Reward";
                this.modalReference = this.modalService.open(content);
         });
     }  


   insertAction(val){
          let formData:FormData = new FormData();
         
          formData.append('name',this.atrcategory.name);
          formData.append('description',this.atrcategory.description);
          formData.append('is_active', this.atrcategory.is_active);
      
          if(val.id!==''){
            this.dataService.save(formData)
                .pipe().subscribe(data =>{
                    this.modalReference.close();
                    this.allAtrCategory();
                    this.alertService.success('ATR created successfully', true);
                },error =>{
                  this.alertService.error(error);
                  document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
                });
          }else{
            formData.append('id', val.id);
            this.dataService.atrCategoryUpdate(formData)
                .pipe().subscribe(data =>{
                  this.modalReference.close();
                  this.allAtrCategory();
                  this.alertService.success('ATR updated successfully', true);
                },error =>{
                  this.alertService.error(error);
                  document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
                });
              }
        }
      
    
  // delete(id){    
  //   if(confirm("Are you sure to delete")) {
  //     this.dataService.atrCategoryDelete(id)
  //         .pipe().subscribe(data =>{
  //           if(data['status']==200){
  //             this.alertService.success('Banners deleted successfully', true);
  //             this.allAtrCategory();
  //           }else{
  //             this.alertService.error('Banners error');
  //           }
  //         },error =>{
  //           this.alertService.error(error);
  //         });
  //       }
  //     }


      delete(item){
        this.loadShow();
        this.dataService.atrCategoryDelete(item.id)
            .pipe().subscribe(data =>{
              if(data['status']==200){
                this.alertService.success('Atr Category deleted successfully', true);
                item.is_active = item.is_active === 'Y' ? 'N' : 'Y';
                this.allAtrCategory();
              }else{
                this.alertService.error('Atr Category error');
              }
            },error =>{
              this.alertService.error(error);
            });
        this.loadHide();
    
      }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

}
